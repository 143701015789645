import moment from "moment"
import { Button, Text, View } from "native-base"
import React from "react"
import { useSelector } from "react-redux"
import Service from "../../../../services/Service"
import { formatAmount, formatNegativeAmount } from "../../../../utils/functions"
import Download from "../../../svg/download"
import styles from "../styles"

const StatementData_v1 = ({ statement }) => {
  const data = {
    "Purchase & Debits": {
      amount: statement.purchasesAndDebits,
      sign: "+",
    },
    Payments: {
      amount: statement.payments,
      sign: "-",
    },
    "Refunds & Credits": {
      amount: statement.refundsAndCredits,
      sign: "-",
    },
    Fees: {
      amount: statement.fees,
      sign: "+",
    },
    "Finance Charges": {
      amount: statement.financeCharges,
      sign: "+",
    },
    Taxes: {
      amount: statement.taxes,
      sign: "+",
    },
    Cashback: {
      amount: statement.cashback,
      sign: "-",
    },
  }

  const [theme, user, session] = useSelector(state => [
    state.theme,
    state.user,
    state.session,
  ])

  const downloadStatement = async (id, fileName) => {
    window.location.href = `${Service.defaults.baseURL}/accounts/${user.account.id}/download-statement/${id}?sessionToken=${session.sessionToken}&fileName=${fileName}`
  }

  return (
    <View style={styles.statementDataHeader} backgroundColor={"#ffffff"}>
      <View px='2' marginBottom={5}>
        <View px='2' style={styles.summaryHeader}>
          <Text fontSize='14px' fontFamily={theme.fontFamily} fontWeight={400}>
            Summary
          </Text>
          <View width='18px' height='16px'>
            <Button
              width='13.5px'
              height='12px'
              p='0'
              bgColor={"#ffffff"}
              borderWidth='0'
              onPress={() =>
                downloadStatement(
                  statement.id,
                  moment(statement.toDate).format("MMM YY"),
                )
              }
            >
              <Download />
            </Button>
          </View>
        </View>
        <View background='#FFF' px='4' py='2' borderRadius={5}>
          <View style={styles.statementDetails}>
            <Text width='70%' {...styles.statementFontCommonStyles}>
              Opening Balance
            </Text>
            <Text
              width='30%'
              textAlign='right'
              {...styles.statementAmountFontCommonStyles}
            >
              {formatNegativeAmount(
                formatAmount(
                  statement.openingBalance.totalBalance === 0
                    ? 0
                    : statement.openingBalance.totalBalance * -1,
                ),
              )}
            </Text>
          </View>
          {Object.keys(data).map((key, statement) =>
            data[key].amount === undefined ||
            data[key].amount === null ? null : (
              <View style={styles.statementDetails} key={key}>
                <Text
                  width='40%'
                  {...styles.statementFontCommonStyles}
                  key={key}
                >
                  {key}
                </Text>
                <Text
                  width='20%'
                  textAlign='center'
                  {...styles.statementAmountFontCommonStyles}
                >
                  {data[key].sign}
                </Text>
                <Text
                  width='30%'
                  textAlign='right'
                  {...styles.statementAmountFontCommonStyles}
                >
                  {formatNegativeAmount(formatAmount(data[key].amount))}
                </Text>
              </View>
            ),
          )}
          <View borderBottomWidth={1} mb='2' borderColor='#BDBDBD' />
          <View style={styles.statementDetails}>
            <Text
              width='50%'
              {...styles.statementFontCommonStyles}
              fontWeight={700}
            >
              Total Amount Due
            </Text>
            <Text
              width='50%'
              textAlign='right'
              {...styles.statementAmountFontCommonStyles}
            >
              {formatNegativeAmount(formatAmount(statement.initialTAD))}
            </Text>
          </View>

          {statement.initialMAD !== undefined &&
            statement.initialMAD !== null && (
              <View style={styles.statementDetails}>
                <Text width='50%' {...styles.statementFontCommonStyles}>
                  Min. Amount Due
                </Text>
                <Text
                  width='50%'
                  textAlign='right'
                  {...styles.statementAmountFontCommonStyles}
                >
                  {formatNegativeAmount(formatAmount(statement.initialMAD))}
                </Text>
              </View>
            )}
        </View>
      </View>
    </View>
  )
}

export default StatementData_v1
