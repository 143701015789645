const styles = {
  mainContainer: {
    width: "100%",
    background: "#fff",
  },
  centering: {
    alignItems: "center",
    justifyContent: "center",
  },

  heading: {
    fontFamily: "Inter",
    fontWeight: "500",
    fontSize: "18px",
    lineHeight: "24px",
    color: "#303030",
  },
}

export default styles
