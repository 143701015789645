import { Header } from "../../core"
import { useWindowDimensions } from "react-native-web"
import styles from "./styles.css"
import { useDispatch, useSelector } from "react-redux"
import { useState } from "react"
import { Radio } from "../../../core/Radio"
import { ColorButton } from "../../core/buttons"
import { captureEvents, formatAmount } from "../../../utils/functions"
import upi from "../../../assets/images/upi.svg"
import qrScan from "../../../assets/images/qr.svg"
import paymentSuccessIcon from "../../../assets/images/paymentSuccess.svg"
import netBanking from "../../../assets/images/netBanking.svg"
import ArrowRight from "../../svg/arrowRight"
import successTick from "../../../assets/images/successTick.svg"
import { useNavigate } from "react-router-dom"
import AccountService from "../../../services/AccountService"
import { EventName } from "../../../utils/enums"
import {
  setPgTransaction,
  setPgVPA,
} from "../../../store/actions/PaymentGateway"

const options = ["@ybi", "@paytm", "@apl", "@axl", "@aubank", "@ilb"]

const PaymentSuccess = () => {
  const windowDim = useWindowDimensions()
  const [inputId, setInputId] = useState("")
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const [theme, user, paymentGateway] = useSelector(state => [
    state.theme,
    state.user,
    state.paymentGateway,
  ])

  const handleProceed = async () => {
    captureEvents({
      eventName: EventName.PAY_BILL_SUCCESS_CTA,
      metadata: { action: "payment completed", method: "UPI" },
    })
    navigate("/")
  }

  return (
    <div
      style={{
        height: windowDim.height,
        backgroundColor: theme.backgroundColor,
      }}
    >
      <Header
        text='Bill Payment'
        onBack={async () => {
          window.history.go(-1)
        }}
      />

      <div className='mainContainer' style={{ color: theme.appTextColor }}>
        <div className='paymentSuccessContentContainer'>
          <div className='paymentSuccessIcon'>
            <img src={paymentSuccessIcon} />
          </div>

          <div className='paymentSuccessTitle'>Payment Successful</div>

          <div className='paymentSuccessDesc'></div>

          <div className='paymentSuccessAmountPaid'>
            <div className='paymentSuccessAmountPaidText'>Amount Paid</div>

            <div className='paymentSuccessAmountPaidAmount'>
              {formatAmount(paymentGateway.amountPayable)}
            </div>
          </div>
        </div>
      </div>
      <div className='paymentGatewayBtnContainer'>
        <ColorButton text='Done' onPress={() => handleProceed()} />
      </div>
    </div>
  )
}

export default PaymentSuccess
