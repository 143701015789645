import { Image, Text, View, useToast } from "native-base"
import React, { useEffect, useState } from "react"
import Confetti from "react-confetti"
import { useWindowDimensions } from "react-native"
import { useDispatch, useSelector } from "react-redux"
import { useLocation, useNavigate } from "react-router-dom"
import settings from "../../assets/images/settings.svg"
import ApiIds from "../../auth/ApiIds"
import AccountService from "../../services/AccountService"
import CardService from "../../services/CardService"
import { clearCommonState, setCommonState } from "../../store/actions/Common"
import { setFirstTimeLoaded } from "../../store/actions/Session"
import {
  setAccount,
  setCards,
  setCustomer,
  setSummary,
  setRewardPoints,
  setactivationCodeFromURL,
} from "../../store/actions/User"
import { handleApiAuth } from "../../utils/auth"
import { DisplayConfig, ProgramTypes } from "../../utils/constants"
import {
  AU_BlockCodes,
  EventName,
  Federal_BlockCodes,
  PwaVersions,
} from "../../utils/enums"
import {
  captureEvents,
  consoleError,
  goToRedirectUrl,
} from "../../utils/functions"
import Preloader_v2 from "../PreLoader/preloader_v2"
import SkeletonLoader from "../core/SkeletonLoader/homeLoader"
import { Header } from "../core/headers"
import { showToast1 } from "../core/toast"
import ErrorScreen from "../messages/errorScreen"
import Settings from "../svg/settings"
import ActivitySection_v1 from "./Components/ActivitySection/ActivitySection_v1"
import ActivitySection_v2 from "./Components/ActivitySection/ActivitySection_v2"
import CardAndBalance_v1 from "./Components/CardAndBalance/CardAndBalance_v1"
import CardAndBalance_v2 from "./Components/CardAndBalance/CardAndBalance_v2"
import TransactionSection_v1 from "./Components/TransactionSection/TransactionSection_v1"
import TransactionSection_v2 from "./Components/TransactionSection/TransactionSection_v2"
import styles from "./styles"
import Loader from "../core/loader"
import { version } from "dompurify"
import SmartNudges from "../core/Nudge/smartNudges"
import CardInHand from "../svg/cardInHand"
import Paths from "../../routes/Paths"
import NudgeLoader from "../core/SkeletonLoader/nudgeLoader"
import ArrowRight from "../svg/arrowRight"
import moment from "moment"

const Home = () => {
  const [theme, user, common, session, config, screen, featureFlags] =
    useSelector(state => [
      state.theme,
      state.user,
      state.common,
      state.session,
      state.config,
      state.screen,
      state.featureFlags,
    ])

  const windowDimensions = useWindowDimensions()
  const dispatch = useDispatch()
  const nativeToast = useToast()
  const location = useLocation()
  const navigate = useNavigate()

  const account = user.account

  const options = {
    ALL: "ALL",
    UNBILLED: "UNBILLED",
  }
  const isAll = theme?.transactionViewType === options.ALL
  const card = user.cards?.[0]
  const [viewStatementByBlockCode, setViewStatementByBlockCode] = useState(true)

  useEffect(() => {
    if (
      session.disabledFunctionsByBlockCode.includes(
        AU_BlockCodes.LOGIN_DISABLE,
      ) ||
      session.disabledFunctionsByBlockCode.includes(
        AU_BlockCodes.REGISTRATION_DISABLE,
      ) ||
      session.disabledFunctionsByBlockCode.includes(
        AU_BlockCodes.ENTIRE_PWA_ACCOUNT_CARD_DISABLE,
      ) ||
      session.disabledFunctionsByBlockCode.includes(
        AU_BlockCodes.ENTIRE_PWA_ACCOUNT_DISABLE,
      )
    ) {
      navigate("/blocked")
    }

    if (
      session.disabledFunctionsByBlockCode.includes(
        Federal_BlockCodes.ENTIRE_PWA_ACCOUNT_CARD_DISABLE,
      ) ||
      session.disabledFunctionsByBlockCode.includes(
        Federal_BlockCodes.ENTIRE_PWA_ACCOUNT_DISABLE,
      )
    ) {
      navigate("/blocked")
    }
  }, [])
  useEffect(() => {
    if (
      session.disabledFunctionsByBlockCode.includes(
        AU_BlockCodes.CARD_DASHBOARD_DISABLE,
      ) ||
      session.disabledFunctionsByBlockCode.includes(
        AU_BlockCodes.VIEW_STATEMENT_DISABLE,
      ) ||
      session.disabledFunctionsByBlockCode.includes(
        Federal_BlockCodes.VIEW_STATEMENT_DISABLE,
      )
    ) {
      if (user.programType !== ProgramTypes.PREPAID) {
        setViewStatementByBlockCode(false)
      }
    }
  }, [])
  const ViewTransaction = () => {
    if (user.programType === ProgramTypes.PREPAID) navigate("/Transactions")
    else if (isAll) navigate("/ViewTransactions")
    else navigate("/Statement")
  }
  const { isHomeSet, hasConfetti, summaryData } = common

  const isAccountActive =
    account.status === "ACTIVE" || account.status === "DORMANT"

  const [creditDetails, setCreditDetails] = useState({})
  // eslint-disable-next-line
  const [cardDetails, setCardDetails] = useState({})
  // eslint-disable-next-line
  const [showReminder, setShowReminder] = useState({})
  // eslint-disable-next-line
  const [offersList, setOffersList] = useState({})
  // eslint-disable-next-line
  const [recentTransactions, setRecentTransactions] = useState([])

  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(false)
  const [allNudges, setAllNudges] = useState()
  const [nudges, setNudges] = useState(null)
  const [nudgeLoading, setNudgeLoading] = useState(false)
  const [unbilledTransaction, setUnbilledTransactions] = useState(null);

  const toast = (message, hasTick = false) => {
    showToast1({ nativeToast, theme, message, hasTick: hasTick })
  }
  const rewards = screen?.home?.reward

  useEffect(() => {
    ; (async () => {
      if (!isHomeSet) {
        dispatch(clearCommonState())
        // if user coming from activation flow then first call activate api
        if (
          location.pathname.includes("/activated") ||
          location.state?.from === "CardActivated"
        ) {
          // activate card
          await handleApiAuth({
            apiId: ApiIds.ACTIVATE_CARD,
            onAuthSuccess: onActivateAuthSuccess,
            onAuthFailure: onActivateAuthFailure,
            onAuthCancel: onActivateAuthCancel,
            otpReason: "to activate card",
            mpinReason: "to activate card",
            toast,
            navigate,
          })
        } else {
          // else directly call summary api
          await handleApiAuth({
            apiId: ApiIds.ACCOUNT_SUMMARY,
            onAuthSuccess: onSummaryAuthSuccess(false),
            onAuthFailure: onSummaryAuthFailure,
            onAuthCancel: onSummaryAuthCancel,
            otpReason: "to get account summary",
            mpinReason: "to get account summary",
            toast,
            navigate,
          })
        }

        if (
          rewards?.aggregateDefinitionId &&
          rewards?.redirectionType === "HF_CASHBACK") {
          RewardPoints()
        } else if (
          rewards?.aggregateDefinitionId &&
          rewards?.redirectionType === "HF_REWARDS"
        ) {
          HfRewardPoints()
        }
      } else {
        getAllNudges()
        dispatch(
          setSummary({
            summary: summaryData,
          }),
        )

        const customer = summaryData.account.customer
        const account = summaryData.account
        const card = summaryData.primaryCard

        // update user store
        dispatch(setCustomer({ customer }))
        dispatch(setAccount({ account }))
        dispatch(setCards({ cards: [card] }))

        setRecentTransactions(summaryData.latestTransactions)

        setCreditDetails({
          availableCredit: account.availableCreditLimit,
          totalCredit: account.approvedCreditLimit,
          availableCash: account.availableCashLimit,
          currentBalance: account.currentBalance,
        })
        setOffersList(summaryData.offers)
        if (summaryData.lastStatement?.dueDate) {
          setShowReminder({
            show: parseFloat(summaryData.lastStatement.balanceAmountDue) > 0,
            amount: summaryData.lastStatement.balanceAmountDue,
            dueDate: summaryData.lastStatement.dueDate,
            graceDate: summaryData.lastStatement.graceDate,
            fromDate: summaryData.lastStatement.fromDate,
            toDate: summaryData.lastStatement.toDate,
          })
        }

        setCardDetails({
          orientation: theme.cardOrientation,
          image: theme.cardDesignUrl,
          name:
            customer?.firstname +
            (customer?.middlename && " " + customer?.middlename) +
            (customer?.lastname && " " + customer?.lastname),
          cardNo: card.maskedCardNumber,
          CVV: "655",
          Expiry: card.cardExpiry,
        })
      }
      dispatch(
        setFirstTimeLoaded({
          isLoadedFirstTime: false,
        }),
      )

      setLoading(false)
    })()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isHomeSet])

  useEffect(() => {
    return () => {
      // on leaving summary page clear common state, we don't want summary data to be cached
      dispatch(clearCommonState())
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])


  const addReplaceCardNudge = arr => {
    let replaceCardNudge = {
      name: "Request New Card",
      description: "Your Card has been blocked, get a new card",
      displayOptions: {
        displayOrder: 10001,
        id: "",
        nudgeDetails: `{\"pwaVersion\":\"v2\",\"location\":\"TopBorder\",\"titleColor\":\"${theme.color1
          }\",\"secondaryColor\":\"${theme.appTextColor}\",\"displayColor\":\"${theme.widgetBackgroundColor
          }\",\"redirectionUrl\":\"${onNavigationReplaceCard()}\",\"illustrationUrl\":\"${(
            <CardInHand />
          )}\"}`,
        requireType: "MANDATORY",
      },
    }
    arr.push(replaceCardNudge)
    return arr
  }

  const addActivatePhysicalCardNudge = arr => {
    let activatePhysicalCardNudge = {
      name: "Activate Physical Card",
      description: "Enable offline usage of your card",
      displayOptions: {
        displayOrder: 10000,
        id: "",
        nudgeDetails: `{\"pwaVersion\":\"v2\",\"location\":\"TopBorder\",\"primaryColor\":\"${theme.appTextColor
          }\",\"secondaryColor\":\"${theme.appTextColor}\",\"displayColor\":\"${theme.widgetBackgroundColor
          }\",\"redirectionUrl\":\"${onClickNavigation()}\",\"illustrationUrl\":\"${(
            <CardInHand />
          )}\"}`,
        requireType: "MANDATORY",
      },
    }
    arr.push(activatePhysicalCardNudge)
    return arr
  }

  const onClickNavigation = () => {
    if (
      config.version === PwaVersions.V1 ||
      ((config?.version === PwaVersions.V2 ||
        config?.version === PwaVersions.V2_1) &&
        screen?.home?.isQrCodeRequiredForActivation)
    ) {
      return "/ActivateCard/Scanner"
    } else {
      return Paths.HOME_WITH_ACTIVATE
    }
  }

  const onNavigationReplaceCard = () => {
    return session.disabledFunctionsByBlockCode.includes(
      AU_BlockCodes.REPLACE_CARD_DISABLE,
    ) ||
      session.disabledFunctionsByBlockCode.includes(
        Federal_BlockCodes.REPLACE_CARD_DISABLE,
      )
      ? ""
      : "/ManageCard/NewCardRequest"
  }
  const onActivateAuthSuccess = async apiToken => {
    let isActivateSuccess = false
    try {
      const activationCode = localStorage.getItem("activationCode")
      localStorage.removeItem("activationCode")

      const response = await CardService.activateCard(
        apiToken,
        user.cards[0]?.id,
        {
          activationCode,
        },
      )
      const result = response.data

      if (result?.success) {
        // card state will be updated by summary api later

        isActivateSuccess = true
      } else {
        consoleError(result?.errors)
        toast("An error occurred while activating your card")
      }
    } catch (error) {
      consoleError(error)
      toast("An error occurred while activating your card")
    }

    // call summary api
    await handleApiAuth({
      apiId: ApiIds.ACCOUNT_SUMMARY,
      onAuthSuccess: onSummaryAuthSuccess(isActivateSuccess),
      onAuthFailure: onSummaryAuthFailure,
      onAuthCancel: onSummaryAuthCancel,
      otpReason: "to get account summary",
      mpinReason: "to get account summary",
      toast,
      navigate,
    })
  }

  const nudgesDisplayHandling = nudgesResponse => {
    setNudgeLoading(true)
    let removedNudges = JSON.parse(localStorage.getItem("removedNudges"))
    if (removedNudges) {
      removedNudges = removedNudges[account.id]
    }
    let nudgesDelivery = JSON.parse(localStorage.getItem("nudgesDelivery"))

    if (nudgesDelivery) {
      nudgesDelivery = nudgesDelivery[account.id]
    }
    let nudgesToBeRemoved = []
    if (nudgesDelivery) {
      const nudgeValueInLocalStorage = {
        ...nudgesDelivery,
      }
      const nudgeIds = Object.keys(nudgesDelivery)
      nudgeIds.forEach(nudgeId => {
        const nudgeDataFromResponse =
          nudgesResponse?.filter?.(nudge => nudge.id === nudgeId)?.[0] || {}

        const sessions = nudgeValueInLocalStorage[nudgeId]?.sessions || []
        const clickCount = nudgeValueInLocalStorage[nudgeId]?.clickCount || 0

        const nudgeDeliveryEligibilityList =
          nudgeDataFromResponse?.displayConstraints
        if ((nudgeDeliveryEligibilityList || [])?.length > 0) {
          nudgeDeliveryEligibilityList?.forEach(nudgeDelivery => {
            if (nudgeDelivery.entityType === "DELIVERY_LOGIC") {
              if (nudgeDelivery.entityAttribute === "CTR_COUNT") {
                if (nudgeDelivery.filter.type === "LT") {
                  if (clickCount >= nudgeDelivery.filter.value) {
                    nudgesToBeRemoved.push(nudgeId)
                  }
                }
                if (nudgeDelivery.filter.type === "LTE") {
                  if (clickCount + 1 > nudgeDelivery.filter.value) {
                    nudgesToBeRemoved.push(nudgeId)
                  }
                }
              }

              if (nudgeDelivery.entityAttribute === "IMPRESSION_COUNT") {
                if (nudgeDelivery.filter.type === "LT") {
                  if (sessions >= nudgeDelivery.filter.value) {
                    nudgesToBeRemoved.push(nudgeId)
                  }
                }
                if (nudgeDelivery.filter.type === "LTE") {
                  if (sessions + 1 > nudgeDelivery.filter.value) {
                    nudgesToBeRemoved.push(nudgeId)
                  }
                }
              }
            }
          })
        }
      })
    }

    if (nudgesToBeRemoved.length > 0) {
      nudgesToBeRemoved = Array.from(new Set(nudgesToBeRemoved))
    }

    if (removedNudges && removedNudges?.length > 100) {
      removedNudges = nudgesResponse?.filter(nudge =>
        removedNudges.includes(nudge.id),
      )
      removedNudges.forEach(nudge => {
        removedNudges.push(nudge.id)
      })
      localStorage.setItem("removedNudges", JSON.stringify(removedNudges))
    }
    const result = nudgesResponse?.filter(
      nudge =>
        !removedNudges?.includes(nudge.id) &&
        !nudgesToBeRemoved.includes(nudge.id),
    )
    setNudges(result)
    setNudgeLoading(false)
  }

  const onSummaryAuthSuccess = isCardActivated => async apiToken => {
    try {
      if(user.activationCode){
        navigate("/ActivateCard/Scanner", {state: {activationCode: user.activationCode} })
        dispatch(setactivationCodeFromURL({
          activationCode: null,
        }))
        return false;
      }
      setNudgeLoading(true)
      const response = await AccountService.getSummary(
        apiToken,
        user.account.id,
        config?.version,
      )
      const result = response.data

      if (result?.success) {
        dispatch(
          setCommonState({
            summaryData: result.data,
            isHomeSet: true,
          }),
        )
    
        setAllNudges(result.data.nudges)
        // nudgesDisplayHandling(result.data.nudges)
        // navigate and show confetti
        if (isCardActivated) {
          toast("Physical Card Activated Successfully")
          dispatch(setCommonState({ hasConfetti: true }))
          setTimeout(() => {
            dispatch(setCommonState({ hasConfetti: false }))
          }, 5000)
        }

        navigate("/", { replace: true })
      } else {
        consoleError(result?.errors)
        setError(true)
      }
    } catch (error) {
      consoleError(error)
      setError(true)
    }
    setNudgeLoading(false)
  }

  const getProgramType = user => {
    if (user.programType === ProgramTypes.CREDIT_CARD) return " credit "
    else if (user.programType === ProgramTypes.PAY_LATER) return " pay later "
    else return " "
  }
  const RewardPoints = async () => {
    try {
      const response = await AccountService.getRewardPoints({
        accessorType: "HYPERFACE",
        accessorId: "hyperface",
        aggregateDefinitionId: rewards?.aggregateDefinitionId,
        entityId: user.account.id,
        showHistoricalFlag: "true",
      })
      const result = response.data
      if (result?.success) {
        dispatch(
          setRewardPoints({
            aggregate: result?.data[0],
          }),
        )
      } else {
        consoleError(result?.errors)
        // setError(true)
      }
    } catch (error) {
      consoleError(error)
      // setError(true)
    }
  }
  useEffect(() =>{
    if( (unbilledTransaction || unbilledTransaction == 0 ) && common  && JSON.stringify(common).length > 5 ){
      handleCaptureEvents(
        common?.summaryData,
        common?.summaryData?.nudges,
        common?.summaryData?.offers,
      )
    }
  }, [unbilledTransaction, common])
  
  const handleCaptureEvents = (dataSummary, nudges, offers) => {
    let data = {
      nudge: nudges,
      cardStatus: user?.cards[0]?.isHotlisted ?  "Blocked"  : user?.cards[0]?.isLocked ? "Temp Blocked" : user?.cards[0]?.cardStatus ,
      billDue: dataSummary?.lastStatement?.totalAmountDue > 0 ? "Yes" : "No",
      dueDate: dataSummary?.lastStatement?.totalAmountDue > 0 ?  moment(dataSummary?.lastStatement?.dueDate).format("DD-MM-YYYY") : "NA",
      unbilledTransactions: unbilledTransaction > 10 ? "10+" : unbilledTransaction ,
    }
    captureEvents({ eventName: EventName.HOME, metadata: data })
  }

  const HfRewardPoints = async () => {
    try {
      const response = await AccountService.getHfRewardPoints({
        accountId: user.account.id,
      })
      const result = response.data
      if (result?.success) {
        dispatch(
          setRewardPoints({
            aggregate: result?.data,
          }),
        )
      } else {
        consoleError(result?.errors)
        // setError(true)
      }
    } catch (error) {
      consoleError(error)
      // setError(true)
    }
  }
  useEffect(() => {
    nudgesDisplayHandling(common?.summaryData?.nudges)
  }, [common])

  const getAllNudges = async () => {
    // try {
    //   setNudgeLoading(true)
    //   const response = await AccountService.getAllNudges(user.account.id)
    //   const result = response.data
    //   if (result?.success) {
    //     handleCaptureEvents(summaryData, result?.data)
    //     // isAccountActive &&
    //     //   card &&
    //     //   !card.isHotlisted &&
    //     //   (user.programType === ProgramTypes.PREPAID
    //     //     ? card?.cardType === "Virtual"
    //     //       ? !card?.isVirtualCardActivated
    //     //       : !card?.isPhysicalCardActivated
    //     //     : !card?.isPhysicalCardActivated) &&
    //     //   // addActivatePhysicalCardNudge(result?.data)
    //     //   isAccountActive &&
    //     //   card &&
    //     //   card.isHotlisted &&
    //     //   addReplaceCardNudge(result?.data)
    //     setAllNudges(result.data)
    //   } else {
    //     handleCaptureEvents(summaryData, [])
    //   }
    //   // else {
    //   //   let emptyArr = []
    //   //   isAccountActive &&
    //   //     card &&
    //   //     !card.isHotlisted &&
    //   //     (user.programType === ProgramTypes.PREPAID
    //   //       ? card?.cardType === "Virtual"
    //   //         ? !card?.isVirtualCardActivated
    //   //         : !card?.isPhysicalCardActivated
    //   //       : !card?.isPhysicalCardActivated) &&
    //   //     addActivatePhysicalCardNudge(emptyArr)
    //   //   isAccountActive &&
    //   //     card &&
    //   //     card.isHotlisted &&
    //   //     addReplaceCardNudge(emptyArr)
    //   //   setAllNudges(emptyArr)
    //   //   consoleError(result?.errors)
    //   // }
    // } catch (error) {
    //   consoleError(error)
    //   setError(true)
    // }
    // setNudgeLoading(false)
  }

  const handleSetUnbilledTransaction = (data) =>{
    setUnbilledTransactions(data)
  }
  const onSummaryAuthFailure = async (error, message) => {
    error && consoleError(error)
    // message && toast(message)
    navigate("/Error", { replace: true })
  }

  const onSummaryAuthCancel = async message => {
    message && toast(message)
    navigate("/", { replace: true })
  }

  const onActivateAuthFailure = async (error, message) => {
    error && consoleError(error)
    message && toast(message)
    navigate("/", { replace: true })
  }

  const onActivateAuthCancel = async message => {
    message && toast(message)
    navigate("/", { replace: true })
  }

  const versioned_home = () => {
    switch (config.version) {
      case PwaVersions.V1:
        return (
          <View _web={{ maxW: DisplayConfig.MAX_WIDTH }} w='100%'>
            {hasConfetti && (
              <Confetti
                width={windowDimensions.width}
                height={windowDimensions.height}
                tweenDuration={2000}
              />
            )}
            <View
              mt='24px'
              mx='16px'
              flexDirection='row'
              justifyContent={"space-between"}
            >
              <View></View>
              <View {...styles.centering}>
                <Text {...styles.heading} fontFamily={theme.fontFamily}>
                  {theme.title}
                </Text>
              </View>
              <View>
                {user.programType === ProgramTypes.PREPAID && (
                  <View
                    onClick={() =>
                      isAccountActive && card ? navigate("/ManageCard") : null
                    }
                  >
                    <Image
                      source={{
                        uri: settings,
                      }}
                      width='24px'
                      height='24px'
                      alt='Alternate Text '
                    />
                  </View>
                )}
              </View>
            </View>
            <View mb='24px'>
              <CardAndBalance_v1
                showReminder={showReminder}
                cardDetails={cardDetails}
                creditDetails={creditDetails}
              />
            </View>
            <View {...styles.centering}>
              <ActivitySection_v1 />
            </View>
            {recentTransactions.length > 0 && (
              <View {...styles.centering}>
                <TransactionSection_v1
                  recentTransactions={recentTransactions}
                />
              </View>
            )}
          </View>
        )
      case PwaVersions.V2:
        return (
          <div
            className='home-v2'
            style={{
              minHeight: windowDimensions.height,
              backgroundColor: theme.backgroundColor,
              maxWidth: DisplayConfig.MAX_WIDTH,
            }}
          >
            {hasConfetti && (
              <Confetti
                width={windowDimensions.width}
                height={windowDimensions.height}
                tweenDuration={2000}
              />
            )}

            <Header
              text={theme.title}
              isHomePage={true}
              customClassName='header-heading'
              onBack={async () => {
                goToRedirectUrl()
              }}
              rightNavigateURL={isAccountActive && card ? "/ManageCard" : null}
              rightProp={<Settings />}
            />
            {/* <View
              py="12px"
              px="12px"
              flexDirection="row"
              justifyContent={"space-between"}
              backgroundColor={theme.headerBackgroundColor}
            >
              <View
                onClick={() => (window.location.href = session.redirectUrl)}
              >
                <ChevronLeftIcon size="6" color={theme.titleColor} />
              </View>
              <View {...styles.centering}>
                <Text
                  {...styles.heading}
                  fontFamily={theme.fontFamily}
                  color={theme.titleColor}
                >
                  {theme.title || "My Credit Card"}
                </Text>
              </View>
              <View>
                <View
                  onClick={() =>
                    isAccountActive && card ? navigate("/ManageCard") : null
                  }
                >
                  <Settings />
                </View>
              </View>
            </View> */}

            {nudgeLoading ? (
              <NudgeLoader />
            ) : nudges?.length > 0 ? (
              <SmartNudges nudgesData={nudges} />
            ) : (
              <></>
            )}
            <div className='home-cardAndBalance'>
              <CardAndBalance_v2
                showReminder={showReminder}
                cardDetails={cardDetails}
                creditDetails={creditDetails}
                offersList={offersList}
              />
            </div>

            <div className='home-transactionContainer'>
              <TransactionSection_v2  setUnbilledTransactions={handleSetUnbilledTransaction} recentTransactions={recentTransactions} />
            </div>
          </div>
        )

      case PwaVersions.V2_1:
        return (
          <div
            className='home-v2'
            style={{
              minHeight: windowDimensions.height,
              backgroundColor: theme.backgroundColor,
              maxWidth: DisplayConfig.MAX_WIDTH,
            }}
          >
            {hasConfetti && (
              <Confetti
                width={windowDimensions.width}
                height={windowDimensions.height}
                tweenDuration={2000}
              />
            )}

            <Header
              text={theme.title}
              isHomePage={true}
              onBack={async () => {
                goToRedirectUrl()
              }}
              rightNavigateURL={isAccountActive && card ? "/ManageCard" : null}
              rightProp={<Settings />}
            />
            {/* <View
                py="12px"
                px="12px"
                flexDirection="row"
                justifyContent={"space-between"}
                backgroundColor={theme.headerBackgroundColor}
              >
                <View
                  onClick={() => (window.location.href = session.redirectUrl)}
                >
                  <ChevronLeftIcon size="6" color={theme.titleColor} />
                </View>
                <View {...styles.centering}>
                  <Text
                    {...styles.heading}
                    fontFamily={theme.fontFamily}
                    color={theme.titleColor}
                  >
                    {theme.title || "My Credit Card"}
                  </Text>
                </View>
                <View>
                  <View
                    onClick={() =>
                      isAccountActive && card ? navigate("/ManageCard") : null
                    }
                  >
                    <Settings />
                  </View>
                </View>
              </View> */}
            {/* <div className='home-activityContainer'>
              <ActivitySection_v2 />
            </div> */}
            {nudgeLoading ? (
              <NudgeLoader />
            ) : nudges?.length > 0 ? (
              <SmartNudges nudgesData={nudges} />
            ) : (
              <></>
            )}
            <div className='home-cardAndBalance'>
              <CardAndBalance_v2
                showReminder={showReminder}
                cardDetails={cardDetails}
                creditDetails={creditDetails}
                offersList={offersList}
              />
            </div>

            <div className='home-transactionContainer'>
              <TransactionSection_v2 setUnbilledTransactions={handleSetUnbilledTransaction} recentTransactions={recentTransactions} />
            </div>
            {config?.version === PwaVersions.V2_1 &&
              theme?.transactionViewType === options.UNBILLED ? (
              <div
                onClick={() => {

                  captureEvents({
                    eventName: EventName.VIEW_STATEMENT_SUMMARY,
                    metadata: {},
                  })
                  viewStatementByBlockCode && ViewTransaction()
                }}
                className='home-transactionSection-unbilled-text-v2_1'
              >
                View your billed transactions
                <span
                  style={{ color: theme.color1, cursor: "pointer" }}
                  className='home-transactionSection-unbilled-text-v2_1-viewHere'
                >
                  {" "}
                  here{" "}
                  <span style={{ display: "inline-block" }}>
                    {" "}
                    <ArrowRight color={theme.color1} size={"9px"} />{" "}
                  </span>
                </span>
              </div>
            ) : (
              <></>
            )}
          </div>
        )
      default:
        return (
          <View _web={{ maxW: DisplayConfig.MAX_WIDTH }} w='100%'>
            {hasConfetti && (
              <Confetti
                width={windowDimensions.width}
                height={windowDimensions.height}
                tweenDuration={2000}
              />
            )}
            <View
              mt='24px'
              mx='16px'
              flexDirection='row'
              justifyContent={"space-between"}
            >
              <View></View>
              <View {...styles.centering}>
                <Text {...styles.heading} fontFamily={theme.fontFamily}>
                  {theme.title}
                </Text>
              </View>
              <View>
                {user.programType === ProgramTypes.PREPAID && (
                  <View
                    onClick={() =>
                      isAccountActive && card ? navigate("/ManageCard") : null
                    }
                  >
                    <Image
                      source={{
                        uri: settings,
                      }}
                      width='24px'
                      height='24px'
                      alt='Alternate Text '
                    />
                  </View>
                )}
              </View>
            </View>
            <View mb='24px'>
              <CardAndBalance_v1
                showReminder={showReminder}
                cardDetails={cardDetails}
                creditDetails={creditDetails}
              />
            </View>
            <View {...styles.centering}>
              <ActivitySection_v1 />
            </View>
            {recentTransactions.length > 0 && (
              <View {...styles.centering}>
                <TransactionSection_v1
                  recentTransactions={recentTransactions}
                />
              </View>
            )}
          </View>
        )
    }
  }


  return (
    <div className='home'>
      <div
        className='home-container'
        style={{ maxWidth: DisplayConfig.MAX_WIDTH }}
      >
        {error ? (
          <ErrorScreen />
        ) : loading &&
          session.isLoadedFirstTime &&
          (config?.version === PwaVersions.V2 ||
            config?.version === PwaVersions.V2_1) ? (
          <div
            className='home-preloaderContainer'
            style={{
              backgroundColor: `${config?.version === PwaVersions.V2 ||
                  config?.version === PwaVersions.V2_1
                  ? theme.backgroundColor
                  : "white"
                }`,
              height: windowDimensions.height,
            }}
          >
            <div className='home-preloader'>
              <Preloader_v2 />
            </div>
          </div>
        ) : loading ? (
          config.version === PwaVersions.V1 ? (
            <div
              className='home-loader'
              height={windowDimensions.height}
              style={{
                backgroundColor: `${config?.version === PwaVersions.V2 ||
                    config?.version === PwaVersions.V2_1
                    ? theme.backgroundColor
                    : "white"
                  }`,
                height: windowDimensions.height,
              }}
            >
              <Loader color={theme.color1} width={120} height={120} />
            </div>
          ) : (
            <div style={{ backgroundColor: theme.backgroundColor }}>
              <SkeletonLoader
                orientation={theme.cardOrientation}
                showRewards={screen?.home?.reward?.isEnabled}
              />
            </div>
          )
        ) : (
          <div className='home-versionContainer'>{versioned_home()}</div>
        )}
      </div>
    </div>
  )
}

export default Home
