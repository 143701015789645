import { React, useEffect, useState } from "react"
import { useWindowDimensions } from "react-native-web"
import { useDispatch, useSelector } from "react-redux"
import { useLocation, useNavigate } from "react-router-dom"
import { Header } from "../../../core"
import styles from "./styles"

import {
  capitalizeFirsLetter,
  captureEvents,
  consoleError,
  formatAmount,
  formatString,
  getTransactionDate,
  truncateString,
} from "../../../../utils/functions"
import { TRANSACTION_LOGO_WIDTH } from "../../../../utils/constants"
import { TRANSACTION_LOGO_HEIGHT } from "../../../../utils/constants"
import { setSpendCategory } from "../../../../store/actions/User"
import AccountService from "../../../../services/AccountService"
import moment from "moment"
import CustomLoader from "../../../core/loader"
import { EventName, PwaVersions } from "../../../../utils/enums"
import OtherLogo from "../../../../assets/images/Utility.svg"

const SpendCategory = () => {
  const [theme, config, user] = useSelector(state => [
    state.theme,
    state.config,
    state.user,
  ])
  const dispatch = useDispatch()
  const [isDescFull, setIsDescFull] = useState(false)
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(false)
  const location = useLocation()
  const state = location.state

  useEffect(() =>{
    captureEvents({eventName:EventName.SPEND_CATEGORY, metadata:{}})
  },[])


  useEffect(() => {
    spendCategoryTxns()
  }, [])
  let months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ]
  function convertDate(date_str) {
    if (
      !date_str ||
      (date_str == state.toDate && moment(date_str).month() == moment().month())
    ) {
      return "Today"
    }
    let temp_date = date_str.split("-")
    return temp_date[2] + " " + months[Number(temp_date[1]) - 1].substring(0, 3)
  }

  const spendCategoryTxns = async () => {
    try {
      setLoading(true)
      const response = await AccountService.getSpendCategory(
        user.account.id,
        state.spendGroupId,
        {
          startDate: state.fromDate,
          endDate: state.toDate,
        },
      )
      console.log(response)
      const result = response.data
      setLoading(false)
      if (result?.success) {
        console.log("Success")
        dispatch(
          setSpendCategory({
            spendCategory: result?.data,
          }),
        )
      } else {
        console.log(result?.errors)
        consoleError(result?.errors)
        setError(true)
      }
    } catch (error) {
      console.log("Error")
      consoleError(error)
      setError(true)
    }
  }
  const spendCategory = state?.spendCategory

  const transactions = user?.spendCategory?.transactions

  const windowDimensions = useWindowDimensions()

  return (
    <div
      style={{
        minHeight: windowDimensions.height,
        backgroundColor: theme.backgroundColor,
      }}
    >
      <Header
        text='Spend Analysis'
        onBack={async () => {
          window.history.go(-1)
        }}
      />
      <div className='spendCategory'>
        <div className='spendCategory-content'>
          {spendCategory.iconUrl ? (
            <img
              className='spendCategory-content-icon'
              src={spendCategory.iconUrl}
              alt='logo'
              width={TRANSACTION_LOGO_WIDTH + "px"}
              height={TRANSACTION_LOGO_HEIGHT + "px"}
            />
          ) : (
            <img
              className='spendCategory-content-icon'
              src={OtherLogo}
              alt='logo'
              width={TRANSACTION_LOGO_WIDTH + "px"}
              height={TRANSACTION_LOGO_HEIGHT + "px"}
            />
          )}
          <div className='spendCategory-content-categoryText'>
            {spendCategory?.categoryName}
          </div>
          <div
            className='spendCategory-content-amount'
            style={{ color: theme.appTextColor }}
            fontWeight={"bold"}
            fontSize={"32px"}
            color={theme.appTextColor}
          >
            {formatAmount(spendCategory?.totalSpend)}
          </div>
          <div
            className='spendCategory-content-date'
            style={{ color: theme.appTextColor }}
          >
            {convertDate(state.fromDate)} - {convertDate(state.toDate)}
          </div>
        </div>
        {loading ? (
          <div
            className='spendCategory-loader'
            style={{
              backgroundColor: `${
                config?.version === PwaVersions.V2 ||
                config?.version === PwaVersions.V2_1
                  ? theme.backgroundColor
                  : "white"
              }`,
            }}
          >
            <CustomLoader color={theme.color1} width={50} height={50} />
          </div>
        ) : (
          <div
            className='spendCategory-widget'
            style={{ backgroundColor: theme.widgetBackgroundColor }}
          >
            {transactions?.map((transaction, index) => (
              <div
                className='spendCategory-widget-transaction'
                style={{
                  marginBottom:
                    index === transactions.length - 1 ? "-12px" : "12px",
                  borderBottomWidth:
                    index === transactions.length - 1 ? null : "1px",
                }}
                key={index}
              >
                <div className='spendCategory-widget-transaction-icon-container'>
                  {transaction.identifiedMerchantLogo ? (
                    <img
                      className='spendCategory-widget-transaction-icon'
                      src={`data:image/jpg;base64,${transaction.identifiedMerchantLogo}`}
                      alt='logo'
                      width={TRANSACTION_LOGO_WIDTH + "px"}
                      height={TRANSACTION_LOGO_HEIGHT + "px"}
                    />
                  ) : (
                    <img
                      className='spendCategory-widget-transaction-icon'
                      src={OtherLogo}
                      alt='logo'
                      width={TRANSACTION_LOGO_WIDTH + "px"}
                      height={TRANSACTION_LOGO_HEIGHT + "px"}
                    />
                  )}
                </div>
                <div className='spendCategory-widget-transaction-content'>
                  <div>
                    <div
                      className='spendCategory-widget-transaction-content-description'
                      style={{
                        fontFamily: theme.fontFamily,
                        color: theme.appTextColor,
                      }}
                      onClick={() => setIsDescFull(!isDescFull)}
                    >
                      {isDescFull
                        ? formatString(capitalizeFirsLetter(transaction?.description))
                        : formatString(truncateString(
                            capitalizeFirsLetter(transaction?.description) ||
                              "",
                            12,
                          ))}
                    </div>
                    <div
                      className='spendCategory-widget-transaction-content-date'
                      style={{ color: theme.appTextColor }}
                    >
                      {getTransactionDate(
                        transaction.transactionDate,
                        transaction.postingDate,
                      )}
                    </div>
                  </div>

                  <div
                    className={
                      transaction.txnNature === "DEBIT"
                        ? "spendCategory-widget-transaction-content-debitAmount"
                        : "spendCategory-widget-transaction-content-creditAmount"
                    }
                  >
                    {transaction.txnNature === "DEBIT" ? "-" : "+"}
                    {formatAmount(transaction.transactionAmount)}
                  </div>
                </div>
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  )
}
export default SpendCategory
