import React, { useEffect } from "react"
import { useWindowDimensions } from "react-native"
import { View, Text, Image, ScrollView } from "native-base"
import { useSelector } from "react-redux"
import { useNavigate } from "react-router-dom"
import styles from "../styles"
import cardImg from "../../../../../assets/images/Card_Illustration.png"

import { ColorButton, WhiteButton } from "../../../../core/buttons"
import { DisplayConfig } from "../../../../../utils/constants"

const BlockCard_v1 = () => {
  const navigate = useNavigate()
  const window = useWindowDimensions()

  const [theme, user] = useSelector(state => [state.theme, state.user])

  useEffect(() => {
    if (
      user.account.status !== "ACTIVE" ||
      !user.cards[0] ||
      user.cards[0].isHotlisted
    ) {
      navigate("/ManageCard/NewCardRequest")
    }
  }, [navigate, user.account.status, user.cards])
  const proceedBlockCard = () => {
    navigate("/ManageCard/BlockCard/Reason")
  }
  const cancel = () => {
    navigate("/ManageCard")
  }

  return (
    <View alignItems='center' justifyContent='center'>
      <View _web={{ maxW: DisplayConfig.MAX_WIDTH }} w='100%'>
        <ScrollView>
          <View
            {...styles.mainContainer}
            height={window.height}
            minHeight='560px'
          >
            <View>
              {/* body content start */}
              <View {...styles.body}>
                <Text {...styles.header} fontFamily={theme.fontFamily}>
                  {" "}
                  Block Card
                </Text>
              </View>
              {/* body title end */}

              {/* content box start */}
              <View {...styles.heroSection}>
                <View alignItems='center'>
                  <Image
                    source={{ uri: cardImg }}
                    width='196px'
                    height='120px'
                    alt='Alternate Text '
                  />
                </View>
                {/* content box Image End */}
                <View {...styles.heroTextSection}>
                  <View {...styles.textPara}>
                    <Text {...styles.text} fontFamily={theme.fontFamily}>
                      Blocking your card will stop all future purchase
                      transactions permanently.
                    </Text>
                  </View>
                  <View {...styles.textPara}>
                    <Text {...styles.text} fontFamily={theme.fontFamily}>
                      You can request an entirely new card post blocking, which
                      will be immediately issued to you
                    </Text>
                  </View>
                </View>
                {/* content box Text end */}
              </View>
              {/* content box start */}

              {/* body content end */}
            </View>

            {/*    CTA Section */}
            <View {...styles.CTAsection}>
              <ColorButton
                text='Proceed to Block Card'
                isDisable={false}
                onPress={proceedBlockCard}
              />
              <WhiteButton text='Cancel' onPress={cancel} />
            </View>
          </View>
        </ScrollView>
      </View>
    </View>
  )
}

export default BlockCard_v1
