import { ChevronRightIcon, Text, useToast, View } from "native-base"
import React, { useEffect } from "react"
import { useSelector } from "react-redux"
import { useNavigate } from "react-router-dom"
import {
  AU_BlockCodes,
  EventName,
  Federal_BlockCodes,
  PwaVersions,
  eventConfig,
} from "../../../../utils/enums"
import {
  captureEvents,
  formatAmount,
  formatNegativeAmount,
} from "../../../../utils/functions"
import { showToast1 } from "../../../core/toast"
import styles from "../styles"
import moment from "moment"
import { ColorButton, WhiteButton } from "../../../core/buttons"

const StatementData_v2_new = ({ statement }) => {
  const navigate = useNavigate()
  const data = {
    Payment: {
      amount: statement.payments,
      sign: "-",
    },
    "Refunds/Credits": {
      amount: statement.refundsAndCredits,
      sign: "-",
    },
    "Purchase/Debits": {
      amount: statement.purchasesAndDebits,
      sign: "+",
    },
    Cashback: {
      amount: statement.cashback,
      sign: "-",
    },
  }

  const nativeToast = useToast()
  const toast = message => {
    showToast1({ nativeToast, theme, message })
  }

  const [theme, session, config, featureFlags] = useSelector(state => [
    state.theme,
    state.session,
    state.config,
    state.featureFlags,
  ])

  useEffect(() => {
    if (
      session.disabledFunctionsByBlockCode.includes(
        AU_BlockCodes.CARD_DASHBOARD_DISABLE,
      ) ||
      session?.disabledFunctionsByBlockCode?.includes(
        AU_BlockCodes.VIEW_STATEMENT_DISABLE,
      ) ||
      session?.disabledFunctionsByBlockCode?.includes(
        Federal_BlockCodes.VIEW_STATEMENT_DISABLE,
      )
    ) {
      toast("Access has been blocked for \nStatement Page")
      navigate("/")
    }
  }, [])
  return (
    <div className='statementData'>
      <div
        className='statementData-header'
        style={{ color: theme.appTextColor, fontFamily: theme.fontFamily }}
      >
        {config?.version === PwaVersions.V2_1
          ? `Summary ${moment(statement.fromDate).format("Do MMM")} to ${moment(
              statement.toDate,
            ).format("Do MMM")}`
          : "Summary"}
      </div>
      <div className='statementData-container'>
        <div className='statementData-openingBalance'>
          <div
            className='statementData-openingBalance-text'
            style={{ color: theme.appTextColor }}
          >
            Opening Balance
          </div>
          <div
            className='statementData-openingBalance-amount'
            style={{ color: theme.appTextColor }}
          >
            {formatNegativeAmount(
              formatAmount(
                statement.openingBalance.totalBalance === 0
                  ? 0
                  : statement.openingBalance.totalBalance * -1,
              ),
            )}
          </div>
        </div>
        {Object.keys(data).map((key, statement) =>
          data[key].amount === undefined || data[key].amount === null ? null : (
            <div className='statementData-details' key={key}>
              <div
                className='statementData-details-text'
                style={{ color: theme.appTextColor }}
                key={key}
              >
                {key}
              </div>

              <div
                className={
                  data[key].sign === "+"
                    ? "statementData-details-sign-credit"
                    : "statementData-details-sign-debit"
                }
                style={{
                  backgroundColor: `${theme.appTextColor}10`,
                  color: theme.appTextColor,
                }}
              >
                {data[key].sign}
              </div>
              <div
                className='statementData-details-amount'
                style={{ color: theme.appTextColor }}
              >
                {formatNegativeAmount(formatAmount(data[key].amount))}
              </div>
            </div>
          ),
        )}
        <div className='statementData-totalDetails' />
        <div className='statementData-totalDetails-content'>
          <div
            className='statementData-totalDetails-content-text'
            style={{ color: theme.appTextColor, textTransform: "uppercase" }}
          >
            Total Amount Due
          </div>
          <div
            className='statementData-totalDetails-content-amount'
            style={{ color: theme.appTextColor }}
          >
            {formatNegativeAmount(formatAmount(statement.initialTAD))}
          </div>
        </div>

        {statement.initialMAD !== undefined &&
          statement.initialMAD !== null && (
            <div className='statementData-minAmount'>
              <div
                className='statementData-minAmount-text'
                style={{
                  color: theme.appTextColor,
                  textTransform: "uppercase",
                }}
              >
                Min. Amount Due
              </div>
              <div
                className='statementData-minAmount-amount'
                style={{ color: theme.appTextColor }}
              >
                {formatNegativeAmount(formatAmount(statement.initialMAD))}
              </div>
            </div>
          )}

        {statement.initialMAD !== undefined &&
          statement.initialMAD !== null && (
            <div className='statementData-dueDate'>
              <div
                className='statementData-dueDate-text'
                style={{
                  color: theme.appTextColor,
                  textTransform: "uppercase",
                }}
              >
                Due Date
              </div>
              <div
                className='statementData-dueDate-date'
                style={{ color: theme.appTextColor }}
              >
                {moment(statement.dueDate).format("DD MMM YYYY")}
              </div>
            </div>
          )}

        {config?.version !== PwaVersions.V2_1 && (
          <div
            className='statementData-viewTransaction'
            onClick={() => {
              captureEvents({
                eventName: EventName.STATEMENT_SUMMARY_SCREEN_CTA,
                metadata: { action: "view statement transactions" },
              })
              navigate("/Transactions", {
                state: { from: statement.fromDate, to: statement.toDate },
              })
            }}
          >
            <div
              className='statementData-viewTransaction-text'
              style={{ color: theme.color1 }}
            >
              View Statement Transactions
              <ChevronRightIcon color={theme.color1} size={5} />
            </div>
          </div>
        )}
        {config.version === PwaVersions.V2_1 && (
          <div className='statementData-payBill-button'>
            <ColorButton
              text={"Pay Bill"}
              onPress={() => {
                featureFlags?.isUPIFlowEnabled
                  ? navigate("/PaymentGateway")
                  : window.open(session.repaymentUrl)
                captureEvents({
                  eventName: EventName.PAY_BILL,
                  metadata: {
                    source: eventConfig[EventName.STATEMENT]?.eventName,
                  },
                })
              }}
            />
          </div>
        )}
        {config.version === PwaVersions.V2_1 && (
          <div className='statementData-transaction-button'>
            <WhiteButton
              text={"View Billed Transactions"}
              onPress={() => {
                captureEvents({
                  eventName: EventName.VIEW_BILLED_TRANSACTIONS,
                  metadata: {},
                })
                navigate("/Transactions", {
                  state: { from: statement.fromDate, to: statement.toDate },
                })
              }}
            />
          </div>
        )}
      </div>
    </div>
  )
}

export default StatementData_v2_new
