import { Image, View } from "native-base"
import React from "react"
import {
  default as cashBack,
  default as Refund,
} from "../../assets/images/transactionLogos/cashback.svg"
import cashWithdrawal from "../../assets/images/transactionLogos/cashWithdrawal.svg"
import customerTxnCatchAll from "../../assets/images/transactionLogos/customerTxnCatchAll.svg"
import emi from "../../assets/images/transactionLogos/emi.svg"
import fee from "../../assets/images/transactionLogos/fee.svg"
import interest from "../../assets/images/transactionLogos/interest.svg"
import payment from "../../assets/images/transactionLogos/payment.svg"
import surcharge from "../../assets/images/transactionLogos/surcharge.svg"
import tax from "../../assets/images/transactionLogos/tax.svg"

import {
  TRANSACTION_LOGO_HEIGHT,
  TRANSACTION_LOGO_WIDTH,
} from "../../utils/constants"

const TransactionLogo = props => {
  const image = type => {
    switch (type) {
      case "REFUND":
        return Refund
      case "REFUND_REVERSAL":
        return Refund
      case "PAYMENT":
        return payment
      case "PAYMENT_REVERSAL":
        return Refund
      case "CASHBACK":
        return cashBack
      case "CASHBACK_REVERSAL":
        return Refund
      case "REPAYMENT":
        return payment
      case "REPAYMENT_REVERSAL":
        return Refund
      case "FEE":
        return fee
      case "FEE_REVERSAL":
        return Refund
      case "TAX":
        return tax
      case "TAX_REVERSAL":
        return Refund
      case "INTEREST":
        return interest
      case "INTEREST_REVERSAL":
        return Refund
      case "SURCHARGE":
        return surcharge
      case "SURCHARGE_REVERSAL":
        return Refund
      case "EMI":
        return emi
      case "CASH_WITHDRAWAL_REVERSAL":
        return Refund
      case "CASH_WITHDRAWAL":
        return cashWithdrawal
      case "SETTLEMENT_DEBIT_CASH":
        return cashWithdrawal
      case "SETTLEMENT_CREDIT_CASH":
        return cashWithdrawal
      case "ADD_FUND":
        return payment
      default:
        return customerTxnCatchAll
    }
  }

  return (
    <View width={"100%"}>
      <Image
        width={TRANSACTION_LOGO_WIDTH + "px"}
        height={TRANSACTION_LOGO_HEIGHT + "px"}
        src={image(props.transactionType)}
        alt='transaction logo'
      />
    </View>
  )
}

export default TransactionLogo
