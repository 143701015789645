import {
    SET_USER,
    SET_CUSTOMER,
    SET_PG_CONFIG,
    SET_PG_VPA,
    SET_PG_TRANSACTION,
    SET_PG_AMOUNT,
  } from "../ActionTypes"
  
  export const setPgConfig = data => dispatch => {
    dispatch({
      type: SET_PG_CONFIG,
      payload: data,
    })
  }
  
  export const setPgVPA = data => dispatch => {
    dispatch({
      type: SET_PG_VPA,
      payload: data,
    })
  }

  export const setPgTransaction = data => dispatch => {
    dispatch({
      type: SET_PG_TRANSACTION,
      payload: data,
    })
  }

  export const setPgAmount = data => dispatch => {
    dispatch({
      type: SET_PG_AMOUNT,
      payload: data,
    })
  }