import React from "react"
import { useSelector } from "react-redux"
import { Icon } from "native-base"

const BlockReplace = () => {
  const theme = useSelector(state => state.theme)
  return (
    <Icon viewBox='0 0 32 32' size='32px'>
      <svg
        width='32'
        height='32'
        viewBox='0 0 32 32'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <path
          fill={theme.appTextColor}
          className="manageCard-blockReplaceCard-icon-svg-path "
          d='M16.0001 2.66675C8.64813 2.66675 2.66675 8.64813 2.66675 16.0001C2.66675 23.352 8.64813 29.3334 16.0001 29.3334C23.352 29.3334 29.3334 23.352 29.3334 16.0001C29.3334 8.64813 23.352 2.66675 16.0001 2.66675ZM16.0001 4.66675C18.7734 4.66675 21.2764 5.69573 23.2436 7.34253L7.34253 23.2423C5.69612 21.2752 4.66675 18.773 4.66675 16.0001C4.66675 9.72901 9.72901 4.66675 16.0001 4.66675ZM24.6576 8.75659C26.3044 10.7238 27.3334 13.2268 27.3334 16.0001C27.3334 22.2712 22.2712 27.3334 16.0001 27.3334C13.2271 27.3334 10.725 26.3041 8.75789 24.6576L24.6576 8.75659Z'
        />
      </svg>
    </Icon>
  )
}

export default BlockReplace
