import { useToast } from "native-base"
import React, { lazy, Suspense, useEffect, useState } from "react"
import { useWindowDimensions } from "react-native"
import { useDispatch, useSelector } from "react-redux"
import { useNavigate } from "react-router-dom"
import ApiIds from "../../../../auth/ApiIds"
import CardService from "../../../../services/CardService"
import {
  clearCommonState,
  setCommonState,
} from "../../../../store/actions/Common"
import { handleApiAuth } from "../../../../utils/auth"
import { DisplayConfig } from "../../../../utils/constants"
import { PwaVersions } from "../../../../utils/enums"
import { consoleError } from "../../../../utils/functions"
import Loader from "../../../core/loader"
import { showToast1 } from "../../../core/toast"
import CardDetailsWidget_v1 from "./CardDetailsWidget_v1"
// import CardDetailsWidget_v2 from "./CardDetailsWidget_v2"
import CardDetailsLoader from "../../../core/SkeletonLoader/cardDetailsLoader"
const CardDetailsWidget_v2 = lazy(() => import("./CardDetailsWidget_v2"))

const CardDetailsWidget = () => {
  const navigate = useNavigate()
  const window = useWindowDimensions()
  const dispatch = useDispatch()
  const nativeToast = useToast()
  const [theme, user, common, config] = useSelector(state => [
    state.theme,
    state.user,
    state.common,
    state.config,
  ])

  const {
    isCardDetailsWidgetSet,
    fetchCardControlsData,
    fetchCardDetailsWidgetData,
    unmaskCardData,
  } = common

  const toast = message => {
    showToast1({ nativeToast, theme, message })
  }

  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(false)
  const [widgetDetails, setWidgetDetails] = useState(null)
  const [url, setUrl] = useState(null)
  const [isOnlineActive, setIsOnlineActive] = useState()

  useEffect(() => {
    if (
      user.account.status !== "ACTIVE" ||
      !user.cards[0] ||
      user.cards[0].isHotlisted ||
      user.cards[0].isLocked
    ) {
      navigate("/")
    }
  }, [user.account.status, user.cards, navigate])

  useEffect(() => {
    ;(async () => {
      if (!isCardDetailsWidgetSet) {
        dispatch(clearCommonState())

        await handleApiAuth({
          apiId: ApiIds.FETCH_CARD_CONTROLS,
          onAuthSuccess: onFetchCardControlsAuthSuccess,
          onAuthFailure: onAuthFailure,
          onAuthCancel: onAuthCancel,
          otpReason: "to get card controls",
          mpinReason: "to get card controls",
          toast,
          navigate,
        })
      } else {
        setIsOnlineActive(
          fetchCardControlsData?.filter(item => {
            return (
              item.labelsMap.SOVEREIGNTY_INDICATOR === "DOMESTIC" &&
              item.labelsMap.MCC === "RETAIL" &&
              item.labelsMap.POS_ENTRY_MODE === "ECOMMERCE"
            )
          })[0].isEnabled,
        )

        if (user.enablePciWidget) {
          setUrl(fetchCardDetailsWidgetData)
        } else {
          setWidgetDetails(unmaskCardData)
        }
      }
      setLoading(false)
    })()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isCardDetailsWidgetSet])

  const onFetchCardControlsAuthSuccess = async apiToken => {
    try {
      const response = await CardService.getCardControls(
        apiToken,
        user.cards[0]?.id,
      )
      const result = response.data

      if (result?.success) {
        dispatch(setCommonState({ fetchCardControlsData: result.data }))

        if (user.enablePciWidget) {
          await handleApiAuth({
            apiId: ApiIds.PCI_CARD_URL,
            onAuthSuccess: onFetchCardDetailsWidgetAuthSuccess,
            onAuthFailure: onAuthFailure,
            onAuthCancel: onAuthCancel,
            otpReason: "to get card details",
            mpinReason: "to get card details",
            toast,
            navigate,
          })
        } else {
          await handleApiAuth({
            apiId: ApiIds.FETCH_UNMASKED_CARD,
            onAuthSuccess: onUnmaskCardAuthSuccess,
            onAuthFailure: onAuthFailure,
            onAuthCancel: onAuthCancel,
            otpReason: "to get card details",
            mpinReason: "to get card details",
            toast,
            navigate,
          })
        }
      } else {
        consoleError(result?.errors)
        setError(true)
      }
    } catch (error) {
      consoleError(error)
      setError(true)
    }
  }

  const onFetchCardDetailsWidgetAuthSuccess = async apiToken => {
    try {
      const response = await CardService.getCardDetailsWidget(
        apiToken,
        user.cards[0]?.id,
        {
          callbackUrl: process.env.REACT_APP_URL + "/CardDetailsError",
        },
      )
      const result = response.data

      if (
        result?.success &&
        result?.data &&
        result.data?.startsWith("https://")
      ) {
        dispatch(
          setCommonState({
            isCardDetailsWidgetSet: true,
            fetchCardDetailsWidgetData: result.data,
          }),
        )
        navigate("/CardDetailsWidget", { replace: true })
      } else {
        consoleError(result?.errors)
        setError(true)
      }
    } catch (error) {
      consoleError(error)
      setError(true)
    }
  }

  const onUnmaskCardAuthSuccess = async apiToken => {
    try {
      const response = await CardService.unMaskCard(apiToken, user.cards[0]?.id)
      const result = response.data

      if (result?.success) {
        dispatch(
          setCommonState({
            isCardDetailsWidgetSet: true,
            unmaskCardData: result.data,
          }),
        )
        navigate("/CardDetailsWidget", { replace: true })
      } else {
        consoleError(result?.errors)
        setError(true)
      }
    } catch (error) {
      consoleError(error)
      setError(true)
    }
  }

  const onAuthFailure = async (error, message) => {
    error && consoleError(error)
    message && toast(message)
    navigate("/", { replace: true })
  }

  const onAuthCancel = async message => {
    message && toast(message)
    navigate("/", { replace: true })
  }

  return loading ? (
    config.version === PwaVersions.V1 ? (
      <div
        className='cardDetailsWidget-loader-v1'
        style={{
          backgroundColor: `${
            config?.version === PwaVersions.V2 ||
            config?.version === PwaVersions.V2_1
              ? theme.backgroundColor
              : "white"
          }`,
          height: window.height,
        }}
      >
        <Loader color={theme.color1} width={120} height={120} />
      </div>
    ) : (
      <div
        style={{
          backgroundColor: theme.backgroundColor,
          minHeight: window.height,
        }}
      >
        <CardDetailsLoader isImagePresent={config?.cardDetailsBannerImageUrl} />
      </div>
    )
  ) : (
    <div
      style={{ maxWidth: DisplayConfig.MAX_WIDTH, minHeight: window.height }}
    >
      {config?.version === PwaVersions.V2 ||
      config?.version === PwaVersions.V2_1 ? (
        <Suspense fallback={<CardDetailsLoader isImagePresent={config?.cardDetailsBannerImageUrl} />}>
          <CardDetailsWidget_v2
            user={user}
            theme={theme}
            error={error}
            widgetDetails={widgetDetails}
            url={url}
            isOnlineActive={isOnlineActive}
          />
        </Suspense>
      ) : (
        <CardDetailsWidget_v1
          user={user}
          theme={theme}
          error={error}
          widgetDetails={widgetDetails}
          url={url}
          isOnlineActive={isOnlineActive}
        />
      )}
    </div>
  )
}

export default CardDetailsWidget
