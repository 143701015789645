const styles = {
  image: {
    mx: "auto",
    mt: 60,
    width: "96px",
    height: "96px",
  },
  mainView: {
    backgroundColor: "#F5F4F5",
    py: "10",
    px: "5",
  },
  widgetErrorTextContainer: {
    alignItems: "center",
    mt: 10,
    mx: 5,
  },
  widgetErrorText: {
    fontWeight: "600",
    fontSize: "15px",
    lineHeight: "25px",
    color: "#7B7B7B",
  },
  header: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    marginBottom: 5,
  },
  title: {
    width: "100%",
    fontSize: 18,
    fontWeight: 500,
    textAlign: "center",
  },
  widget: {
    borderRadius: "8px",
    backgroundColor: "#fff",
    m: 1,
  },
  cardContainer: {
    m: "12px",
    alignSelf: "center",
    alignItems: "center",
    justifyContent: "center",
    mb: "16px",
  },
  error: {
    width: "100%",
    fontSize: 18,
    fontWeight: 500,
    textAlign: "center",
    mt: 20,
  },
}

export default styles
