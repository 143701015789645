import {
  ChevronDownIcon,
  ChevronLeftIcon,
  ChevronUpIcon,
  Input,
  KeyboardAvoidingView,
  Switch,
  Text,
  View,
  useToast,
} from "native-base"
import React, { useEffect, useRef, useState } from "react"
import { useWindowDimensions } from "react-native"
import { useDispatch, useSelector } from "react-redux"
import { Link, useNavigate } from "react-router-dom"
import ApiIds from "../../../../auth/ApiIds"
import Loader from "../../../../components/core/loader"
import CardService from "../../../../services/CardService"
import {
  clearCommonState,
  setCommonState,
} from "../../../../store/actions/Common"
import { setCards } from "../../../../store/actions/User"
import * as analytics from "../../../../utils/analytics"
import { handleApiAuth } from "../../../../utils/auth"
import {
  DisplayConfig,
  INTEGER_REGEX,
  ProgramTypes,
} from "../../../../utils/constants"
import {
  AU_BlockCodes,
  Federal_BlockCodes,
  PwaVersions,
} from "../../../../utils/enums"
import {
  consoleError,
  getAnalyticsProgramType,
} from "../../../../utils/functions"
import { Header } from "../../../core"
import Nudge from "../../../core/Nudge/nudge"
import { ColorButton, WhiteButton } from "../../../core/buttons"
import { showToast1 } from "../../../core/toast"
import ErrorScreen from "../../../messages/errorScreen"
import styles from "./styles"

// analytics
const getAnalyticsLocation = location => {
  if (location === "domestic") return "Dom"
  else if (location === "international") return "Intl"
  else return "Undefined Location"
}

const getAnalyticsKey = key => {
  if (key === "online") return "Online Transaction"
  else if (key === "offline") return "Offline Transaction"
  else if (key === "contactless") return "Contactless"
  else if (key === "cash") return "Cash Withdrawal"
  else return "Undefined Mode"
}

const trackBackClick = programType => {
  analytics.track(
    `${getAnalyticsProgramType(
      programType,
    )} - Click on back button (Card Controls)`,
  )
}

const trackCancelClick = programType => {
  analytics.track(
    `${getAnalyticsProgramType(programType)} - Click on Cancel (Card Controls)`,
  )
}

const trackCardStatus = (programType, cardStatus) => {
  analytics.track(
    `${getAnalyticsProgramType(programType)} - Click on Card Status`,
    {
      status: cardStatus ? "on" : "off",
    },
  )
}

const trackToggle = (programType, location, key, isEnabled, limit) => {
  const name = `${getAnalyticsProgramType(
    programType,
  )} - ${getAnalyticsLocation(location)} - ${getAnalyticsKey(key)}`

  analytics.track(name, {
    status: isEnabled ? "on" : "off",
    limit,
  })
}

const trackLimit = (programType, location, key, limit) => {
  const name = `${getAnalyticsProgramType(
    programType,
  )} - ${getAnalyticsLocation(location)} - limit - ${getAnalyticsKey(key)}`

  analytics.track(name, { limit: Number(limit) })
}

const trackSaveClick = (programType, cardStatus, cardSetting) => {
  const isPaylaterOrPrepaidProgram =
    programType === ProgramTypes.PAY_LATER ||
    programType === ProgramTypes.PREPAID

  const name = `${getAnalyticsProgramType(
    programType,
  )} - Click on Save Changes (Card Controls)`

  let props = {
    "Card Status": cardStatus ? "on" : "off",

    [getAnalyticsLocation("domestic")]: {
      [getAnalyticsKey("online")]: {
        status: cardSetting.domestic.online.isEnabled ? "on" : "off",
        limit: Number(cardSetting.domestic.online.dailyLimit),
      },
      [getAnalyticsKey("offline")]: {
        status: cardSetting.domestic.offline.isEnabled ? "on" : "off",
        limit: Number(cardSetting.domestic.offline.dailyLimit),
      },
      [getAnalyticsKey("contactless")]: {
        status: cardSetting.domestic.contactless.isEnabled ? "on" : "off",
        limit: Number(cardSetting.domestic.contactless.dailyLimit),
      },
    },
  }

  if (!isPaylaterOrPrepaidProgram) {
    props = {
      ...props,
      [getAnalyticsLocation("domestic")]: {
        ...props[getAnalyticsLocation("domestic")],
        [getAnalyticsKey("cash")]: {
          status: cardSetting.domestic.cash.isEnabled ? "on" : "off",
          limit: Number(cardSetting.domestic.cash.dailyLimit),
        },
      },
      [getAnalyticsLocation("international")]: {
        [getAnalyticsKey("online")]: {
          status: cardSetting.international.online.isEnabled ? "on" : "off",
          limit: Number(cardSetting.international.online.dailyLimit),
        },
        [getAnalyticsKey("offline")]: {
          status: cardSetting.international.offline.isEnabled ? "on" : "off",
          limit: Number(cardSetting.international.offline.dailyLimit),
        },
        [getAnalyticsKey("contactless")]: {
          status: cardSetting.international.contactless.isEnabled
            ? "on"
            : "off",
          limit: Number(cardSetting.international.contactless.dailyLimit),
        },
        [getAnalyticsKey("cash")]: {
          status: cardSetting.international.cash.isEnabled ? "on" : "off",
          limit: Number(cardSetting.international.cash.dailyLimit),
        },
      },
    }
  }

  analytics.track(name, props)
}

const CardSettings_v1 = () => {
  const nativeToast = useToast()
  const navigate = useNavigate()
  const windowDim = useWindowDimensions()

  const dispatch = useDispatch()
  const [theme, user, common, config, session] = useSelector(state => [
    state.theme,
    state.user,
    state.common,
    state.config,
    state.session,
  ])

  const account = user.account
  const card = user.cards?.[0]

  let approvedCashLimit = account.approvedCashLimit
  let approvedCreditLimit = account.approvedCreditLimit

  // common store vars
  const { isCardSettingsSet, fetchCardData, fetchCardControlsData } = common

  const isAccountActive = account.status === "ACTIVE"

  const [loading, setLoading] = useState(true)
  const [isDomesticOpen, setIsDomesticOpen] = useState(true)
  const [isInternationalOpen, setIsInternationalOpen] = useState(false)
  const [error, setError] = useState(false)
  const [submitLoading, setSubmitLoading] = useState(false)

  const [disableAllControls, setDisableAllControls] = useState(false)

  const [cardStatus, setCardStatus] = useState(true)
  const [cardSetting, setCardSetting] = useState()
  let initCardSettings = useRef({})

  const isPaylaterOrPrepaidProgram =
    user.programType === ProgramTypes.PAY_LATER ||
    user.programType === ProgramTypes.PREPAID

  useEffect(() => {
    if (
      session.disabledFunctionsByBlockCode.includes(
        AU_BlockCodes.CARD_DASHBOARD_DISABLE,
      ) ||
      session?.disabledFunctionsByBlockCode?.includes(
        AU_BlockCodes.UNBLOCK_CARD_DISABLE,
      ) ||
      session.disabledFunctionsByBlockCode.includes(
        Federal_BlockCodes.UNBLOCK_CARD_DISABLE,
      )
    ) {
      toast("Access has been blocked for \nCard Settings page")
      navigate("/")
      return false
    }

    if (
      session.disabledFunctionsByBlockCode.includes(
        AU_BlockCodes.TEMP_CARD_DISABLE,
      ) ||
      session.disabledFunctionsByBlockCode.includes(
        AU_BlockCodes.PERMANENT_CARD_DISABLE,
      ) ||
      session.disabledFunctionsByBlockCode.includes(
        Federal_BlockCodes.CARD_CONTROL_DISABLE,
      )
    ) {
      setDisableAllControls(true)
    }
  })

  useEffect(() => {
    if (
      user.account.status !== "ACTIVE" ||
      !user.cards[0] ||
      user.cards[0].isHotlisted
    ) {
      navigate("/")
    }
  }, [user.account.status, user.cards, navigate])

  useEffect(() => {
    ;(async () => {
      if (!isCardSettingsSet) {
        // if state is not set in store

        // clear common store
        dispatch(clearCommonState())

        // call init apis
        await handleApiAuth({
          apiId: ApiIds.FETCH_CARD,
          onAuthSuccess: onFetchCardAuthSuccess,
          onAuthFailure: onAuthFailure,
          onAuthCancel: onAuthCancel,
          otpReason: "to get card details",
          mpinReason: "to get card details",
          toast,
          navigate,
        })
      } else {
        const isLocked = fetchCardData.isLocked
        const cardControl = fetchCardControlsData

        // get current config
        let initConfig = {
          domesticOnline: cardControl.filter(item => {
            return (
              item.labelsMap.SOVEREIGNTY_INDICATOR === "DOMESTIC" &&
              item.labelsMap.MCC === "RETAIL" &&
              item.labelsMap.POS_ENTRY_MODE === "ECOMMERCE"
            )
          })[0],
          domesticOffline: cardControl.filter(item => {
            return (
              item.labelsMap.SOVEREIGNTY_INDICATOR === "DOMESTIC" &&
              item.labelsMap.MCC === "RETAIL" &&
              item.labelsMap.POS_ENTRY_MODE === "CONTACT"
            )
          })[0],
          domesticContactless: cardControl.filter(item => {
            return (
              item.labelsMap.SOVEREIGNTY_INDICATOR === "DOMESTIC" &&
              item.labelsMap.MCC === "RETAIL" &&
              item.labelsMap.POS_ENTRY_MODE === "CONTACTLESS"
            )
          })[0],
        }

        if (!isPaylaterOrPrepaidProgram) {
          initConfig = {
            ...initConfig,
            domesticCash: cardControl.filter(item => {
              return (
                item.labelsMap.SOVEREIGNTY_INDICATOR === "DOMESTIC" &&
                item.labelsMap.MCC === "CASH" &&
                item.labelsMap.POS_ENTRY_MODE === undefined
              )
            })[0],
            internationalOnline: cardControl.filter(item => {
              return (
                item.labelsMap.SOVEREIGNTY_INDICATOR === "INTERNATIONAL" &&
                item.labelsMap.MCC === "RETAIL" &&
                item.labelsMap.POS_ENTRY_MODE === "ECOMMERCE"
              )
            })[0],
            internationalOffline: cardControl.filter(item => {
              return (
                item.labelsMap.SOVEREIGNTY_INDICATOR === "INTERNATIONAL" &&
                item.labelsMap.MCC === "RETAIL" &&
                item.labelsMap.POS_ENTRY_MODE === "CONTACT"
              )
            })[0],
            internationalContactless: cardControl.filter(item => {
              return (
                item.labelsMap.SOVEREIGNTY_INDICATOR === "INTERNATIONAL" &&
                item.labelsMap.MCC === "RETAIL" &&
                item.labelsMap.POS_ENTRY_MODE === "CONTACTLESS"
              )
            })[0],
            internationalCash: cardControl.filter(item => {
              return (
                item.labelsMap.SOVEREIGNTY_INDICATOR === "INTERNATIONAL" &&
                item.labelsMap.MCC === "CASH" &&
                item.labelsMap.POS_ENTRY_MODE === undefined
              )
            })[0],
          }
        }

        // save current config
        initCardSettings.current = {
          cardStatus: !isLocked,

          domestic: {
            online: {
              isEnabled: initConfig.domesticOnline.isEnabled,
              dailyLimit: initConfig.domesticOnline.dailyTxnLimit?.limit || 0,
            },
            offline: {
              isEnabled: initConfig.domesticOffline.isEnabled,
              dailyLimit: initConfig.domesticOffline.dailyTxnLimit?.limit || 0,
            },
            contactless: {
              isEnabled: initConfig.domesticContactless.isEnabled,
              dailyLimit:
                initConfig.domesticContactless.dailyTxnLimit?.limit || 0,
            },
          },
        }

        if (!isPaylaterOrPrepaidProgram) {
          initCardSettings.current.domestic.cash = {
            isEnabled: initConfig.domesticCash.isEnabled,
            dailyLimit: initConfig.domesticCash.dailyTxnLimit?.limit || 0,
          }

          initCardSettings.current.international = {
            online: {
              isEnabled: initConfig.internationalOnline.isEnabled,
              dailyLimit:
                initConfig.internationalOnline.dailyTxnLimit?.limit || 0,
            },
            offline: {
              isEnabled: initConfig.internationalOffline.isEnabled,
              dailyLimit:
                initConfig.internationalOffline.dailyTxnLimit?.limit || 0,
            },
            contactless: {
              isEnabled: initConfig.internationalContactless.isEnabled,
              dailyLimit:
                initConfig.internationalContactless.dailyTxnLimit?.limit || 0,
            },
            cash: {
              isEnabled: initConfig.internationalCash.isEnabled,
              dailyLimit:
                initConfig.internationalCash.dailyTxnLimit?.limit || 0,
            },
          }
        }

        // set lock in state
        setCardStatus(!fetchCardData.isLocked)

        // set settings in state
        setCardSetting(() => {
          const setting = {
            domestic: {
              online: {
                label: "Online Transaction",
                ...initCardSettings.current.domestic.online,
              },
              offline: {
                label: "Offline Transaction",
                ...initCardSettings.current.domestic.offline,
              },
              contactless: {
                label: "Contactless",
                ...initCardSettings.current.domestic.contactless,
              },
            },
          }

          if (!isPaylaterOrPrepaidProgram) {
            setting.domestic.cash = {
              label: "Cash Withdrawal",
              ...initCardSettings.current.domestic.cash,
            }

            setting.international = {
              online: {
                label: "Online Transaction",
                ...initCardSettings.current.international.online,
              },
              offline: {
                label: "Offline Transaction",
                ...initCardSettings.current.international.offline,
              },
              contactless: {
                label: "Contactless",
                ...initCardSettings.current.international.contactless,
              },
              cash: {
                label: "Cash Withdrawal",
                ...initCardSettings.current.international.cash,
              },
            }
          }

          return setting
        })
      }
      setLoading(false)
    })()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isPaylaterOrPrepaidProgram, isCardSettingsSet])

  const haveCardControlsChanged = () => {
    return (
      // check domestic
      initCardSettings.current.domestic.online.isEnabled !==
        cardSetting.domestic.online.isEnabled ||
      initCardSettings.current.domestic.online.dailyLimit !==
        Number(cardSetting.domestic.online.dailyLimit) ||
      initCardSettings.current.domestic.offline.isEnabled !==
        cardSetting.domestic.offline.isEnabled ||
      initCardSettings.current.domestic.offline.dailyLimit !==
        Number(cardSetting.domestic.offline.dailyLimit) ||
      initCardSettings.current.domestic.contactless.isEnabled !==
        cardSetting.domestic.contactless.isEnabled ||
      initCardSettings.current.domestic.contactless.dailyLimit !==
        Number(cardSetting.domestic.contactless.dailyLimit) ||
      (!isPaylaterOrPrepaidProgram &&
        initCardSettings.current.domestic.cash.isEnabled !==
          cardSetting.domestic.cash.isEnabled) ||
      (!isPaylaterOrPrepaidProgram &&
        initCardSettings.current.domestic.cash.dailyLimit !==
          Number(cardSetting.domestic.cash.dailyLimit)) ||
      // check international
      (!isPaylaterOrPrepaidProgram &&
        initCardSettings.current.international.online.isEnabled !==
          cardSetting.international.online.isEnabled) ||
      (!isPaylaterOrPrepaidProgram &&
        initCardSettings.current.international.online.dailyLimit !==
          Number(cardSetting.international.online.dailyLimit)) ||
      (!isPaylaterOrPrepaidProgram &&
        initCardSettings.current.international.offline.isEnabled !==
          cardSetting.international.offline.isEnabled) ||
      (!isPaylaterOrPrepaidProgram &&
        initCardSettings.current.international.offline.dailyLimit !==
          Number(cardSetting.international.offline.dailyLimit)) ||
      (!isPaylaterOrPrepaidProgram &&
        initCardSettings.current.international.contactless.isEnabled !==
          cardSetting.international.contactless.isEnabled) ||
      (!isPaylaterOrPrepaidProgram &&
        initCardSettings.current.international.contactless.dailyLimit !==
          Number(cardSetting.international.contactless.dailyLimit)) ||
      (!isPaylaterOrPrepaidProgram &&
        initCardSettings.current.international.cash.isEnabled !==
          cardSetting.international.cash.isEnabled) ||
      (!isPaylaterOrPrepaidProgram &&
        initCardSettings.current.international.cash.dailyLimit !==
          Number(cardSetting.international.cash.dailyLimit))
    )
  }

  const hasStateChanged = () => {
    if (
      !Object.keys(initCardSettings.current).length ||
      !Object.keys(cardSetting).length
    )
      return false

    return (
      // check lock
      initCardSettings.current.cardStatus !== cardStatus ||
      haveCardControlsChanged()
    )
  }

  const toast = (message, hasTick = false) => {
    showToast1({ nativeToast, theme, message, hasTick })
  }

  const areControlsValid = () => {
    if (
      user.programType !== ProgramTypes.PREPAID &&
      approvedCashLimit &&
      approvedCashLimit
    ) {
      if (
        Number(cardSetting.domestic.online.dailyLimit) > approvedCreditLimit ||
        Number(cardSetting.domestic.offline.dailyLimit) > approvedCreditLimit
      ) {
        toast("Transaction limit cannot exceed " + approvedCreditLimit)
        return false
      }
      if (Number(cardSetting.domestic.cash.dailyLimit) > approvedCashLimit) {
        toast("Cash Transaction limit cannot exceed " + approvedCashLimit)
        return false
      }
      if (
        Number(cardSetting.international.online.dailyLimit) >
          approvedCreditLimit ||
        Number(cardSetting.international.offline.dailyLimit) >
          approvedCreditLimit
      ) {
        toast("Transaction limit cannot exceed " + approvedCreditLimit)
        return false
      }
      if (
        Number(cardSetting.international.cash.dailyLimit) > approvedCashLimit
      ) {
        toast("Cash Transaction limit cannot exceed " + approvedCashLimit)
        return false
      }

      if (
        Number(cardSetting.domestic.contactless.dailyLimit) >
        approvedCreditLimit
      ) {
        toast("Contactless limit cannot exceeed " + approvedCreditLimit)
        return false
      }
    }

    if (Number(cardSetting.domestic.online.dailyLimit <= 0)) {
      toast("Domestic online limit cannot be 0")
      return false
    }
    if (Number(cardSetting.domestic.online.dailyLimit) % 100 !== 0) {
      toast("limit must be a multiple of 100")
      return false
    }

    if (Number(cardSetting.domestic.offline.dailyLimit <= 0)) {
      toast("Domestic offline limit cannot be 0")
      return false
    }
    if (Number(cardSetting.domestic.offline.dailyLimit) % 100 !== 0) {
      toast("limit must be a multiple of 100")
      return false
    }

    if (Number(cardSetting.domestic.contactless.dailyLimit <= 0)) {
      toast("Domestic contactless limit cannot be 0")
      return false
    }
    if (Number(cardSetting.domestic.contactless.dailyLimit) % 100 !== 0) {
      toast("limit must be a multiple of 100")
      return false
    }

    if (!isPaylaterOrPrepaidProgram) {
      if (Number(cardSetting.domestic.cash.dailyLimit <= 0)) {
        toast("Domestic cash limit cannot be 0")
        return false
      }
      if (Number(cardSetting.domestic.cash.dailyLimit) % 100 !== 0) {
        toast("limit must be a multiple of 100")
        return false
      }

      if (Number(cardSetting.international.online.dailyLimit <= 0)) {
        toast("International online limit cannot be 0")
        return false
      }
      if (Number(cardSetting.international.online.dailyLimit) % 100 !== 0) {
        toast("limit must be a multiple of 100")
        return false
      }

      if (Number(cardSetting.international.offline.dailyLimit <= 0)) {
        toast("International offline limit cannot be 0")
        return false
      }
      if (Number(cardSetting.international.offline.dailyLimit) % 100 !== 0) {
        toast("limit must be a multiple of 100")
        return false
      }

      if (Number(cardSetting.international.contactless.dailyLimit <= 0)) {
        toast("International contactless limit cannot be 0")
        return false
      }
      if (
        Number(cardSetting.international.contactless.dailyLimit) % 100 !==
        0
      ) {
        toast("limit must be a multiple of 100")
        return false
      }

      if (Number(cardSetting.international.cash.dailyLimit <= 0)) {
        toast("International cash limit cannot be 0")
        return false
      }
      if (Number(cardSetting.international.cash.dailyLimit) % 100 !== 0) {
        toast("limit must be a multiple of 100")
        return false
      }
    }

    // check if card is unlocked but none of the toggles are enabled
    let areAllNonCCControlsDisabled =
      !cardSetting.domestic.online.isEnabled &&
      !cardSetting.domestic.offline.isEnabled &&
      !cardSetting.domestic.contactless.isEnabled
    let areAllCCControlsDisabled = false
    if (!isPaylaterOrPrepaidProgram) {
      areAllCCControlsDisabled =
        !cardSetting.domestic.cash.isEnabled &&
        !cardSetting.international.online.isEnabled &&
        !cardSetting.international.offline.isEnabled &&
        !cardSetting.international.contactless.isEnabled &&
        !cardSetting.international.cash.isEnabled
    }

    if (
      disableAllControls === false &&
      cardStatus &&
      ((isPaylaterOrPrepaidProgram && areAllNonCCControlsDisabled) ||
        (!isPaylaterOrPrepaidProgram &&
          areAllNonCCControlsDisabled &&
          areAllCCControlsDisabled))
    ) {
      toast("Please enable at least one setting")
      return false
    }

    return true
  }

  const handleSubmitClick = async () => {
    if (!areControlsValid()) return

    trackSaveClick(user.programType, cardStatus, cardSetting)
    setSubmitLoading(true)

    if (initCardSettings.current.cardStatus !== cardStatus) {
      // if lock status has changed
      // first call lock api, then its callback will call card controls api
      await handleApiAuth({
        apiId: cardStatus ? ApiIds.UNLOCK_CARD : ApiIds.LOCK_CARD,
        onAuthSuccess: onLockAuthSuccess,
        onAuthFailure: onAuthFailure,
        onAuthCancel: onAuthCancel,
        otpReason: "to change card status",
        mpinReason: "to change card status",
        toast,
        navigate,
      })
    } else {
      // if lock status didn't change and user was shown Save button then card controls must have changed
      // directly update card controls
      await handleApiAuth({
        apiId: ApiIds.UPDATE_CARD_CONTROLS,
        flowId: "3",
        onAuthSuccess: onControlsAuthSuccess,
        onAuthFailure: onAuthFailure,
        onAuthCancel: onAuthCancel,
        otpReason: "to update card controls",
        mpinReason: "to update card controls",
        toast,
        navigate,
      })
    }

    setSubmitLoading(false)
  }

  const onFetchCardAuthSuccess = async apiToken => {
    try {
      const response = await CardService.getCardDetails(
        apiToken,
        user.cards[0]?.id,
      )
      const result = response.data

      if (result?.success) {
        dispatch(setCommonState({ fetchCardData: result.data }))

        // call api for fetching card controls
        await handleApiAuth({
          apiId: ApiIds.FETCH_CARD_CONTROLS,
          onAuthSuccess: onFetchCardControlsAuthSuccess,
          onAuthFailure: onAuthFailure,
          onAuthCancel: onAuthCancel,
          otpReason: "to get card controls",
          mpinReason: "to get card controls",
          toast,
          navigate,
        })
      } else {
        consoleError(result?.errors)
        setError(true)
      }
    } catch (error) {
      consoleError(error)
      setError(true)
    }
  }

  const onFetchCardControlsAuthSuccess = async apiToken => {
    try {
      const response = await CardService.getCardControls(
        apiToken,
        user.cards[0]?.id,
      )
      const result = response.data

      if (result?.success) {
        // set flag and result in store
        dispatch(
          setCommonState({
            isCardSettingsSet: true,
            fetchCardControlsData: result.data,
          }),
        )

        // navigate back to this screen
        // if already on this screen (in case auth wasn't required) then navigate won't have any effect
        //only if allowed by block codes
        if (
          (session.disabledFunctionsByBlockCode.includes(
            Federal_BlockCodes.UNBLOCK_CARD_DISABLE,
          ) ||
            session.disabledFunctionsByBlockCode.includes(
              AU_BlockCodes.CARD_DASHBOARD_DISABLE,
            ) ||
            session.disabledFunctionsByBlockCode?.includes(
              AU_BlockCodes.UNBLOCK_CARD_DISABLE,
            )) === false
        )
          navigate("/ManageCard/CardSettings", { replace: true })
      } else {
        consoleError(result?.errors)
        setError(true)
      }
    } catch (error) {
      consoleError(error)
      setError(true)
    }
  }

  const onLockAuthSuccess = async apiToken => {
    try {
      const response = cardStatus
        ? await CardService.unlockCard(apiToken, user.cards[0]?.id)
        : await CardService.lockCard(apiToken, user.cards[0]?.id)
      const result = response?.data

      if (result?.success) {
        const tempCard = { ...card }
        tempCard.isLocked = !cardStatus
        dispatch(setCards({ cards: [tempCard] }))

        if (haveCardControlsChanged()) {
          // call api for updating card controls
          await handleApiAuth({
            apiId: ApiIds.UPDATE_CARD_CONTROLS,
            flowId: "3",
            onAuthSuccess: onControlsAuthSuccess,
            onAuthFailure: onAuthFailure,
            onAuthCancel: onAuthCancel,
            otpReason: "to update card controls",
            mpinReason: "to update card controls",
            toast,
            navigate,
          })
        } else {
          toast("Card settings updated successfully", true)
          navigate("/", { replace: true })
        }
      } else {
        consoleError(result.errors)
        toast("An error occurred while updating \ncard settings!!")
        navigate("/", { replace: true })
      }
    } catch (error) {
      consoleError(error)
      toast("Sorry, an error occurred while \nupdating card status")
      navigate("/", { replace: true })
    }
  }

  const onControlsAuthSuccess = async apiToken => {
    const controls = []

    if (
      initCardSettings.current.domestic.online.isEnabled !==
        cardSetting.domestic.online.isEnabled ||
      initCardSettings.current.domestic.online.dailyLimit !==
        Number(cardSetting.domestic.online.dailyLimit)
    ) {
      controls.push({
        txnTags: {
          location: "DOMESTIC",
          txnType: "RETAIL",
          txnChannel: "ECOMMERCE",
        },
        isEnabled: cardSetting.domestic.online.isEnabled,
        dailyTxnLimit: Number(cardSetting.domestic.online.dailyLimit),
      })
    }

    if (
      initCardSettings.current.domestic.offline.isEnabled !==
        cardSetting.domestic.offline.isEnabled ||
      initCardSettings.current.domestic.offline.dailyLimit !==
        Number(cardSetting.domestic.offline.dailyLimit)
    ) {
      controls.push({
        txnTags: {
          location: "DOMESTIC",
          txnType: "RETAIL",
          txnChannel: "CONTACT",
        },
        isEnabled: cardSetting.domestic.offline.isEnabled,
        dailyTxnLimit: Number(cardSetting.domestic.offline.dailyLimit),
      })

      // need to set same config for magnetic
      controls.push({
        txnTags: {
          location: "DOMESTIC",
          txnType: "RETAIL",
          txnChannel: "MAGNETIC",
        },
        isEnabled: cardSetting.domestic.offline.isEnabled,
        dailyTxnLimit: Number(cardSetting.domestic.offline.dailyLimit),
      })
    }

    if (
      initCardSettings.current.domestic.contactless.isEnabled !==
        cardSetting.domestic.contactless.isEnabled ||
      initCardSettings.current.domestic.contactless.dailyLimit !==
        Number(cardSetting.domestic.contactless.dailyLimit)
    ) {
      controls.push({
        txnTags: {
          location: "DOMESTIC",
          txnType: "RETAIL",
          txnChannel: "CONTACTLESS",
        },
        isEnabled: cardSetting.domestic.contactless.isEnabled,
        dailyTxnLimit: Number(cardSetting.domestic.contactless.dailyLimit),
      })
    }

    if (!isPaylaterOrPrepaidProgram) {
      if (
        initCardSettings.current.domestic.cash.isEnabled !==
          cardSetting.domestic.cash.isEnabled ||
        initCardSettings.current.domestic.cash.dailyLimit !==
          Number(cardSetting.domestic.cash.dailyLimit)
      ) {
        controls.push({
          txnTags: {
            location: "DOMESTIC",
            txnType: "CASH",
          },
          isEnabled: cardSetting.domestic.cash.isEnabled,
          dailyTxnLimit: Number(cardSetting.domestic.cash.dailyLimit),
        })
      }

      if (
        initCardSettings.current.international.online.isEnabled !==
          cardSetting.international.online.isEnabled ||
        initCardSettings.current.international.online.dailyLimit !==
          Number(cardSetting.international.online.dailyLimit)
      ) {
        controls.push({
          txnTags: {
            location: "INTERNATIONAL",
            txnType: "RETAIL",
            txnChannel: "ECOMMERCE",
          },
          isEnabled: cardSetting.international.online.isEnabled,
          dailyTxnLimit: Number(cardSetting.international.online.dailyLimit),
        })
      }

      if (
        initCardSettings.current.international.offline.isEnabled !==
          cardSetting.international.offline.isEnabled ||
        initCardSettings.current.international.offline.dailyLimit !==
          Number(cardSetting.international.offline.dailyLimit)
      ) {
        controls.push({
          txnTags: {
            location: "INTERNATIONAL",
            txnType: "RETAIL",
            txnChannel: "CONTACT",
          },
          isEnabled: cardSetting.international.offline.isEnabled,
          dailyTxnLimit: Number(cardSetting.international.offline.dailyLimit),
        })

        // need to set same config for magnetic
        controls.push({
          txnTags: {
            location: "INTERNATIONAL",
            txnType: "RETAIL",
            txnChannel: "MAGNETIC",
          },
          isEnabled: cardSetting.international.offline.isEnabled,
          dailyTxnLimit: Number(cardSetting.international.offline.dailyLimit),
        })
      }

      if (
        initCardSettings.current.international.contactless.isEnabled !==
          cardSetting.international.contactless.isEnabled ||
        initCardSettings.current.international.contactless.dailyLimit !==
          Number(cardSetting.international.contactless.dailyLimit)
      ) {
        controls.push({
          txnTags: {
            location: "INTERNATIONAL",
            txnType: "RETAIL",
            txnChannel: "CONTACTLESS",
          },
          isEnabled: cardSetting.international.contactless.isEnabled,
          dailyTxnLimit: Number(
            cardSetting.international.contactless.dailyLimit,
          ),
        })
      }

      if (
        initCardSettings.current.international.cash.isEnabled !==
          cardSetting.international.cash.isEnabled ||
        initCardSettings.current.international.cash.dailyLimit !==
          Number(cardSetting.international.cash.dailyLimit)
      ) {
        controls.push({
          txnTags: {
            location: "INTERNATIONAL",
            txnType: "CASH",
          },
          isEnabled: cardSetting.international.cash.isEnabled,
          dailyTxnLimit: Number(cardSetting.international.cash.dailyLimit),
        })
      }
    }

    const response = await CardService.updateCardControls(
      apiToken,
      user.cards[0]?.id,
      {
        updateCardControls: controls,
      },
    )
    const result = response?.data

    if (result?.success) {
      dispatch(setCards({ cards: [result.data] }))

      toast("Card settings updated successfully", true)
      navigate("/", { replace: true })
    } else {
      consoleError(result.errors)

      const limitExceedErrors =
        result.errors?.filter(error =>
          error.reason.includes("dailyTxnLimit cannot exceed"),
        ) || []

      if (limitExceedErrors.length > 0) {
        const errorMsg = limitExceedErrors[0].reason.replace(
          "dailyTxnLimit",
          "Transaction limit",
        )
        toast(errorMsg)
      } else {
        toast("An error occurred while \nupdating card settings")
      }

      navigate("/ManageCard/CardSettings", { replace: true })
    }
  }

  const onAuthFailure = async (error, message) => {
    error && consoleError(error)
    toast(message ? message : "Oops an internal error occurred!!")
    navigate("/ManageCard/CardSettings", { replace: true })
  }

  const onAuthCancel = async message => {
    message && toast(message)
    navigate("/", { replace: true })
  }
  const getSettings = location => {
    // location can be domestic or international
    return (
      <View mt='10px'>
        {Object.entries(cardSetting[location]).map(([key, control]) => {
          // key can be online, cash etc.
          return (
            <View key={key} {...styles.cardSettingContainer}>
              <View {...styles.onlineTransaction} {...styles.commonStyles}>
                <Text
                  {...styles.cardStatusText}
                  color={getVersionedDisplay() ? theme.appTextColor : null}
                  fontFamily={theme.fontFamily}
                >
                  {control.label}
                </Text>
                <Switch
                  {...styles.cardControlSwitch}
                  isDisabled={
                    !cardStatus ||
                    ((disableAllControls &&
                      (cardStatus || disableAllControls)) ||
                    (isAccountActive &&
                      card &&
                      !card.isHotlisted &&
                      control.label === "Online Transaction")
                      ? !card.isVirtualCardActivated
                      : (control.label === "Offline Transaction" ||
                          control.label === "Contactless") &&
                        user.programType === ProgramTypes.PREPAID
                      ? card?.cardType === "Virtual"
                        ? card?.isVirtualCardActivated
                        : !card?.isPhysicalCardActivated
                      : !card?.isPhysicalCardActivated)
                  }
                  isChecked={
                    cardStatus &&
                    control.isEnabled &&
                    !(disableAllControls ||
                    (isAccountActive &&
                      card &&
                      !card.isHotlisted &&
                      control.label === "Online Transaction")
                      ? !card.isVirtualCardActivated
                      : (control.label === "Offline Transaction" ||
                          control.label === "Contactless") &&
                        user.programType === ProgramTypes.PREPAID
                      ? card?.cardType === "Virtual"
                        ? card?.isVirtualCardActivated
                        : !card?.isPhysicalCardActivated
                      : !card?.isPhysicalCardActivated)
                  }
                  onTrackColor={
                    getVersionedDisplay() ? theme.color1 : theme.color2
                  }
                  size='md'
                  onToggle={() => {
                    setCardSetting(currCardSetting => ({
                      ...currCardSetting,
                      [location]: {
                        ...currCardSetting[location],
                        [key]: {
                          ...currCardSetting[location][key],
                          isEnabled: !currCardSetting[location][key].isEnabled,
                        },
                      },
                    }))

                    trackToggle(
                      user.programType,
                      location,
                      key,
                      !cardSetting[location][key].isEnabled, // pass reverse of current toggle state
                      cardSetting[location][key].dailyLimit,
                    )
                  }}
                />
              </View>
              {cardStatus &&
                !disableAllControls &&
                control.isEnabled &&
                !((disableAllControls && (cardStatus || disableAllControls)) ||
                (isAccountActive &&
                  card &&
                  !card.isHotlisted &&
                  control.label === "Online Transaction")
                  ? !card.isVirtualCardActivated
                  : (control.label === "Offline Transaction" ||
                      control.label === "Contactless") &&
                    user.programType === ProgramTypes.PREPAID
                  ? card?.cardType === "Virtual"
                    ? card?.isVirtualCardActivated
                    : !card?.isPhysicalCardActivated
                  : !card?.isPhysicalCardActivated) && (
                  <>
                    <View {...styles.limitContainer}>
                      <View w='35%'>
                        <Input
                          {...styles.inputField}
                          isReadOnly={
                            !cardStatus ||
                            ((disableAllControls &&
                              (cardStatus || disableAllControls)) ||
                            (isAccountActive &&
                              card &&
                              !card.isHotlisted &&
                              control.label === "Online Transaction")
                              ? !card.isVirtualCardActivated
                              : (control.label === "Offline Transaction" ||
                                  control.label === "Contactless") &&
                                user.programType === ProgramTypes.PREPAID
                              ? card?.cardType === "Virtual"
                                ? card?.isVirtualCardActivated
                                : !card?.isPhysicalCardActivated
                              : !card?.isPhysicalCardActivated)
                          }
                          value={control.dailyLimit}
                          onChangeText={amount => {
                            // if entered character is valid
                            if (amount.length > 9) return false
                            if (INTEGER_REGEX.test(amount)) {
                              setCardSetting(currCardSetting => ({
                                ...currCardSetting,
                                [location]: {
                                  ...currCardSetting[location],
                                  [key]: {
                                    ...currCardSetting[location][key],
                                    dailyLimit: amount,
                                  },
                                },
                              }))

                              trackLimit(
                                user.programType,
                                location,
                                key,
                                amount, // pass amount
                              )
                            }
                          }}
                          _focus={{
                            borderWidth: 1,
                            borderColor: control.isEnabled
                              ? theme.color1
                              : "#e3e3e3",
                            backgroundColor: control.isEnabled
                              ? "#fff"
                              : "blueGray.50",
                          }}
                        />
                      </View>
                      <Text
                        fontFamily={theme.fontFamily}
                        {...styles.limitText}
                        color={
                          getVersionedDisplay()
                            ? `${theme.appTextColor}:alpha.60`
                            : null
                        }
                      >
                        Daily Limit
                      </Text>
                    </View>
                  </>
                )}
            </View>
          )
        })}
      </View>
    )
  }

  const getVersionedDisplay = () => {
    return (
      config?.version === PwaVersions.V2 || config?.version === PwaVersions.V2_1
    )
  }

  return (
    // not using scrollview as react-native-web adds adds an extra div for it
    <View backgroundColor='#F8F8F8' {...styles.expand} minH={window.height}>
      {loading ? (
        <View
          w='100%'
          alignItems='center'
          justifyContent='center'
          height={windowDim.height}
          style={{
            backgroundColor: `${
              config?.version === PwaVersions.V2 ||
              config?.version === PwaVersions.V2_1
                ? theme.backgroundColor
                : "white"
            }`,
          }}
        >
          <Loader color={theme.color1} width={120} height={120} />
        </View>
      ) : error ? (
        <ErrorScreen />
      ) : (
        <View
          {...styles.expand}
          minH={windowDim.height}
          _web={{ maxW: DisplayConfig.MAX_WIDTH }}
          bgColor={getVersionedDisplay() ? theme.backgroundColor : null}
          py={!getVersionedDisplay() ? "10" : "0"}
          px={!getVersionedDisplay() ? "12px" : "0"}
        >
          <KeyboardAvoidingView>
            <View {...styles.expand}>
              <View alignItems='center' justifyContent='center'>
                <View _web={{ maxW: DisplayConfig.MAX_WIDTH }} w='100%'>
                  {config?.version === PwaVersions.V2 ||
                  config?.version === PwaVersions.V2_1 ? (
                    <Header
                      text='Card Controls'
                      onBack={async () => {
                        window.history.go(-1)
                      }}
                    />
                  ) : (
                    <View
                      {...styles.header}
                      w='100%'
                      bgColor={
                        getVersionedDisplay()
                          ? theme.headerBackgroundColor
                          : null
                      }
                      py={getVersionedDisplay() ? "12px" : "0"}
                      px={getVersionedDisplay() ? "12px" : "0"}
                    >
                      <Link
                        to='/ManageCard'
                        onClick={() => {
                          trackBackClick(user.programType)
                        }}
                      >
                        <ChevronLeftIcon
                          color={
                            getVersionedDisplay() ? theme.titleColor : "#303030"
                          }
                          size='24px'
                        />
                      </Link>
                      <Text
                        {...styles.cardCotnrolText}
                        fontFamily={theme.fontFamily}
                        color={
                          getVersionedDisplay() ? theme.titleColor : "#303030"
                        }
                      >
                        Card Controls
                      </Text>
                    </View>
                  )}
                </View>
              </View>
              <View
                alignItems='center'
                justifyContent='center'
                px={getVersionedDisplay() ? "12px" : "0"}
              >
                <View _web={{ maxW: DisplayConfig.MAX_WIDTH }} w='100%'>
                  <View
                    {...styles.cardStatus}
                    bgColor={
                      getVersionedDisplay()
                        ? theme.widgetBackgroundColor
                        : "#FFFFFF"
                    }
                  >
                    <Text
                      {...styles.cardStatusText}
                      fontFamily={theme.fontFamily}
                      color={getVersionedDisplay() ? theme.appTextColor : null}
                    >
                      Card Status
                    </Text>
                    <Switch
                      {...styles.cardControlSwitch}
                      isChecked={cardStatus}
                      onTrackColor={
                        getVersionedDisplay() ? theme.color1 : theme.color2
                      }
                      size='md'
                      onToggle={() => {
                        setCardStatus(!cardStatus)
                        trackCardStatus(user.programType, !cardStatus)
                      }}
                    />
                  </View>
                </View>
              </View>
              <View
                alignItems='center'
                justifyContent='center'
                px={getVersionedDisplay() ? "12px" : "0"}
              >
                <View _web={{ maxW: DisplayConfig.MAX_WIDTH }} w='100%'>
                  <View mt='20px'>
                    <View
                      opacity={cardStatus ? 1 : 0.5}
                      {...styles.overallCardSettingContainer}
                      bgColor={
                        getVersionedDisplay()
                          ? theme.widgetBackgroundColor
                          : "#FFFFFF"
                      }
                    >
                      {/* showing only domestic for paylater so no need for separate accordion */}
                      {!isPaylaterOrPrepaidProgram && (
                        <View {...styles.sovereigntyHeading}>
                          <View>
                            <Text
                              {...styles.containerHeader}
                              color={
                                getVersionedDisplay()
                                  ? theme.appTextColor
                                  : null
                              }
                            >
                              Domestic
                            </Text>
                          </View>
                          <View
                            cursor='pointer'
                            onClick={() => setIsDomesticOpen(!isDomesticOpen)}
                          >
                            {!isDomesticOpen ? (
                              <ChevronDownIcon
                                size='24px'
                                color={
                                  getVersionedDisplay()
                                    ? theme.appTextColor
                                    : null
                                }
                              />
                            ) : (
                              <ChevronUpIcon
                                size='24px'
                                color={
                                  getVersionedDisplay()
                                    ? theme.appTextColor
                                    : null
                                }
                              />
                            )}
                          </View>
                        </View>
                      )}
                      <View
                        display={
                          isPaylaterOrPrepaidProgram || isDomesticOpen
                            ? "flex"
                            : "none"
                        }
                      >
                        {getSettings("domestic")}
                      </View>
                    </View>
                    {/* don't show international for paylater */}
                    {!isPaylaterOrPrepaidProgram && (
                      <View
                        opacity={cardStatus ? 1 : 0.5}
                        {...styles.overallCardSettingContainer}
                        bgColor={
                          getVersionedDisplay()
                            ? theme.widgetBackgroundColor
                            : "#FFFFFF"
                        }
                        mt='32px'
                      >
                        <View {...styles.sovereigntyHeading}>
                          <View>
                            <Text
                              {...styles.containerHeader}
                              color={
                                getVersionedDisplay()
                                  ? theme.appTextColor
                                  : null
                              }
                            >
                              International
                            </Text>
                          </View>
                          <View
                            cursor='pointer'
                            onClick={() =>
                              setIsInternationalOpen(!isInternationalOpen)
                            }
                          >
                            {!isInternationalOpen ? (
                              <ChevronDownIcon
                                size='24px'
                                color={
                                  getVersionedDisplay()
                                    ? theme.appTextColor
                                    : null
                                }
                              />
                            ) : (
                              <ChevronUpIcon
                                size='24px'
                                color={
                                  getVersionedDisplay()
                                    ? theme.appTextColor
                                    : null
                                }
                              />
                            )}
                          </View>
                        </View>
                        <View display={isInternationalOpen ? "flex" : "none"}>
                          {getSettings("international")}
                        </View>
                      </View>
                    )}

                    {isAccountActive &&
                      card &&
                      !card.isHotlisted &&
                      (user.programType === ProgramTypes.PREPAID
                        ? card?.cardType === "Virtual"
                          ? !card?.isVirtualCardActivated
                          : !card?.isPhysicalCardActivated
                        : !card?.isPhysicalCardActivated) && (
                        <View mt='24px'>
                          <Nudge width='100%' />
                        </View>
                      )}
                  </View>
                </View>
              </View>
              {/* dummy div to expand and push below div to bottom */}
              <View {...styles.expand}></View>
              {hasStateChanged() && (
                <View
                  alignItems='center'
                  justifyContent='center'
                  w='100%'
                  px={getVersionedDisplay() ? "12px" : "0"}
                >
                  <View
                    {...styles.button}
                    _web={{ maxW: DisplayConfig.MAX_WIDTH }}
                  >
                    <ColorButton
                      text='Save Changes'
                      isLoading={submitLoading}
                      isDisable={false}
                      onPress={handleSubmitClick}
                    />
                    {!(
                      config?.version == PwaVersions.V2 ||
                      config?.version == PwaVersions.V2_1
                    ) ? (
                      <WhiteButton
                        text='Cancel'
                        onPress={() => {
                          navigate("/")
                          trackCancelClick(user.programType)
                        }}
                      />
                    ) : null}
                  </View>
                </View>
              )}
            </View>
          </KeyboardAvoidingView>
        </View>
      )}
    </View>
  )
}

export default CardSettings_v1
