import { PWA_MAX_WIDTH_IN_PX } from "../utils/constants"
import { PwaVersions } from "../utils/enums"

const addCoreStyles = ({
  version,
  color1,
  color2,
  fontFamily,
  toastTextColor,
  toastBackgroundColor,
}) => {
  const css = `
		/* button start */
		.hf-btn {
			display: flex;
			align-items: center;
			justify-content: center;
			border-style: solid;
			border-width: 0.5px;
			border-radius: 6px;
			white-space: nowrap;
			font-size: 16px;
			font-weight: 600;
			padding: 12px 18px;
		}

		.hf-btn-primary {
			border-color: ${color1};
			background-color: ${color1};
			font-family: ${fontFamily};
			color: ${version === PwaVersions.V2 || version === PwaVersions.V2_1  ? color2 : "#FFFFFF"};
		}

		.hf-btn-secondary {
			borderColor: ${color1};
			backgroundColor: ${version === PwaVersions.V2 || version === PwaVersions.V2_1  ? color2 : "#FFFFFF"};
			fontFamily: ${fontFamily};
			color: ${color1};
		}
		/* button end */

		/* slider start */
		.hf-slider {
			-webkit-appearance: none;
			appearance: none;
			background: grey;
			cursor: pointer;
			width: 15rem;
			background-repeat: no-repeat;
			border-radius: 0.5rem;
			background-image: linear-gradient(${color1}, ${color1});
		}

		/* Removes default focus */
		.hf-slider:hover {
			outline: none;
		}

		/***** Webkit - Chrome, Safari, Opera and Edge Chromium styles *****/
		.hf-slider::-webkit-slider-thumb {
			-webkit-appearance: none; /* Override default look */
			appearance: none;
		}

		/******** Firefox styles ********/
		.hf-slider::-moz-range-thumb {
			border: none; /*Removes extra border that FF applies*/
			border-radius: 0; /*Removes default border-radius that FF applies*/
		}

		/******** common styles for webkit and moz ********/

		/* can't use commas to combine vendor-prefixed selectors */
		.hf-slider::-webkit-slider-runnable-track {
			background: transparent;
			height: 0.3rem;
		}
		.hf-slider::-moz-range-track {
			background: transparent;
			height: 0.3rem;
		}

		.hf-slider::-webkit-slider-thumb {
			background-color: #FFFFFF;
			border-radius: 50%;
			box-shadow: rgba(0, 0, 0, 0.25) 0px 2px 3.84px;
			width: 1rem;
			height: 1rem;
			margin-top: -5.5px;
		}
		.hf-slider::-moz-range-thumb {
			background-color: #FFFFFF;
			border-radius: 50%;
			box-shadow: rgba(0, 0, 0, 0.25) 0px 2px 3.84px;
			width: 1rem;
			height: 1rem;
			margin-top: -5.5px;
		}

		.hf-slider:hover::-webkit-slider-thumb {
			margin-top: -7px;
			height: 1.2rem;
			width: 1.2rem;
		}
		.hf-slider:hover::-moz-range-thumb {
			margin-top: -7px;
			height: 1.2rem;
			width: 1.2rem;
		}
		/* slider end */

		/* radio start */
		.hf-radio-container {
			display: flex;
			flex-direction: column;
			margin-top:5px;
		}

		.hf-radio {
			display: none;
		}

		.hf-radio + *::before {
			content: "";
			display: inline-block;
			vertical-align: middle;
			width: 18px;
			height: 18px;
			margin-top: 4px;
			margin-bottom: 2px;
			margin-right: 10px;
			border-radius: 50%;
			border-style: solid;
			border-width: 0.1rem;
		}
	
		.hf-radio:checked + *::before {
			background: radial-gradient(${color1} 0%, ${color1} 40%, transparent 50%, transparent);
			border-color: ${color1};
		}

		.hf-radio-label {
			display: flex;
			align-items: center;
		}
		/* radio end */

		/* toggle start */
		.hf-toggle-container {
			display: flex;
			flex-direction: row;
		}
	
		.hf-toggle-label {
			margin-right: 10px;
		}
	
		.hf-toggle-parent {
			position: relative;
			width: 45px;
			height: 20px;
		}
		
		.hf-toggle-switch {
			position: absolute;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
			background-color: #ccc;
			border-radius: 34px;
			cursor: pointer;
			transition: background-color 0.3s;
		}
		
		.hf-toggle-switch::before {
			position: absolute;
			content: '';
			height: 23px;
			width: 23px;
			left: -1px;
			bottom: -1px;
			background-color: #fafafa;
			box-shadow: 1px 1px 1px 1px grey;
			border-radius: 50%;
			transition: transform 0.3s;
		}
		
		.hf-toggle {
			display: none;
		}
		
		.hf-toggle:checked + .hf-toggle-switch {
			background-color: ${color1};
		}
		
		.hf-toggle:checked + .hf-toggle-switch::before {
			transform: translateX(26px);
		}
		/* toggle end */

		/* checkbox start */
		.hf-checkbox-container {
      display: inline-flex;
      align-items: center;
      cursor: pointer;
      font-size: 16px;
    }
    
    .hf-checkbox-checkmark {
      position: relative;
      display: inline-block;
      width: 22px;
      height: 22px;
      border: 1px solid #999;
      border-radius: 3px;
      margin-right: 8px;
      transition: background-color 0.3s;
    }
    
    .hf-checkbox-checkmark::after {
      content: '';
      position: absolute;
      display: none;
      left: 7px;
      top: 3px;
      width: 6px;
      height: 10px;
      border: solid white;
      border-width: 0 2px 2px 0;
      transform: rotate(45deg);
    }
    
    .hf-checkbox {
      position: absolute;
      opacity: 0;
      cursor: pointer;
    }
    
    .hf-checkbox:checked ~ .hf-checkbox-checkmark {
      background-color: ${color1};
      border-color: #5cb85c;
    }
    
    .hf-checkbox:checked ~ .hf-checkbox-checkmark::after {
      display: block;
    }

		.hf-checkbox-label {
			margin-left: 3px;
		}
		/* checkbox end */

		/* toast start */
		.hf-toast {
			background-color: ${toastBackgroundColor};
			color: ${toastTextColor};
			font-family: ${fontFamily};
			max-width: ${PWA_MAX_WIDTH_IN_PX - 50}px;
			border-radius: 10px;
			min-height: unset;
		}

		.hf-toast-body {
			padding: 8px;
		}
		/* toast end */

		/* toast 2 start */
		.hf-toast-2 {}
		.hf-toast-2-body {}
		/* toast 2 end */
	`

  const head = document.head || document.getElementsByTagName("head")[0]
  const style = document.createElement("style")
  style.type = "text/css"
  style.id = "core-style"

  if (style.styleSheet) {
    // This is required for IE8 and below.
    style.styleSheet.cssText = css
  } else {
    style.appendChild(document.createTextNode(css))
  }

  let currentStyle = document.getElementById("core-style")
  if (currentStyle) {
    head.removeChild(currentStyle)
  }

  head.appendChild(style)
}

export { addCoreStyles }
