import moment from "moment"
import {
  ChevronDownIcon,
  ChevronRightIcon,
  ChevronUpIcon,
  useToast,
} from "native-base"
import React, { useEffect, useRef, useState } from "react"
import { Animated } from "react-native"
import { useSelector } from "react-redux"
import { useNavigate } from "react-router-dom"
import emptyRewardsDefault from "../../../../assets/images/emptyRewardsDefault.svg"
import HorizontalCard from "../../../../assets/images/sampleHorizontalCard.svg"
import VerticalCard from "../../../../assets/images/sampleVerticalCard.svg"
import CommonService from "../../../../services/CommonService"
import { CardOrientation, ProgramTypes } from "../../../../utils/constants"
import {
  AU_BlockCodes,
  EventName,
  Federal_BlockCodes,
} from "../../../../utils/enums"
import { captureEvents, formatAmount } from "../../../../utils/functions"
import CustomLoader from "../../../core/loader"
import { showToast1 } from "../../../core/toast"
import Offers_v2 from "../OffersSection/v2/Offers_v2"
import styles from "./styles"
import "./styles.css"
import { PrimaryButton } from "../../../../core"
import click2 from "../../../../assets/images/click2.svg"
import CardImageLoader from "../../../core/SkeletonLoader/cardImageLoader"
import { PwaVersions } from "../../../../utils/enums"
import { ColorButton } from "../../../core/buttons"

const animationName = [
  "CARD_FLASH",
  "TILT_SHAKING",
  "GROW",
  "VIEW_CARD",
  "BUBBLE",
]
const overlayPosition = {
  BOTTOM: "BOTTOM",
  RIGHT: "RIGHT",
  NONE: "NONE",
}

const CardAndBalance_v2 = props => {
  const navigate = useNavigate()
  const nativeToast = useToast()
  const viewCard = true
  const [redirectionLoading, setRedirectionLoading] = useState(false)
  const [viewStatementByBlockCode, setViewStatementByBlockCode] = useState(true)
  const [imgLoaded, setImgLoaded] = useState(false)
  const animatedController = useRef(new Animated.Value(0)).current
  const [isOpen, setIsOpen] = useState(
    props?.showReminder?.toDate &&
      moment(props.showReminder.toDate).format("YYYY-MM-DD") <=
        moment().format("YYYY-MM-DD") &&
      moment(props.showReminder.dueDate).format("YYYY-MM-DD") >=
        moment().format("YYYY-MM-DD"),
  )
  animatedController.interpolate({
    inputRange: [-1, 1],
    outputRange: ["-0.8rad", "0.8rad"],
  })

  const [theme, user, session, preferences, screen, featureFlags, config] =
    useSelector(state => [
      state.theme,
      state.user,
      state.session,
      state.preferences,
      state.screen,
      state.featureFlags,
      state.config,
    ])

  console.log("val")
  useEffect(() => {
    if (user.programType === ProgramTypes.PREPAID) setIsOpen(true)
    let htmlElement = document.getElementsByClassName(
      "cardAndBalance-cardSummary-accordian",
    )
    let styleObject = window.getComputedStyle(htmlElement[0], null)
    let displayValue = styleObject.getPropertyValue("display")
    if (displayValue == "none") {
      setIsOpen(true)
    }
    if (
      session.disabledFunctionsByBlockCode.includes(
        AU_BlockCodes.CARD_DASHBOARD_DISABLE,
      ) ||
      session.disabledFunctionsByBlockCode.includes(
        AU_BlockCodes.VIEW_STATEMENT_DISABLE,
      ) ||
      session.disabledFunctionsByBlockCode.includes(
        Federal_BlockCodes.VIEW_STATEMENT_DISABLE,
      )
    ) {
      setViewStatementByBlockCode(false)
    }
  }, [])

  const handleImageLoaded = () => {
    setImgLoaded(true)
  }

  const lrRedirection = async () => {
    setRedirectionLoading(true)
    if (screen?.home?.reward?.redirectionType === "SSO") {
      const response = await CommonService.getLrSsoUrl()

      if (response.data.success) {
        window.location.href = response.data.data.ssoUrl
      } else {
        toast("redirection to rewards failed")
        navigate("/rewards")
        setRedirectionLoading(false)
      }
    } else if (screen?.home?.reward?.redirectionType === "URL") {
      window.location.href = screen?.home?.reward?.lrUrl
    } else {
      navigate("/rewards")
    }
    setRedirectionLoading(false)
  }

  const repayUrlCall = () => {
    featureFlags?.isUPIFlowEnabled
      ? navigate("/PaymentGateway")
      : (window.location.href = session.repaymentUrl)
  }

  const toast = (message, hasTick = false) => {
    showToast1({ nativeToast, theme, message, hasTick })
  }

  const seeCardDetails = () => {
    captureEvents({ eventName: EventName.VIRTUAL_CARD_CLICKED, metadata: {} })
    if (!stripMsg) {
      navigate("/CardDetailsWidget")
    }
  }

  const seePrepaidCardDetails = () => {
    if (card?.isHotlisted) {
      toast("Request a replacement card from \n settings to access your card")
    } else if (card?.isLocked) {
      toast(
        "Unlock your card from the settings \n to access virtual card details",
      )
    } else {
      navigate("/CardDetailsWidget")
    }
  }

  const account = user.account
  const card = user.cards?.[0]

  const hideVirtualCard = () => {
    if (
      session.disabledFunctionsByBlockCode.includes(
        AU_BlockCodes.VIRTUAL_CARD_DISABLE,
      ) ||
      session.disabledFunctionsByBlockCode.includes(
        AU_BlockCodes.TEMP_CARD_DISABLE,
      ) ||
      session?.disabledFunctionsByBlockCode.includes(
        AU_BlockCodes.PERMANENT_CARD_DISABLE,
      )
    ) {
      return true
    }
    return (
      session.disabledFunctionsByBlockCode.includes(
        Federal_BlockCodes.VIRTUAL_CARD_DISABLE,
      ) ||
      session.disabledFunctionsByBlockCode.includes(
        Federal_BlockCodes.PERMANENT_CARD_DISABLE,
      ) ||
      session?.disabledFunctionsByBlockCode.includes(
        Federal_BlockCodes.TEMP_CARD_DISABLE,
      )
    )
  }

  const getStripMsg = () => {
    let msg = ""
    if (
      session.disabledFunctionsByBlockCode.includes(AU_BlockCodes.CARD_LOCKED)
    ) {
      msg = "Card Blocked"
    } else if (
      session.disabledFunctionsByBlockCode.includes(
        AU_BlockCodes.PERMANENT_CARD_DISABLE,
      ) ||
      session.disabledFunctionsByBlockCode.includes(
        AU_BlockCodes.TEMP_CARD_DISABLE,
      ) ||
      session.disabledFunctionsByBlockCode.includes(
        AU_BlockCodes.VIRTUAL_CARD_DISABLE,
      )
    ) {
      msg = "Card Blocked"
    } else if (
      session.disabledFunctionsByBlockCode.includes(
        Federal_BlockCodes.CARD_BLOCKED,
      )
    ) {
      msg = "Card Blocked"
    } else if (account.status === "SUSPENDED") {
      msg = "Account is temporarily suspended"
    } else if (account.status === "FORCED_SUSPENDED") {
      msg = "Account is suspended"
    } else if (account.status === "CLOSED") {
      msg = "Account is closed"
    } else if (!card) {
      msg = "Card is not issued"
    } else if (card.isHotlisted) {
      msg = "Card Blocked"
    } else if (card.isLocked) {
      msg = "Card Blocked"
    }
    return msg
  }
  const stripMsg = getStripMsg()
  const overlayImageUrl = theme?.iconOverlayImageUrl || ""
  const overlay = !!theme?.iconOverlayImageUrl
    ? theme?.iconOverlay
    : overlayPosition.NONE
  const animation = !stripMsg ? theme?.virtualCardAnimation : "NONE"

  const renderViewCardCta = () => (
    <div
      style={{ display: "none", margin: "7px 0px 10px 0px" }}
      className='home-cardAndBalance-virtualCard-viewCardCta'
      onClick={() =>
        user.programType === ProgramTypes.PREPAID
          ? seePrepaidCardDetails()
          : seeCardDetails()
      }
    >
      <div
        style={{
          fontFamily: theme.fontFamily,
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <img src={click2} alt='View Card' style={{ width: "13px" }} />
        <div style={{ fontSize: "12px", marginLeft: "5px" }}>View Card</div>
      </div>
    </div>
  )

  const renderHorizontal = () => {
    switch (animation) {
      case animationName[0]:
        return (
          <div
            class={`container-vertical ${
              viewCard ? "container-vertical-viewcard" : ""
            }`}
          >
            <div className='strip'></div>
            <div className='rectangle-vertical'>
              <div
                className={
                  hideVirtualCard()
                    ? "cardAndBalance-horizontalCard-hideVirtualCard"
                    : "cardAndBalance-horizontalCard"
                }
                onClick={() =>
                  user.programType === ProgramTypes.PREPAID
                    ? seePrepaidCardDetails()
                    : seeCardDetails()
                }
              >
                <img
                  className='cardAndBalance-horizontalCard-img'
                  src={props.cardDetails.image || HorizontalCard}
                  alt='Alternate Text '
                  style={
                    !imgLoaded ? { display: "none" } : { display: "block" }
                  }
                  onLoad={handleImageLoaded}
                />
                {overlay === overlayPosition.RIGHT ? (
                  <div>
                    <img
                      className='cardAndBalance-horizontalCard-img-right-overlayIcon'
                      src={overlayImageUrl}
                      alt='Alternate Text '
                    />
                  </div>
                ) : null}
                {overlay === overlayPosition.BOTTOM ? (
                  <div>
                    <img
                      className='cardAndBalance-horizontalCard-img-bottom-overlayIcon'
                      src={overlayImageUrl}
                      alt='Alternate Text '
                    />
                  </div>
                ) : null}
              </div>
            </div>
          </div>
        )
      case animationName[1]:
        return (
          <div class='tilt-shaking'>
            <div
              className={
                hideVirtualCard()
                  ? "cardAndBalance-horizontalCard-hideVirtualCard"
                  : "cardAndBalance-horizontalCard"
              }
              onClick={() =>
                user.programType === ProgramTypes.PREPAID
                  ? seePrepaidCardDetails()
                  : seeCardDetails()
              }
            >
              <img
                className='cardAndBalance-horizontalCard-img'
                src={props.cardDetails.image || HorizontalCard}
                alt='Alternate Text '
                style={!imgLoaded ? { display: "none" } : { display: "block" }}
                onLoad={handleImageLoaded}
              />
              {overlay === overlayPosition.RIGHT ? (
                <div>
                  <img
                    className='cardAndBalance-horizontalCard-img-right-overlayIcon'
                    src={overlayImageUrl}
                    alt='Alternate Text '
                  />
                </div>
              ) : null}
              {overlay === overlayPosition.BOTTOM ? (
                <div>
                  <img
                    className='cardAndBalance-horizontalCard-img-bottom-overlayIcon'
                    src={overlayImageUrl}
                    alt='Alternate Text '
                  />
                </div>
              ) : null}
            </div>
          </div>
        )
      case animationName[2]:
        return (
          <>
            <div
              class='grow-horizontal'
              style={{ backgroundColor: theme?.color1 }}
            ></div>
            {/* <div class='grow-outline'></div> */}
            <div
              className={
                hideVirtualCard()
                  ? "cardAndBalance-horizontalCard-hideVirtualCard"
                  : "cardAndBalance-horizontalCard"
              }
              onClick={() =>
                user.programType === ProgramTypes.PREPAID
                  ? seePrepaidCardDetails()
                  : seeCardDetails()
              }
            >
              <img
                className='cardAndBalance-horizontalCard-img'
                src={props.cardDetails.image || HorizontalCard}
                alt='Alternate Text '
                style={!imgLoaded ? { display: "none" } : { display: "block" }}
                onLoad={handleImageLoaded}
              />
              {overlay === overlayPosition.RIGHT ? (
                <div>
                  <img
                    className='cardAndBalance-horizontalCard-img-right-overlayIcon'
                    src={overlayImageUrl}
                    alt='Alternate Text '
                  />
                </div>
              ) : null}
              {overlay === overlayPosition.BOTTOM ? (
                <div>
                  <img
                    className='cardAndBalance-horizontalCard-img-bottom-overlayIcon'
                    src={overlayImageUrl}
                    alt='Alternate Text '
                  />
                </div>
              ) : null}
            </div>
          </>
        )
      case animationName[3]:
        return (
          <div className='cardAndBalance-vertivalCard-viewCardAnimation'>
            <div style={{ backgroundColor: theme?.color1 }}></div>
            <div
              className='cardAndBalance-horizontalCard-viewCardAnimation-widget'
              style={{ backgroundColor: theme.widgetBackgroundColor }}
              onClick={() =>
                user.programType === ProgramTypes.PREPAID
                  ? seePrepaidCardDetails()
                  : seeCardDetails()
              }
            >
              <img
                src={props.cardDetails.image || HorizontalCard}
                className='cardAndBalance-horizontalCard-viewCardAnimation-img'
                alt='Alternate Text '
                style={!imgLoaded ? { display: "none" } : { display: "block" }}
                onLoad={handleImageLoaded}
              />
              <div>
                <div
                  className='cardAndBalance-horizontalCard-viewCardAnimation-text'
                  style={{ color: theme.color1 }}
                >
                  View Card
                  <ChevronRightIcon size='4' mb='2px' color={theme.color1} />
                </div>
              </div>
            </div>
          </div>
        )
      default:
        return (
          <div
            className={
              hideVirtualCard()
                ? "cardAndBalance-horizontalCard-hideVirtualCard"
                : "cardAndBalance-horizontalCard"
            }
            onClick={() =>
              user.programType === ProgramTypes.PREPAID
                ? seePrepaidCardDetails()
                : seeCardDetails()
            }
          >
            <img
              className='cardAndBalance-horizontalCard-img'
              src={props.cardDetails.image || HorizontalCard}
              alt='Alternate Text '
              style={!imgLoaded ? { display: "none" } : { display: "block" }}
              onLoad={handleImageLoaded}
            />
            {overlay === overlayPosition.RIGHT ? (
              <div>
                <img
                  className='cardAndBalance-horizontalCard-img-right-overlayIcon'
                  src={overlayImageUrl}
                  alt='Alternate Text '
                />
              </div>
            ) : null}
            {overlay === overlayPosition.BOTTOM ? (
              <div>
                <img
                  className='cardAndBalance-horizontalCard-img-bottom-overlayIcon'
                  src={overlayImageUrl}
                  alt='Alternate Text '
                />
              </div>
            ) : null}
          </div>
        )
    }
  }
  const renderVertical = () => {
    switch (animation) {
      case animationName[0]:
        return (
          <div class='container-horizontal'>
            <div className='strip'></div>
            <div className='rectangle'>
              <div
                className='cardAndBalance-vertivalCard'
                onClick={() =>
                  user.programType === ProgramTypes.PREPAID
                    ? seePrepaidCardDetails()
                    : seeCardDetails()
                }
              >
                <img
                  className='cardAndBalance-vertivalCard-img'
                  src={props.cardDetails.image || VerticalCard}
                  style={
                    !imgLoaded ? { display: "none" } : { display: "block" }
                  }
                  // class="zoom-vertical"
                  // class="flip"
                  alt='Alternate Text '
                  onLoad={handleImageLoaded}
                />
                {overlay === overlayPosition.RIGHT ? (
                  <div>
                    <img
                      src={overlayImageUrl}
                      className='cardAndBalance-vertivalCard-img-right-overlayIcon'
                      alt='Alternate Text '
                    />
                  </div>
                ) : null}
                {overlay === overlayPosition.BOTTOM ? (
                  <div>
                    <img
                      src={overlayImageUrl}
                      className='cardAndBalance-vertivalCard-img-bottom-overlayIcon'
                      alt='Alternate Text '
                    />
                  </div>
                ) : null}
              </div>
            </div>
          </div>
        )
      case animationName[1]:
        return (
          <div class='tilt-shaking'>
            {/* <div class='grow' style={{ backgroundColor: theme?.color1, zIndex: -1}}></div> */}
            {/* <div class='grow-outline'></div> */}
            <div
              className='cardAndBalance-vertivalCard'
              onClick={() =>
                user.programType === ProgramTypes.PREPAID
                  ? seePrepaidCardDetails()
                  : seeCardDetails()
              }
            >
              <img
                className='cardAndBalance-vertivalCard-img'
                src={props.cardDetails.image || VerticalCard}
                style={!imgLoaded ? { display: "none" } : { display: "block" }}
                // class="zoom-vertical"
                // class="flip"
                alt='Alternate Text '
                onLoad={handleImageLoaded}
              />
              {overlay === overlayPosition.RIGHT ? (
                <div>
                  <img
                    src={overlayImageUrl}
                    className='cardAndBalance-vertivalCard-img-right-overlayIcon'
                    alt='Alternate Text '
                  />
                </div>
              ) : null}
              {overlay === overlayPosition.BOTTOM ? (
                <div>
                  <img
                    src={overlayImageUrl}
                    className='cardAndBalance-vertivalCard-img-bottom-overlayIcon'
                    alt='Alternate Text '
                  />
                </div>
              ) : null}
            </div>
          </div>
        )
      case animationName[2]:
        return (
          <div>
            <div
              class='grow'
              style={
                !imgLoaded
                  ? { display: "none", backgroundColor: theme?.color1 }
                  : { display: "block", backgroundColor: theme?.color1 }
              }
            ></div>
            {/* <div class='grow-outline'></div> */}
            <div
              className='cardAndBalance-vertivalCard'
              onClick={() =>
                user.programType === ProgramTypes.PREPAID
                  ? seePrepaidCardDetails()
                  : seeCardDetails()
              }
            >
              <img
                className='cardAndBalance-vertivalCard-img'
                src={props.cardDetails.image || VerticalCard}
                style={!imgLoaded ? { display: "none" } : { display: "block" }}
                // class="zoom-vertical"
                // class="flip"
                alt='Alternate Text '
                onLoad={handleImageLoaded}
              />
              {overlay === overlayPosition.RIGHT ? (
                <div>
                  <img
                    src={overlayImageUrl}
                    className='cardAndBalance-vertivalCard-img-right-overlayIcon'
                    alt='Alternate Text '
                  />
                </div>
              ) : null}
              {overlay === overlayPosition.BOTTOM ? (
                <div>
                  <img
                    src={overlayImageUrl}
                    className='cardAndBalance-vertivalCard-img-bottom-overlayIcon'
                    alt='Alternate TexvertivalCardt '
                  />
                </div>
              ) : null}
            </div>
          </div>
        )

      case animationName[3]:
        return (
          <div className='cardAndBalance-vertivalCard-viewCardAnimation'>
            <div style={{ backgroundColor: theme?.color1 }}></div>
            <div
              className='cardAndBalance-vertivalCard-viewCardAnimation-widget'
              style={{ backgroundColor: theme.widgetBackgroundColor }}
              onClick={() =>
                user.programType === ProgramTypes.PREPAID
                  ? seePrepaidCardDetails()
                  : seeCardDetails()
              }
            >
              <img
                src={props.cardDetails.image || VerticalCard}
                className='cardAndBalance-vertivalCard-viewCardAnimation-img'
                alt='Alternate Text '
                onLoad={handleImageLoaded}
              />
              <div>
                <div
                  className='cardAndBalance-vertivalCard-viewCardAnimation-text'
                  style={{ color: theme.color1 }}
                >
                  View Card
                  <ChevronRightIcon size='4' mb='2px' color={theme.color1} />
                </div>
              </div>
            </div>
          </div>
        )
      case animationName[4]:
        return (
          <div class='tilt-shaking'>
            <div
              className='cardAndBalance-vertivalCard'
              onClick={() =>
                user.programType === ProgramTypes.PREPAID
                  ? seePrepaidCardDetails()
                  : seeCardDetails()
              }
            >
              <img
                className='cardAndBalance-vertivalCard-img'
                src={props.cardDetails.image || VerticalCard}
                style={!imgLoaded ? { display: "none" } : { display: "block" }}
                alt='Alternate Text '
                onLoad={handleImageLoaded}
              />
              <div className='vertical-bubble-container'>
                <div className='btn'>
                  <div className='bubble'></div>
                </div>
              </div>
            </div>
          </div>
        )

      default:
        return (
          <div
            className={
              hideVirtualCard()
                ? "cardAndBalance-vertivalCard-hideVirtualCard"
                : "cardAndBalance-vertivalCard"
            }
            onClick={() =>
              user.programType === ProgramTypes.PREPAID
                ? seePrepaidCardDetails()
                : seeCardDetails()
            }
          >
            <img
              className='cardAndBalance-vertivalCard-img'
              style={!imgLoaded ? { display: "none" } : { display: "block" }}
              src={props.cardDetails.image || VerticalCard}
              // class="zoom-vertical"
              onLoad={handleImageLoaded}
            />
            {overlay === overlayPosition.RIGHT ? (
              <div>
                <img
                  className='cardAndBalance-vertivalCard-img-right-overlayIcon'
                  src={overlayImageUrl}
                  alt='Alternate Text '
                />
              </div>
            ) : null}
            {overlay === overlayPosition.BOTTOM ? (
              <div>
                <img
                  className='cardAndBalance-vertivalCard-img-bottom-overlayIcon'
                  src={overlayImageUrl}
                  alt='Alternate Text '
                />
              </div>
            ) : null}
          </div>
        )
    }
  }

  return (
    <div className='cardAndBalance'>
      {!imgLoaded ? (
        <CardImageLoader
          orientation={props.cardDetails.orientation}
          showRewards={screen?.home?.reward?.isEnabled}
        />
      ) : (
        <></>
      )}
      {/** Card Section */}
      {props.cardDetails.orientation === CardOrientation.HORIZONTAL ? (
        <div>
          <div className='cardAndBalance-horizontalOrientation'>
            {renderHorizontal()}
            {renderViewCardCta()}
            {screen?.home?.reward?.isEnabled && imgLoaded && (
              <div
                className='cardAndBalance-horizontalOrientation-rewards'
                style={{ backgroundColor: theme.widgetBackgroundColor }}
              >
                <div className='cardAndBalance-horizontalOrientation-rewards-content'>
                  {Number(user?.rewardsSummary?.currentValue) === 0 &&
                  Number(user?.aggregate?.accrued) === 0 &&
                  Number(user?.aggregate?.currentValue) === 0 ? (
                    <div
                      className='cardAndBalance-horizontalOrientation-rewardsEmpty-text'
                      style={{
                        fontFamily: theme.fontFamily,
                        color: theme.appTextColor,
                      }}
                    >
                      {screen?.home?.reward?.rewardEmptyText ||
                        "Start shopping to earn points!"}
                    </div>
                  ) : (
                    <>
                      {user?.rewardsSummary && (
                        <div
                          className='cardAndBalance-horizontalOrientation-rewardsDescription-text'
                          style={{
                            fontFamily: theme.fontFamily,
                            color: theme.appTextColor,
                          }}
                        >
                          {screen?.home?.reward?.rewardDescText ||
                            "Lifetime Reward Points!"}
                        </div>
                      )}
                      <div className='cardAndBalance-horizontalOrientation-rewards-div'>
                        <div
                          className='cardAndBalance-horizontalOrientation-rewards-div-text'
                          style={{
                            fontFamily: theme.fontFamily,
                            color: theme.appTextColor,
                          }}
                          onClick={() => lrRedirection()}
                        >
                          {screen?.home?.reward?.redirectionType ===
                          "LR_TRANSACTIONS"
                            ? user?.rewardsSummary?.currentValue || "0"
                            : screen?.home?.reward?.redirectionType ===
                              "HF_REWARDS"
                            ? user?.aggregate?.accrued || "View rewards"
                            : user?.aggregate?.currentValue || "View rewards"}
                        </div>
                        {!redirectionLoading ? (
                          <ChevronRightIcon
                            size='5'
                            color={theme.appTextColor}
                            onClick={() => lrRedirection()}
                          />
                        ) : (
                          <CustomLoader
                            height='16px'
                            width='16px'
                            color={theme.appTextColor}
                          />
                        )}
                      </div>
                    </>
                  )}
                </div>
                <img
                  src={
                    screen?.home?.reward?.rewardBannerImageUrl ||
                    emptyRewardsDefault
                  }
                  className='cardAndBalance-horizontalOrientation-rewards-bannerImg'
                  alt='Alternate Text '
                />
              </div>
            )}
          </div>
          {Boolean(stripMsg) && (
            <div
              className='cardAndBalance-horizontalOrientation-stripMsg'
              style={{ backgroundColor: theme.widgetBackgroundColor }}
            >
              <div
                className='cardAndBalance-horizontalOrientation-stripMsg-text'
                style={{
                  fontFamily: theme.fontFamily,
                  color: theme.appTextColor,
                }}
              >
                {stripMsg}
              </div>
            </div>
          )}
        </div>
      ) : (
        <div className='cardAndBalance-verticalOrientation'>
          <div className='cardAndBalance-verticalOrientation-container'>
            <div
              className='cardAndBalance-verticalOrientation-div'
              style={{
                width: !screen?.home?.reward?.isEnabled ? "100%" : null,
              }}
              r
            >
              {renderVertical()}
              {renderViewCardCta()}
            </div>

            {screen?.home?.reward?.isEnabled && imgLoaded && (
              <div
                className='cardAndBalance-verticalOrientation-rewards'
                style={{ backgroundColor: theme.widgetBackgroundColor }}
              >
                <img
                  src={
                    screen?.home?.reward?.rewardBannerImageUrl ||
                    emptyRewardsDefault
                  }
                  className='cardAndBalance-verticalOrientation-rewards-bannerImg'
                  alt='Alternate Text '
                  style={{
                    height: config?.jsonConfig?.litRedirection
                      ? "120px"
                      : "170px",
                    width: config?.jsonConfig?.litRedirection
                      ? "120px"
                      : "170px",
                    padding: "0px",
                  }}
                />

                {config?.version !== PwaVersions.V2_1 &&
                (screen?.home?.reward?.redirectionType === "LR_TRANSACTIONS"
                  ? Number(user?.rewardSummary?.currentValue) === 0
                  : Number(user?.aggregate?.currentValue) === 0) ? (
                  <div
                    className='cardAndBalance-verticalOrientation-rewardsEmpty-container'
                    style={{ backgroundColor: theme.backgroundColor }}
                  >
                    <div
                      className='cardAndBalance-verticalOrientation-rewardsEmpty-text'
                      style={{
                        fontFamily: theme.fontFamily,
                        color: theme.appTextColor,
                      }}
                    >
                      {screen?.home?.reward?.rewardEmptyText ||
                        "Start shopping to earn points!"}
                    </div>
                  </div>
                ) : (
                  <div
                    className='cardAndBalance-verticalOrientation-rewards-content'
                    style={{
                      backgroundColor:
                        config?.version === PwaVersions.V2_1
                          ? "#F0EFF5"
                          : theme.backgroundColor,
                    }}
                  >
                    {(user?.rewardSummary ||
                      user?.aggregate?.currentValue ||
                      user?.aggregate?.accrued) && (
                      <div>
                        <div
                          className='cardAndBalance-verticalOrientation-rewardsDescription-text'
                          style={{
                            fontFamily: theme.fontFamily,
                            color: theme.appTextColor,
                          }}
                        >
                          {screen?.home?.reward?.rewardDescText ||
                            "Lifetime Reward Points!"}
                        </div>
                      </div>
                    )}
                    <div
                      className='cardAndBalance-verticalOrientation-rewards-div'
                      flexDir={"row"}
                      justifyContent='space-between'
                      alignItems={"center"}
                    >
                      <div
                        className={
                          user?.rewardSummary?.currentValue ||
                          user?.aggregate?.currentValue ||
                          user?.aggregate?.accrued
                            ? "cardAndBalance-verticalOrientation-rewards-div-text"
                            : "cardAndBalance-verticalOrientation-rewards-div-null-text"
                        }
                        style={{
                          fontFamily: theme.fontFamily,
                          color: theme.appTextColor,
                        }}
                        onClick={() => lrRedirection()}
                      >
                        {screen?.home?.reward?.redirectionType ===
                        "LR_TRANSACTIONS"
                          ? user?.rewardSummary?.currentValue
                            ? Number(user?.rewardSummary?.currentValue)
                            : "view rewards"
                          : screen?.home?.reward?.redirectionType ===
                            "HF_REWARDS"
                          ? Number(user?.aggregate?.accrued) || "View rewards"
                          : Number(user?.aggregate?.currentValue) ||
                            "View rewards"}
                      </div>
                      {!redirectionLoading ? (
                        <ChevronRightIcon
                          size='5'
                          color={theme.appTextColor}
                          onClick={() => lrRedirection()}
                        />
                      ) : (
                        <CustomLoader
                          height='16px'
                          width='16px'
                          color={theme.appTextColor}
                        />
                      )}
                    </div>
                  </div>
                )}
                {config?.jsonConfig?.litRedirection && (
                  <div style={{ width: "90%", marginTop: "8px" }}>
                    <ColorButton
                      text={"Manage Benefits"}
                      width='100%'
                      fontSize='12px'
                      onPress={() =>
                        window.open(
                          config?.jsonConfig?.litRedirectionUrl || "#",
                        )
                      }
                    />
                  </div>
                )}
              </div>
            )}
          </div>
          {Boolean(stripMsg) && (
            <div
              className={
                !screen?.home?.reward?.isEnabled
                  ? "cardAndBalance-verticalOrientation-stripMsg"
                  : "cardAndBalance-verticalOrientation-stripMsg-rewardsEnabled"
              }
            >
              <div
                style={{ backgroundColor: theme.widgetBackgroundColor }}
                className='cardAndBalance-verticalOrientation-stripMsg-container'
              >
                <div
                  className='cardAndBalance-verticalOrientation-stripMsg-text'
                  style={{
                    fontFamily: theme.fontFamily,
                    color: theme.appTextColor,
                  }}
                >
                  {stripMsg}
                </div>
              </div>
            </div>
          )}
        </div>
      )}

      {screen?.home?.reward?.isOffersEnabled &&
        props?.offersList?.length > 0 && (
          <Offers_v2 offerList={props.offersList} />
        )}
      <div
        className='cardAndBalance-cardSummary'
        style={{ backgroundColor: theme.widgetBackgroundColor }}
      >
        <div className='cardAndBalance-cardSummary-accordian'>
          <div
            className='cardAndBalance-cardSummary-accordian-text'
            style={{ fontFamily: theme.fontFamily, color: theme.appTextColor }}
          >
            {user.programType === ProgramTypes.PREPAID
              ? "Balance Details"
              : config?.version === PwaVersions.V2_1
              ? "Account Summary"
              : "Card Summary"}
          </div>
          <div style={{ cursor: "pointer" }} onClick={() => setIsOpen(!isOpen)}>
            {isOpen ? (
              <ChevronUpIcon size='6' color={theme.appTextColor} />
            ) : (
              <ChevronDownIcon size='6' color={theme.appTextColor} />
            )}
          </div>
        </div>
        {user.programType === ProgramTypes.PREPAID
          ? isOpen && (
              <div>
                <div className='cardAndBalance-cardSummary-content'>
                  <div>
                    <div
                      className='cardAndBalance-cardSummary-content-text'
                      style={{
                        fontFamily: theme.fontFamily,
                        color: theme.appTextColor,
                      }}
                    >
                      CURRENT BALANCE
                    </div>
                    <div
                      className='cardAndBalance-cardSummary-content-amount'
                      style={{
                        fontFamily: theme.fontFamily,
                        color: theme.appTextColor,
                      }}
                    >
                      {formatAmount(props.creditDetails.currentBalance)}
                    </div>
                  </div>
                  {session.repaymentFlow && (
                    <div className='cardAndBalance-cardSummary-content-repaymentFlow-button'>
                      <PrimaryButton
                        style={{ width: "100%" }}
                        className='home-cardAndBalance-manageBalance-button'
                        onClick={() => repayUrlCall()}
                      >
                        Manage Balance
                      </PrimaryButton>
                    </div>
                  )}
                </div>
              </div>
            )
          : isOpen && (
              <div className='cardAndBalance-cardSummary-content'>
                <div>
                  <div
                    className='cardAndBalance-cardSummary-content-text-1'
                    style={{
                      fontFamily: theme.fontFamily,
                      color: theme.appTextColor,
                    }}
                  >
                    Available Credit
                  </div>
                  <div
                    className='cardAndBalance-cardSummary-content-amount-1'
                    style={{
                      fontFamily: theme.fontFamily,
                      color: theme.appTextColor,
                    }}
                  >
                    {formatAmount(props.creditDetails.availableCredit)}
                  </div>
                </div>
                <div>
                  <div
                    className='cardAndBalance-cardSummary-content-text-2'
                    style={{
                      fontFamily: theme.fontFamily,
                      color: theme.appTextColor,
                    }}
                  >
                    {user.programType === ProgramTypes.CREDIT_CARD
                      ? config.version === PwaVersions.V2_1
                        ? "Total Outstanding"
                        : "Available Cash"
                      : "Used Credit"}
                  </div>
                  <div
                    className='cardAndBalance-cardSummary-content-amount-2'
                    style={{
                      fontFamily: theme.fontFamily,
                      color: theme.appTextColor,
                    }}
                  >
                    {formatAmount(
                      user.programType !== ProgramTypes.PAY_LATER
                        ? config.version === PwaVersions.V2_1
                          ? Number(props.creditDetails.totalCredit) -
                            Number(props.creditDetails.availableCredit)
                          : props.creditDetails.availableCash
                        : Number(props.creditDetails.totalCredit) -
                            Number(props.creditDetails.availableCredit),
                    )}
                  </div>
                </div>
              </div>
            )}
        {/**Balance Amount Ends  */}

        {/**Pay Reminder Setio Starts  */}

        {isOpen && session.repaymentFlow ? (
          <div
            className={
              props.showReminder.show
                ? "cardAndBalance-cardSummary-repaymentFlow-container"
                : "cardAndBalance-cardSummary-content-repaymentFlow-container-noReminder"
            }
            style={{
              backgroundColor: theme.widgetBackgroundColor,
              borderTopRadius: card && !card.isHotlisted ? "0px" : "8px",
            }}
          >
            {
              <div className='cardAndBalance-cardSummary-content-repaymentFlow-content'>
                <div className='cardAndBalance-cardSummary-content-repaymentFlow-content-div'>
                  <div
                    className='cardAndBalance-cardSummary-content-repaymentFlow-content-div-amount'
                    style={{
                      fontFamily: theme.fontFamily,
                      color: theme.appTextColor,
                    }}
                  >
                    {formatAmount(props.showReminder.amount)}
                  </div>
                  <div
                    className='cardAndBalance-cardSummary-content-repaymentFlow-content-div-text'
                    style={{ color: `${theme.appTextColor}80` }}
                  >
                    {moment(props.showReminder.dueDate).format("YYYY-MM-DD") >=
                    moment().format("YYYY-MM-DD")
                      ? `Due on ${moment(props.showReminder.dueDate).format(
                          "DD MMM YYYY",
                        )}`
                      : `Was due on ${moment(props.showReminder.dueDate).format(
                          "DD MMM YYYY",
                        )}`}
                  </div>
                </div>
                {config.version === PwaVersions.V2_1 &&
                  moment(props.showReminder.toDate).format("YYYY-MM-DD") <=
                    moment().format("YYYY-MM-DD") &&
                  moment(props.showReminder.dueDate).format("YYYY-MM-DD") >=
                    moment().format("YYYY-MM-DD") && (
                    <div className='cardAndBalance-cardSummary-content-repaymentFlow-content-div-text-v2_1'>
                      {`${Math.abs(
                        moment(
                          moment(props.showReminder.dueDate).endOf("day"),
                        ).diff(moment(), "days"),
                      )} days to go `}
                    </div>
                  )}
              </div>
            }

            <div className='cardAndBalance-cardSummary-content-repaymentFlow-content-button-container'>
              <div
                className='cardAndBalance-cardSummary-content-repaymentFlow-content-button-1'
                style={{
                  opacity: !viewStatementByBlockCode ? "0.4" : "1",
                  backgroundColor: theme.color2,
                  border: `2px solid ${theme.color1}`,
                  color: theme.color1,
                }}
                onClick={() => {
                  !viewStatementByBlockCode ? <></> : navigate("/Statement")
                  captureEvents({
                    eventName: EventName.VIEW_STATEMENT_SUMMARY,
                    metadata: {},
                  })
                }}
              >
                {config?.version === PwaVersions.V2_1
                  ? "View Summary"
                  : "View Statement"}
              </div>
              <div
                className='cardAndBalance-cardSummary-content-repaymentFlow-content-button-2'
                style={{
                  opacity:
                    session?.disabledFunctionsByBlockCode?.includes(
                      AU_BlockCodes.PAY_BILL_DISABLE,
                    ) ||
                    session.disabledFunctionsByBlockCode?.includes(
                      Federal_BlockCodes.PAY_BILL_DISABLE,
                    )
                      ? "0.4"
                      : "1",
                  backgroundColor: theme.color1,
                  border: `2px solid ${theme.color1}`,
                  color: theme.color2,
                }}
                onClick={() => {
                  session.disabledFunctionsByBlockCode?.includes(
                    Federal_BlockCodes.PAY_BILL_DISABLE,
                  ) ||
                  session?.disabledFunctionsByBlockCode?.includes(
                    AU_BlockCodes.PAY_BILL_DISABLE,
                  ) ? (
                    <></>
                  ) : (
                    repayUrlCall()
                  )
                  captureEvents({
                    eventName: EventName.PAY_BILL,
                    metadata: { source: EventName.HOME },
                  })
                }}
              >
                Pay Bill
              </div>
            </div>
          </div>
        ) : (
          isOpen && (
            <div
              className={
                props.showReminder.show
                  ? "cardAndBalance-cardSummary-content-div"
                  : "cardAndBalance-cardSummary-content-div-noReminder"
              }
              style={{
                backgroundColor: theme.color1,
                borderTopRadius: card && !card.isHotlisted ? "0px" : "8px",
              }}
              onClick={() => navigate("/Statement")}
            >
              <div className='cardAndBalance-cardSummary-content-div-1'>
                <div
                  className='cardAndBalance-cardSummary-content-div-1-amount'
                  style={{
                    fontFamily: theme.fontFamily,
                    color: theme.appTextColor,
                  }}
                >
                  {formatAmount(props.showReminder.amount)}
                </div>

                <div
                  className='cardAndBalance-cardSummary-content-div-1-text'
                  style={{
                    fontFamily: theme.fontFamily,
                    color: theme.appTextColor,
                  }}
                >
                  due on
                </div>
                <div
                  className='cardAndBalance-cardSummary-content-div-1-date'
                  style={{
                    fontFamily: theme.fontFamily,
                    color: theme.appTextColor,
                  }}
                >
                  {moment(props.showReminder.dueDate).format(
                    preferences.dateFormat,
                  )}
                </div>
                <div>
                  <ChevronRightIcon color={theme.appTextColor} size='6' />
                </div>
              </div>
            </div>
          )
        )}
        {/**Pay Reminder Setio ends  */}
      </div>
    </div>
  )
}

export default CardAndBalance_v2
