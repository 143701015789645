import React from "react"
import { Text, Button } from "native-base"
import { useSelector } from "react-redux"
import { PwaVersions } from "../../utils/enums"

const btnStyles = {
  alignItems: "center",
  justifyContent: "center",
  borderWidth: "2px",
  py: "14px",
  borderRadius: "6px",
}

const ColorButton = props => {
  const [theme, config] = useSelector(state => [state.theme, state.config])
  return (
    <Button
      borderColor={
        props.isDisable && config?.version === PwaVersions.V2_1
          ? "#A7A7A7"
          : props.primaryColor || theme.color1
      }
      {...props}
      {...btnStyles}
      width={props.width || "90%"}
      isDisabled={props.isDisable}
      borderWidth={config?.version === PwaVersions.V2 ? "0px" : "2px"}
      backgroundColor={
        props.isDisable
          ? config?.version === PwaVersions.V2_1
            ? "#A7A7A7"
            : `${theme.color1}:alpha.70` || `${props.primaryColor}:alpha.70`
          : `${theme.color1}`
      }
      opacity={1}
    >
      <Text
        color={
          config?.version === PwaVersions.V2 ||
          config?.version === PwaVersions.V2_1
            ? props.secondaryColor || theme.color2
            : "#fff"
        }
        fontFamily={theme.fontFamily}
        fontSize={props.fontSize || "16px"}
        fontWeight='600'
      >
        {props.text}
      </Text>
    </Button>
  )
}

const WhiteButton = props => {
  const [theme, config] = useSelector(state => [state.theme, state.config])
  return (
    <Button
      onPress={() => props.onPress()}
      mt='8px'
      bgColor={
        config?.version === PwaVersions.V2 ||
        config?.version === PwaVersions.V2_1
          ? theme.color2
          : "#fff"
      }
      borderColor={
        props.isDisable && config?.version === PwaVersions.V2_1
          ? "#A7A7A7"
          : props.primaryColor || theme.color1
      }
      {...btnStyles}
      borderWidth={config?.version === PwaVersions.V2 ? "0px" : "2px"}
      width={props.width || "90%"}
    >
      <Text
        color={theme.color1}
        fontFamily={theme.fontFamily}
        fontSize='16px'
        fontWeight='600'
      >
        {props.text}
      </Text>
    </Button>
  )
}

export { ColorButton, WhiteButton }
