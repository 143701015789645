import {
  SET_USER,
  SET_CUSTOMER,
  SET_ACCOUNT,
  SET_CARDS,
  SET_SUMMARY,
  SET_EMI_DETAILS,
  SET_REWARD,
  SET_SPEND_GROUP,
  SET_SPEND_CATEGORY,
  SET_CARD_REPLACEMENT_FEE,
  SET_REWARD_SUMMARY,
  SET_ACTIVATION_CODE,
} from "../ActionTypes"
import { ProgramTypes } from "../../utils/constants"

const initialState = {
  customer: {},
  cards: [],
  account: {},
  summary: null,
  programType: ProgramTypes.PAY_LATER,
  enablePciWidget: false,
  enableManageEMI: false,
  enableBalanceEMI: false,
  enableTransactionEMI: false,
  rewardsSummary: {
    currentValue: null,
  },
  activationCode: null,
}

const UserReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_USER:
      return {
        ...state,
        customer: action.payload.customer,
        cards: action.payload.cards,
        account: action.payload.account,
        programType: action.payload.programType,
        enablePciWidget: action.payload.enablePciWidget,
      }
    case SET_CUSTOMER:
      return {
        ...state,
        customer: action.payload.customer,
      }
    case SET_ACCOUNT:
      return {
        ...state,
        account: action.payload.account,
      }
    case SET_CARDS:
      return {
        ...state,
        cards: action.payload.cards,
      }
    case SET_EMI_DETAILS:
      return {
        ...state,
        enableManageEMI: action.payload.enableManageEMI,
        enableBalanceEMI: action.payload.enableBalanceEMI,
        enableTransactionEMI: action.payload.enableTransactionEMI,
      }
    case SET_SUMMARY:
      return {
        ...state,
        summary: action.payload.summary,
      }
    case SET_REWARD:
      return {
        ...state,
        aggregate: action.payload.aggregate,
      }
    case SET_SPEND_GROUP:
      return {
        ...state,
        spendGroup: action.payload.spendGroup,
      }
    case SET_SPEND_CATEGORY:
      return {
        ...state,
        spendCategory: action.payload.spendCategory,
      }
    case SET_CARD_REPLACEMENT_FEE:
      return {
        ...state,
        cardReplacementFee: action.payload.cardReplacementFee,
      }
    case SET_REWARD_SUMMARY:
      return {
        ...state,
        rewardSummary: action.payload.rewardSummary,
      }
    case SET_ACTIVATION_CODE:
      return {
        ...state,
        activationCode: action.payload.activationCode
      }
    default:
      return state
  }
}

export default UserReducer
