import { Text, View } from "native-base"
import React from "react"
import { useDispatch, useSelector } from "react-redux"
import { useNavigate } from "react-router-dom"
import Paths from "../../../routes/Paths"
import { clearCommonState } from "../../../store/actions/Common"
import { ProgramTypes } from "../../../utils/constants"
import { PwaVersions } from "../../../utils/enums"
import CardInHand from "../../svg/cardInHand"
import styles from "./styles"

const getProgramType = user => {
  if (user.programType === ProgramTypes.CREDIT_CARD) return " credit "
  else if (user.programType === ProgramTypes.PAY_LATER) return " pay later "
  else return " "
}
const Nudge = props => {
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const [theme, user, config, featureFlags, screen] = useSelector(state => [
    state.theme,
    state.user,
    state.config,
    state.featureFlags,
    state.screen
  ])

  const onClickNavigation = () => {
    if (props?.to) {
      return props.to
    } else if (
      config.version === PwaVersions.V1 ||
      ((config?.version === PwaVersions.V2 ||
        config?.version === PwaVersions.V2_1) &&
        screen?.home?.isQrCodeRequiredForActivation)
    ) {
      return "/ActivateCard/Scanner"
    } else {
      return Paths.HOME_WITH_ACTIVATE
    }
  }

  const getBgColor = () => {
    if (
      config?.version === PwaVersions.V2 ||
      config?.version === PwaVersions.V2_1
    ) {
      return theme.widgetBackgroundColor
    } else if (user.programType === ProgramTypes.PREPAID) {
      return `${theme.color1}:alpha.10`
    } else {
      return theme.color3
    }
  }

  return (
    <div style={{ width: props.width }}>
      <div
        onClick={() => {
          navigate("/SessionExpired", { replace: true })
          // home component needs to be remounted if path is HOME_WITH_ACTIVATE

          // clear home component state to mimic a remount
          dispatch(clearCommonState())
          navigate(onClickNavigation(), { replace: true })
        }}
      >
        {
          <div
            className='nudge'
            style={{
              backgroundColor: getBgColor(),
              boxShadow:
                config?.version === PwaVersions.V2 ||
                config?.version === PwaVersions.V2_1
                  ? 2
                  : 0,
            }}
          >
            {/* Activate Physical Card Section */}
            <div>
              <CardInHand />
            </div>
            <div className='nudge-text'>
              <div
                className='nudge-heading'
                style={{
                  fontFamily: theme.fontFamily,
                  color:
                    config?.version === PwaVersions.V2 ||
                    config?.version === PwaVersions.V2_1
                      ? theme.appTextColor
                      : null,
                }}
              >
                {props.text ? props.text : "Activate Physical Card"}
              </div>
              <div>
                {props.subText ? (
                  <div
                    className='nudge-subHeading'
                    style={{
                      fontFamily: theme.fontFamily,
                      color:
                        config?.version === PwaVersions.V2 ||
                        config?.version === PwaVersions.V2_1
                          ? theme.appTextColor
                          : null,
                    }}
                  >
                    {props.subText}
                  </div>
                ) : (
                  <div
                    className='nudge-subHeading'
                    style={{
                      fontFamily: theme.fontFamily,
                      color:
                        config?.version === PwaVersions.V2 ||
                        config?.version === PwaVersions.V2_1
                          ? theme.appTextColor
                          : null,
                    }}
                  >
                    Enable offline usage of your
                    {getProgramType(user)}
                    card
                  </div>
                )}
              </div>
            </div>
          </div>
        }
      </div>
    </div>
  )
}

export default Nudge
