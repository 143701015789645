import DOMPurify from "dompurify"
import { ChevronLeftIcon, ChevronRightIcon, Text, View } from "native-base"
import React from "react"
import { useSelector } from "react-redux"
import { useNavigate } from "react-router-dom"
import { DisplayConfig } from "../../utils/constants"
import { captureEvents } from "../../utils/functions"
import { EventName } from "../../utils/enums"

export const Header = ({
  text,
  onBack,
  rightProp,
  rightNavigateURL,
  modalOpenFuntion,
  textColor,
  isHomePage,
  headerBackgroundColor,
  customClassName,
  ...otherProps
}) => {
  const theme = useSelector(state => state.theme)
  const config = useSelector(state => state.config)
  const screen = useSelector(state => state.screen)
  const session = useSelector(state => state.session)
  const navigate = useNavigate()
  let headerData = screen.header

  return (
    <View>
      {/* View For Above Header Bar */}

      {headerData?.isEnabled ? (
        <View
          display='none'
          _web={{ maxW: DisplayConfig.MAX_WIDTH, display: "flex" }}
          zIndex='1'
          style={{
            boxShadow: "black 0px 3px 7px -4px",
            backgroundColor: headerData?.color || "#FFFFFF",
          }}
          width='100%'
          flexDirection='row'
          px='12px'
          margin='auto'
        >
          <Text
            flex='2'
            textAlign='left'
            display='flex'
            margin='auto'
            py='10px'
          >
            <div
              dangerouslySetInnerHTML={{
                __html: DOMPurify.sanitize(config.clientHeaderLeftElement),
              }}
            ></div>
          </Text>

          <Text
            flex='5'
            display='flex'
            margin='auto'
            py='10px'
            justifyContent='center'
          >
            <div
              dangerouslySetInnerHTML={{
                __html: DOMPurify.sanitize(config.clientHeaderCenterText),
              }}
            ></div>
          </Text>

          <Text
            flex='2'
            display='flex'
            margin='auto'
            py='10px'
            justifyContent='flex-end'
          >
            <div
              dangerouslySetInnerHTML={{
                __html: DOMPurify.sanitize(config.clientHeaderRightElement),
              }}
            ></div>
          </Text>
        </View>
      ) : (
        <View></View>
      )}

      <View
        width='100%'
        display='flex'
        flexDirection='row'
        alignItems='center'
        shadow={2}
        backgroundColor={
          headerBackgroundColor
            ? headerBackgroundColor
            : theme.headerBackgroundColor || theme.color1
        }
        px='12px'
        pl={isHomePage ? "24px" : "4px"}
        py='16px'
        mb='24px'
        {...otherProps}
      >
        {onBack && (
          <View
            position='absolute'
            left='10px'
            onClick={() => {
              captureEvents({ eventName: EventName.BACK_BUTTON_CTA, metadata: { lastEvent: session?.eventFlow } })
              onBack()
            }
            }
            cursor='pointer'
          >
            <ChevronLeftIcon size='24px' color={theme.titleColor || "#fff"} />
          </View>
        )}

        <div className={`default-header-heading ${customClassName}`}
          style={{color: textColor ? textColor: theme.titleColor || "#fff"}}
        >
          {text}
        </div>

        {rightNavigateURL ? (
          <View cursor='pointer' onClick={() => navigate(rightNavigateURL)}>
            {rightProp}
          </View>
        ) : (
          <>
            <View
              onClick={() => modalOpenFuntion(true)}
              cursor='pointer'
              color={theme.color2}
              fontSize='14px'
              lineHeight='20px'
              fontWeight='500'
            >
              {rightProp}
            </View>
            {rightProp === "EMI Table" ? (
              <ChevronRightIcon color={theme.color2} size={6} />
            ) : (
              <></>
            )}
          </>
        )}
      </View>
    </View>
  )
}
