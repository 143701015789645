const styles = {
  mainView: {
    backgroundColor: "#f8f8f8",
    py: "10",
    px: "5",
  },
  toast: {
    fontWeight: "600",
    fontSize: "16px",
    lineHeight: "19px",
    letterSpacing: "0.01em",
  },

  loader: { w: "100%", alignItems: "center", justifyContent: "center" },
  header: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    marginBottom: 5,
  },
  manageCard: {
    width: "100%",
    fontSize: 18,
    fontFamily: "Inter",
    fontWeight: 500,
    textAlign: "center",
  },
  manageCardFont: {
    color: "#7f98ff",
    backgroundColor: "#f0f6ff",
    padding: 8,
    borderRadius: 25,
  },
  fontText: {
    fontSize: "16",
    fontFamily: "Inter",
    fontWeight: 500,
    ml: "3",
  },
  cardControls: {
    backgroundColor: "#FFF",
    px: 5,
    py: 3,
    borderRadius: 7,
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    cursor: "pointer",
    marginBottom: 2,
  },
  perTransaction: {
    backgroundColor: "#FFF",
    px: 5,
    py: 4,
    borderRadius: 7,
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    cursor: "pointer",
    marginBottom: 3,
  },
  resetPin: {
    backgroundColor: "#FFF",
    px: 5,
    py: 4,
    borderRadius: 7,
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    cursor: "pointer",
    marginBottom: 3,
  },
  imageStyle: {
    resizeMode: "stretch",
    width: "95%",
    height: 220,
    marginTop: 15,
    marginLeft: "2.5%",
  },
  payNow: {
    backgroundColor: "#FFF",
    px: 5,
    py: 4,
    borderRadius: 7,
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    cursor: "pointer",
    marginBottom: 10,
  },
  commonStyles: {
    width: "100%",
    borderRadius: "12px",
    borderWidth: "1px",
    borderColor: "#ECECEC",
    shadow: 2,
  },
}

export default styles
