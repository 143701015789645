import React, { useState } from "react"
import { View, Text, Image, ScrollView, useToast } from "native-base"
import { useSelector } from "react-redux"
import { useWindowDimensions } from "react-native"
import { useLocation } from "react-router-dom"
import queryString from "query-string"
import verifyOTP from "../../../assets/images/verifyOtp.svg"
import setupPin from "../../../assets/images/setupPin.svg"
import cardActivated from "../../../assets/images/cardActivated.svg"
import lineSeparator from "../../../assets/images/lineSeparator.svg"
import PublicService from "../../../services/PublicService"
import { ColorButton } from "../../core/buttons"
import { showToast1 } from "../../core/toast"
import { consoleError } from "../../../utils/functions"
import styles from "./styles"
import { EXTERNAL_PATH_ROOT } from "../../../utils/constants"

const ContextSetting = () => {
  const windowDimensions = useWindowDimensions()
  const location = useLocation()
  const nativeToast = useToast()
  const theme = useSelector(state => state.theme)

  const [submitLoading, setSubmitLoading] = useState(false)

  const toast = message => {
    showToast1({ nativeToast, theme, message })
  }

  const getResetPinWidget = async () => {
    const queryParams = queryString.parse(location.search)

    if (!queryParams?.activationCode) {
      consoleError("Invalid activation code")
      toast("Invalid activation code")
      return
    }

    setSubmitLoading(true)
    try {
      const getInfoResponse = await PublicService.getInfoFromActivationCode({
        activationCode: queryParams?.activationCode,
      })
      const getInfoResult = getInfoResponse.data

      if (getInfoResult?.success) {
        const getResetPinResponse = await PublicService.getResetPinWidget({
          cardId: getInfoResult.data?.cardId,
          callbackUrl:
            process.env.REACT_APP_URL +
            `/${EXTERNAL_PATH_ROOT}/CardActivation/CardActive`,
        })
        const getResetPinResult = getResetPinResponse.data

        if (getResetPinResult?.success) {
          localStorage.setItem("cardId", getInfoResult.data?.cardId)
          window.location.href = getResetPinResult.data
        } else {
          consoleError(getResetPinResult?.errors)
          toast("An error occurred while requesting OTP")
        }
      } else {
        consoleError(getInfoResult?.errors)
        toast("An error occurred while requesting OTP")
      }
    } catch (error) {
      consoleError(error)
      toast("An error occurred while requesting OTP")
    }
    setSubmitLoading(false)
  }

  return (
    <ScrollView>
      <View height={windowDimensions.height} minHeight='900px'>
        <View {...styles.mainView}>
          {/* <View alignItems="center" justifyContent="center">
            <View my="16px">
              {orientation === "Vertical" ? (
                <Image
                  source={{ uri: VerticalCard }}
                  height={"343px"}
                  width={"216px"}
                />
              ) : (
                <Image
                  source={{ uri: HorizontalCard }}
                  height={"216px"}
                  width={"343px"}
                />
              )}
            </View>
          </View> */}
          <View mt='16px'>
            <Text {...styles.clientText} fontFamily={theme.fontFamily}>
              Activate your card in 4 easy steps
            </Text>
            <Text
              {...styles.physicalpayLatertext}
              fontFamily={theme.fontFamily}
            >
              Activate your physical card for all Offline Transactions
            </Text>
          </View>
          <View {...styles.processView}>
            <View {...styles.processMainView}>
              <Image source={{ uri: verifyOTP }} {...styles.processImage} />
              <View ml='3'>
                <Text {...styles.processStep} fontFamily={theme.fontFamily}>
                  Step 1
                </Text>
                <Text {...styles.processStepName} fontFamily={theme.fontFamily}>
                  Verify Card details
                </Text>
              </View>
            </View>
            <Image source={{ uri: lineSeparator }} {...styles.lineSeparator} />
            <View>
              <View {...styles.processIndividualStep}>
                <Image source={{ uri: setupPin }} {...styles.processImage} />
                <View ml='3'>
                  <Text {...styles.processStep} fontFamily={theme.fontFamily}>
                    Step 2
                  </Text>
                  <Text
                    {...styles.processStepName}
                    fontFamily={theme.fontFamily}
                  >
                    Enter OTP & Date of Birth
                  </Text>
                </View>
              </View>
            </View>
            <Image source={{ uri: lineSeparator }} {...styles.lineSeparator} />
            <View>
              <View {...styles.processIndividualStep}>
                <Image source={{ uri: setupPin }} {...styles.processImage} />
                <View ml='3'>
                  <Text {...styles.processStep} fontFamily={theme.fontFamily}>
                    Step 3
                  </Text>
                  <Text
                    {...styles.processStepName}
                    fontFamily={theme.fontFamily}
                  >
                    Setup PIN
                  </Text>
                </View>
              </View>
            </View>
            <Image source={{ uri: lineSeparator }} {...styles.lineSeparator} />
            <View>
              <View {...styles.processIndividualStep}>
                <Image
                  source={{ uri: cardActivated }}
                  {...styles.processImage}
                />
                <View ml='3'>
                  <Text {...styles.processStep} fontFamily={theme.fontFamily}>
                    Step 4
                  </Text>
                  <Text
                    {...styles.processStepName}
                    fontFamily={theme.fontFamily}
                  >
                    Card Activated
                  </Text>
                </View>
              </View>
            </View>
          </View>
        </View>
        <View {...styles.buttonContainer}>
          <ColorButton
            isLoading={submitLoading}
            text='Request OTP'
            onPress={() => getResetPinWidget()}
          />
        </View>
      </View>
    </ScrollView>
  )
}

export default ContextSetting
