import { toast } from "react-toastify"

const coreToast = (content, options) => {
  const finalOptions = {
    position: toast.POSITION.BOTTOM_CENTER,
    autoClose: 3000,
    hideProgressBar: true,
    closeButton: false,
    className: "hf-toast",
    bodyClassName: "hf-toast-body",
    ...options,
  }

  toast(content, finalOptions)
}

const coreToast2 = (content, options) => {
  coreToast(content, {
    className: "hf-toast-2",
    bodyClassName: "hf-toast-2-body",
    ...options,
  })
}

export { coreToast as toast, coreToast2 as toast2 }
