import { Label } from "./Label"

const Checkbox = ({
  className = "",
  checkmarkClassName = "",
  style,
  label,
  value,
  isDisabled,
  isChecked,
  onChange,
  ...props
}) => {
  return (
    <div className='hf-checkbox-container'>
      <input
        type='checkbox'
        className={`hf-checkbox ${className}`}
        style={style}
        disabled={isDisabled}
        checked={isChecked}
        onChange={onChange}
        {...props}
      />
      <div className={`hf-checkbox-checkmark ${checkmarkClassName}` }></div>
      {label &&
        (typeof label === "string" ? (
          <Label className='hf-checkbox-label'>{label}</Label>
        ) : (
          <div className='hf-checkbox-label'>{label}</div>
        ))}
    </div>
  )
}

export { Checkbox }
