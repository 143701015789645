import {
  SET_AUTH_STATE,
  SET_VERIFIED_CHALLENGES,
  SET_DEVICE_ID,
  SET_CURRENT_FACTOR,
  SET_AFA_DETAILS,
  SAVE_STATE_BEFORE_SET_MPIN,
  RESTORE_STATE_BEFORE_SET_MPIN,
  SET_IS_MPIN_SET,
} from "../ActionTypes"

export const setAuthState = data => dispatch => {
  dispatch({
    type: SET_AUTH_STATE,
    payload: data,
  })
}

export const setVerifiedChallenges = data => dispatch => {
  dispatch({
    type: SET_VERIFIED_CHALLENGES,
    payload: data,
  })
}

export const setCurrentFactor = data => dispatch => {
  dispatch({
    type: SET_CURRENT_FACTOR,
    payload: data,
  })
}

export const setAfaDetails = data => dispatch => {
  dispatch({
    type: SET_AFA_DETAILS,
    payload: data,
  })
}

export const saveStateBeforeSetMpin = () => dispatch => {
  dispatch({
    type: SAVE_STATE_BEFORE_SET_MPIN,
  })
}

export const restoreStateBeforeSetMpin = () => dispatch => {
  dispatch({
    type: RESTORE_STATE_BEFORE_SET_MPIN,
  })
}

export const setDeviceId = data => dispatch => {
  localStorage.setItem("deviceId", data.deviceId)

  dispatch({
    type: SET_DEVICE_ID,
    payload: data,
  })
}

export const setIsMpinSet = data => dispatch => {
  dispatch({
    type: SET_IS_MPIN_SET,
    payload: data,
  })
}
