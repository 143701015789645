import React, { useEffect } from "react"
import { useWindowDimensions } from "react-native"
import { useSelector } from "react-redux"
import { View, Text, Image, ScrollView } from "native-base"
import { useLocation, useNavigate } from "react-router-dom"
import styles from "../styles"
import cardImg from "../../../../../assets/images/Card_Blocked.png"

import { ColorButton, WhiteButton } from "../../../../core/buttons"
import moment from "moment"
import { DisplayConfig } from "../../../../../utils/constants"

const CardBlocked_v1 = () => {
  const navigate = useNavigate()
  const window = useWindowDimensions()
  const [theme, user] = useSelector(state => [state.theme, state.user])
  const location = useLocation()

  const date = moment().format("DD-MM-YYYY")

  useEffect(() => {
    // this screen comes after block card reason screen

    // if account is inactive or card was not issued, user wouldn't have reached the block card reason screen
    // if card is not hotlisted then user didn't successfully submit the block card reason
    if (
      user.account.status !== "ACTIVE" ||
      !user.cards[0] ||
      !user.cards[0].isHotlisted
    ) {
      navigate("/")
    }

    // reaching this point means card hotlisted, but this screen should only come after the block card reason screen.
    if (location.state?.from !== "blockCard") {
      navigate("/ManageCard/NewCardRequest")
    }
  }, [navigate, user.account.status, user.cards, location.state?.from])

  const requestNewCard = () => {
    navigate("/ManageCard/NewCardRequest")
  }
  const returnHome = () => {
    navigate("/")
  }

  return (
    <View alignItems='center' justifyContent='center'>
      <View _web={{ maxW: DisplayConfig.MAX_WIDTH }} w='100%'>
        <ScrollView>
          <View
            {...styles.mainContainer}
            height={window.height}
            minHeight='560px'
          >
            <View>
              {/* body content start */}
              <View {...styles.body}>
                <Text {...styles.header} fontFamily={theme.fontFamily}>
                  {" "}
                  Block Card
                </Text>
              </View>
              {/* body title end */}

              {/* content box start */}
              <View {...styles.cardBlockedContentBox}>
                <View alignItems='center'>
                  <Image
                    source={{ uri: cardImg }}
                    width='196px'
                    height='120px'
                    alt='Alternate Text '
                  />
                </View>
                {/* content box Image End */}
                <View {...styles.heroTextSection}>
                  <View {...styles.cardBlockedTextsection}>
                    <Text
                      {...styles.cardBlockedText}
                      fontFamily={theme.fontFamily}
                    >
                      Your Card is blocked effective {date}
                    </Text>
                  </View>
                </View>
                {/* content box Text end */}
              </View>
              {/* content box start */}

              {/* body content end */}
            </View>

            {/*    CTA Section */}
            <View {...styles.CTAsection}>
              <ColorButton
                text='Request a New Card'
                isDisable={false}
                onPress={requestNewCard}
              />
              <WhiteButton text='Return to Home Screen' onPress={returnHome} />
            </View>
          </View>
        </ScrollView>
      </View>
    </View>
  )
}

export default CardBlocked_v1
