import React, { useState } from "react"
import { View, Text } from "native-base"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { useSelector } from "react-redux"
import { faChevronDown, faChevronUp } from "@fortawesome/free-solid-svg-icons"
import styles from "../../Accordion/styles"
import StatementAccordionBody from "../StatementAccordionBody/StatementAccordionBody"
import moment from "moment"
import { formatAmount, formatNegativeAmount } from "../../../../utils/functions"
import { PwaVersions } from "../../../../utils/enums"

const StatementAccordions = ({ statements, yearIndex }) => {
  const [theme, config] = useSelector(state => [state.theme, state.config])

  // only the first statement of first year should be open initially
  const [isOpened, setIsOpened] = useState(
    yearIndex === 0
      ? new Array(statements.length)
          .fill(false, 0, 1)
          .fill(true, 1, statements.length)
      : new Array(statements.length).fill(true, 0, statements.length),
  )
  // having states for all accordions in an array allows to have feature of closing one accordion when another opens

  const toggle = index => {
    const temp = [...isOpened]
    temp[index] = !isOpened[index]
    setIsOpened(temp)
  }
  return config?.version === PwaVersions.V1 ? (
    <View {...styles.mainView}>
      {statements.length > 0 ? (
        statements.map((statement, index) => (
          <View key={index}>
            <View {...styles.statementHeader} onClick={() => toggle(index)}>
              <Text {...styles.periodText} fontFamily={theme.fontFamily}>
                {moment(statement.fromDate).format("Do MMM")} -{" "}
                {moment(statement.toDate).format("Do MMM")}
              </Text>
              <Text {...styles.periodAmount} fontFamily={theme.fontFamily}>
                {formatNegativeAmount(formatAmount(statement.totalAmountDue))}
              </Text>
              <FontAwesomeIcon
                icon={isOpened[index] ? faChevronDown : faChevronUp}
                size='xs'
                style={{ width: "20%", float: "right", color: "#777777" }}
              />
            </View>

            {index !== statements.length - 1 && (
              <View borderBottomWidth={1} width='100%' borderColor='#ededed' />
            )}
            <StatementAccordionBody
              statement={statement}
              statementIndex={index}
              isOpened={isOpened[index]}
            />
          </View>
        ))
      ) : (
        <View m='8px' alignItems='center' justifyContent='center'>
          <Text
            textAlign='center'
            fontFamily={theme.fontFamily}
            {...styles.emptyStateText}
          >
            No Statements found
          </Text>
        </View>
      )}
    </View>
  ) : (
    <View>
      {statements.length > 0 ? (
        statements.map((statement, index) => (
          <View
            key={index}
            borderRadius={"12px"}
            borderWidth={"1px"}
            borderColor='#ECECEC'
            bgColor={theme.widgetBackgroundColor}
            shadow={"3"}
            mb='12px'
            mx='16px'
          >
            <View
              display={"flex"}
              alignItems='center'
              flexDir={"row"}
              justifyContent='space-between'
              py='16px'
              px='12px'
              onClick={() => toggle(index)}
            >
              <Text
                fontWeight='400'
                fontSize='16px'
                lineHeight='22px'
                color={theme.appTextColor}
                fontFamily={theme.fontFamily}
              >
                {moment(statement.fromDate).format("Do MMM")} -{" "}
                {moment(statement.toDate).format("Do MMM")}
              </Text>

              <FontAwesomeIcon
                cursor='pointer'
                icon={isOpened[index] ? faChevronDown : faChevronUp}
                size='24px'
                style={{
                  width: "20%",
                  float: "right",
                  color: theme.appTextColor,
                }}
              />
            </View>

            {index !== statements.length - 1 && (
              <View width='100%' borderColor='#ededed' />
            )}
            <StatementAccordionBody
              statement={statement}
              statementIndex={index}
              isOpened={isOpened[index]}
            />
          </View>
        ))
      ) : (
        <View m='8px' alignItems='center' justifyContent='center'>
          <Text
            textAlign='center'
            fontFamily={theme.fontFamily}
            {...styles.emptyStateText}
            color={theme.appTextColor}
          >
            No Statements found
          </Text>
        </View>
      )}
    </View>
  )
}

export default StatementAccordions
