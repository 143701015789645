import React from "react"
import { View, Text, Image } from "native-base"
import styles from "./styles"

import emptyTransaction from "../../../assets/images/emptyTransaction.svg"

const Empty = props => {
  return (
    <View
      mt='30%'
      alignItems='center'
      justifyContent='center'
      alignSelf='center'
    >
      <View alignItems='center' justifyContent='center'>
        <View
          bgColor={"#F8F8F8"}
          px='15px'
          py='25px'
          borderRadius='100px'
          mt='40px'
        >
          <Image source={{ uri: emptyTransaction }} {...styles.emptyImage} />
        </View>
        <View mt='32px' alignItems='center' justifyContent='center'>
          <Text
            textAlign='center'
            fontFamily={props.fontFamily}
            {...styles.emptyStateText}
          >
            No transactions found.
          </Text>
          <Text
            textAlign='center'
            mt='24px'
            fontFamily={props.fontFamily}
            {...styles.emptyStateText}
          >
            Start using your card and keep a track of your transactions here.
          </Text>
        </View>
      </View>
    </View>
  )
}

export default Empty
