export const FLOAT_REGEX = /^\d*\.?\d*$/
export const INTEGER_REGEX = /^\d*?\d*$/
export const EXTERNAL_PATH_ROOT = "external"
export const PHYSICAL_CARD_ACTIVATION_PATH = "PhysicalCardActivation"
export const TRANSACTION_LOGO_WIDTH = 50
export const TRANSACTION_LOGO_HEIGHT = 50
export const DEFAULT_OTP_SMS_SIZE = 4
export const DEFAULT_OTP_EMAIL_SIZE = 4
export const DEFAULT_MPIN_SIZE = 6
export const HEADER_HEIGHT_IN_PX = 70

export const Envs = Object.freeze({
  DEV: "dev",
  UAT: "uat",
  SANDBOX: "sandbox",
  PROD: "prod",
  AU_SANDBOX: "au-sandbox",
})

export const ProgramTypes = Object.freeze({
  CREDIT_CARD: "CREDIT_CARD",
  PAY_LATER: "PAY_LATER",
  PREPAID: "PREPAID",
  OTHER: "OTHER",
})

export const CardOrientation = Object.freeze({
  HORIZONTAL: "HORIZONTAL",
  VERTICAL: "VERTICAL",
})

export const AuthChallenges = Object.freeze({
  OTP_SMS: "OTP_SMS",
  OTP_EMAIL: "OTP_EMAIL",
  MPIN: "MPIN",
  VERIFY_CUSTOMER: "VERIFY_CUSTOMER",
})

export const AuthScopes = Object.freeze({
  FIRST_FACTOR: "FIRST_FACTOR",
  SECOND_FACTOR: "SECOND_FACTOR",
  STEP_UP: "STEP_UP",
})

export const EmiStatus = Object.freeze({
  NOT_CREATED: "NOT_CREATED",
  PENDING: "PENDING",
  ACTIVE: "ACTIVE",
  CANCELLED: "CANCELLED",
  FORECLOSED: "FORECLOSED",
  CLOSED: "CLOSED",
})

export const OtpErrors = Object.freeze({
  RETRIES_EXCEEDED: "RETRIES_EXCEEDED",
  BLOCKED_TEMPORARILY: "BLOCKED_TEMPORARILY",
  INVALID_OTP: "INVALID_OTP",
  OTP_EXPIRED: "OTP_EXPIRED",
})

export const MpinErrors = Object.freeze({
  INCORRECT_MPIN: "INCORRECT_MPIN",
  BLOCKED: "BLOCKED",
})

export const TransactionDateTypes = Object.freeze({
  TRANSACTION_DATE: "TRANSACTION_DATE",
  POSTING_DATE: "POSTING_DATE",
})

export const DisplayConfig = Object.freeze({
  MAX_WIDTH: "450px",
})

export const PWA_MAX_WIDTH_IN_PX = 450

//Merchant Category Codes

export const FUEL = [5172,5541,5542,5552,5983]

export const UTILITY = [4812,4814,4899,4900]

export const DINING = [5811,5812,5813,5814]

export const GROCERY = [5411,5422,5441,5451,5462,5499,5921]

export const ELECTRONICS = [4816,4812,5722,5044,5045,5732,5734,7622]

export const CASH_WITHDRAWAL = [6010,6011]

export const TRAVEL = [3000,3001,3004,3005,3006,3007,3008,3009,3010,3011,3012,3013,3014,3015,3016,3017,3018,3020,3021,3022,3023,3024,3025,3026,3027,3028,3029,3030,3031,3032,3034,3035,3037,3038,3039,3040,3042,3043,3044,3045,3047,3048,3049,3050,3051,3052,3054,3058,3061,3064,3066,3068,3069,3071,3072,3075,3076,3077,3078,3079,3082,3083,3084,3085,3088,3089,3090,3096,3098,3099,3100,3102,3103,3111,3112,3115,3127,3129,3132,3136,3144,3146,3156,3161,3167,3171,3174,3175,3177,3180,3181,3182,3183,3184,3186,3187,3190,3191,3193,3196,3200,3204,3206,3211,3212,3213,3217,3219,3220,3221,3228,3234,3236,3240,3245,3246,3247,3248,3252,3256,3259,3260,3261,3266,3267,3280,3282,3285,3286,3287,3294,3295,3297,3298,3299,3300,3301,4511,4722,4723,4112,4131]

export const RENT = [6513]

export const APPAREL = [5611,5621,5631,5641,5651,5655,5661,5698,5699,5139,5697,5941,7296]