import { View } from "native-base"
import React from "react"
import { useWindowDimensions } from "react-native-web"
import { useSelector } from "react-redux"
import { useNavigate } from "react-router-dom"
import { DisplayConfig } from "../../utils/constants"
import Close from "../svg/close"

const Payment = () => {
  const [session] = useSelector(state => [state.session])
  const window = useWindowDimensions()
  const navigate = useNavigate()
  return (
    <View alignItems='center' justifyContent='center'>
      <View _web={{ maxW: DisplayConfig.MAX_WIDTH }} w='100%'>
        <View
          w='100%'
          alignItems='flex-end'
          p='16px'
          onClick={() => navigate(-1)}
        >
          <Close />
        </View>
        <View>
          <iframe
            height={window.height - 64}
            title='Card Details Widget'
            allow='clipboard-write'
            src={session.repaymentUrl}
          />
        </View>
      </View>
    </View>
  )
}

export default Payment
