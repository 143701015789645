import React from "react"
import { CardSkeleton, TextWrapperSkeleton } from "./styledComponents.js"

function TopWrapper() {
  return (
    <CardSkeleton
      margin='0px 0px 0px 10px'
      width='95%'
      height='100px'
      padding='15px'
      display='flex'
      justifyContent='space-between'
      alignItems='center'
    >
      <TextWrapperSkeleton width='55px' height='50px' br='60%' />

      <TextWrapperSkeleton margin='0px 0px 0px -130px' width='50px' />

      <CardSkeleton>
        <TextWrapperSkeleton width='50px' />
        <TextWrapperSkeleton width='50px' margin='20px auto' />
      </CardSkeleton>
    </CardSkeleton>
  )
}

function ListWrapper() {
  return (
    <CardSkeleton
      margin='0px 0px 0px 10px'
      width='95%'
      height='100px'
      padding='15px'
      display='flex'
      justifyContent='space-between'
      alignItems='center'
    >
      <CardSkeleton>
        <TextWrapperSkeleton width='50px' />
        <TextWrapperSkeleton width='100px' margin='20px auto' />
      </CardSkeleton>
      <TextWrapperSkeleton margin='0px 0px 0px -130px' width='50px' />
    </CardSkeleton>
  )
}

function EmiListLoader() {
  return (
    <>
      <CardSkeleton
        br='0px 0px 0px 0px'
        width='100%'
        display='flex'
        justifyContent='center'
        alignItems='center'
        height="56px"
      >
        <TextWrapperSkeleton height='10px' width='200px' />
      </CardSkeleton>

      <CardSkeleton
        margin='0px auto 45px auto'
        width='100%'
        display='flex'
        justifyContent='space-between'
      >
        <CardSkeleton width='97%' br='0px 0px 0px 0px' height='50px'>
          <TextWrapperSkeleton height='10px' margin='20px auto' width='150px' />
        </CardSkeleton>
        <CardSkeleton width='97%' br='0px 0px 0px 0px' height='50px'>
          <TextWrapperSkeleton height='10px' margin='20px auto' width='150px' />
        </CardSkeleton>
      </CardSkeleton>

      <CardSkeleton margin='0px auto' width='90%' height='610px'>
        <TopWrapper />
        <ListWrapper />
        <ListWrapper />
        <ListWrapper />
        <ListWrapper />
        <ListWrapper />
      </CardSkeleton>
    </>
  )
}

export default EmiListLoader
