import React from "react"
import {useSelector} from "react-redux"
import { CardSkeleton, TextWrapperSkeleton } from "./styledComponents.js"

function PlansWrapper() {
  return (
    <CardSkeleton margin="10px auto" width="90%" height="140px" padding="12px">
    <TextWrapperSkeleton width="100px" />
    <TextWrapperSkeleton margin="10px 0px 50px 0px" width="150px"/>
    <TextWrapperSkeleton  width="90%"/>
    <TextWrapperSkeleton  margin="10px 0px" width="90%"/>
  </CardSkeleton>
  )
}

function ConvertEmiLoader(){
  const[theme] = useSelector(state=>[state.theme])
    return(
        <>
          <CardSkeleton
        width='100%'
        display='flex'
        justifyContent='center'
        alignItems='center'
        br='0'
        height="56px"
      >
        <TextWrapperSkeleton height='12px' width='200px' />
      </CardSkeleton>
      <CardSkeleton width="100%" bg={theme.backgroundColor} alignItems="center" height="200px" justifyContent="center" display="flex" flexDirection="column">
        <CardSkeleton width="80px" height="80px" br="50%"  />
        <CardSkeleton margin="15px" height="10px" width="100px"/>
        <CardSkeleton width="180px" height="10px"/>
      </CardSkeleton>
      <CardSkeleton height="10px" margin="24px" width="150px"/>
      <PlansWrapper/>
      <PlansWrapper/>
      <PlansWrapper/>
      <CardSkeleton height="40px" margin="24px auto" width="80%"/>
      </>
    )
}

export default ConvertEmiLoader;