import moment from "moment"
import {
  ChevronLeftIcon,
  ChevronRightIcon,
  Text,
  View,
  Image,
} from "native-base"
import { React, useEffect, useState } from "react"
import { useWindowDimensions } from "react-native-web"
import { useSelector, useDispatch } from "react-redux"
import { useNavigate } from "react-router-dom"
import { Header } from "../../../core"
import styles from "./styles"

import {
  AU_BlockCodes,
  EventName,
  Federal_BlockCodes,
  PwaVersions,
} from "../../../../utils/enums"
import {
  capitalizeFirsLetter,
  captureEvents,
  consoleError,
  formatAmount,
  formatString,
  truncateString,
} from "../../../../utils/functions"
import { TRANSACTION_LOGO_WIDTH } from "../../../../utils/constants"
import { TRANSACTION_LOGO_HEIGHT } from "../../../../utils/constants"
import DateSelect from "../../../Statement/fetchAll/DateSelect/DateSelect"
import AccountService from "../../../../services/AccountService"
import { setSpendGroup } from "../../../../store/actions/User"
import CustomLoader from "../../../core/loader"
import OtherLogo from "../../../../assets/images/Utility.svg"
import homeEmptyTransaction from "../../../../assets/images/homeEmptyTransaction.svg"

const months = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
]

const AnalyseSpend = () => {
  const [theme, screen, session, user, config] = useSelector(state => [
    state.theme,
    state.screen,
    state.session,
    state.user,
    state.config,
  ])
  const navigate = useNavigate()
  const [isDescFull, setIsDescFull] = useState(false)
  const [currDate, setCurrDate] = useState(moment().date())
  const cycleStartDate = moment(user?.account?.currentCycleStartDate).date()
  const [currYear, setCurrYear] = useState(moment().year())
  const [currMonth, setCurrMonth] = useState(
    user?.spendGroup?.month ||
      months[moment(user?.account?.currentCycleStartDate).month()],
  )
  const [startDate, setStartDate] = useState(
    moment(
      currYear + "-" + (months.indexOf(currMonth) + 1) + "-" + cycleStartDate,
    ),
  )
  const [endDate, setEndDate] = useState(
    moment(user?.account?.currentCycleEndDate),
  )
  const [spendData, setSpendData] = useState([])
  const [spendGroups, setSpendGroups] = useState()
  const [error, setError] = useState(false)
  const [loading, setLoading] = useState(false)
  const currentMonth = moment().month()
  const dispatch = useDispatch()
  const category = screen.analyzeSpends?.spendCategory

  const genrateModifiedData = spendGroupData => {
    const modifiedSpendData = []
    category.forEach(item => {
      let tempObj = item
      const groupId = item.groupingField
      spendGroupData?.spendGroups?.forEach(spendGroup => {
        if (groupId === spendGroup.groupId) {
          tempObj = { ...tempObj, ...spendGroup }
          modifiedSpendData.push(tempObj)
        }
      })
    })
    setSpendData(modifiedSpendData)
  }

  useEffect(() =>{
    captureEvents({eventName:EventName.ANALYSE_SPENDS, metadata:{}})
  },[])

  useEffect(() => {
    spendGroup()
  }, [])

  const spendGroup = async () => {
    if (user?.spendGroup) {
      setStartDate(user?.spendGroup.fromDate)
      setEndDate(user?.spendGroup.toDate)
      setSpendGroup(user?.spendGroup)
      genrateModifiedData(user?.spendGroup)
      return false
    }
    try {
      setLoading(true)
      const response = await AccountService.getSpendGroups(user.account.id, {
        period: {
          type: "BILLING",
          offset: 0,
        },
      })
      console.log(response)
      const result = response.data
      setLoading(false)
      if (result?.success) {
        result.data.fromDate = moment(fromDate)
        result.data.toDate = moment(toDate)
        result.data.month = currMonth
        console.log("Success")
        dispatch(
          setSpendGroup({
            spendGroup: result?.data,
          }),
        )
        setSpendGroup(result?.data)
        genrateModifiedData(result?.data)
      } else {
        console.log(result?.errors)
        consoleError(result?.errors)
        setError(true)
      }
    } catch (error) {
      console.log("Error")
      consoleError(error)
      setError(true)
    }
  }

  let summaryFound = false
  spendData?.forEach(spendGroup => {
    if (spendGroup.countOfTxns > 0) summaryFound = true
  })
  const spendSummary = async (fromDate, toDate) => {
    try {
      setLoading(true)
      const response = await AccountService.getSpendGroups(user.account.id, {
        period: {
          type: "RANGE",
          offset: 0,
          startDate: fromDate.format("YYYY-MM-DD"),
          endDate: toDate.format("YYYY-MM-DD"),
        },
      })
      console.log(response)
      const result = response.data
      setLoading(false)
      if (result?.success) {
        result.data.fromDate = moment(fromDate)
        result.data.toDate = moment(toDate)
        result.data.month = currMonth
        console.log("Success")
        dispatch(
          setSpendGroup({
            spendGroup: result?.data,
          }),
        )

        setSpendGroups(result?.data)
        genrateModifiedData(result?.data)
      } else {
        console.log(result?.errors)
        consoleError(result?.errors)
        setError(true)
      }
    } catch (error) {
      console.log("Error")
      consoleError(error)
      setError(true)
    }
  }
  const windowDimensions = useWindowDimensions()
  let fromDate = moment(
    currYear + "-" + (months.indexOf(currMonth) + 1) + "-" + cycleStartDate,
  )
  let toDate = moment(
    currYear + "-" + (months.indexOf(currMonth) + 2) + "-" + cycleStartDate,
  ).subtract(1, "days")
  const handleSpendSummary = () => {
    setStartDate(moment(fromDate))
    setEndDate(moment(toDate))
    spendSummary(fromDate, toDate)
  }
  const handleCategory = groupId => {
    let spendCategory = {}
    spendData?.forEach(data => {
      if (data.groupingField == groupId) {
        spendCategory = data
      }
    })

    navigate("/ManageCard/AnalyseSpend/Category", {
      state: {
        spendGroupId: groupId,
        spendCategory: spendCategory,
        fromDate: startDate.format("YYYY-MM-DD"),
        toDate: endDate
          ? endDate.format("YYYY-MM-DD")
          : toDate.format("YYYY-MM-DD"),
      },
    })
  }
  const getTotalSpend = () => {
    let totalSpend = 0
    spendData?.map(spendGroup => {
      totalSpend += spendGroup.totalSpend
    })
    return totalSpend
  }

  return (
    <div
      style={{
        minHeight: windowDimensions.height,
        backgroundColor: theme.backgroundColor,
      }}
    >
      <Header
        text='Spend Analysis'
        onBack={async () => {
          window.history.go(-1)
        }}
      />

      <div className='analyseSpends-dateSelect' mt='-16px' zIndex={1}>
        <DateSelect
          onViewStatementsClick={handleSpendSummary}
          setCurrMonth={setCurrMonth}
          setCurrYear={setCurrYear}
          btnText={"View Spend Summary"}
          monthOffset={1}
          defaultMonth={user.spendGroup?.month}
          uptoYear = {moment(user.account.dateCreated).year() }
          uptoMonth = {moment(user.account.dateCreated).month()}
        />
      </div>
      {loading ? (
        <div
          className='analyseSpends-loader'
          style={{
            backgroundColor: `${
              config?.version === PwaVersions.V2 ||
              config?.version === PwaVersions.V2_1
                ? theme.backgroundColor
                : "white"
            }`,
          }}
        >
          <CustomLoader color={theme.color1} width={50} height={50} />
        </div>
      ) : (
        <div className='analyseSpends'>
          <div className='analyseSpends-content'>
            <div
              className='analyseSpends-content-text'
              style={{ color: theme.appTextColor }}
            >
              You have Spent
            </div>
            <div
              className='analyseSpends-content-amount'
              style={{ color: theme.appTextColor }}
            >
              {formatAmount(getTotalSpend())}
            </div>
          </div>
          {summaryFound ? (
            <div
              className='analyseSpends-widget'
              style={{ backgroundColor: theme.widgetBackgroundColor }}
            >
              <div
                className='analyseSpends-widget-header'
                style={{ fontFamily: theme.fontFamily }}
              >
                {startDate.format("Do MMM")} -{" "}
                {endDate && endDate.month() != currentMonth
                  ? endDate.format("Do MMM")
                  : "Today"}
              </div>

              {spendData?.map((spendGroup, index) =>
                spendGroup.countOfTxns > 0 ? (
                  <div
                    className='analyseSpends-widget-transaction'
                    style={{
                      marginBottom:
                        index === spendData.length - 1 ? "-12px" : "12px",
                      borderBottomWidth:
                        index === spendData.length - 1 ? null : "1px",
                    }}
                    key={index}
                  >
                    <div className='analyseSpends-widget-icon-container'>
                      {spendGroup.iconUrl ? (
                        <img
                          {...styles.image}
                          src={spendGroup.iconUrl}
                          alt='logo'
                          width={TRANSACTION_LOGO_WIDTH + "px"}
                          height={TRANSACTION_LOGO_HEIGHT + "px"}
                        />
                      ) : (
                        <img
                          src={OtherLogo}
                          alt='logo'
                          width={TRANSACTION_LOGO_WIDTH + "px"}
                          height={TRANSACTION_LOGO_HEIGHT + "px"}
                        />
                      )}
                    </div>

                    <div className='analyseSpends-widget-transaction-content'>
                      <div className='analyseSpends-widget-transaction-content-div-1'>
                        <div
                          className='spendCategory-widget-transaction-content-description'
                          style={{
                            fontFamily: theme.fontFamily,
                            color: theme.appTextColor,
                          }}
                          onClick={() => setIsDescFull(!isDescFull)}
                        >
                          {isDescFull
                            ? formatString(capitalizeFirsLetter(spendGroup?.categoryName))
                            : formatString(truncateString(
                                capitalizeFirsLetter(
                                  spendGroup?.categoryName,
                                ) || "",
                                12,
                              ))}
                        </div>
                        <div className='analyseSpends-widget-transaction-content-amount'>
                          <div
                            className='analyseSpends-widget-transaction-content-amount-text'
                            style={{
                              fontFamily: theme.fontFamily,
                              color: theme.appTextColor,
                            }}
                          >
                            {formatAmount(spendGroup.totalSpend)}
                          </div>
                          <ChevronRightIcon
                            color={theme.appTextColor}
                            size='25px'
                            width='fit-content'
                          />
                        </div>
                      </div>

                      <div className='analyseSpends-widget-transaction-content-div-2'>
                        <div
                          className='analyseSpends-widget-transaction-content-percentage'
                          style={{
                            fontFamily: theme.fontFamily,
                            color: theme.appTextColor,
                          }}
                        >
                          {Math.round(
                            (spendGroup.totalSpend / getTotalSpend()) * 100,
                          )}
                          %
                        </div>

                        <div className='analyseSpends-widget-transaction-content-countOfTxn'>
                          <div
                            className='analyseSpends-widget-transaction-content-countOfTxn-label'
                            {...styles.transactionContent}
                            textAlign={"end"}
                            onClick={() =>
                              session.disabledFunctionsByBlockCode.includes(
                                AU_BlockCodes.EMI_CONVERSION_DISABLE,
                              ) ||
                              session.disabledFunctionsByBlockCode.includes(
                                Federal_BlockCodes.PAY_BILL_DISABLE,
                              )
                                ? null
                                : handleCategory(spendGroup.groupId)
                            }
                          >
                            {spendGroup.countOfTxns} Transactions
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ) : (
                  <></>
                ),
              )}
            </div>
          ) : (
            <div
              className='analyseSpends-noSpends'
              style={{ backgroundColor: theme.widgetBackgroundColor }}
            >
              <div className='analyseSpends-noSpends-content'>
                <img
                  className='analyseSpends-noSpends-content-img'
                  src={
                    screen?.home?.transaction?.transactioEmptyImageUrl ||
                    homeEmptyTransaction
                  }
                  source={{
                    uri:
                      screen?.home?.transaction?.transactioEmptyImageUrl ||
                      homeEmptyTransaction,
                  }}
                  alt='Alternate Text '
                />
                <div
                  className='analyseSpends-noSpends-content-text-1'
                  style={{
                    fontFamily: theme.fontFamily,
                    color: theme.appTextColor,
                  }}
                >
                  No Spends Yet
                </div>
                <div
                  className='analyseSpends-noSpends-content-text-2'
                  style={{
                    fontFamily: theme.fontFamily,
                    color: theme.appTextColor,
                  }}
                >
                  Track your monthly spends based on category
                </div>
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  )
}
export default AnalyseSpend
