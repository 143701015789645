import React from "react"
import { Text, useToast, View } from "native-base"
import { showToast1 } from "../../core/toast"
import Copy from "../../svg/copy"
import styles from "../styles"
import { formatCardNumber, copyToClipboard } from "../../../utils/functions"
import { ProgramTypes } from "../../../utils/constants"

const CardDetails_v1 = props => {
  const nativeToast = useToast()

  const toast = message => {
    const theme = props.theme
    showToast1({ nativeToast, theme, message })
  }
  return props.user.programType === ProgramTypes.PREPAID ? (
    <View
      borderColor={"#ECECEC"}
      borderWidth='1px'
      shadow={"4"}
      borderRadius='8px'
    >
      <View px='20px' py='24px'>
        <View>
          <Text {...styles.widgetHeading}>Name on Card</Text>
        </View>
        <View alignItems='center' flexDirection='row'>
          <View mt='4px'>
            <Text {...styles.widgetDetailText}>
              {props.user.customer.firstName +
                " " +
                props.user.customer.lastName}
            </Text>
          </View>
        </View>
      </View>
      <View px='20px' py='24px'>
        <View>
          <Text {...styles.widgetHeading}>Card Number</Text>
        </View>
        <View alignItems='center' flexDirection='row' w='100%'>
          <View mt='4px'>
            <Text {...styles.widgetDetailText}>
              {formatCardNumber(props.widgetDetails.unmaskedCardNumber)}
            </Text>
          </View>
          <View
            ml='12px'
            onClick={() => {
              copyToClipboard(props.widgetDetails?.unmaskedCardNumber || "")
              toast("Card Number Copied")
            }}
          >
            <Copy />
          </View>
        </View>
      </View>
      <View flexDirection={"row"}>
        <View px='20px' py='24px'>
          <View>
            <Text {...styles.widgetHeading}>Expiry</Text>
          </View>
          <View mt='4px'>
            <Text {...styles.widgetDetailText}>
              {props.widgetDetails.cardExpiry}
            </Text>
          </View>
        </View>
        <View px='20px' py='24px'>
          <View>
            <Text {...styles.widgetHeading}>CVV</Text>
          </View>
          <View alignItems='center' flexDirection='row'>
            <View mt='4px'>
              <Text {...styles.widgetDetailText}>
                {props.widgetDetails.cvv}
              </Text>
            </View>
            <View
              ml='12px'
              onClick={() => {
                copyToClipboard(props.widgetDetails?.cvv || "")
                toast("CVV Copied")
              }}
            >
              <Copy />
            </View>
          </View>
        </View>
      </View>
    </View>
  ) : (
    <View>
      <View px='20px' py='24px'>
        <View>
          <Text {...styles.widgetHeading}>Card Number</Text>
        </View>
        <View alignItems='center' flexDirection='row'>
          <View mt='4px'>
            <Text {...styles.widgetDetailText}>
              {formatCardNumber(props.widgetDetails.unmaskedCardNumber)}
            </Text>
          </View>
          <View
            ml='12px'
            onClick={() => {
              copyToClipboard(props.widgetDetails?.unmaskedCardNumber || "")
              toast("Card Number Copied")
            }}
          >
            <Copy />
          </View>
        </View>
      </View>
      <View px='20px' py='24px'>
        <View>
          <Text {...styles.widgetHeading}>Expiry</Text>
        </View>
        <View mt='4px'>
          <Text {...styles.widgetDetailText}>
            {props.widgetDetails.cardExpiry}
          </Text>
        </View>
      </View>
      <View px='20px' py='24px'>
        <View>
          <Text {...styles.widgetHeading}>CVV</Text>
        </View>
        <View alignItems='center' flexDirection='row'>
          <View mt='4px'>
            <Text {...styles.widgetDetailText}>{props.widgetDetails.cvv}</Text>
          </View>
          <View
            ml='12px'
            onClick={() => {
              copyToClipboard(props.widgetDetails?.cvv || "")
              toast("CVV Copied")
            }}
          >
            <Copy />
          </View>
        </View>
      </View>
    </View>
  )
}

export default CardDetails_v1
