const styles = {
  header: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    color: "#303030",
    fontSize: "18px",
    fontWeight: 500,
    justifyContent: "space-between",
    alignItems: "center",
  },
  statementText: {
    width: "100%",
    textAlign: "center",
  },
  accordionNav: {
    color: "red",
    backgroundColor: "#4e4e4e",
  },
  emptyImage: {
    width: "81.62px",
    height: "98.8px",
  },

  emptyStateText: {
    width: "100%",
    fontSize: "16px",
    lineHeight: "20px",
    fontWeight: "400",
    color: "#303030",
    opacity: "0.64",
    textAlign: "center",
    letterSpacing: "0.02em",
  },
}
export default styles
