import { Image, View } from "native-base"
import React from "react"
import {
  default as cashBack,
  default as Refund,
} from "../../assets/images/transactionLogos/cashback.svg"
import cashWithdrawal from "../../assets/images/transactionLogos/cashWithdrawal.svg"
import customerTxnCatchAll from "../../assets/images/transactionLogos/customerTxnCatchAll.svg"
import emi from "../../assets/images/transactionLogos/emi.svg"
import fee from "../../assets/images/transactionLogos/fee.svg"
import interest from "../../assets/images/transactionLogos/interest.svg"
import payment from "../../assets/images/transactionLogos/payment.svg"
import surcharge from "../../assets/images/transactionLogos/surcharge.svg"
import tax from "../../assets/images/transactionLogos/tax.svg"

import {
  APPAREL,
  CASH_WITHDRAWAL,
  DINING,
  ELECTRONICS,
  FUEL,
  GROCERY,
  RENT,
  TRANSACTION_LOGO_HEIGHT,
  TRANSACTION_LOGO_WIDTH,
  TRAVEL,
  UTILITY,
} from "../../utils/constants"
import { useSelector } from "react-redux"

const TransactionLogo_v2_1 = props => {
  const [screen] = useSelector(state => [state.screen])
  let s3_url = process.env.REACT_APP_S3_URL
  const mccCheck = code => {
    let MCC_Code = parseInt(code)
    if (FUEL.includes(MCC_Code)) {
      //TO:DO here s3_url variable contains only folder name
      return `${s3_url}/${screen.home.transaction.transactionLogoType.toLowerCase()}/Fuel.svg`
    } else if (UTILITY.includes(MCC_Code)) {
      return `${s3_url}/${screen.home.transaction.transactionLogoType.toLowerCase()}/Utilities.svg`
    } else if (DINING.includes(MCC_Code)) {
      return `${s3_url}/${screen.home.transaction.transactionLogoType.toLowerCase()}/FoodGrocery.svg`
    } else if (GROCERY.includes(MCC_Code)) {
      return `${s3_url}/${screen.home.transaction.transactionLogoType.toLowerCase()}/FoodGrocery.svg`
    } else if (ELECTRONICS.includes(MCC_Code)) {
      return `${s3_url}/${screen.home.transaction.transactionLogoType.toLowerCase()}/Electronics.svg`
    } else if (TRAVEL.includes(MCC_Code)) {
      return `${s3_url}/${screen.home.transaction.transactionLogoType.toLowerCase()}/Transport.svg`
    } else if (RENT.includes(MCC_Code)) {
      return `${s3_url}/${screen.home.transaction.transactionLogoType.toLowerCase()}/Utilities.svg`
    } else if (APPAREL.includes(MCC_Code)) {
      return `${s3_url}/${screen.home.transaction.transactionLogoType.toLowerCase()}/Apparel.svg`
    } else {
      return customerTxnCatchAll
    }
  }
  const image = type => {
    switch (type) {
      case "REFUND":
        return `${s3_url}/${screen.home.transaction.transactionLogoType.toLowerCase()}/Refund.svg`
      case "REFUND_REVERSAL":
        return `${s3_url}/${screen.home.transaction.transactionLogoType.toLowerCase()}/RefundReversal.svg`
      case "PAYMENT":
        return mccCheck(props.mcc)
      case "PURCHASE":
        return mccCheck(props.mcc)
      case "PAYMENT_REVERSAL":
        return `${s3_url}/${screen.home.transaction.transactionLogoType.toLowerCase()}/PaymentReversal.svg`
      case "CASHBACK":
        return `${s3_url}/${screen.home.transaction.transactionLogoType.toLowerCase()}/Cashback.svg`
      case "CASHBACK_REVERSAL":
        return `${s3_url}/${screen.home.transaction.transactionLogoType.toLowerCase()}/CashbackReversal.svg`
      case "REPAYMENT":
        return `${s3_url}/${screen.home.transaction.transactionLogoType.toLowerCase()}/Repayment.svg`
      case "REPAYMENT_REVERSAL":
        return `${s3_url}/${screen.home.transaction.transactionLogoType.toLowerCase()}/RepaymentReversal.svg`
      case "FEE":
        return `${s3_url}/${screen.home.transaction.transactionLogoType.toLowerCase()}/Fee.svg`
      case "FEE_REVERSAL":
        return `${s3_url}/${screen.home.transaction.transactionLogoType.toLowerCase()}/FeeReversal.svg`
      case "TAX":
        return `${s3_url}/${screen.home.transaction.transactionLogoType.toLowerCase()}/Tax.svg`
      case "TAX_REVERSAL":
        return `${s3_url}/${screen.home.transaction.transactionLogoType.toLowerCase()}/TaxReversal.svg`
      case "INTEREST":
        return `${s3_url}/${screen.home.transaction.transactionLogoType.toLowerCase()}/Interest-1.svg`
      case "INTEREST_REVERSAL":
        return `${s3_url}/${screen.home.transaction.transactionLogoType.toLowerCase()}/InterestReversal-1.svg`
      case "SURCHARGE":
        return `${s3_url}/${screen.home.transaction.transactionLogoType.toLowerCase()}/Surcharge.svg`
      case "SURCHARGE_REVERSAL":
        return `${s3_url}/${screen.home.transaction.transactionLogoType.toLowerCase()}/SurchargeReversal.svg`
      case "EMI":
        return `${s3_url}/${screen.home.transaction.transactionLogoType.toLowerCase()}/Emi.svg`
      case "CASH_WITHDRAWAL_REVERSAL":
        return `${s3_url}/${screen.home.transaction.transactionLogoType.toLowerCase()}/CashWithdrawalReversal.svg`
      case "CASH_WITHDRAWAL":
        return `${s3_url}/${screen.home.transaction.transactionLogoType.toLowerCase()}/CashWithdrawal.svg`
      case "SETTLEMENT_DEBIT_CASH":
        return `${s3_url}/${screen.home.transaction.transactionLogoType.toLowerCase()}/SettlementDebit.svg`
      case "SETTLEMENT_CREDIT_CASH":
        return `${s3_url}/${screen.home.transaction.transactionLogoType.toLowerCase()}/SettlementCredit.svg`
      case "ADD_FUND":
        return `${s3_url}/${screen.home.transaction.transactionLogoType.toLowerCase()}/AddFund.svg`
      default:
        return customerTxnCatchAll
    }
  }

  return (
    <div>
      <img
        width={TRANSACTION_LOGO_WIDTH + "px"}
        height={TRANSACTION_LOGO_HEIGHT + "px"}
        src={image(props.transactionType)}
        alt='transaction logo'
      />
    </div>
  )
}

export default TransactionLogo_v2_1
