import React, { useState, useRef, useEffect } from "react"
import { useSelector } from "react-redux"
import { View, Text, FlatList, ChevronLeftIcon, useToast } from "native-base"
import { Link } from "react-router-dom"
import moment from "moment"
import styles from "./styles"
import Transactions from "./transactions"
import Loader from "../../core/loader"
import Toast from "../../core/toast"
import AccountService from "../../../services/AccountService"
import Empty from "./empty"
import { useWindowDimensions } from "react-native"
import { DisplayConfig } from "../../../utils/constants"

const initStartDate = moment().subtract(12, "months").format("YYYY-MM-DD")
const initCurrentDate = moment().format("YYYY-MM-DD")
const initTransactions = [
  { type: "TITLE", date: moment().format("YYYY-MM-DD") },
]

const initOffset = 0
const transactionCount = 10

const ShowTransactions_v1 = () => {
  const [theme, user] = useSelector(state => [state.theme, state.user])

  const [isTransactionsLoading, setIsTransactionsLoading] = useState(false)
  const isTransactionsLoadingRef = useRef(false)
  const [apiError, setApiError] = useState(0)

  const preferences = useSelector(state => state.preferences)
  const toast = useToast()
  const window = useWindowDimensions()

  // transactions array contains -
  // 1. Title
  // 2. transactions
  // 3. Dates
  const [transactions, setTransactions] = useState(initTransactions)
  const offset = useRef(initOffset)
  // date till which txns should be loaded
  const startDate = useRef(initStartDate)
  // pointer to current month being fetched
  const currentMonth = useRef(initCurrentDate)

  useEffect(() => {
    if (apiError >= 3) {
      toast("Error fetching Transactions")
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [apiError])

  const getTransactions = async () => {
    try {
      // get txns for current month with the set offset
      const response = await AccountService.getTransactions(user.account.id, {
        count: transactionCount,
        offset: offset.current,
        from: moment(currentMonth.current)
          .startOf("month")
          .format("YYYY-MM-DD"),
        to: moment(currentMonth.current).endOf("month").format("YYYY-MM-DD"),
      })

      const result = response.data

      // if api call successful
      if (result?.success) {
        if (result.data.totalCount !== 0) {
          setTransactions(transactions => [
            ...transactions,
            ...result.data.transactions,
          ])
        }
        // if current month has more txns pending, stay in this month
        if (result.data.hasMore) {
          // increment offset, next call happens for current month with this offset
          offset.current += transactionCount
        } else {
          // else move current month pointer to next month (chronologically previous month)

          // get next month's value
          const nextMonthToFetch = moment(currentMonth.current)
            .subtract(1, "month")
            .format("YYYY-MM-DD")

          // move pointer to next month
          currentMonth.current = nextMonthToFetch
          // reset offset
          offset.current = 0
        }
      } else {
        setApiError(apiError + 1)
      }
    } catch (error) {
      setApiError(apiError + 1)
    }
  }

  const loadMoreTransaction = async () => {
    // if current date's month is greater than or equal to start date's month (i.e. end not reached)
    // and any other api call for loading is not pending (txns must be loaded synchronously)
    if (
      moment(currentMonth.current).format("YYYY-MM") >=
        moment(startDate.current).format("YYYY-MM") &&
      !isTransactionsLoadingRef.current &&
      apiError < 3
    ) {
      // use ref to track loading as setState can be async
      isTransactionsLoadingRef.current = true
      // show loader in UI
      setIsTransactionsLoading(true)

      // get txns
      await getTransactions()

      isTransactionsLoadingRef.current = false
      setIsTransactionsLoading(false)
    } else if (
      moment(currentMonth.current).format("YYYY-MM") <
        moment(startDate.current).format("YYYY-MM") &&
      transactions.length === 1
    ) {
      setTransactions(transactions => [...transactions, { type: "EMPTY" }])
    }
  }

  const renderLoader = () => {
    return isTransactionsLoading ? (
      <View {...styles.loader}>
        <Loader color={theme.color1} width={20} height={20} />
      </View>
    ) : null
  }

  const renderItem = ({ item, index }) => {
    switch (item.type) {
      case "TITLE":
        return (
          <>
            <View {...styles.header}>
              <Link to='/'>
                <View>
                  <ChevronLeftIcon size='5' color='#7B7B7B' />
                </View>
              </Link>
              <View>
                <Text fontFamily={theme.fontFamily} {...styles.heading}>
                  Transactions
                </Text>
              </View>
              <View></View>
            </View>
          </>
        )

      case "DATE":
        return (
          <View marginTop={30} marginLeft={6}>
            <Text {...styles.dateText}>
              {moment(item.date).format("YYYY-MM-DD") ===
              moment().format("YYYY-MM-DD")
                ? "Today"
                : moment(item.date).format("Do MMM YY")}
            </Text>
          </View>
        )

      case "EMPTY":
        return <Empty />

      default:
        return (
          <View>
            <Transactions
              transaction={item}
              fontFamily={theme.fontFamily}
              dateFormat={preferences.dateFormat}
              timeFormat={preferences.timeFormat}
              isLast={index === transactions.length - 1}
            />
          </View>
        )
    }
  }

  const handleRefresh = async () => {
    setTransactions(initTransactions)
    offset.current = initOffset
    startDate.current = initStartDate
    currentMonth.current = initCurrentDate
  }

  return (
    <View alignItems='center' justifyContent='center'>
      <View _web={{ maxW: DisplayConfig.MAX_WIDTH }} w='100%'>
        <View height={window.height}>
          {/* Header Title section */}

          {
            <FlatList
              data={transactions}
              renderItem={renderItem}
              keyExtractor={(_transaction, index) => index}
              onEndReached={loadMoreTransaction}
              ListFooterComponent={renderLoader}
              onEndReachedThreshold={0.2}
              refreshing={false}
              onRefresh={handleRefresh}
            />
          }
        </View>
      </View>
    </View>
  )
}

export default ShowTransactions_v1
