import React from "react"
import { Path, G } from "react-native-svg"
import { useSelector } from "react-redux"
import { Icon } from "native-base"

const Download = () => {
  const theme = useSelector(state => state.theme)
  return (
    <Icon viewBox='0 0 18 16' size='18px'>
      <G
        width='18'
        height='16'
        viewBox='0 0 18 16'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <Path
          d='M9.14711 10.2787L12.0979 7.32791M9.14711 10.2787V1.42627V10.2787ZM9.14711 10.2787L6.19629 7.32791L9.14711 10.2787Z'
          stroke={theme.color1}
          strokeWidth='1.77049'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <Path
          d='M1.77051 11.7539L2.22862 13.5871C2.30841 13.9063 2.49259 14.1897 2.7519 14.3922C3.01121 14.5947 3.33076 14.7047 3.65977 14.7047H14.6353C14.9644 14.7047 15.2839 14.5947 15.5432 14.3922C15.8025 14.1897 15.9867 13.9063 16.0665 13.5871L16.5246 11.7539'
          stroke={theme.color1}
          strokeWidth='1.77049'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
      </G>
    </Icon>
  )
}

export default Download
