import { CarouselProvider, Slide, Slider } from "pure-react-carousel"
import "pure-react-carousel/dist/react-carousel.es.css"
import React, { useEffect, useState } from "react"
import { useSelector } from "react-redux"
import { useNavigate } from "react-router-dom"
import ArrowRight from "../../../../svg/arrowRight"
import OffersCard_v2 from "./OffersCard_v2"
import { useWindowDimensions } from "react-native-web"

const Offers_v2 = props => {
  const [activeIndex, setActiveIndex] = useState(0)
  const [screen, theme] = useSelector(state => [state.screen, state.theme])
  const [offerList, setOfferList] = useState([])
  const navigate = useNavigate()
  const windowDim = useWindowDimensions()
  useEffect(() => {
    setOfferList(
      props.offerList?.slice(
        0,
        screen?.home?.offersListLength ? screen?.home?.offersListLength : 5,
      ) || [],
    )
  }, [])
  return (
    <div
      className='offer'
      style={{ backgroundColor: theme.widgetBackgroundColor }}
    >
      <div className='offer-text-div'>
        <div className='offer-promotions-text-div'>
          <div
            className='offer-promotions-text'
            style={{ fontFamily: theme.fontFamily, color: theme.appTextColor }}
          >
            Offers & Promotions
          </div>
        </div>
        <div
          className='offer-viewAll-text-div'
          onClick={() => {
            navigate("/Offers")
          }}
        >
          <div
            className='offer-viewAll-text'
            style={{ color: theme.color1, fontFamily: theme.fontFamily }}
          >
            View All
          </div>
          <ArrowRight
            className='offer-viewAll-arrowRight'
            size='9px'
            color={theme.color1}
          />
        </div>
      </div>
      <div className='offer-carouselProvider'>
        <CarouselProvider
          naturalSlideWidth={200}
          naturalSlideHeight={windowDim.width < 400 ? 60 : 50}
          totalSlides={offerList.length}
          visibleSlides={1.1}
          currentSlide={activeIndex}
        >
          <Slider>
            {offerList.map((item, index) => (
              <Slide index={index}>
                <div
                  className='offer-slide'
                  onClick={() => setActiveIndex(index)}
                  style={{
                    marginRight: index === offerList.length ? "5%" : 0,
                    marginLeft: index === 0 ? "2px" : "6px",
                  }}
                >
                  <OffersCard_v2
                    data={item}
                    index={index}
                    length={offerList.length}
                  />
                </div>
              </Slide>
            ))}
          </Slider>
        </CarouselProvider>
      </div>
    </div>
  )
}
export default Offers_v2
