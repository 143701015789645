import React from "react"
import { Icon } from "native-base"

const RewardIcon = props => {
  return (
    <Icon viewBox='0 0 66 66' size={props.size}>
      <svg
        width='66'
        height='66'
        viewBox='0 0 66 66'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <rect x='13' y='13' width='40' height='40' rx='20' fill={props.color} />
        <path
          d='M30.5 22.5C28.714 22.5 27.25 23.964 27.25 25.75C27.25 26.3934 27.4429 26.9935 27.7695 27.5H25.25C24.56 27.5 24 28.06 24 28.75V31.75C24 32.44 24.56 33 25.25 33H32.25V27.5H30.5C29.525 27.5 28.75 26.725 28.75 25.75C28.75 24.775 29.525 24 30.5 24C31.475 24 32.25 24.775 32.25 25.75V27.5H33.75V25.75C33.75 24.775 34.525 24 35.5 24C36.475 24 37.25 24.775 37.25 25.75C37.25 26.725 36.475 27.5 35.5 27.5H33.75V33H40.75C41.44 33 42 32.44 42 31.75V28.75C42 28.06 41.44 27.5 40.75 27.5H38.2305C38.5571 26.9935 38.75 26.3934 38.75 25.75C38.75 23.964 37.286 22.5 35.5 22.5C34.4758 22.5 33.5971 23.0112 33 23.7588C32.4029 23.0112 31.5242 22.5 30.5 22.5ZM25 34.5V39.75C25 41.54 26.46 43 28.25 43H32.25V34.5H25ZM33.75 34.5V43H37.75C39.54 43 41 41.54 41 39.75V34.5H33.75Z'
          fill='#303030'
        />
        <circle
          cx='33'
          cy='33'
          r='29'
          transform='rotate(0 33 33)'
          stroke='#EBEDF0'
          stroke-width='4'
        />
        <circle
          cx='33'
          cy='33'
          r='29'
          transform='rotate(-90 33 33)'
          stroke={props.color}
          stroke-width='4'
          stroke-dasharray='182.2'
          stroke-dashoffset={
            props.percent <= 0
              ? 0
              : props.percent >= 1
              ? 182.2
              : 182.2 * props.percent
          }
        />
        {/* <path
          d="M33 5C37.4187 5 41.7747 6.04577 45.7117 8.05182C49.6488 10.0579 53.0552 12.9672 55.6525 16.542C58.2497 20.1168 59.964 24.2555 60.6553 28.6198C61.3465 32.9841 60.995 37.4501 59.6296 41.6525C58.2641 45.8549 55.9235 49.6745 52.799 52.799C49.6745 55.9235 45.8549 58.2641 41.6525 59.6296C37.45 60.995 32.9841 61.3465 28.6198 60.6553C24.2555 59.964 20.1168 58.2497 16.542 55.6525"
          stroke={props.color}
          stroke-width="4"
          stroke-linecap="round"
          stroke-linejoin="round"
        /> */}
      </svg>
    </Icon>
  )
}

export default RewardIcon
