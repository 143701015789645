import React from "react"
import { useSelector } from "react-redux"
import { Icon } from "native-base"

const ToastExclamation = () => {
  const theme = useSelector(state => state.theme)
  return (
    <Icon>
      <svg
        width='25'
        height='24'
        viewBox='0 0 25 24'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <path
          d='M12.4483 1C6.4115 1 1.5 5.9346 1.5 12C1.5 18.0654 6.4346 23 12.5 23C18.5654 23 23.5 18.0654 23.5 12C23.5 5.9346 18.5423 1 12.4483 1ZM12.5 20.8C7.6479 20.8 3.7 16.8521 3.7 12C3.7 7.1479 7.6237 3.2 12.4483 3.2C17.3301 3.2 21.3 7.1479 21.3 12C21.3 16.8521 17.3521 20.8 12.5 20.8Z'
          fill={theme.toastColor}
        />
        <path
          d='M11.5 6H13.5V13.7H11.5V6ZM11.5 14.8H13.5V17H11.5V14.8Z'
          fill={theme.toastColor}
        />
      </svg>
    </Icon>
  )
}

export default ToastExclamation
