import React, { lazy, Suspense } from "react"
import { useSelector } from "react-redux"
import { PwaVersions } from "../../utils/enums"
import RewardLoader from "../core/SkeletonLoader/rewardLoader"
// import OfferList_v2 from "./v2/OfferList_v2"
const OfferList_v2 = lazy(() => import("./v2/OfferList_v2"));

const OfferList = () => {
  const [config] = useSelector(state => [state.config])
  return (config?.version === PwaVersions.V2 || config?.version === PwaVersions.V2_1) ?<Suspense fallback={<RewardLoader/>}>  <OfferList_v2 /> </Suspense> : <></>
}

export default OfferList
