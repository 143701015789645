import React from "react"
import { Icon } from "native-base"

const Info = ({ iconProps }) => {
  return (
    <Icon viewBox='0 0 30 30' size='24px' {...iconProps}>
      <svg
        width='10'
        height='12'
        viewBox='0 0 10 12'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <path
          d='M9.66665 5.99999C9.66665 5.35657 9.1434 4.83332 8.49998 4.83332H7.91665V3.08332C7.91665 1.47507 6.60823 0.166656 4.99998 0.166656C3.39173 0.166656 2.08331 1.47507 2.08331 3.08332V4.83332H1.49998C0.856563 4.83332 0.333313 5.35657 0.333313 5.99999V10.6667C0.333313 11.3101 0.856563 11.8333 1.49998 11.8333H8.49998C9.1434 11.8333 9.66665 11.3101 9.66665 10.6667V5.99999ZM3.24998 3.08332C3.24998 2.11849 4.03515 1.33332 4.99998 1.33332C5.96481 1.33332 6.74998 2.11849 6.74998 3.08332V4.83332H3.24998V3.08332Z'
          fill='#7B7B7B'
        />
      </svg>
    </Icon>
  )
}

export default Info
