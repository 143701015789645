import moment from "moment"
import {
  FormControl,
  Input,
  Text,
  useToast,
  View,
  WarningOutlineIcon,
} from "native-base"
import React, { useEffect, useRef, useState } from "react"
import DatePicker from "react-date-picker"
import { useWindowDimensions } from "react-native"
import { useDispatch, useSelector } from "react-redux"
import { useNavigate } from "react-router-dom"
import CommonService from "../../../services/CommonService"
import { setVerifiedChallenges } from "../../../store/actions/Auth"
import {
  getHeaderText,
  onFactorCompletion,
  showCancelButton,
} from "../../../utils/auth"
import {
  AuthChallenges,
  DisplayConfig,
  HEADER_HEIGHT_IN_PX,
} from "../../../utils/constants"
import { EventName, PwaVersions } from "../../../utils/enums"
import {
  captureEvents,
  goToRedirectUrl,
  setInputValue,
  truncateString,
} from "../../../utils/functions"
import { Header } from "../../core"
import { ColorButton, WhiteButton } from "../../core/buttons"
import { showToast1 } from "../../core/toast"
import styles from "./styles"

export const ErrorMsgToIgnore = Object.freeze({
  MSG_1: "switchAccountNumber is not found",
  MSG_2: "Customer data is not found for the account",
  MSG_3: "Unable to find",
})

const Customer = () => {
  const windowDimensions = useWindowDimensions()
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const nativeToast = useToast()

  const [theme, authStore, user, config, session] = useSelector(state => [
    state.theme,
    state.auth,
    state.user,
    state.config,
    state.session,
  ])

  const [submitLoading, setSubmitLoading] = useState(false)
  const [error, setError] = useState(false)
  const [errorCount, setErrorCount] = useState(0)
  const [pan, setPan] = useState("")
  const [dob, setDob] = useState("")
  const [panInvalid, setPanInvalid] = useState(false)
  const [dateInvalid, setDateInvalid] = useState(false)
  const [enableVerify, setEnableVerify] = useState(false)
  const [changeFocus, setChangeFocus] = useState(false)
  const showHeader =
    config?.version === PwaVersions.V2 || config?.version === PwaVersions.V2_1
  const headerText = getHeaderText(AuthChallenges.VERIFY_CUSTOMER)
  const [errMsg, setErrMsg] = useState("")

  const panInput = useRef(null)
  const dobInput = useRef(null)

  const toast = (message, hasExclamationMark = false) => {
    showToast1({ nativeToast, theme, message, hasExclamationMark })
  }

  useEffect(() => {
    captureEvents({
      eventName: EventName.AUTH_CUSTOMER,
      metadata: {},
    })
  }, [])

  useEffect(() => {
    checkIsPanInvalid()
    checkIsDateValid()
  }, [pan, dob])

  useEffect(() => {
    let year = moment(dob).year()
    if (
      (year + "").length < 4 ||
      pan.length !== 10 ||
      !dob ||
      panInvalid ||
      dateInvalid
    ) {
      setEnableVerify(false)
    } else {
      setEnableVerify(true)
    }
  }, [panInvalid, dateInvalid, pan, dob])

  const checkAge = () => {
    let today = new Date()
    let birthDate = moment(dob)
    let age = today.getFullYear() - birthDate.year()
    let m = today.getMonth() - birthDate.month()
    if (m < 0 || (m === 0 && today.getDate() < birthDate.date())) {
      age--
    }
    return age
  }

  const checkIsDateValid = () => {
    let dateFor = moment(dob)
    let yearInput = document.querySelector('[aria-label="year-label"]')
    let year = dateFor.year()
    let today = moment()
    let age = checkAge()
    let isValid = checkInvalidDate()
    if ((year + "").length >= 4 && !isValid) {
      setErrMsg("Invalid Date")
      setDateInvalid(true)
      return false
    } else if (
      (year + "").length >= 4 &&
      (year < 1900 || dateFor > today || (age >= 0 && age < 18))
    ) {
      if (dateFor > today) {
        setErrMsg("Future date may not be selected.")
      } else if (year < 1900) {
        setErrMsg("DOB should be greater than 01-01-1900")
      } else if (age >= 0 && age < 18) {
        setErrMsg("Age should be greater than 18 years")
      }
      setDateInvalid(true)
      return false
    } else {
      console.log(year, year.toString().length)
      year.toString().length === 4 ? yearInput.blur() : console.log()
    }
    setDateInvalid(false)
  }

  function checkInvalidDate() {
    let dayInput = document.querySelector('[aria-label="day-label"]')
    let monthInput = document.querySelector('[aria-label="month-label"]')
    let yearInput = document.querySelector('[aria-label="year-label"]')
    let enteredDate =
      dayInput.value + "/" + monthInput.value + "/" + yearInput.value
    let momentDate = moment(enteredDate, "DD/MM/YYYY")
    let isValid = momentDate.isValid()
    if (yearInput.length < 4) return false
    return isValid
  }

  function onBackClick(event) {
    let dayInput = document.querySelector('[aria-label="day-label"]')
    let monthInput = document.querySelector('[aria-label="month-label"]')
    checkIsDateValid()

    let label = event?.target?.ariaLabel
    let value = event?.target?.value
    if (value == "") {
      if (changeFocus) {
        if (label === "year-label") {
          monthInput?.focus()
        } else if (label === "month-label") {
          dayInput?.focus()
        }
        setChangeFocus(false)
      }
      setChangeFocus(true)
    } else {
      setChangeFocus(false)
    }
  }

  const checkIsPanInvalid = () => {
    if (pan.length < 10) {
      setPanInvalid(false)
      return true
    }
    for (let ind = 0; ind < pan.length; ind++) {
      let newPan = pan
      let currChar = newPan.charAt(ind)
      if ((ind >= 0 && ind <= 4) || ind === 9) {
        if (currChar < "A" || currChar > "Z") {
          setPanInvalid(true)
          return true
        }
      } else if (ind >= 5 && ind <= 8) {
        if (currChar < "0" || currChar > "9") {
          setPanInvalid(true)
          return true
        }
      }
    }
    setPanInvalid(false)
    return false
  }

  useEffect(() => {
    if (localStorage.getItem("customerVerifyBlockId") === user.account.id) {
      const time = moment().diff(
        moment(localStorage.getItem("customerVerifyBlockTime")),
      )
      if (time < 86400000) {
        navigate("/BlockedAttemptExhausted", {
          state: { time: "24 hours", from: "Customer" },
        })
      }
    } else {
      localStorage.removeItem("customerVerifyBlockTime")
      localStorage.removeItem("customerVerifyBlockId")
    }
  }, [])

  const verifyCustomer = async () => {
    if (config?.version === PwaVersions.V2_1 && errorCount >= 2) {
      localStorage.setItem("customerVerifyBlockTime", moment())
      localStorage.setItem("customerVerifyBlockId", user.account.id)
      navigate("/BlockedAttemptExhausted", {
        state: { time: "24 hours", from: "Customer" },
      })
    }
    if (pan.length !== 10) {
      setError(true)
      captureEvents({
        eventName: EventName.INCORRECT_INPUT,
        metadata: {},
      })
      toast("PAN must have 10 digits", true)
      return
    }
    if (!dob) {
      setError(true)
      setDateInvalid(true)
      captureEvents({
        eventName: EventName.INCORRECT_INPUT,
        metadata: {},
      })
      toast("Please enter Date of Birth Correctly", true)
      return
    }

    if (panInvalid || dateInvalid) {
      return false
    }
    setSubmitLoading(true)

    try {
      const response = await CommonService.verifyChallengeCustomer({
        apiToken: authStore.apiToken,
        challengeScope: authStore.currentFactor.scope,

        accountId: user.account.id,
        applicationId: !user.account.id
          ? localStorage.getItem("applicationId")
          : null,
        pan,
        dob: moment(dob).format("YYYYMMDD"),
      })
      const result = response.data

      if (result?.success) {
        setError(false)

        const verifiedChallenges = {
          ...authStore.verifiedChallenges,
          customerRefId: result.data.verificationRefId,
        }

        dispatch(setVerifiedChallenges({ verifiedChallenges }))

        await onFactorCompletion(navigate)
      } else {
        let msg = ""
        setErrorCount(errorCount + 1)
        //add condition here
        if (
          result?.errors.message.startsWith(ErrorMsgToIgnore.MSG_1) ||
          result?.errors.message.startsWith(ErrorMsgToIgnore.MSG_2) ||
          result?.errors.message.startsWith(ErrorMsgToIgnore.MSG_3)
        ) {
          msg = `An error occurred. Please try again later`
        } else if (result?.errors.message === "Verification failed") {
          captureEvents({
            eventName: EventName.INCORRECT_INPUT,
            metadata: {},
          })
          msg = `Invalid details. \nYou have ${
            errorCount === 0
              ? "two attempts remaining"
              : errorCount === 1
              ? "one attempt remaining"
              : errorCount === 2
              ? "Last attempt"
              : ""
          }`
        } else if (result?.errors.message.length > 45) {
          msg = "An error occurred. Please try again later"
        } else {
          msg = truncateString(result?.errors.message, 45)
        }
        captureEvents({
          eventName: EventName.INCORRECT_INPUT,
          metadata: {},
        })
        toast(msg, true)
      }
    } catch (error) {
      await authStore.onAuthFailure(
        error,
        "An error occurred. Please try again later.",
      )
    }

    setSubmitLoading(false)
  }

  return (
    <div className='authCustomer'>
      <div
        className='authCustomer-container'
        style={{
          backgroundColor:
            config?.version === PwaVersions.V2 ||
            config?.version === PwaVersions.V2_1
              ? theme.backgroundColor
              : null,
          minHeight: windowDimensions.height,
        }}
      >
        {showHeader ? (
          <Header
            text={
              config?.version === PwaVersions.V2_1 &&
              (headerText == "Reset MPIN" || headerText == "Change MPIN")
                ? "Setup mPIN"
                : headerText
            }
            onBack={async () => {
              showCancelButton() ? window.history.go(-1) : goToRedirectUrl()
            }}
          />
        ) : (
          <div className='header-disabled'></div>
        )}
        <div
          className={
            config?.version === PwaVersions.V2
              ? "authCustomer-container-content-v2"
              : "authCustomer-container-content"
          }
          // className='authSmsOtp-widget'
          style={{
            backgroundColor:
              config?.version === PwaVersions.V2
                ? theme.widgetBackgroundColor
                : null,
          }}
        >
          <div>
            <div
              className='authCustomer-heading'
              style={{
                fontFamily: theme.fontFamily,
                color:
                  config?.version === PwaVersions.V2 ||
                  config?.version === PwaVersions.V2_1
                    ? theme.appTextColor
                    : "#303030",
              }}
            >
              Enter PAN and DOB
            </div>
            <div
              className='authCustomer-subHeading'
              style={{
                color:
                  config?.version === PwaVersions.V2 ||
                  config?.version === PwaVersions.V2_1
                    ? theme.appTextColor
                    : "#303030",
              }}
            >
              {config?.version === PwaVersions.V2
                ? "We will need to verify your identity"
                : "Please enter these details for user authentication"}
            </div>
          </div>
          <div className='authCustomer-input'>
            <div
              style={{
                color:
                  config?.version === PwaVersions.V2 ||
                  config?.version === PwaVersions.V2_1
                    ? theme.appTextColor
                    : "#303030",
              }}
            >
              PAN
            </div>
            <FormControl isInvalid={panInvalid}>
              <Input
                {...styles.textInput}
                // borderColor={error ? "#C2181B" : "#ECECEC"}
                placeholder='ABCDE1234X'
                placeholderTextColor={
                  config?.version == PwaVersions.V2_1 ? "#CDCFD4" : ""
                }
                value={pan}
                ref={panInput}
                onChange={e => {
                  const finalValue = e.target.value
                    ?.replace(/\W/g, "")
                    ?.toUpperCase()
                  setInputValue(e, finalValue)

                  let dayInput = document.querySelector(
                    '[aria-label="day-label"]',
                  )
                  if (finalValue.length <= 10) {
                    setPan(finalValue)
                  }
                  if (finalValue.length == 10) {
                    dayInput.focus()
                  }
                }}
              />
              <FormControl.ErrorMessage
                leftIcon={<WarningOutlineIcon size='xs' />}
              >
                Invalid PAN
              </FormControl.ErrorMessage>
            </FormControl>
            <div className='authCustomer-input-container'>
              <div
                className='authCustomer-input-label'
                style={{
                  color:
                    config?.version === PwaVersions.V2 ||
                    config?.version === PwaVersions.V2_1
                      ? theme.appTextColor
                      : "#303030",
                }}
              >
                Date of birth
              </div>
              <style>{styles.datePickerStyle}</style>
              <FormControl isInvalid={dateInvalid}>
                <DatePicker
                  ref={dobInput}
                  maxDate={new Date("12-31-9999")}
                  className='verify-customer-date-picker'
                  onFocus={() => {
                    setChangeFocus(false)
                  }}
                  onKeyUp={e => onBackClick(e)}
                  onChange={date => {
                    setDob(date)
                  }}
                  value={dob}
                  disableCalendar={true}
                  format='dd/MM/y'
                  monthPlaceholder='MM'
                  yearPlaceholder='YYYY'
                  dayPlaceholder='DD'
                  nativeInputAriaLabel='Date'
                  yearAriaLabel='year-label'
                  dayAriaLabel='day-label'
                  monthAriaLabel='month-label'
                />
                <FormControl.ErrorMessage
                  leftIcon={<WarningOutlineIcon size='xs' />}
                >
                  {errMsg}
                </FormControl.ErrorMessage>
              </FormControl>
              <div>
                <Text
                  font-weight='400'
                  font-size='12px'
                  line-height='18px'
                  color={`${
                    config?.version === PwaVersions.V2 ||
                    config?.version === PwaVersions.V2_1
                      ? theme.appTextColor
                      : "#303030"
                  }:alpha.80`}
                ></Text>
              </div>
            </div>
          </div>
          <View mt='12px' display={error ? "flex" : "none"}>
            <Text
              fontFamily={theme.fontFamily}
              font-weight='700'
              font-size='14px'
              line-height='18px'
              letter-spacing='0.02em'
              color='#C2181B'
            >
              {error}
            </Text>
          </View>
          <div className='authCustomer-button-container'>
            <div
              className='authCustomer-button'
              style={{ maxWidth: DisplayConfig.MAX_WIDTH }}
            >
              <ColorButton
                text={"Verify Details"}
                isDisable={!enableVerify}
                onPress={verifyCustomer}
                isLoading={submitLoading}
              />
              {showCancelButton() &&
                !(
                  config?.version == PwaVersions.V2 ||
                  config?.version === PwaVersions.V2_1
                ) && (
                  <WhiteButton
                    text='Cancel'
                    onPress={async () => {
                      await authStore.onAuthCancel()
                    }}
                  />
                )}
            </div>
          </div>

          {/* Scrollable Date Picker */}

          {/* <View
            marginTop="8px"
            borderRadius={"10px"}
            flexDirection="row"
            paddingTop={"5px"}
            paddingBottom="5px"
            backgroundColor={theme.color2}
            position="absolute"
            width="100%"
            left="0"
            bottom="0"
            zIndex="11"
          >
            <View flex={"2"}>
              {!isDayPickerOpen ? (
                <></>
              ) : (
                <View margin={"auto"} width="100%">
                  <MyDatePicker
                    setOpen={setIsDayPickerOpen}
                    items={days}
                    open={isDayPickerOpen}
                    setSelected={setSelectedDate}
                    selected={selectedDate}
                  />
                </View>
              )}
            </View>

            <View flex={"2"}>
              {!isMonthPickerOpen ? (
                <></>
              ) : (
                <View margin={"auto"} width="100%">
                  <MyDatePicker
                    setOpen={setisMonthPickerOpen}
                    items={months}
                    open={isMonthPickerOpen}
                    setSelected={setSelectedMonth}
                    selected={selectedMonth}
                  />
                </View>
              )}
            </View>

            <View flex={"2"}>
              {!isYearPickerOpen ? (
                <></>
              ) : (
                <View margin={"auto"} width="100%">
                  <MyDatePicker
                    setOpen={setIsYearickerOpen}
                    items={years}
                    open={isYearPickerOpen}
                    setSelected={setSelectedYear}
                    selected={selectedYear}
                  />
                </View>
              )}
            </View>
          </View> */}
        </div>
      </div>
    </div>
  )
}

export default Customer
