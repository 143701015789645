import { Image, Text, useToast, View } from "native-base"
import React from "react"
import { useWindowDimensions } from "react-native-web"
import aboutProgramReward2 from "../../assets/images/aboutProgramReward2.svg"
import HorizontalCard from "../../assets/images/sampleHorizontalCard.svg"
import PublicService from "../../services/PublicService"
import { CardOrientation, DisplayConfig } from "../../utils/constants"
import { PwaVersions } from "../../utils/enums"
import { ColorButton } from "../core/buttons"
import { showToast1 } from "../core/toast"
const Cvp_v2 = props => {
  const windowDimensions = useWindowDimensions()
  const nativeToast = useToast()

  const toast = (theme, message, hasTick = false) => {
    showToast1({ nativeToast, theme, message, hasTick })
  }
  const handleClick = async () => {
    const response = await PublicService.onboardingAcknowledgement({
      refId: props.refId,
    })
    if (response.data.success) {
      window.location.href = props.onboardingUrl
    } else {
      toast(
        {
          fontFamily: "Inter",
          toastColor: "#FFFFFF",
        },
        "Something went wrong please try again after sometime",
      )
      setTimeout(() => {
        window.location.href = props.redirectUrl
      }, 1000)
    }
  }
  return (
    <View
      alignItems='center'
      minH={windowDimensions.height}
      bgColor={
        props.programConfig?.version === PwaVersions.V2
          ? props.programConfig.backgroundColor
          : null
      }
    >
      <View>
        <View mt='10%'>
          {props.programConfig.virtualCardOrientation ===
          CardOrientation.VERTICAL ? (
            <View
              borderRadius={"12px"}
              alignItems='center'
              justifyContent={"center"}
            >
              <Image
                src={props.programConfig.virtualCardDesignUrl || HorizontalCard}
                // class="zoom-vertical"
                shadow={4}
                width='165'
                height='261'
                alt='Alternate Text '
                borderRadius='12px'
              />
            </View>
          ) : (
            <View
              borderRadius={"12px"}
              alignItems='center'
              justifyContent={"center"}
            >
              <Image
                src={props.programConfig.virtualCardDesignUrl || HorizontalCard}
                // class="zoom-vertical"
                shadow={4}
                height='165'
                width='261'
                alt='Alternate Text '
                borderRadius='12px'
              />
            </View>
          )}
          <View
            mt='12px'
            mb='40px'
            mx='16px'
            borderRadius={"12px"}
            bgColor={props.programConfig.widgetBackgroundColor}
            shadow='1'
            borderColor={"#ECECEC"}
            borderWidth='1px'
          >
            {props.programConfig?.screenConfig.aboutProgram?.cvp.map(item => (
              <View
                p='24px'
                borderTopWidth={"1px"}
                borderColor={"#ECECEC"}
                flexDir={"row"}
                alignItems='center'
                justifyContent={"center"}
              >
                <View w='28%' mr='2%'>
                  {item?.imageUrl && (
                    <Image
                      source={{
                        uri: aboutProgramReward2 || item?.imageUrl,
                      }}
                      width='72px'
                      height='42px'
                      alt='Alternate Text '
                    />
                  )}
                </View>
                <View
                  w='70%'
                  bgColor={props.programConfig.widgetBackgroundColor}
                >
                  <Text
                    fontSize='16px'
                    lineHeight='22px'
                    fontWeight='600'
                    color={props.programConfig.textColor}
                  >
                    {item?.title}
                  </Text>
                  <Text
                    fontSize='12px'
                    lineHeight='16px'
                    fontWeight='400'
                    color={props.programConfig.textColor}
                  >
                    {item?.desc}
                  </Text>
                </View>
              </View>
            ))}
          </View>
          <View alignItems='center' justifyContent='center' w='100%' mt='48px'>
            <View
              alignItems='center'
              justifyContent='center'
              width='100%'
              bottom='4px'
              _web={{ maxW: DisplayConfig.MAX_WIDTH }}
            >
              <ColorButton
                text='Proceed'
                primaryColor={props.programConfig.primaryColor}
                secondaryColor={props.programConfig.secondaryColor}
                onPress={() => handleClick()}
              />
            </View>
          </View>
        </View>
      </View>
    </View>
  )
}
export default Cvp_v2
