import React, { useEffect, useState } from "react"
import { useWindowDimensions } from "react-native-web"
import { useSelector } from "react-redux"
import aboutProgramReward2 from "../../../../../assets/images/aboutProgramReward2.svg"
import { Header } from "../../../../core"
import FaqAccordian from "./FaqAccordian"
import { EventName, PwaVersions } from "../../../../../utils/enums"
import ArrowUp from "../../../../svg/arrowUp"
import ArrowDown from "../../../../svg/arrowDown"
import ArrowRight from "../../../../svg/arrowRight"
import { captureEvents } from "../../../../../utils/functions"

const AboutProgram_v2 = () => {
  const [theme, screen, config] = useSelector(state => [
    state.theme,
    state.screen,
    state.config,
  ])
  const windowDimensions = useWindowDimensions()

  useEffect(() => {
    captureEvents({ eventName: EventName.ABOUT_PROGRAM, metadata: {} })
  }, [])

  const [isOpen, setIsOpen] = useState(true)
  return (
    <div
      style={{
        minHeight: windowDimensions.height,
        backgroundColor: theme.backgroundColor,
      }}
    >
      <Header
        text='About Card Program'
        onBack={async () => {
          window.history.go(-1)
        }}
      />
      {config?.version !== PwaVersions.V2_1 &&
        screen?.aboutProgram?.description && (
          <div
            className='aboutProgram-description'
            style={{
              backgroundColor: theme.widgetBackgroundColor,
              color: theme.appTextColor,
            }}
          >
            {screen?.aboutProgram?.description}
          </div>
        )}

      <div
        className='aboutProgram-cvp'
        style={{ backgroundColor: theme.widgetBackgroundColor }}
      >
        {config?.version === PwaVersions.V2_1 && (
          <div className='aboutProgram-description-container_v2'>
            <div
              className='aboutProgram-description_v2'
              style={{
                backgroundColor: theme.widgetBackgroundColor,
                color: theme.appTextColor,
              }}
            >
              {screen?.aboutProgram?.description}
            </div>
          </div>
        )}
        {screen?.aboutProgram?.cvp.map(item => (
          <div className='aboutProgram-cvp-container'>
            <div className='aboutProgram-cvp-border'>
              <div className='aboutProgram-cvp-img-container'>
                {item?.imageUrl && (
                  <img
                    className='aboutProgram-cvp-img'
                    src={item?.imageUrl || aboutProgramReward2}
                    alt='Alternate Text '
                  />
                )}
              </div>
              <div
                style={{
                  width: "78%",
                  backgroundColor: theme.widgetBackgroundColor,
                }}
              >
                <div
                  className='aboutProgram-cvp-title'
                  style={{ color: theme.appTextColor }}
                >
                  {item?.title}
                </div>
                <div
                  className='aboutProgram-cvp-description'
                  style={{ color: `${theme.appTextColor}90` }}
                >
                  {item?.desc}
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
      {screen?.aboutProgram?.faq?.length > 0 &&
        config?.version === PwaVersions.V1 && (
          <div
            className='aboutProgram-faq'
            style={{ backgroundColor: theme.widgetBackgroundColor }}
          >
            <div className='aboutProgram-faq-container'>
              <div
                className='aboutProgram-faq-title'
                style={{ color: theme.appTextColor }}
              >
                FAQ
              </div>
              <div
                onClick={() => setIsOpen(!isOpen)}
                style={{ cursor: "pointer" }}
              >
                {isOpen ? (
                  <ArrowUp
                    className='aboutProgram-faq-title-arrowUp'
                    size='6'
                    color={theme.appTextColor}
                  />
                ) : (
                  <ArrowDown
                    className='aboutProgram-faq-title-arrowDown'
                    size='6'
                    color={theme.appTextColor}
                  />
                )}
              </div>
            </div>
            {isOpen && (
              <div>
                {screen?.aboutProgram?.faq.map((item, index) => (
                  <FaqAccordian item={item} />
                ))}
              </div>
            )}
          </div>
        )}
      {screen?.aboutProgram?.knowMoreUrl && (
        <div
          className='aboutProgram-knowMore-container'
          onClick={() =>
            (window.location.href =
              screen?.aboutProgram?.knowMoreUrl || "https://www.google.com")
          }
        >
          <div className='aboutProgram-knowMore-text'>
            <div style={{ color: theme.appTextColor }}>
              {`${screen?.aboutProgram?.knowMoreText} ` ||
                "Want to know more? "}
            </div>
            <div
              style={{
                color: theme.color1,
                marginLeft: "4px",
                marginRight: "4px",
              }}
            >
              Click Here
            </div>
          </div>
          <div className='aboutProgram-knowMoreUrl'>
            <ArrowRight
              className='aboutProgram-knowMoreUrl-arrowRight'
              size='10px'
              color={theme.color1}
            />
          </div>
        </div>
      )}
    </div>
  )
}

export default AboutProgram_v2
