const styles = {
  header: {
    alignItems: "center",
    justifyContent: "space-between",
    flexDirection: "row",
    mt: "24px",
    mx: "16px",
  },
  toast: {
    fontWeight: "600",
    fontSize: "16px",
    lineHeight: "19px",
    letterSpacing: "0.01em",
  },
  loader: { w: "100%", alignItems: "center", justifyContent: "center" },
  heading: {
    fontFamily: "Inter",
    fontWeight: "500",
    fontSize: "18px",
    lineHeight: "24px",
    color: "#303030",
  },

  dateText: {
    color: "#7B7B7B",
    fontWeight: "500",
    fontSize: "12px",
    lineHeight: "18px",
  },

  transactionContainer: {
    flexDirection: "row",
    mb: "12px",
    alignItems: "center",
    justifyContent: "space-between",
    borderBottomWidth: "0.5px",
    borderBottomColor: "#F0F0F0",
  },
  transactionConvertToEMI: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    // justifyContent: "center",
  },
  transactionConvertToEMIText: {
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "14px",
    lineHeight: "20px",
    display: "flex",
    alignItems: "center",
    textAlign: "center",
    letterSpacing: " 0.02em",
    color: "#334E9E",
  },
  transactionConvertedToEMI: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    padding: "2px 8px",
    gap: " 2px",
    // width: "fit-content",
    width: 100,
    borderRadius: "2px",
  },
  transactionConvertedToEMIText: {
    fontFamily: "Inter",
    fontStyle: "italic",
    fontWeight: 400,
    fontSize: " 11px",
    lineHeight: "16px",
    display: "flex",
    alignItems: "center",
    letterSpacing: "0.04em",

    /* Black/Strong */

    color: "#303030",

    mixBlendMode: "normal",
  },

  image: {
    width: "80%",
    height: "80%",
    borderRadius: "50%",
    alt: "Alternate Text ",
  },

  transactee: {
    color: "#303030",
    fontWeight: "500",
    fontSize: "14px",
    lineHeight: "18px",
  },

  transactionTime: {
    mt: "4px",
    color: "#7B7B7B",
    fontWeight: "400",
    fontSize: "12px",
    lineHeight: "16px",
  },

  transactionType: {
    mr: "4px",
    color: "#303030",
    fontWeight: "600",
    fontSize: "12px",
    lineHeight: "16px",
  },

  transactionAmount: {
    color: "#303030",
    fontWeight: "600",
    fontSize: "12px",
    lineHeight: "16px",
  },

  transactionSection: { my: "12px", mx: "16px" },

  transactionAmountContainer: {
    width: "fit-content",
    display: "flex",
    flexDirection: "row",
    alignItems: "flex-end",
    justifyContent: "center",
    alignSelf: "flex-end",
    mb: "16px",
  },
}

export default styles
