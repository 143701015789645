const styles = {
  horizontalContainer: {
    mt: "24px",
    alignItems: "center",
    justifyContent: "center",
  },
  horizontalCard: {
    p: "24px",
    bgColor: "#5367FF",
    width: "90%",
    borderRadius: "10px",
    shadow: "6",
  },
  btnStyles: {
    alignItems: "center",
    justifyContent: "center",
    borderWidth: "0.5",
    py: "12px",
    px: "18px",
    borderRadius: "6px",
  },
  horizontalCardTopContainer: {
    alignItems: "center",
    justifyContent: "space-between",
    flexDirection: "row",
  },
  cardHeaderText: {
    fontFamily: "Inter",
    fontWeight: "500",
    fontSize: "16px",
    lineHeight: "18px",
    color: "#ffffff",
  },
  horizontalCardBottomContainer: {
    mt: "64px",
    alignItems: "center",
    justifyContent: "space-between",
    flexDirection: "row",
  },
  CardMetaHeadingText: {
    fontFamily: "Inter",
    fontWeight: "300",
    fontSize: "16px",
    lineHeight: "18px",
    color: "#ffffff",
    opacity: "0.6",
  },
  CardMetaText: {
    fontFamily: "Inter",
    fontWeight: "400",
    fontSize: "16px",
    lineHeight: "18px",
    color: "#ffffff",
  },

  horizontalCreditContainer: {
    mt: "24px",
    w: "90%",
    bg: "#ffffff",
    borderRadius: "8px",
    borderWidth: "1px",
    shadow: "1",
    borderColor: "#ECECEC",
  },
  horizontalCreditBalanceContainer: {
    alignItems: "center",
    justifyContent: "space-between",
    w: "100%",
    px: "16px",
    py: "14px",
    flexDirection: "row",
  },
  CreditBalanceHeading: {
    fontFamily: "Inter",
    fontWeight: "500",
    fontSize: "11px",

    letterSpacing: "0.16em",
    lineHeight: "13px",
    color: "#303030",
    opacity: "0.64",
  },
  CreditBalanceText: {
    fontFamily: "Inter",
    fontWeight: "600",
    fontSize: "16px",
    lineHeight: "28px",
    mt: "2px",
    color: "#303030",
  },
  horizontalPayReminder: {
    px: "16px",
    py: "12px",
    borderBottomRadius: "8px",
    flexDirection: "row",
  },

  PayReminderText: {
    fontFamily: "Inter",
    fontWeight: "600",
    fontSize: "16px",
    lineHeight: "30px",
    color: "#fff",
    letterSpacing: "0.02em",
  },
  PayReminderTextLight: {
    mx: "3px",
    color: "#fff",
    fontFamily: "Inter",
    fontWeight: "400",
    fontSize: "16px",
    lineHeight: "16px",
  },

  repaymentReminderText: {
    fontFamily: "Inter",
    fontWeight: "600",
    fontSize: "16px",
    lineHeight: "30px",
    color: "#303030",
    letterSpacing: "0.02em",
  },
  repaymentReminderTextLight: {
    mx: "3px",
    fontFamily: "Inter",
    fontWeight: "400",
    fontSize: "11px",
    lineHeight: "16px",
  },

  verticalContainer: {
    alignSelf: "center",
    mt: "24px",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "row",
    w: "90%",
  },
  verticalCard: {
    py: "24px",
    px: "12px",
    bgColor: "#5367FF",
    width: "57%",
    borderRadius: "10px",
    shadow: "6",
    height: "100%",
  },
  verticalCardTopContainer: {
    mt: "36px",
    alignItems: "center",
    justifyContent: "space-between",
    flexDirection: "row",
  },
  verticalCardBottomContainer: {
    mt: "64px",
    alignItems: "center",
    justifyContent: "space-between",
    flexDirection: "row",
  },
  verticalCreditContainer: {
    ml: "8px",
    w: "40%",
    justifyContent: "space-between",
  },
  verticalCreditBalanceContainer: {
    bg: "#ffffff",
    borderRadius: "8px",
    shadow: "6",
    alignItems: "center",
    justifyContent: "center",
    px: "8px",
    py: "36px",
  },
  verticalPayReminder: {
    bgColor: "#A2A2A2",
    px: "8px",
    py: "12px",
    borderRadius: "8px",
    alignItems: "center",
    justifyContent: "space-between",
    flexDirection: "row",
    mt: "12px",
  },
  verticalCardDetailsContainer: {
    px: "12%",
    py: "8px",
    bgColor: "#ffffff",
    borderRadius: "8px",
    alignItems: "center",
    justifyContent: "space-between",
    flexDirection: "row",
    borderWidth: "1px",
    mt: "24px",
  },
  horizontalCardDetailsContainer: {
    px: "25%",
    py: "8px",
    bgColor: "#ffffff",
    borderRadius: "8px",
    alignItems: "center",
    justifyContent: "space-between",
    flexDirection: "row",
    borderWidth: "1px",
    mt: "24px",
  },

  PayNowContainer: {
    width: "90%",
    px: "5%",
    py: "12px",
    borderRadius: "8px",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "row",
    mt: "12px",
  },
  PayNowContainerText: {
    fontFamily: "Inter",
    fontWeight: "500",
    fontSize: "14px",
    lineHeight: "20px",
    letterSpacing: "0.02em",
    color: "#FFFFFF",
  },
  CardDetailsText: {
    fontFamily: "Inter",
    fontWeight: "600",
    fontSize: "14px",
    lineHeight: "18px",
    display: "flex",
    alignItems: "center",
    textAlign: "center",
    letterSpacing: "0.02em",
    mr: "4px",
  },
}

export default styles
