import { ChevronRightIcon, Image, Text, View } from "native-base"
import React from "react"
import { useSelector } from "react-redux"
import { Link } from "react-router-dom"
import Statement from "../../../../assets/images/Statement.svg"
import Transactions from "../../../../assets/images/Transactions.svg"
import CardInHand from "../../../../assets/images/cardInHand.svg"
import manageCard from "../../../../assets/images/manageCard.svg"
import { ProgramTypes } from "../../../../utils/constants"
import styles from "./styles"

const ActivitySection_v1 = () => {
  const [theme, user] = useSelector(state => [state.theme, state.user])

  const account = user.account
  const card = user.cards?.[0]

  const isAccountActive = account.status === "ACTIVE"

  return (
    <>
      {/* Activate Physical Card Section Ends above*/}

      {isAccountActive && card && card.isHotlisted && (
        <Link
          to='/ManageCard/NewCardRequest'
          style={{
            textDecoration: "None",
            width: "90%",
          }}
        >
          <View
            {...styles.activateCardContainer}
            bgColor={theme.color3}
            borderColor='#CCCCCC'
          >
            {/* Activate Physical Pay Later Card Section */}

            <View>
              <Image
                source={{ uri: CardInHand }}
                width='36px'
                height='36px'
                alt='Alternate Text '
              />
            </View>
            <View w='70%'>
              <View>
                <Text
                  {...styles.activateCardHeading}
                  fontFamily={theme.fontFamily}
                >
                  Request New Card
                </Text>
              </View>
              <View>
                <Text
                  {...styles.activateCardSubHeading}
                  fontFamily={theme.fontFamily}
                >
                  Your Card has been blocked, get a new card
                </Text>
              </View>
            </View>
            <View>
              <ChevronRightIcon size='6' color='#303003' opacity='0.64' />
            </View>
          </View>
        </Link>
      )}
      {user.programType !== ProgramTypes.PREPAID && (
        <View {...styles.CardOptionsContainer}>
          <View
            {...styles.centering}
            opacity={isAccountActive && card ? "1" : "0.6"}
          >
            <Link to={isAccountActive && card ? "/ManageCard" : ""}>
              <View p='12px' bgColor={theme.color1} borderRadius='8px'>
                <Image
                  source={{ uri: manageCard }}
                  {...styles.optionsIcon}
                  color='red'
                />
              </View>
            </Link>
            <View mt='8px'>
              <Text fontFamily={theme.fontFamily}>Manage Card</Text>
            </View>
          </View>
          <View {...styles.centering}>
            <Link to='/Transactions'>
              <View p='12px' bgColor={theme.color1} borderRadius='8px'>
                <Image source={{ uri: Transactions }} {...styles.optionsIcon} />
              </View>
            </Link>
            <View mt='8px'>
              <Text fontFamily={theme.fontFamily}>Transactions</Text>
            </View>
          </View>
          <View {...styles.centering}>
            <Link to='/Statement'>
              <View p='12px' bgColor={theme.color1} borderRadius='8px'>
                <Image source={{ uri: Statement }} {...styles.optionsIcon} />
              </View>
            </Link>
            <View mt='8px'>
              <Text fontFamily={theme.fontFamily}>Statement</Text>
            </View>
          </View>
        </View>
      )}
    </>
  )
}

export default ActivitySection_v1
