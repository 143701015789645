import { Input, useToast } from "native-base"
import React, { useEffect, useRef, useState } from "react"
import { useWindowDimensions } from "react-native-web"
import { useDispatch, useSelector } from "react-redux"
import { useNavigate } from "react-router-dom"
import ApiIds from "../../../auth/ApiIds"
import CommonService from "../../../services/CommonService"
import {
  restoreStateBeforeSetMpin,
  saveStateBeforeSetMpin,
  setDeviceId,
  setIsMpinSet,
  setVerifiedChallenges,
} from "../../../store/actions/Auth"
import { setSetMpinState } from "../../../store/actions/SetMpin"
import * as analytics from "../../../utils/analytics"
import {
  getHeaderText,
  onFactorCompletion,
  showCancelButton,
} from "../../../utils/auth"
import {
  AuthChallenges,
  DEFAULT_MPIN_SIZE,
  MpinErrors,
} from "../../../utils/constants"
import {
  AU_BlockCodes,
  EventName,
  Federal_BlockCodes,
  PwaVersions,
} from "../../../utils/enums"
import {
  captureEvents,
  getAnalyticsProgramType,
  goToRedirectUrl,
} from "../../../utils/functions"
import { Header } from "../../core"
import { ColorButton, WhiteButton } from "../../core/buttons"
import MpinBlocked from "./MpinBlocked"
import styles from "./styles"
import ArrowRight from "../../svg/arrowRight"

import { showToast1 } from "../../core/toast"

const Mpin = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const nativeToast = useToast()

  const [theme, authStore, user, config, screen, session] = useSelector(
    state => [
      state.theme,
      state.auth,
      state.user,
      state.config,
      state.screen,
      state.session,
    ],
  )
  const toast = (message, hasTick = false) => {
    showToast1({ nativeToast, theme, message, hasTick })
  }

  const resetStateOnWrongMPIN = () =>{
    setPin(["","","","","","",""]);
    mpin0.current.focus();
  }

  useEffect(() => {
    captureEvents({
      eventName: EventName.MPIN,
      metadata: { },
    })
  }, [])

  useEffect(() => {
    if (
      config?.version === PwaVersions.V2 ||
      config?.version === PwaVersions.V2_1
    ) {
      if (
        session.disabledFunctionsByBlockCode.includes(
          AU_BlockCodes.LOGIN_DISABLE,
        ) ||
        session.disabledFunctionsByBlockCode.includes(
          AU_BlockCodes.REGISTRATION_DISABLE,
        ) ||
        session.disabledFunctionsByBlockCode.includes(
          AU_BlockCodes.ENTIRE_PWA_ACCOUNT_CARD_DISABLE,
        ) ||
        session.disabledFunctionsByBlockCode.includes(
          AU_BlockCodes.ENTIRE_PWA_ACCOUNT_DISABLE,
        )
      ) {
        navigate("/blocked")
      }

      if (
        session.disabledFunctionsByBlockCode.includes(
          Federal_BlockCodes.ENTIRE_PWA_ACCOUNT_CARD_DISABLE,
        ) ||
        session.disabledFunctionsByBlockCode.includes(
          Federal_BlockCodes.ENTIRE_PWA_ACCOUNT_DISABLE,
        )
      ) {
        navigate("/blocked")
      }
    }
  }, [])

  const [submitLoading, setSubmitLoading] = useState(false)
  const [error, setError] = useState(false)
  const [showMpinBlocked, setShowMpinBlocked] = useState(false)
  const [pin, setPin] = useState(["", "", "", "", "", "", ""])

  const mpin0 = useRef(null)
  const mpin1 = useRef(null)
  const mpin2 = useRef(null)
  const mpin3 = useRef(null)
  const mpin4 = useRef(null)
  const mpin5 = useRef(null)
  const mpin6 = useRef(null)
  const windowDimensions = useWindowDimensions()

  const showHeader =
    config?.version === PwaVersions.V2 || config?.version === PwaVersions.V2_1
  const headerText = getHeaderText(AuthChallenges.MPIN)
  const showCancelBtn = !(
    authStore.apiId === ApiIds.ACCOUNT_SUMMARY ||
    (!authStore.afaDetails.config.isMpinSet.result &&
      authStore.apiId === ApiIds.SET_MPIN)
  )

  const mpinSize =
    config?.auth?.[AuthChallenges.MPIN]?.length || DEFAULT_MPIN_SIZE

  const verifyMpin = async () => {
    analytics.track(
      `${getAnalyticsProgramType(user.programType)} - Click on Verify MPIN`,
    )

    if (pin.join("").length < mpinSize) {
      setError(true)
      captureEvents({
        eventName: EventName.INCORRECT_INPUT,
        metadata: { },
      })
      resetStateOnWrongMPIN()
      toast("Incorrect MPIN", true)
      return
    }

    setSubmitLoading(true)

    try {
      const response = await CommonService.verifyChallengeMpin({
        apiToken: authStore.apiToken,
        challengeScope: authStore.currentFactor.scope,

        customerId: user.customer.id,
        programId: user.account.programId,
        mpin: pin.join(""),
      })
      const result = response.data

      if (result?.success) {
        setError(false)

        const verifiedChallenges = {
          ...authStore.verifiedChallenges,
          mpinRefId: result.data.mpinRefId,
        }

        dispatch(setVerifiedChallenges({ verifiedChallenges }))

        if (!authStore.verifiedChallenges.deviceId) {
          // generate device token
          const deviceIdResponse = await CommonService.generateDeviceId({
            accountId: user.account.id,
          })
          const deviceIdResult = deviceIdResponse.data

          if (deviceIdResult?.success) {
            dispatch(
              setDeviceId({
                deviceId: deviceIdResult.data?.deviceToken,
              }),
            )
            // this will also set device id in local storage
          }
        }

        await onFactorCompletion(navigate)
      } else {
        if (result?.errors?.status === MpinErrors.INCORRECT_MPIN) {
          setError(true)
          const attempts =
            result?.errors?.attemptsLeft === 2
              ? ".\nYou have two attempts remaining."
              : result?.errors?.attemptsLeft === 1
              ? ". \nYou have one attempt remaining."
              : ""
          captureEvents({
            eventName: EventName.INCORRECT_INPUT,
            metadata: { },
          })
          resetStateOnWrongMPIN()
          config?.version === PwaVersions.V2 ? 
            toast(`Incorrect MPIN${attempts}`, true)
          :  toast(`Incorrect mPIN${attempts}`, true)
        } else if (result?.errors?.status === MpinErrors.BLOCKED) {
          // update isMpinSet flag
          dispatch(setIsMpinSet({ isMpinSet: { result: false } }))

          // show mpin blocked screen
          setShowMpinBlocked(true)
        } else {
          await authStore.onAuthFailure(
            result?.errors?.reason,
            "An error occurred while verifying MPIN.\n Please try again later.",
          )
        }
      }
    } catch (error) {
      await authStore.onAuthFailure(
        error,
        "An error occurred. Please try again later.",
      )
    }

    setSubmitLoading(false)
  }

  const handleForgotMpin = () => {
    dispatch(saveStateBeforeSetMpin())

    dispatch(
      setSetMpinState({
        onSetMpinSuccess: async () => {
          dispatch(restoreStateBeforeSetMpin())
          navigate("/Auth/Mpin", { replace: true })
        },

        onSetMpinFailure: async (error, message) => {
          dispatch(restoreStateBeforeSetMpin())
          await authStore.onAuthFailure(
            "Failed to set mpin",
            "An error occurred. Please try again later.",
          )
        },

        onSetMpinCancel: async message => {
          dispatch(restoreStateBeforeSetMpin())
          // on cancel navigate back here
          navigate("/Auth/Mpin", { replace: true })
        },
      }),
    )

    navigate("/Auth/SetMpin", { replace: true })
  }

  return showMpinBlocked ? (
    <MpinBlocked onSubmit={handleForgotMpin}></MpinBlocked>
  ) : (
    <div
      style={{
        minHeight: windowDimensions.height,
        backgroundColor: theme.backgroundColor,
      }}
    >
      {showHeader ? (
        <Header
          text={headerText}
          onBack={async () => {
            showCancelButton() ? window.history.go(-1) : goToRedirectUrl()
          }}
        />
      ) : (
        <div className='header-disabled'></div>
      )}
      <div
        className='authMpin-widget'
        style={{
          backgroundColor: theme.widgetBackgroundColor,
        }}
      >
        <div>
          <div
            className='authMpin-widget-heading'
            style={{ font: theme.fontFamily, color: theme.appTextColor }}
          >
            {config?.version === PwaVersions.V2
              ? "Verify through MPIN"
              : "Verify through mPIN"}
          </div>
          <div
            className='authMpin-widget-subHeading'
            style={{ font: theme.fontFamily, color: theme.appTextColor }}
          >
            {config?.version === PwaVersions.V2
              ? "Please enter your MPIN to access the card"
              : "Please enter your mPIN to access the card"}
          </div>
        </div>
        <div className='authMpin-widget-pin-container'>
          <Input
            {...styles.textInput}
            borderColor={error ? "#C2181B" : "fff"}
            ref={mpin0}
            value={pin[0]}
            onChangeText={text => {
              if ((text < "0" || text > "9") && text !== "") return false
              setPin([text, pin[1], pin[2], pin[3], pin[4], pin[5], pin[6]])
              if (text !== "") {
                mpin1.current.focus()
              }
            }}
          />
          <Input
            {...styles.textInput}
            borderColor={error ? "#C2181B" : "fff"}
            ref={mpin1}
            value={pin[1]}
            onKeyPress={({ nativeEvent }) => {
              if (nativeEvent.key === "Backspace" && pin[1] === "") {
                mpin0.current.focus()
                setPin(["", "", pin[2], pin[3], pin[4], pin[5], pin[6]])
              }
            }}
            onChangeText={text => {
              if ((text < "0" || text > "9") && text !== "") return false
              setPin([pin[0], text, pin[2], pin[3], pin[4], pin[5], pin[6]])
              if (text !== "") {
                mpin2.current.focus()
              }
            }}
          />
          <Input
            {...styles.textInput}
            borderColor={error ? "#C2181B" : "fff"}
            ref={mpin2}
            value={pin[2]}
            onKeyPress={({ nativeEvent }) => {
              if (nativeEvent.key === "Backspace" && pin[2] === "") {
                mpin1.current.focus()
                setPin([pin[0], "", "", pin[3], pin[4], pin[5], pin[6]])
              }
            }}
            onChangeText={text => {
              if ((text < "0" || text > "9") && text !== "") return false
              setPin([pin[0], pin[1], text, pin[3], pin[4], pin[5], pin[6]])
              if (text !== "") {
                mpin3.current.focus()
              }
            }}
          />
          <Input
            {...styles.textInput}
            borderColor={error ? "#C2181B" : "fff"}
            ref={mpin3}
            value={pin[3]}
            onKeyPress={({ nativeEvent }) => {
              if (nativeEvent.key === "Backspace" && pin[3] === "") {
                mpin2.current.focus()
                setPin([pin[0], pin[1], "", "", pin[4], pin[5], pin[6]])
              }
            }}
            onChangeText={text => {
              if ((text < "0" || text > "9") && text !== "") return false
              setPin([pin[0], pin[1], pin[2], text, pin[4], pin[5], pin[6]])
              if (text !== "") {
                mpinSize === 4 ? mpin3.current.blur() : mpin4.current.focus()
              }
            }}
          />
          {mpinSize > 4 && (
            <Input
              {...styles.textInput}
              borderColor={error ? "#C2181B" : "fff"}
              ref={mpin4}
              value={pin[4]}
              onKeyPress={({ nativeEvent }) => {
                if (nativeEvent.key === "Backspace" && pin[4] === "") {
                  mpin3.current.focus()
                  setPin([pin[0], pin[1], pin[2], "", "", pin[5], pin[6]])
                }
              }}
              onChangeText={text => {
                if ((text < "0" || text > "9") && text !== "") return false
                setPin([pin[0], pin[1], pin[2], pin[3], text, pin[5], pin[6]])
                if (text !== "") {
                  mpinSize === 5 ? mpin4.current.blur() : mpin5.current.focus()
                }
              }}
            />
          )}
          {mpinSize > 5 && (
            <Input
              {...styles.textInput}
              borderColor={error ? "#C2181B" : "fff"}
              ref={mpin5}
              value={pin[5]}
              onKeyPress={({ nativeEvent }) => {
                if (nativeEvent.key === "Backspace" && pin[5] === "") {
                  mpin4.current.focus()
                  setPin([pin[0], pin[1], pin[2], pin[3], "", "", pin[6]])
                }
              }}
              onChangeText={text => {
                if ((text < "0" || text > "9") && text !== "") return false
                setPin([pin[0], pin[1], pin[2], pin[3], pin[4], text, pin[6]])
                if (text !== "") {
                  mpinSize === 6 ? mpin5.current.blur() : mpin6.current.focus()
                }
              }}
            />
          )}
          {mpinSize > 6 && (
            <Input
              {...styles.textInput}
              borderColor={error ? "#C2181B" : "fff"}
              ref={mpin6}
              value={pin[6]}
              onKeyPress={({ nativeEvent }) => {
                if (nativeEvent.key === "Backspace" && pin[6] === "") {
                  mpin5.current.focus()
                  setPin([pin[0], pin[1], pin[2], pin[3], pin[4], "", ""])
                }
              }}
              onChangeText={text => {
                if ((text < "0" || text > "9") && text !== "") return false
                setPin([pin[0], pin[1], pin[2], pin[3], pin[4], pin[5], text])
                if (text !== "") {
                  mpin6.blur()
                }
              }}
            />
          )}
        </div>
        {/* <View mt="12px" display={error ? "flex" : "None"}>
              <Text
                fontFamily={theme.fontFamily}
                font-weight="700"
                font-size="14px"
                line-height="18px"
                letter-spacing="0.02em"
                color="#C2181B"
              >
                {error}
              </Text>
            </View> */}
        <div className='authMpin-button-container'>
          {/* {(config?.version !== PwaVersions.V2_1 ||
            config?.version !== PwaVersions.V2) && (
            <div className='authMpin-button'>
              <ColorButton
                text={
                  config?.version == PwaVersions.V2_1
                    ? "Verify mPIN"
                    : "Verify MPIN"
                }
                isDisable={pin.join("").length !== mpinSize}
                onPress={verifyMpin}
                isLoading={submitLoading}
              />
            </div>
          )} */}
          {showCancelBtn &&
            !(
              config?.version == PwaVersions.V2 ||
              config?.version == PwaVersions.V2_1
            ) && (
              <WhiteButton
                text='Cancel'
                onPress={async () => {
                  analytics.track(
                    `${getAnalyticsProgramType(
                      user.programType,
                    )} - Click on Cancel(MPIN)`,
                  )
                  await authStore.onAuthCancel()
                }}
              />
            )}
          <div className='authMpin-forgotMpin-container'>
            <div
              className='authMpin-forgotMpin-text'
              style={{ color: theme.appTextColor }}
            >
              {config?.version == PwaVersions.V2_1
                ? "Forgot mPIN"
                : "Forgot MPIN"}
            </div>
            <div
              className='authMpin-resetNow-text'
              style={{ color: theme.color1 }}
              onClick={handleForgotMpin}
            >
              Reset Now
            </div>

            <ArrowRight
              className='authMpin-resetNow-arrowRight'
              size='8px'
              color={theme.color1}
            />
          </div>
        </div>
      </div>
      {screen?.mpin?.bannerImageUrl && (
        <img
          className='authMpin-bannerImg'
          src={screen?.mpin?.bannerImageUrl}
        />
      )}
      {
        <div className='authMpin-bottom-button'>
          <ColorButton
            text={
              config?.version == PwaVersions.V2_1
                ? "Verify mPIN"
                : "Verify MPIN"
            }
            isDisable={pin.join("").length !== mpinSize}
            onPress={verifyMpin}
            isLoading={submitLoading}
          />
        </div>
      }
    </div>
  )
}

export default Mpin
