import {
  SET_AUTH_STATE,
  SET_VERIFIED_CHALLENGES,
  SET_CURRENT_FACTOR,
  SET_AFA_DETAILS,
  SAVE_STATE_BEFORE_SET_MPIN,
  RESTORE_STATE_BEFORE_SET_MPIN,
  SET_DEVICE_ID,
  SET_IS_MPIN_SET,
} from "../ActionTypes"

const initialState = {
  // set on each api call
  apiId: null, // "UR7HMFUogL"
  apiToken: null, // "api_HYPWREn8OrpMI"
  onAuthSuccess: null, // () => {}
  onAuthFailure: null, // () => {}
  onAuthCancel: null, // () => {}
  factors: [
    // {
    //   scope: "FIRST_FACTOR",
    //   challenge: "OTP_SMS",
    // },
    // {
    //   scope: "SECOND_FACTOR",
    //   challenge: "MPIN",
    // },
  ],
  currentFactor: {
    // scope: "FIRST_FACTOR",
    // challenge: "OTP_SMS",
  },
  otpReason: "", // text to be displayed in otp screen
  mpinReason: "", // text to be displayed in mpin screen
  // not adding reasons for any more auth factors as of now

  // set after successful challenge completion
  verifiedChallenges: {
    deviceId: null, // "wXEEnNIleZABXk+q0qob3Q=="
    otpRefId: null, // "1b2f07d8-246d-4c8a-9a31-becd6bd34fae"
    emailOtpRefId: null, // "1b2f07d8-246d-4c8a-9a31-becd6bd34fae"
    mpinRefId: null, // "1b2f07d8-246d-4c8a-9a31-becd6bd34fae"
    customerRefId: null, // "cv_HYPBJ9ydfU0v9"
  },

  // set on app load
  afaDetails: {
    config: {
      // UR7HMFUogL: {
      //   result: true,
      //   challenges: [
      //     {
      //       scope: "FIRST_FACTOR",
      //       challenge: "OTP_SMS",
      //     },
      //     {
      //       scope: "SECOND_FACTOR",
      //       challenge: "OTP_SMS",
      //     }
      //   ],
      //   conditional: true,
      //   dynamicChallenges: [
      //     {
      //       flowId: "1",
      //       deviceTokenPresent: false,
      //       mpinKnown: false,
      //       challenges: [
      //         {
      //           scope: "FIRST_FACTOR",
      //           challenge: "OTP_SMS"
      //         },
      //         {
      //           scope: "SECOND_FACTOR",
      //           challenge: "OTP_EMAIL"
      //         },
      //         {
      //           scope: "STEP_UP",
      //           challenge: "VERIFY_CUSTOMER"
      //         }
      //       ]
      //     },
      //   ]
      // },
      // UR7HMFeogL: {
      //   result: false,
      // },
      // isMpinSet: {
      //   result: false,
      // },
    },
  },

  // set by MPIN screen when "forgot mpin" is clicked in between an ongoing auth
  stateBeforeSetMpin: null,
}

const AuthReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_AUTH_STATE:
      return {
        ...state,
        apiId: action.payload.apiId, // currently unused, might be needed later
        apiToken: action.payload.apiToken,
        onAuthSuccess: action.payload.onAuthSuccess,
        onAuthFailure: action.payload.onAuthFailure,
        onAuthCancel: action.payload.onAuthCancel,
        factors: action.payload.factors,
        currentFactor: action.payload.currentFactor,
        otpReason: action.payload.otpReason,
        mpinReason: action.payload.mpinReason,
      }
    case SET_VERIFIED_CHALLENGES:
      return {
        ...state,
        verifiedChallenges: action.payload.verifiedChallenges,
      }
    case SET_DEVICE_ID:
      return {
        ...state,
        verifiedChallenges: {
          ...state.verifiedChallenges,
          deviceId: action.payload.deviceId,
        },
      }
    case SET_CURRENT_FACTOR:
      return {
        ...state,
        currentFactor: action.payload.currentFactor,
      }
    case SET_AFA_DETAILS:
      return {
        ...state,
        afaDetails: action.payload.afaDetails,
      }
    case SAVE_STATE_BEFORE_SET_MPIN:
      return {
        ...initialState,
        verifiedChallenges: state.verifiedChallenges,
        afaDetails: state.afaDetails,
        stateBeforeSetMpin: state,
      }
    case RESTORE_STATE_BEFORE_SET_MPIN:
      return {
        ...state.stateBeforeSetMpin,
        stateBeforeSetMpin: null,
      }
    case SET_IS_MPIN_SET:
      return {
        ...state,
        afaDetails: {
          ...state.afaDetails,
          config: {
            ...state.afaDetails.config,
            isMpinSet: action.payload.isMpinSet,
          },
        },
      }
    default:
      return state
  }
}

export default AuthReducer
