import moment from "moment"
import React, { useEffect, useRef, useState } from "react"
import { useSelector } from "react-redux"
import downFilledArrow from "../../../../assets/images/downFilledArrow.svg"
import { ProgramTypes } from "../../../../utils/constants"

const months = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
]
let currMonth = months[moment().month()]
const currYear = moment().year()

let years = []

const DateSelect = ({
  setCurrYear,
  setCurrMonth,
  onViewStatementsClick,
  btnText,
  numOfYear,
  monthOffset,
  defaultMonth,
  notBtn,
  monthIndex,
  fromRewards,
  selectYear,
  uptoYear,
  uptoMonth,
  contentOpenTop
}) => {
  const [theme, user] = useSelector(state => [state.theme, state.user])
  const wrapperRef = useRef(null)
  useOutsideAlerter(wrapperRef)
  monthOffset = monthOffset || 0

  let currMonthIndex ;
  if (user?.summary?.lastStatement && Object.keys(user?.summary?.lastStatement)?.length > 0) {
    currMonthIndex = (monthIndex && months.indexOf(monthIndex)) ||
    (moment(user?.account?.currentCycleStartDate).month() ||
      moment().month() + 1) -
      1 +
      monthOffset
  currMonth =
    months[
      moment(user?.account?.currentCycleStartDate).month() - 1 + monthOffset ||
      moment().month() + monthOffset
    ]

  } else {
    currMonthIndex = (monthIndex && months.indexOf(monthIndex)) ||
      (moment(user?.account?.currentCycleStartDate).month() ||
        (moment().month() + 1) -
        1 +
        monthOffset)

    currMonth =
      months[
      moment(user?.account?.currentCycleStartDate).month() + monthOffset ||
      moment().month() + monthOffset
      ]
  }

  const [selectedYear, setSelectedYear] = useState(selectYear || currYear)
  const [selectedMonth, setSelectedMonth] = useState(
    currMonth || months[moment().month()],
  )
  const [yearScrollOpen, setYearScrollOpen] = useState(false)
  const [monthScrollOpen, setMonthScrollOpen] = useState(false)
  if(uptoYear){
    numOfYear = moment().year() - Number(uptoYear) + 1  }

  useEffect(() => {
    if (defaultMonth) {
      setSelectedMonth(defaultMonth)
    }
    let setYear = currYear
    for (let ind = 0; ind < numOfYear; ind++) {
      years[ind] = setYear-- + ""
    }
  }, [])


  function useOutsideAlerter(ref) {
    useEffect(() => {
      /**
       * Alert if clicked on outside of element
       */
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          setMonthScrollOpen(false)
          setYearScrollOpen(false)
        }
      }
      // Bind the event listener
      document.addEventListener("mousedown", handleClickOutside)
      return () => {
        // Unbind the event listener on clean up
        document.removeEventListener("mousedown", handleClickOutside)
      }
    }, [ref])
  }

  useEffect(() => {
    onViewStatementsClick()
  }, [selectedMonth, selectedYear])

  return (
    <div
      ref={wrapperRef}
      className='dateSelect'
      style={
        fromRewards
          ? { backgroundColor: theme.widgetBackgroundColor, margin: "0px" }
          : { backgroundColor: theme.widgetBackgroundColor }
      }
    >
      <div
        style={fromRewards ? { paddingLeft: "0px", paddingRight: "0px" } : {}}
        className='dateSelect-container'
      >
        <div
          style={fromRewards ? { padding: "0px", boxShadow: "none" } : {}}
          className='dateSelect-container-box'
        >
          <div className='dateSelect-container-box-dropdown'>
            <div
              className='dateSelect-container-box-dropdown-content'
              style={ { marginLeft: "0px" } }
              onClick={() => {
                setYearScrollOpen(!yearScrollOpen)
                setMonthScrollOpen(false)
              }}
            >
              <div
                className='dateSelect-container-box-dropdown-content-text'
                style={{ color: theme.appTextColor }}
              >
                {selectedYear}
              </div>
              <div className='dateSelect-container-box-dropdown-content-imgBox'>
                <img
                  className='dateSelect-container-box-dropdown-content-img'
                  src={downFilledArrow}
                  alt='Alternate Text '
                />
              </div>
            </div>
            <div
              className={
                yearScrollOpen
                  ? "dateSelect-container-box-dropdown-contentOpen-year"
                  : "dateSelect-container-box-dropdown-contentClose-year"
              }
              style={
                fromRewards
                  ? {
                    width:"45%",
                      top: "73px",
                      left: "4px",
                      height: 30 + numOfYear * 28 + "px",
                      backgroundColor: theme.widgetBackgroundColor,
                    }
                  : {
                      top: contentOpenTop ? contentOpenTop :{},
                      height: 30 + numOfYear * 28 + "px",
                      backgroundColor: theme.widgetBackgroundColor,
                    }
              }
            >
              <div className='dateSelect-container-box-dropdown-contentOpen-year-scroll'>
                {years.map((year, key) => {
                  return (
                    <>
                      <div
                        className={
                          year === selectedYear
                            ? "dateSelect-container-box-dropdown-contentOpen-yearSelected-text"
                            : "dateSelect-container-box-dropdown-contentOpen-yearNotSelected-text"
                        }
                        style={{
                          backgroundColor:
                            year == selectedYear
                              ? `${theme.color1}`
                              : theme.widgetBackgroundColor,
                          color:
                            selectedYear == year
                              ? theme.color2
                              : theme.appTextColor,
                        }}
                        key={key}
                        onClick={() => {
                          setSelectedYear(year)
                          setCurrYear(year)

                          if(year == selectedYear){
                            // skip
                          }
                          else {
                          if (
                            months.indexOf(selectedMonth) > currMonthIndex &&
                            currYear == Number(year)
                          ) {
                            setSelectedMonth(months[currMonthIndex])
                            setCurrMonth(months[currMonthIndex])
                          }

                          if(year ==  uptoYear){
                            if(months.indexOf(selectedMonth)  < uptoMonth ){
                              setSelectedMonth(months[uptoMonth])
                              setCurrMonth(months[uptoMonth])
                            }
                          }
                          }                      
                          setYearScrollOpen(false)
                          setMonthScrollOpen(false)
                          !notBtn && onViewStatementsClick()
                        }}
                      >
                        {year}
                      </div>
                    </>
                  )
                })}
              </div>
            </div>
          </div>

          <div className='dateSelect-container-box-dropdown'>
            <div
              className='dateSelect-container-box-dropdown-content'
              style={ { marginRight: "0px" } }
              onClick={() => {
                setMonthScrollOpen(!monthScrollOpen)
                setYearScrollOpen(false)
              }}
            >
              <div
                className='dateSelect-container-box-dropdown-content-text'
                style={{ color: theme.appTextColor }}
              >
                {selectedMonth}
              </div>
              <div className='dateSelect-container-box-dropdown-content-imgBox'>
                <img
                  className='dateSelect-container-box-dropdown-content-img'
                  src={downFilledArrow}
                  alt='Alternate Text '
                />
              </div>
            </div>
            <div
              className={
                monthScrollOpen
                  ? "dateSelect-container-box-dropdown-contentOpen-month"
                  : "dateSelect-container-box-dropdown-contentClose-month"
              }
              style={
                fromRewards
                  ? {
                    width:"45%",
                      top: "73px",
                      right: "4px",
                      height:
                        selectedYear == currYear
                          ? 35 + (currMonthIndex + 1) * 29 + "px"
                          : "340px",
                      backgroundColor: theme.widgetBackgroundColor,
                    }
                  : {
                    top: contentOpenTop ? contentOpenTop :{},
                    height:
                      selectedYear == currYear && selectedYear == uptoYear ?
                        35 + (currMonthIndex - Number(uptoMonth) + 1) * 29 + "px"
                        : selectedYear == currYear

                          ? 35 + (currMonthIndex + 1) * 29 + "px"
                          : selectedYear == uptoYear ? 35 + (12 - Number(uptoMonth) ) * 29 + "px"
                            : "340px",
                      backgroundColor: theme.widgetBackgroundColor,
                    }
              }
            >
              <div className='dateSelect-container-box-dropdown-contentOpen-month-scroll'>
                {months.map((month, key) => {
                  if (selectedYear == currYear) {
                      if (selectedYear == uptoYear  ) {
                        if(key <= currMonthIndex && key >= uptoMonth ){
                        return (
                          <>
                            <div
                              className={
                                month === selectedMonth
                                  ? "dateSelect-container-box-dropdown-contentOpen-monthSelected-text"
                                  : "dateSelect-container-box-dropdown-contentOpen-monthNotSelected-text"
                              }
                              style={{
                                backgroundColor:
                                  month === selectedMonth
                                    ? `${theme.color1}`
                                    : theme.widgetBackgroundColor,
                                color:
                                  selectedMonth == month
                                    ? theme.color2
                                    : theme.appTextColor,
                              }}
                              key={key}
                              onClick={() => {
                                setSelectedMonth(month)
                                setCurrMonth(month)
                                setMonthScrollOpen(false)
                                setYearScrollOpen(false)
                                !notBtn && onViewStatementsClick()
                              }}
                            >
                              {month}
                            </div>
                          </>
                        )
                        }
                      }
                      else if(key <= currMonthIndex) {
                      return (
                        <>
                          <div
                            className={
                              month === selectedMonth
                                ? "dateSelect-container-box-dropdown-contentOpen-monthSelected-text"
                                : "dateSelect-container-box-dropdown-contentOpen-monthNotSelected-text"
                            }
                            style={{
                              backgroundColor:
                                month === selectedMonth
                                  ? `${theme.color1}`
                                  : theme.widgetBackgroundColor,
                              color:
                                selectedMonth == month
                                  ? theme.color2
                                  : theme.appTextColor,
                            }}
                            key={key}
                            onClick={() => {
                              setSelectedMonth(month)
                              setCurrMonth(month)
                              setMonthScrollOpen(false)
                              setYearScrollOpen(false)
                              !notBtn && onViewStatementsClick()
                            }}
                          >
                            {month}
                          </div>
                        </>
                      )
                    }
                  } 
                  else if(selectedYear == uptoYear){
                    if( key >= uptoMonth ){
                      return (
                        <>
                          <div
                            className={
                              month === selectedMonth
                                ? "dateSelect-container-box-dropdown-contentOpen-monthSelected-text"
                                : "dateSelect-container-box-dropdown-contentOpen-monthNotSelected-text"
                            }
                            style={{
                              backgroundColor:
                                month == selectedMonth
                                  ? `${theme.color1}`
                                  : theme.widgetBackgroundColor,
                              color:
                                selectedMonth == month
                                  ? theme.color2
                                  : theme.appTextColor,
                            }}
                            key={key}
                            onClick={() => {
                              setSelectedMonth(month)
                              setCurrMonth(month)
                              setMonthScrollOpen(false)
                              !notBtn && onViewStatementsClick()
                            }}
                          >
                            {month}
                          </div>
                        </>
                      )
                    }
                  }
                  else  {
                    return (
                      <>
                        <div
                          className={
                            month === selectedMonth
                              ? "dateSelect-container-box-dropdown-contentOpen-monthSelected-text"
                              : "dateSelect-container-box-dropdown-contentOpen-monthNotSelected-text"
                          }
                          style={{
                            backgroundColor:
                              month == selectedMonth
                                ? `${theme.color1}`
                                : theme.widgetBackgroundColor,
                            color:
                              selectedMonth == month
                                ? theme.color2
                                : theme.appTextColor,
                          }}
                          key={key}
                          onClick={() => {
                            setSelectedMonth(month)
                            setCurrMonth(month)
                            setMonthScrollOpen(false)
                            !notBtn && onViewStatementsClick()
                          }}
                        >
                          {month}
                        </div>
                      </>
                    )
                  }
                })}
              </div>
            </div>
          </div>
        </div>

        {/* <div
          className='dateSelect-button'
          style={{ backgroundColor: theme.color1 }}
          onClick={() => onViewStatementsClick()}
        >
          <div
            className='dateSelect-button-text'
            style={{ color: theme.color2, fontFamily: theme.fontFamily }}
          >
            {btnText
              ? btnText
              : user.programType === ProgramTypes.PREPAID
              ? "View All Transactions"
              : "View Statement Summary"}
          </div>
        </div> */}
      </div>
    </div>
  )
}

export default DateSelect
