import React from "react"
import { CardSkeleton, TextWrapperSkeleton } from "./styledComponents.js"

function NudgeLoader({isImagePresent}) {
  return (
    <>
      
      <CardSkeleton height='85px' br="12px" margin='0px auto 20px auto' width='90%' >
       
       
      
      </CardSkeleton>
     
    </>
  )
}

export default NudgeLoader
