import Service from "./Service"
import HeartBeatService from "./HeartBeatService"
import { getApiConfig } from "../utils/functions"

const CommonService = {
  getSessionInfo: () => Service.get(`/get-session-info`),

  sendHeartBeat: () => HeartBeatService.get(`/send-heartbeat`),

  getLrSsoUrl: () => Service.get(`/get-lrSsoUrl`),

  getDesignConfig: () => Service.get(`/get-design-config`),

  generateDeviceId: data => Service.post(`/generate-device-id`, data),

  verifyDeviceId: data => Service.post(`/verify-device-id`, data),

  getAfaDetails: data => Service.post(`/get-afa-details`, data),

  generateOTP: data => Service.post(`/generate-otp`, data),

  verifyOTP: data => Service.post(`/verify-otp`, data),

  generateChallengeOTP: data => Service.post(`/generate-challenge-otp`, data),

  verifyChallengeOTP: data => Service.post(`/verify-challenge-otp`, data),

  generateChallengeEmailOTP: data =>
    Service.post(`/generate-challenge-email-otp`, data),

  verifyChallengeOTPEmail: data =>
    Service.post(`/verify-challenge-email-otp`, data),

  setMpin: (apiToken, data) =>
    Service.post(`/set-mpin`, data, getApiConfig(apiToken)),

  verifyChallengeMpin: data => Service.post(`/verify-challenge-mpin`, data),

  verifyChallengeCustomer: data =>
    Service.post(`/verify-challenge-customer`, data),
}

export default CommonService
