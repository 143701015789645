import { createStore, applyMiddleware } from "redux"
import thunk from "redux-thunk"
import RootReducer from "./reducers/index"

const middleware = [thunk]
const initialState = {}

const store = createStore(
  RootReducer,
  initialState,
  applyMiddleware(...middleware),
)

export default store
