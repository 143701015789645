/* eslint-disable */
import { CarouselProvider, Slide, Slider } from "pure-react-carousel"
import React, { useEffect, useRef, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useNavigate } from "react-router-dom"
import Paths from "../../../routes/Paths"
import { clearCommonState } from "../../../store/actions/Common"
import { ProgramTypes } from "../../../utils/constants"
import { EventName, NudgeType, PwaVersions } from "../../../utils/enums"
import OffersCard_v2 from "../../Home/Components/OffersSection/v2/OffersCard_v2"
import CardInHand from "../../svg/cardInHand"
import styles from "./styles"
import CrossFilled from "../../svg/crossFilled"
import AccountService from "../../../services/AccountService"
import {
  captureEvents,
  consoleError,
  truncateString,
} from "../../../utils/functions"
import { toast } from "../../../core/Toast"
import { useIntersection } from "../../../hooks/useIntersection"

const getProgramType = user => {
  if (user.programType === ProgramTypes.CREDIT_CARD) return " credit "
  else if (user.programType === ProgramTypes.PAY_LATER) return " pay later "
  else return " "
}
const SmartNudges = props => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const [activeIndex, setActiveIndex] = useState(0)
  const [nudges, setNudges] = useState([])

  const [theme, user, config, featureFlags, screen] = useSelector(state => [
    state.theme,
    state.user,
    state.config,
    state.featureFlags,
    state.screen,
  ])


  const allNudges = props.nudgesData


  // incrementing impression count of first nudge on load
  useEffect(() => {
    if (allNudges) {
      let id = allNudges[0].id;
      let nudgesDelivery = JSON.parse(localStorage.getItem("nudgesDelivery")) || {}
      nudgesDelivery.lastImpressionId = id;
      localStorage.setItem(
        "nudgesDelivery",
        JSON.stringify({
          ...nudgesDelivery,
          [accountId]: {
            ...(nudgesDelivery?.[accountId] || {}),
            [id]: {
              ...(nudgesDelivery?.[accountId]?.[id] || {}),
              sessions: (nudgesDelivery?.[accountId]?.[id]?.sessions || 0) + 1,
            },
          },
        }),
      )
    }
  }, [])

  useEffect(() => {
    allNudges.sort(
      (a, b) =>
        a?.displayOptions?.displayOrder - b?.displayOptions?.displayOrder,
    )

    setNudges(allNudges)
  }, [props.nudgesData])

  const onClickNavigation = () => {
    if (props?.to) {
      return props.to
    } else if (
      config.version === PwaVersions.V1 ||
      ((config?.version === PwaVersions.V2 ||
        config?.version === PwaVersions.V2_1) &&
        screen?.home?.isQrCodeRequiredForActivation)
    ) {
      return "/ActivateCard/Scanner"
    } else {
      return Paths.HOME_WITH_ACTIVATE
    }
  }

  const getBgColor = () => {
    if (
      config?.version === PwaVersions.V2 ||
      config?.version === PwaVersions.V2_1
    ) {
      return theme.widgetBackgroundColor
    } else if (user.programType === ProgramTypes.PREPAID) {
      return `${theme.color1}:alpha.10`
    } else {
      return theme.color3
    }
  }

  const handleNudgeDisable = async item => {
    try {
      let data = {
        nudgeDfnId: item.id,
        channel: "PWA",
      }
      const response = await AccountService.disableNudge(
        user?.account?.id,
        data,
      )
      const result = response.data

      if (result?.success) {
        // card state will be updated by summary api later
        let updatedArray = removeNudgeFromState(item.id)
        setNudges(updatedArray)
      } else {
        consoleError(result?.errors)
      }
    } catch (error) {
      consoleError(error)
    }
  }
  const accountId = user?.account?.id

  function removeNudgeFromState(id) {
    let arr = nudges
    return arr.filter(obj => obj.id !== id)
  }

  const handleNudgeTouch = id => {
    let nudgesDelivery = JSON.parse(localStorage.getItem("nudgesDelivery")) || {}
    let lastNudgeId = nudgesDelivery?.lastImpressionId || "";
    if (id == lastNudgeId) {
      return false;
    }
    else {
      nudgesDelivery.lastImpressionId = id;
    }
    localStorage.setItem(
      "nudgesDelivery",
      JSON.stringify({
        ...nudgesDelivery,
        [accountId]: {
          ...(nudgesDelivery?.[accountId] || {}),
          [id]: {
            ...(nudgesDelivery?.[accountId]?.[id] || {}),
            sessions: (nudgesDelivery?.[accountId]?.[id]?.sessions || 0) + 1,
          },
        },
      }),
    )
  }

  const handleNudgeClick = id => {
    let nudgesDelivery = JSON.parse(localStorage.getItem("nudgesDelivery"))
    localStorage.setItem(
      "nudgesDelivery",
      JSON.stringify({
        ...nudgesDelivery,
        [accountId]: {
          ...(nudgesDelivery?.[accountId] || {}),
          [id]: {
            ...(nudgesDelivery?.[accountId]?.[id] || {}),
            clickCount:
              (nudgesDelivery?.[accountId]?.[id]?.clickCount || 0) + 1,
          },
        },
      }),
    )
  }

  return (
    <div
      style={nudges?.length === 0 ? { height: "0px" } : {}}
      className='nudge-smartnudges'
    >
      <CarouselProvider
        naturalSlideWidth={200}
        naturalSlideHeight={200}
        isIntrinsicHeight={true}
        totalSlides={nudges?.length}
        visibleSlides={nudges?.length === 1 ? 1 : 1.1}
        currentSlide={activeIndex}
      >
        <Slider>
          {nudges &&
            nudges?.map(
              (item, index) => (
                (
                  <Slide
                    index={index}>
                    <div
                      onTouchEnd={(e) => handleNudgeTouch(e?.target?.id)}
                      id={item.id}
                      style={{
                        backgroundColor: JSON.parse(
                          item?.displayOptions?.nudgeDetails,
                        )?.backgroundColor,
                        marginLeft: index === 0 ? "16px" : "16px",
                        marginRight:
                          index === nudges.length - 1 ? "16px" : "16px",
                      }}
                      className='nudge-smartnudges-nudge-container'
                      onClick={(e) => {
                        captureEvents({
                          eventName: EventName.NUDGE_CLICK,
                          metadata: {
                            nudgeDetails: JSON.parse(
                              item?.displayOptions?.nudgeDetails,
                            ),
                            position: index + 1,
                          },
                        })
                        JSON.parse(
                          item?.displayOptions?.nudgeDetails,
                        )?.redirectionUrl.startsWith("https")
                          ? (console.log(""),
                            (window.location.href = JSON.parse(
                              item?.displayOptions?.nudgeDetails,
                            )?.redirectionUrl))
                          : console.log(""),
                          navigate(
                            JSON.parse(item?.displayOptions?.nudgeDetails)
                              ?.redirectionUrl,
                          )
                        setActiveIndex(index)
                        handleNudgeClick(item.id)
                        e.stopPropagation()
                      }}
                    >
                      <div id={item.id} className='nudge-smartnudges-nudge-icon'>
                        {JSON.parse(item?.displayOptions?.nudgeDetails)
                          ?.illustrationUrl ? (
                          <img
                          id={item.id}
                            src={
                              JSON.parse(item?.displayOptions?.nudgeDetails)
                                ?.illustrationUrl
                            }
                            width={"60px"}
                            height={"60px"}
                          ></img>
                        ) : (
                          item.name !== "Activate Physical Card" &&
                          item.name !== "Request New Card" && (
                            <CardInHand type={"new"} color={theme.color1} />
                          )
                        )}
                      </div>

                      <div  id={item.id}
                        className='nudge-smartnudges-nudge-nudgeTitleDescriptionContainer'
                      >
                        <div
                         id={item.id}
                          style={{
                            color: JSON.parse(
                              item?.displayOptions?.nudgeDetails,
                            )?.titleColor,
                          }}
                          className='nudge-smartnudges-nudge-nudgeTitle'
                        >
                          {
                            JSON.parse(item?.displayOptions?.nudgeDetails)
                              ?.title
                          }
                        </div>
                        <div  id={item.id}
                          style={{
                            color: JSON.parse(
                              item?.displayOptions?.nudgeDetails,
                            )?.subtitleColor,
                          }}
                          className='nudge-smartnudges-nudge-nudgeDescription'
                        >
                          {truncateString(
                            JSON.parse(item?.displayOptions?.nudgeDetails)
                              ?.description,
                            75,
                          )}
                        </div>
                      </div>

                      {item?.displayOptions?.requireType ===
                      NudgeType.OPTIONAL ? (
                        <div
                          onClick={e => {
                            e.stopPropagation()
                            handleNudgeDisable(item)
                          }}
                          className='nudge-smartnudges-nudge-crossImage'
                        >
                          {" "}
                          <CrossFilled
                            fillColor={
                              JSON.parse(item?.displayOptions?.nudgeDetails)
                                ?.backgroundColor
                            }
                            crossColor={
                              JSON.parse(item?.displayOptions?.nudgeDetails)
                                ?.titleColor
                            }
                          />
                        </div>
                      ) : (
                        <></>
                      )}
                    </div>
                  </Slide>
                )
              ),
            )}
        </Slider>
      </CarouselProvider>
    </div>
  )
}

export default SmartNudges
