import moment from "moment"
import { FlatList, Image, Text, useToast, View } from "native-base"
import React, { useEffect, useRef, useState } from "react"
import { useWindowDimensions } from "react-native-web"
import { useSelector } from "react-redux"
import { useNavigate } from "react-router-dom"
import rewardsEmptyImage from "../../../assets/images/rewarTxnEmty.svg"
import rewardSummaryBanner from "../../../assets/images/rewardSummaryBanner.svg"
import AccountService from "../../../services/AccountService"
import { DisplayConfig } from "../../../utils/constants"
import { captureEvents, formatAmount } from "../../../utils/functions"
import { Header } from "../../core"
import CustomLoader from "../../core/loader"
import { showToast1 } from "../../core/toast"
import Transactions from "./Transactions"
import { ColorButton } from "../../core/buttons"
import { EventName, PwaVersions } from "../../../utils/enums"
import Settings from "../../svg/settings"
import DateSelect from "../../Statement/fetchAll/DateSelect/DateSelect"

const initStartDate = moment().subtract(12, "months").format("YYYY-MM-DD")
const initCurrentDate = moment().format("YYYY-MM-DD")
const initTransactions = [
  { type: "TITLE", date: moment().format("YYYY-MM-DD") },
]

const initOffset = 0
const transactionCount = 10

const Rewards_v2 = () => {
  const [isTransactionsLoading, setIsTransactionsLoading] = useState(false)
  const isTransactionsLoadingRef = useRef(false)
  const [apiError, setApiError] = useState(0)

  const [transactions, setTransactions] = useState(initTransactions)
  const [ssoRewardsLoaded, setSsoRewardLoaded] = useState(false)
  const offset = useRef(initOffset)
  // date till which txns should be loaded
  const startDate = useRef(initStartDate)
  // pointer to current month being fetched
  const currentMonth = useRef(initCurrentDate)

  const [currYear, setCurrYear] = useState(moment().year())
  const [currMonth, setCurrMonth] = useState(moment().format("MMM"))

  const [selectedYear, setSelecteYear] = useState()

  const navigate = useNavigate()
  const nativeToast = useToast()
  const windowDimensions = useWindowDimensions()
  const [theme, user, preferences, screen, config] = useSelector(state => [
    state.theme,
    state.user,
    state.preferences,
    state.screen,
    state.config,
  ])
  

  const rewardPoints = user.aggregate?.currentValue
  const hfRewardPoints = user?.aggregate?.accrued
  const ssoRewardPoints = user?.rewardSummary?.currentValue || 0

  const account = user.account
  const card = user.cards?.[0]
  const isAccountActive =
    account.status === "ACTIVE" || account.status === "DORMANT"
  const toast = message => {
    showToast1({ nativeToast, theme, message })
  }
  const [accountCreatedDate, setAccountCreatedDate] = useState(
    account?.dateCreated,
  )
  const [lrCheck, setLrCheck] = useState(false)

  const [lrDate, setLrDate] = useState("15-" + currMonth + "-" + currYear)
  
  const numOfYear =
    moment().format("YYYY") - moment(accountCreatedDate).format("YYYY") + 1

  const lr =
    Boolean(screen?.home?.reward?.redirectionType !== "LR_TRANSACTIONS") ||
    Boolean(!user?.rewardSummary?.currentValue)

  useEffect(() => {
    let data = {
      pointBalance:
        Number(user?.rewardSummary?.currentValue) ||
        Number(user?.rewardSummary?.metadata?.balance) || 0,
      totalRedeemed: user?.rewardSummary?.metadata?.redeemed
        ? Number(user?.rewardSummary?.metadata?.redeemed)
        : 0,
      totalRewards: user?.rewardSummary?.metadata?.balance
        ? Number(user?.rewardSummary?.metadata?.balance) +
          Number(user?.rewardSummary?.metadata?.redeemed)
        : 0,
    }
    captureEvents({ eventName: EventName.REWARDS, metadata: data })
  }, [])

  useEffect(() => {
    if (apiError >= 3) {
      captureEvents({
        eventName: EventName.REWARDS_ERROR,
        metadata: { msg: "Error fetching Transactions" },
      })
      toast("Error fetching Transactions")
    }

    if (!user.aggregate && lr) {
      navigate("/")
      captureEvents({
        eventName: EventName.REWARDS_ERROR,
        metadata: {
          msg: "Uh-oh! Couldn’t fetch your rewards \nhistory Please try again later",
        },
      })
      toast(
        " Uh-oh! Couldn’t fetch your rewards \nhistory. Please try again later",
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [apiError])

  useEffect(() => {
    if (lrDate != "" && lrDate != "FOUND") {
      getTransactions()
    }
  }, [lrDate])
 
 const payLoadRef = useRef("");
 setTimeout(() => {
  var element = document.querySelector('[aria-label="llllll"]')
  if (element?.childNodes[0]?.childNodes[0]) {
    element.childNodes[0].childNodes[0].style.zIndex = 1
  }
 }, 100);
  const getTransactions = async () => {
    try {
      let date = "15-" + currMonth + "-" + currYear

      // get txns for current month with the set offset
      let response
      if (screen?.home?.reward?.redirectionType === "HF_CASHBACK") {
        let payload = {
          count: transactionCount,
          offset: offset.current,
          from: moment(date).startOf("month").format("YYYY-MM-DD"),
          to: moment(date).endOf("month").format("YYYY-MM-DD"),
        }
        if(JSON.stringify(payload) === JSON.stringify(payLoadRef.current) ){
          return false;
        }
        payLoadRef.current = payload
        response = await AccountService.getCashbacks(user.account.id, payload)
      } else if (screen?.home?.reward?.redirectionType === "LR_TRANSACTIONS") {
        if (lrDate != "" && lrDate != "FOUND") {
          date = lrDate
        }
        let payload = {
          id: user.account.id,
          tag: "REWARDS",
          accountId: user.account.id,
          page: 1,
          startDate: moment(date).startOf("month").format("YYYY-MM-DD"),
          endDate: moment(date).endOf("month").format("YYYY-MM-DD"),
        }
        if(JSON.stringify(payload) === JSON.stringify(payLoadRef.current)){
          return false;
        }
        payLoadRef.current = payload
        response = await AccountService.getSsoRewards(payload)
      } else {
        let payload = {
          count: transactionCount,
          offset: offset.current,
          from: moment(date).startOf("month").format("YYYY-MM-DD"),
          to: moment(date).endOf("month").format("YYYY-MM-DD"),
        }
        if(JSON.stringify(payload) === JSON.stringify(payLoadRef.current)){
          return false;
        }
        payLoadRef.current = payload
        response = await AccountService.getRewards(user.account.id, payload)
      }

      const result = response.data

      // if api call successful
      if (result?.success) {
        if (screen?.home?.reward?.redirectionType === "LR_TRANSACTIONS") {
          if (result.data.totalCount !== 0 || result.data.count) {
            setSsoRewardLoaded(true)
            if (result.data.page == 1) {
              if (result?.data?.count == 0 && lrDate != "FOUND") {
                let newDate = moment(lrDate)
                  .subtract(1, "months")
                  .format("DD-MMM-YYYY")
                if (
                  moment(accountCreatedDate).format("YYYY-MM") >=
                    moment(lrDate).format("YYYY-MM") &&
                  lrDate != "FOUND"
                ) {
                  setLrCheck(true)
                  setLrDate("FOUND")
                } else {
                  setLrDate(newDate)
                  setCurrMonth(moment(newDate).format("MMM"))
                  setCurrYear(moment(newDate).format("YYYY"))
                }
              } else if (lrDate != "FOUND") {
                setLrDate("FOUND")
              }
              setTransactions(transactions => [
                ...initTransactions,
                ...result.data.data,
              ])
            } else {
              setTransactions(transactions => [
                ...transactions,
                ...result.data.data,
              ])
            }

            let metadata = {
              month: currMonth,
              year: currYear,
              count: result.data.totalCount || result.data.count,
            }
            captureEvents({
              eventName: EventName.REWARDS_HISTORY,
              metadata: metadata,
            })
            return false
          }
        } else {
          setTransactions(transactions => [...initTransactions, ...result.data])
        }
        let metadata = {
          month: currMonth,
          year: currYear,
          count: result.data.totalCount || result.data.count,
        }
        captureEvents({
          eventName: EventName.REWARDS_HISTORY,
          metadata: metadata,
        })
        // if current month has more txns pending, stay in this month
        if (result.data.hasMore || result.data?.hasNextPage) {
          // increment offset, next call happens for current month with this offset
          offset.current += transactionCount
        } else {
          // else move current month pointer to next month (chronologically previous month)

          // get next month's value
          // const nextMonthToFetch = moment(currentMonth.current)
          //   .subtract(1, "month")
          //   .format("YYYY-MM-DD")

          // move pointer to next month
          // currentMonth.current = nextMonthToFetch
          // reset offset
          offset.current = 0
        }
      } else {
        setApiError(apiError + 1)
      }
    } catch (error) {
      setApiError(apiError + 1)
    }
  }

 
  const loadMoreTransaction = async () => {
    // if current date's month is greater than or equal to start date's month (i.e. end not reached)
    // and any other api call for loading is not pending (txns must be loaded synchronously)

    // api is giving all transactions at once irrespective of date, so not running this function.
    if (ssoRewardsLoaded) {
      return false
    }
    if (
      moment(currentMonth.current).format("YYYY-MM") >=
        moment(startDate.current).format("YYYY-MM") &&
      !isTransactionsLoadingRef.current &&
      apiError < 3 &&
      screen?.home?.reward?.redirectionType === "LR_TRANSACTIONS"
    ) {
      // use ref to track loading as setState can be async
      isTransactionsLoadingRef.current = true
      // show loader in UI
      setIsTransactionsLoading(true)

      // get txns
      await getTransactions()

      isTransactionsLoadingRef.current = false
      setIsTransactionsLoading(false)
    } else if (
      moment(currentMonth.current).format("YYYY-MM") <
        moment(startDate.current).format("YYYY-MM") &&
      transactions.length === 1
    ) {
      setTransactions(transactions => [...transactions, { type: "EMPTY" }])
    }
  }

  const renderLoader = () => {
    return isTransactionsLoading ? (
      <View w='100%' alignItems='center' justifyContent='center'>
        <CustomLoader color={theme.color1} width={20} height={20} />
      </View>
    ) : null
  }

  const renderItem = ({ item, index }) => {
    switch (item.type) {
      case "TITLE":
        return (
          <View zIndex={"39"}>
            <View zIndex={"123"} mb='14px'>
              <Text
                fontFamily={theme.fontFamily}
                fontWeight='700'
                fontSize='18px'
                lineHeight='18px'
                letterSpacing='0.02em'
                color={theme.appTextColor}
              >
                {screen?.home?.reward?.redirectionType === "HF_CASHBACK"
                  ? "Cashbacks History"
                  : "Rewards History"}
              </Text>
              <View width={"100%"} zIndex={"93"}>
                <DateSelect
                  key={lrCheck}
                  numOfYear={numOfYear}
                  monthOffset={0}
                  notBtn={true}
                  onViewStatementsClick={getTransactions}
                  setCurrMonth={setCurrMonth}
                  setCurrYear={setCurrYear}
                  fromRewards={true}
                  defaultMonth={currMonth}
                  monthIndex={moment().format("MMMM")}
                  selectYear={currYear}
                />
              </View>
            </View>
          </View>
        )

      case "DATE":
        return <View zIndex={12} marginTop={30} marginLeft={6}></View>

      case "EMPTY":
        return (
          <View>
            <View mt='32px' alignSelf={"center"}>
              <Image
                source={{
                  uri: rewardsEmptyImage,
                }}
                width='192px'
                height='183px'
                alt='Alternate Text '
              />
            </View>
            <View alignSelf={"center"} mt='16px'>
              <Text
                fontFamily={theme.fontFamily}
                fontWeight='600'
                fontSize='14px'
                lineHeight='18px'
                letterSpacing='0.02em'
                color={theme.appTextColor}
              >
                Start shopping to earn rewards!
              </Text>
            </View>
          </View>
        )

      default:
        return (
          <View>
            <Transactions
              fromRewards={true}
              transaction={item}
              theme={theme}
              type={screen?.home?.reward?.redirectionType}
              dateFormat={preferences.dateFormat}
              timeFormat={preferences.timeFormat}
              isLast={index === transactions.length - 1}
            />
          </View>
        )
    }
  }

  const handleRefresh = async () => {
    setTransactions(initTransactions)
    offset.current = initOffset
    startDate.current = initStartDate
    currentMonth.current = initCurrentDate
  }
  return (
    <View>
      <View m='0'>
        <Header
          marginBottom='0px'
          text={"Rewards"}
          onBack={() => navigate("/")}
          rightNavigateURL={isAccountActive && card ? "/ManageCard" : null}
          rightProp={<Settings />}
        />
      </View>
      <View
        alignItems={"center"}
        _web={{ maxW: DisplayConfig.MAX_WIDTH }}
        w='100%'
        minH={windowDimensions.height}
        bgColor={theme.backgroundColor}
      >
        <View
          borderRadius={"8px"}
          background={theme.widgetBackgroundColor}
          borderWidth={"1px"}
          borderColor='#ECECEC'
          shadow={2}
          w='90%'
          padding='24px'
          mt='24px'
        >
          <View
            flexDir={"row"}
            alignItems={"center"}
            justifyContent={"space-between"}
          >
            <View justifyContent={"center"}>
              <View>
                <Text
                  fontFamily={theme.fontFamily}
                  fontWeight='600'
                  fontSize='16px'
                  lineHeight='18px'
                  letterSpacing='0.02em'
                  color={`${theme.appTextColor}:alpha.70`}
                >
                  {screen?.home?.reward?.redirectionType === "HF_CASHBACK"
                    ? "Total Available Cashbacks"
                    : "Total Available Rewards"}
                </Text>
              </View>
              <View mt='12px'>
                <Text
                  fontFamily={theme.fontFamily}
                  fontWeight='600'
                  fontSize='32px'
                  lineHeight='18px'
                  letterSpacing='0.02em'
                  color={theme.appTextColor}
                >
                  {screen?.home?.reward?.redirectionType === "HF_CASHBACK"
                    ? formatAmount(rewardPoints)
                    : screen?.home?.reward?.redirectionType === "HF_REWARDS"
                    ? Number(hfRewardPoints)
                    : screen?.home?.reward?.redirectionType ===
                      "LR_TRANSACTIONS"
                    ? Number(user?.rewardSummary?.currentValue)
                    : rewardPoints}
                </Text>
              </View>
              {config.version === PwaVersions.V2_1 && (
                <div
                  style={{ color: theme.appTextColor }}
                  className='rewards-earned-redeemed-container'
                >
                  <div
                    className='rewards-earned-text'
                    style={{ color: `${theme.appTextColor}90` }}
                  >
                    Total Earned
                    <div
                      className='rewards-earned-amount'
                      style={{ color: `${theme.appTextColor}` }}
                    >
                      {user?.rewardSummary?.metadata?.balance
                        ? Number(user?.rewardSummary?.metadata?.balance) +
                          Number(user?.rewardSummary?.metadata?.redeemed)
                        : 0}
                    </div>
                  </div>
                  <div
                    className='rewards-redeem-text'
                    style={{ color: `${theme.appTextColor}90` }}
                  >
                    Total Redeemed
                    <div
                      className='rewards-redeem-amount'
                      style={{ color: `${theme.appTextColor}` }}
                    >
                      {user?.rewardSummary?.metadata?.redeemed
                        ? Number(user?.rewardSummary?.metadata?.redeemed)
                        : 0}
                    </div>
                  </div>
                </div>
              )}
            </View>
            <View>
              <Image
                source={{
                  uri: rewardSummaryBanner,
                }}
                width='70px'
                height='70px'
                alt='Alternate Text '
              />
            </View>
          </View>

          {config.version === PwaVersions.V2_1 ? (
            <>
              <View mt={"24px"} alignItems='center' width={"100%"}>
                <ColorButton
                  width={"100%"}
                  text={"Redeem Rewards"}
                  isDisable={user?.cards[0]?.isHotlisted}
                  onPress={() => {
                    let data = {
                      pointBalance:
                        Number(user?.rewardSummary?.currentValue) ||
                        Number(user?.rewardSummary?.metadata?.balance) || 0,
                      totalRedeemed: user?.rewardSummary?.metadata?.redeemed
                        ? Number(user?.rewardSummary?.metadata?.redeemed)
                        : 0,
                      totalRewards: user?.rewardSummary?.metadata?.balance
                        ? Number(user?.rewardSummary?.metadata?.balance) +
                          Number(user?.rewardSummary?.metadata?.redeemed)
                        : 0,
                    }
                    captureEvents({
                      eventName: EventName.REWARDS_REDEEM_CTA,
                      metadata: data,
                    })
                    window.open("https://rewardz.aubank.in/")
                  }}
                />
              </View>
              <div className='rewards-v2_1-note'>
                Note - It usually takes up to 5 days to process your reward
                points.
              </div>
            </>
          ) : (
            <></>
          )}
        </View>
        {screen?.home?.reward?.redirectionType !== "LR_TRANSACTIONS" &&
          screen?.home?.reward?.redirectionType !== "HF_CASHBACK" && (
            <View
              borderRadius={"8px"}
              background={theme.widgetBackgroundColor}
              borderWidth={"1px"}
              borderColor='#ECECEC'
              shadow={2}
              w='90%'
              mt='12px'
            >
              <View
                background={theme.color1}
                p='2px'
                alignSelf={"flex-end"}
                textAlign='center'
                w='14%'
                mr='10%'
                borderBottomRadius={"4px"}
              >
                <Text
                  fontFamily={theme.fontFamily}
                  fontWeight='400'
                  fontSize='12px'
                  lineHeight='18px'
                  letterSpacing='0.02em'
                  color={theme.color2}
                >
                  Note
                </Text>
              </View>
              <View w='75%' mx='12px' pb='8px'>
                <Text
                  fontFamily={theme.fontFamily}
                  fontWeight='500'
                  fontSize='12px'
                  lineHeight='18px'
                  letterSpacing='0.02em'
                  color={theme.appTextColor}
                >
                  Reward processing is not instantaneous and the points
                  displayed on the Rewards Portal reflect the current status
                </Text>
              </View>
            </View>
          )}
        <View
          borderRadius={"8px"}
          background={theme.widgetBackgroundColor}
          borderWidth={"1px"}
          borderColor='#ECECEC'
          shadow={2}
          w='90%'
          px='16px'
          py='24px'
          mt='24px'
          zIndex={"76"}
        >
          {transactions.length > 1 ? (
            <FlatList
              aria-label='llllll'
              data={transactions}
              renderItem={renderItem}
              keyExtractor={(_transaction, index) => index}
              onEndReached={loadMoreTransaction}
              ListFooterComponent={renderLoader}
              onEndReachedThreshold={0.2}
              refreshing={false}
              onRefresh={handleRefresh}
            />
          ) : (
            <View>
              <View>
                <Text
                  fontFamily={theme.fontFamily}
                  fontWeight='700'
                  fontSize='18px'
                  lineHeight='18px'
                  letterSpacing='0.02em'
                  color={theme.appTextColor}
                >
                  Rewards History
                </Text>
              </View>

              <View width={"100%"}>
                <DateSelect
                  key={lrCheck}
                  numOfYear={numOfYear}
                  monthOffset={0}
                  notBtn={true}
                  onViewStatementsClick={getTransactions}
                  setCurrMonth={setCurrMonth}
                  setCurrYear={setCurrYear}
                  fromRewards={true}
                  defaultMonth={currMonth}
                  monthIndex={moment().format("MMMM")}
                  selectYear={currYear}
                />
              </View>
              <View mt='32px' zIndex={"-1"} alignSelf={"center"}>
                <Image
                  source={{
                    uri: rewardsEmptyImage,
                  }}
                  width='192px'
                  height='183px'
                  alt='Alternate Text '
                />
              </View>
              <View alignSelf={"center"} mt='16px' zIndex={-1}>
                <Text
                  fontFamily={theme.fontFamily}
                  fontWeight='600'
                  fontSize='14px'
                  lineHeight='18px'
                  letterSpacing='0.02em'
                  color={theme.appTextColor}
                >
                  Start shopping to earn rewards!
                </Text>
              </View>
            </View>
          )}
        </View>
      </View>
    </View>
  )
}

export default Rewards_v2
