import { Text, Input, View } from "native-base"
import React, { useEffect, useRef, useState } from "react"
import { useWindowDimensions } from "react-native-web"
import { DisplayConfig } from "../../../utils/constants"
import { ColorButton, WhiteButton } from "../../core/buttons"
import styles from "../styles"

const ResetPin_v1 = props => {
  const windowDim = useWindowDimensions()
  const confirmPin0 = useRef(null)
  const confirmPin1 = useRef(null)
  const confirmPin2 = useRef(null)
  const confirmPin3 = useRef(null)

  const pin0 = useRef(null)
  const pin1 = useRef(null)
  const pin2 = useRef(null)
  const pin3 = useRef(null)
  const mainContainerRef = useRef(null)

  const [mainContainerHeight, setMainContainerHeight] = useState(0)

  useEffect(() => {
    if (mainContainerRef.current) {
      setMainContainerHeight(mainContainerRef.current.clientHeight)
    }
  }, [mainContainerRef.current])

  return (
    <View width='100%' height={window.height}>
      <View ref={mainContainerRef}>
        <View mt='16px' px='16px'>
          <Text {...styles.mainHeading}>PIN Setup</Text>
        </View>
        <View px='16px' mt='12px'>
          <View>
            <Text {...styles.descriptionText}>Set new PIN for your card</Text>
          </View>
          <View
            mt='16px'
            backgroundColor={props.theme.color3}
            {...styles.pinMainView}
          >
            <View mt='12px'>
              <View px='16px' alignItems='center' mb='12px'>
                <Text {...styles.pinSectionHeading}>Set Pin</Text>
              </View>
              <View {...styles.pinMainView1}>
                <Input
                  {...styles.textInput}
                  bgColor={props.error ? "#FDF6F6" : "#fff"}
                  ref={pin0}
                  value={props.pin[0]}
                  onChangeText={text => {
                    props.setPin([
                      text,
                      props.pin[1],
                      props.pin[2],
                      props.pin[3],
                    ])
                    if (text !== "") {
                      pin1.current.focus()
                    }
                  }}
                />
                <Input
                  {...styles.textInput}
                  bgColor={props.error ? "#FDF6F6" : "#fff"}
                  ref={pin1}
                  value={props.pin[1]}
                  onKeyPress={({ nativeEvent }) => {
                    if (
                      nativeEvent.key === "Backspace" &&
                      props.pin[1] === ""
                    ) {
                      pin0.current.focus()
                      props.setPin(["", "", props.pin[2], props.pin[3]])
                    }
                  }}
                  onChangeText={text => {
                    props.setPin([
                      props.pin[0],
                      text,
                      props.pin[2],
                      props.pin[3],
                    ])
                    if (text !== "") {
                      pin2.current.focus()
                    }
                  }}
                />
                <Input
                  {...styles.textInput}
                  bgColor={props.error ? "#FDF6F6" : "#fff"}
                  ref={pin2}
                  value={props.pin[2]}
                  onKeyPress={({ nativeEvent }) => {
                    if (
                      nativeEvent.key === "Backspace" &&
                      props.pin[2] === ""
                    ) {
                      pin1.current.focus()
                      props.setPin([props.pin[0], "", "", props.pin[3]])
                    }
                  }}
                  onChangeText={text => {
                    props.setPin([
                      props.pin[0],
                      props.pin[1],
                      text,
                      props.pin[3],
                    ])
                    if (text !== "") {
                      pin3.current.focus()
                    }
                  }}
                />
                <Input
                  {...styles.textInput}
                  bgColor={props.error ? "#FDF6F6" : "#fff"}
                  ref={pin3}
                  value={props.pin[3]}
                  onKeyPress={({ nativeEvent }) => {
                    if (
                      nativeEvent.key === "Backspace" &&
                      props.pin[3] === ""
                    ) {
                      pin2.current.focus()
                      props.setPin([props.pin[0], props.pin[1], "", ""])
                    }
                  }}
                  onChangeText={text => {
                    props.setPin([
                      props.pin[0],
                      props.pin[1],
                      props.pin[2],
                      text,
                    ])
                    if (text !== "") {
                      pin3.current.blur()
                    }
                  }}
                />
              </View>
            </View>
            <View mt='40px'>
              <View px='16px' alignItems='center' mb='12px'>
                <Text {...styles.pinSectionHeading}>Confirm Pin</Text>
              </View>
              <View {...styles.pinMainView1}>
                <Input
                  {...styles.textInput}
                  bgColor={props.error ? "#FDF6F6" : "#fff"}
                  ref={confirmPin0}
                  value={props.confirmPin[0]}
                  onChangeText={text => {
                    props.setConfirmPin([
                      text,
                      props.confirmPin[1],
                      props.confirmPin[2],
                      props.confirmPin[3],
                    ])
                    if (text !== "") {
                      confirmPin1.current.focus()
                    }
                  }}
                />
                <Input
                  {...styles.textInput}
                  bgColor={props.error ? "#FDF6F6" : "#fff"}
                  ref={confirmPin1}
                  value={props.confirmPin[1]}
                  onKeyPress={({ nativeEvent }) => {
                    if (
                      nativeEvent.key === "Backspace" &&
                      props.confirmPin[1] === ""
                    ) {
                      confirmPin0.current.focus()
                      props.setConfirmPin([
                        "",
                        "",
                        props.confirmPin[2],
                        props.confirmPin[3],
                      ])
                    }
                  }}
                  onChangeText={text => {
                    props.setConfirmPin([
                      props.confirmPin[0],
                      text,
                      props.confirmPin[2],
                      props.confirmPin[3],
                    ])
                    if (text !== "") {
                      confirmPin2.current.focus()
                    }
                  }}
                />
                <Input
                  {...styles.textInput}
                  bgColor={props.error ? "#FDF6F6" : "#fff"}
                  ref={confirmPin2}
                  value={props.confirmPin[2]}
                  onKeyPress={({ nativeEvent }) => {
                    if (
                      nativeEvent.key === "Backspace" &&
                      props.confirmPin[2] === ""
                    ) {
                      confirmPin1.current.focus()
                      props.setConfirmPin([
                        props.confirmPin[0],
                        "",
                        "",
                        props.confirmPin[3],
                      ])
                    }
                  }}
                  onChangeText={text => {
                    props.setConfirmPin([
                      props.confirmPin[0],
                      props.confirmPin[1],
                      text,
                      props.confirmPin[3],
                    ])
                    if (text !== "") {
                      confirmPin3.current.focus()
                    }
                  }}
                />
                <Input
                  {...styles.textInput}
                  bgColor={props.error ? "#FDF6F6" : "#fff"}
                  ref={confirmPin3}
                  value={props.confirmPin[3]}
                  onKeyPress={({ nativeEvent }) => {
                    if (
                      nativeEvent.key === "Backspace" &&
                      props.confirmPin[3] === ""
                    ) {
                      confirmPin2.current.focus()
                      props.setConfirmPin([
                        props.confirmPin[0],
                        props.confirmPin[1],
                        "",
                        "",
                      ])
                    }
                  }}
                  onChangeText={text => {
                    props.setConfirmPin([
                      props.confirmPin[0],
                      props.confirmPin[1],
                      props.confirmPin[2],
                      text,
                    ])
                    if (text !== "") {
                      confirmPin3.current.blur()
                    }
                  }}
                />
              </View>
            </View>
          </View>
        </View>
      </View>
      <View alignItems='center' justifyContent='center' w='100%'>
        <View
          {...styles.buttonSection}
          _web={{ maxW: DisplayConfig.MAX_WIDTH }}
          position='relative'
          paddingTop={10}
          minH={windowDim.height - mainContainerHeight}
          justifyContent='flex-end'
        >
          <ColorButton
            text='Submit'
            isLoading={props.submitLoading}
            onPress={props.handleSubmit}
          />
          <WhiteButton text='Cancel' onPress={props.Cancel} />
        </View>
      </View>
    </View>
  )
}

export default ResetPin_v1
