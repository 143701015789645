import { SET_PG_AMOUNT, SET_PG_CONFIG, SET_PG_TRANSACTION, SET_PG_VPA } from "../ActionTypes"
import { ProgramTypes } from "../../utils/constants"

const initialState = {
    pgconfig: {},
    vpaTxnRefId: null,
    accountHolderName: null,
    redirectUrl: null,
    txnRefId: null,
    amountPayable: null,
    vpaId:null,
}

const PaymentGatewayReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_PG_CONFIG:
            return {
                ...state,
                pgconfig: action.payload.pgconfig,
            }
        case SET_PG_VPA:
            return {
                ...state,
                vpaTxnRefId: action.payload.vpaTxnRefId,
                accountHolderName: action.payload.accountHolderName,
                vpaId:action.payload.vpaId,
            }
        case SET_PG_TRANSACTION:
            return {
                ...state,
                txnRefId: action.payload.txnRefId,
            }
        case SET_PG_AMOUNT:
            return {
                ...state,
                amountPayable: action.payload.amountPayable,
            }
        default:
            return state
    }
}

export default PaymentGatewayReducer
