import React from "react"
import { Text } from "native-base"
import { View, Animated } from "react-native"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faChevronDown, faChevronUp } from "@fortawesome/free-solid-svg-icons"
import StatementAccordions from "../StatementAccordions/StatementAccordions"
import { useSelector } from "react-redux"
import style from "../styles"

const YearAccordion_v1 = ({
  yearIndex,
  year,
  statements,
  isOpened,
  toggle,
  bodyHeight,
  setBodySectionHeight,
}) => {
  const theme = useSelector(state => state.theme)

  return (
    <>
      <View style={{ backgroundColor: theme.color3, ...style.header }}>
        <Text {...style.yearText} fontFamily={theme.fontFamily}>
          {year}
        </Text>
        <FontAwesomeIcon
          icon={isOpened ? faChevronUp : faChevronDown}
          size='xs'
          style={{ width: "10%", color: "#777777" }}
          onClick={() => toggle()}
        />
      </View>
      <Animated.View
        style={{
          marginBottom: 15,
          overflow: "hidden",
          height: bodyHeight,
        }}
      >
        <View
          onLayout={event => {
            setBodySectionHeight(event.nativeEvent.layout.height)
          }}
        >
          <StatementAccordions yearIndex={yearIndex} statements={statements} />
        </View>
      </Animated.View>
    </>
  )
}

export default YearAccordion_v1
