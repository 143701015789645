import React, { lazy, Suspense } from "react"
import { useSelector } from "react-redux"
import { PwaVersions } from "../../../../utils/enums"
import StatementLoader from "../../../core/SkeletonLoader/statementLoader"
import Statement from "./Main"
const StatementNew = lazy(() => import("./MainNew"));


const StatementData = props => {
  const [config] = useSelector(state => [state.config])
  

  const getVersionedComponent = version => { 
    switch (version) {
      case PwaVersions.V1:
        return < Statement />
      case PwaVersions.V2:
        return <Suspense fallback={<StatementLoader/>}  > < StatementNew/></Suspense> 
      case PwaVersions.V2_1:
        return <Suspense fallback={<StatementLoader/>}  > < StatementNew/></Suspense> 
      default:
        return < Statement />
    }
  }
  return getVersionedComponent(config?.version)
}

export default StatementData
