import { Header } from "../../core"
import { useWindowDimensions } from "react-native-web"
import styles from "./styles.css"
import { useSelector } from "react-redux"
import { useEffect, useState } from "react"
import { Radio } from "../../../core/Radio"
import { ColorButton } from "../../core/buttons"
import { captureEvents, formatAmount } from "../../../utils/functions"
import upi from "../../../assets/images/upi.svg"
import qrScan from "../../../assets/images/qr.svg"
import netBanking from "../../../assets/images/netBanking.svg"
import ArrowRight from "../../svg/arrowRight"
import { useNavigate } from "react-router-dom"
import { EventName } from "../../../utils/enums"
const options = [
    {img: upi,
        text1:"UPI ID",
        text2:"A Payment request will be sent to your UPI app.",
        text3:"UPI",
    },
    // {img: qrScan,
    //     text1:"QR Code",
    //     text2:"Scan the QR using any UPI app on your mobile phone.",
    // },
    {img: netBanking,
        text1:"Net Banking",
        text2:"Login to your internet banking portal and make the payment.",
        text3:"NetBanking"
    }
]
const PaymentOptions = ()=>{
    const windowDim = useWindowDimensions();
    const [isOtherAmountSelected,setIsOtherAmountSelected] = useState(false)
    const[selectedValue,setSelectedValue] = useState(null)
    console.log(isOtherAmountSelected)
    const [theme, paymentGateway, session] =
    useSelector(state => [
      state.theme,
      state.paymentGateway,
      state.session
    ])
    const redirectionUrl = paymentGateway?.pgconfig[0]?.flows[1]?.metadata?.redirectUrl
    const navigate = useNavigate()
    useEffect(() => {
        captureEvents({eventName:EventName.PAY_BILL_MODE_SELECTION_PAGE, metadata:{}})
      }, [])

    return(
        <div
        style={{
          height: windowDim.height,
          backgroundColor: theme.backgroundColor,
        }}
      >
            <Header
          text='Bill Payment'
          onBack={async () => {
            window.history.go(-1)
          }}
            />
            <div className="mainContainer" style={{color: theme.appTextColor}}>
                <div className="optionsWidget" style={{backgroundColor: theme.widgetBackgroundColor}}>
                    <div className="optionsContainer">
                        <div className="optionsHeading">Amount Payable</div>
                        <div className="optionsAmount">{formatAmount(paymentGateway.amountPayable)}</div>
                    </div>
                    <div className="borderLine"></div>
                    <div className="optionsContainer">
                        <div className="optionsHeading2">Pay using</div>
                        {options.map((item, index)=>(
                            <>
                        <div onClick={() => { 
                            captureEvents({eventName:EventName.PAY_BILL_MODE_SELECTION_CTA, metadata:{paymentMethod:item?.text3 }})
                            item.text1 == "UPI ID" ?   navigate('/PaymentGateway/options/verifyUpi')
                            : item.text1 == "Net Banking" ? window.open(redirectionUrl)
                            : console.log()
                    }} className="optionsContent optionsContentpg">
                            <img src={item.img} width={"38px"} height={"38px"}/>
                            <div style={{width:"70%", marginLeft:"-5px"}} >
                                <div className="optionsContentHeading">{item.text1}</div>
                                <div className="optionsContentText">{item.text2}</div>
                            </div>
                            <ArrowRight size={"10px"} color={theme.appTextColor}/>
                        </div>
                        {index != (options?.length-1)  &&
                            <div className="borderLine2"></div>
                        }
                        </>
                        ))}
                    </div>
                    {/* <div className="subHeading">Confirm the amount you wish to pay</div> */}
                   
                </div>
            </div>
        </div>
    )
}

export default PaymentOptions;