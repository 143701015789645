import React from "react"
import { PwaVersions } from "../../../../utils/enums"
import EMIPlans_v1 from "./EMIPlans_v1"
import EMIPlans_v2 from "./EMIPlans_v2"
import { useSelector } from "react-redux"

const EMIPlans = () => {
  const [config] = useSelector(state => [state.config])
  return config?.version === PwaVersions.V1 ? (
    <EMIPlans_v1 />
  ) : config?.version === PwaVersions.V2 ? (
    <EMIPlans_v1 />
  ) : config?.version === PwaVersions.V2_1 ? (
    <EMIPlans_v2 />
  ) : (
    <></>
  )
}

export default EMIPlans
