import React from "react"
import { useSelector } from "react-redux"
import { PwaVersions } from "../../../../utils/enums"
import AboutProgram_v2 from "./AboutProgram_v2/AboutProgram_v2"

const AboutProgram = () => {
  const config = useSelector(state => state.config)
  const versionedAboutProgram = programType => {
    switch (programType) {
      case PwaVersions.V1:
        return <></>
      case PwaVersions.V2:
        return <AboutProgram_v2 />
      case PwaVersions.V2_1:
        return <AboutProgram_v2 />
      default:
        return <></>
    }
  }
  return versionedAboutProgram(config?.version)
}

export default AboutProgram
