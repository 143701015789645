import Service from "./Service"
import { getApiConfig } from "../utils/functions"

const AccountService = {
  getSummary: (apiToken, accountId, version) =>
    Service.get(`/accounts/${accountId || "temp_id"}/summary`, {
      params: {
        version: version,
      },
      ...getApiConfig(apiToken),
    }),

  getOffersList: (accountId, programId) =>
    Service.get(`/accounts/get-offers-list`, {
      params: {
        programId: programId,
        accountId: accountId,
      },
    }),

  getTransactions: (accountId, data, apiToken) =>
    Service.post(
      `/accounts/${accountId}/transactions`,
      data,
      getApiConfig(apiToken),
    ),

  getRewards: (accountId, data) =>
    Service.post(`/accounts/${accountId}/rewards`, data),

  getSsoRewards: data =>
    Service.post(`/accounts/grimlock/aggregate/statement`, data),

  getAggregateSummary: data =>
    Service.post(`/accounts/grimlock/aggregate/summary`, data),

  getCashbacks: (accountId, data) =>
    Service.post(`/accounts/${accountId}/cashbacks`, data),

  getUnbilledTransactions: (accountId, data) =>
    Service.post(`/accounts/${accountId}/unbilled`, data),

  getStatements: (accountId, data) =>
    Service.post(`/accounts/${accountId}/statements`, data),

  downloadStatement: (accountId, statementId, fileName) =>
    Service.get(`/accounts/${accountId}/download-statement/${statementId}`, {
      responseType: "blob",
    }),

  getEMIConfig: (apiToken, accountId, amount, txnRefId) =>
    Service.get(`/accounts/${accountId}/emi`, {
      params: { amount: amount, txnRefId: txnRefId },
      ...getApiConfig(apiToken),
    }),

  createEMI: (apiToken, accountId, data) =>
    Service.post(
      `/accounts/${accountId}/emi/create`,
      data,
      getApiConfig(apiToken),
    ),

  listEmi: (apiToken, accountId) =>
    Service.get(`/accounts/${accountId}/list-emi`, getApiConfig(apiToken)),

  getForeclosureDetails: (apiToken, accountId, data) =>
    Service.post(
      `/accounts/${accountId}/get-foreclosure-details`,
      data,
      getApiConfig(apiToken),
    ),

  forecloseEmi: (apiToken, accountId, data) =>
    Service.post(
      `/accounts/${accountId}/foreclose-emi`,
      data,
      getApiConfig(apiToken),
    ),

  getRewardPoints: data => Service.post(`/accounts/aggregates`, data),
  getHfRewardPoints: data => Service.post(`/accounts/rewards/summary`, data),

  getSpendGroups: (accountId, data) =>
    Service.post(`/accounts/${accountId}/spendGroups`, data),

  getSpendCategory: (accountId, spendGroupId, data) =>
    Service.post(
      `/accounts/${accountId}/spendGroups/${spendGroupId}/transactions`,
      data,
    ),

  getAllNudges: accountId =>
    Service.get(`/accounts/nudges/account/${accountId}`),

  getPGConfig: () => Service.get(`/accounts/grimlock/pg/config`),

  initiatePGTransaction: data =>
    Service.post(`/accounts/grimlock/pg/transaction/initiate`, data),

  getPGTransactionStatus: data =>
    Service.post(`/accounts/grimlock/pg/transaction/status`, data),

  verifyVpa: data => Service.post(`/accounts/grimlock/pg/vpa/verify`, data),
  disableNudge: (accountId, data) =>
    Service.post(`/accounts/nudges/account/${accountId}/channel/disable`, data),
}

export default AccountService
