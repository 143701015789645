import React from "react"
import { useSelector } from "react-redux"
import { Icon } from "native-base"

const CrossFilled = ({fillColor, crossColor} ) => {
  const theme = useSelector(state => state.theme)
  console.log(fillColor)
  console.log(crossColor)
  return (
    <Icon >
        <svg width="28" height="28" viewBox="8 7 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle style={{fill : fillColor}}  className="nudge-cross-filled" cx="24.0003" cy="24.0001" r="16.6957" fill="#EF5350"/>
        <path  style={{fill:crossColor}} className="nudge-cross-icon"  d="M30.8925 18.3023C30.5025 17.9123 29.8725 17.9123 29.4825 18.3023L24.5925 23.1823L19.7025 18.2923C19.3125 17.9023 18.6825 17.9023 18.2925 18.2923C17.9025 18.6823 17.9025 19.3123 18.2925 19.7023L23.1825 24.5923L18.2925 29.4823C17.9025 29.8723 17.9025 30.5023 18.2925 30.8923C18.6825 31.2823 19.3125 31.2823 19.7025 30.8923L24.5925 26.0023L29.4825 30.8923C29.8725 31.2823 30.5025 31.2823 30.8925 30.8923C31.2825 30.5023 31.2825 29.8723 30.8925 29.4823L26.0025 24.5923L30.8925 19.7023C31.2725 19.3223 31.2725 18.6823 30.8925 18.3023Z" fill="white"/>
        </svg> 
    </Icon>
  )
}

export default CrossFilled
