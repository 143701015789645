import { Header } from "../../core"
import { useWindowDimensions } from "react-native-web"
import styles from "./styles.css"
import { useDispatch, useSelector } from "react-redux"
import { useEffect, useState } from "react"
import { Radio } from "../../../core/Radio"
import { ColorButton } from "../../core/buttons"
import { captureEvents, formatAmount } from "../../../utils/functions"
import upi from "../../../assets/images/upi.svg"
import qrScan from "../../../assets/images/qr.svg"
import netBanking from "../../../assets/images/netBanking.svg"
import ArrowRight from "../../svg/arrowRight"
import successTick from "../../../assets/images/successTick.svg"
import { useNavigate } from "react-router-dom"
import AccountService from "../../../services/AccountService"
import {
  setPgTransaction,
  setPgVPA,
} from "../../../store/actions/PaymentGateway"
import { useToast } from "native-base"
import { showToast1 } from "../../core/toast"
import { EventName } from "../../../utils/enums"

const options = [
  "@oksbi",
  "@ybl",
  "@paytm",
  "@upi",
  "@aubank",
  "@axisbank",
  "@jio",
  "@jio",
]

const PaymentUpiVerify = () => {
  const windowDim = useWindowDimensions()
  const [isOtherAmountSelected, setIsOtherAmountSelected] = useState(false)
  const [selectedValue, setSelectedValue] = useState(null)
  const [inputId, setInputId] = useState("")
  const [accHolderName, setAccHolderName] = useState("")
  const [vpaTxnId, setVpaTxnId] = useState("")
  const [isVerified, setIsVerified] = useState(false)
  const showOptions = !inputId?.includes("@") && inputId?.length > 0
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const nativeToast = useToast()
  const [submitLoading, setSubmitLoading] = useState(false)
  const toast = (message, hasTick = false) => {
    showToast1({ nativeToast, theme, message, hasTick })
  }
  const [theme, user, paymentGateway] =
    useSelector(state => [
      state.theme,
      state.user,
      state.paymentGateway
    ])

    useEffect(() => {
      captureEvents({eventName:EventName.PAY_BILL_UPI_ID_PAGE, metadata:{amount:paymentGateway?.amountPayable}})
    }, [])
  
  const handleVerify = async () => {
    let accountId = user?.account?.id
    let data = {
      accountId,
      vpaId: inputId,
      pgId: paymentGateway?.pgconfig[0]?.id,
      pgFlowId: paymentGateway?.pgconfig[0]?.flows[0]?.id,
    }
    captureEvents({eventName:EventName.PAY_BILL_UPI_VERIFY_BTN, metadata:{amount:paymentGateway?.amountPayable, upiId:inputId}})

    try {
      const response = await AccountService.verifyVpa(data)
      if (response.status === 200) {
        setIsVerified(true)
        setAccHolderName(response.data?.data.accountHolderName)
        setVpaTxnId(response.data?.data?.vpaTxnRefId)
        dispatch(
          setPgVPA({
            vpaTxnRefId: response?.data?.data?.vpaTxnRefId,
            accountHolderName: response?.data?.data?.accountHolderName,
            vpaId: inputId,
          }),
        )
      } else {
        toast("Incorrect UPI ID")
      }
    } catch (err) {
      toast("Incorrect UPI ID")
    }
  }

  const handleProceed = async () => {
    let data = {
      accountId: user.account.id,
      vpaTxnRefId: vpaTxnId,
      amount: paymentGateway.amountPayable,
      pgId: paymentGateway?.pgconfig[0]?.id,
      pgFlowId: paymentGateway?.pgconfig[0]?.flows[0]?.id,
    }

    captureEvents({eventName:EventName.PAY_BILL_UPI_ID_CTA, metadata:{amount:paymentGateway?.amountPayable, upiId:inputId}})

    try {
      setSubmitLoading(true)
      const response = await AccountService.initiatePGTransaction(data)
      if (response.status == 200) {
        dispatch(
          setPgTransaction({
            txnRefId: response.data?.data?.txnRefId,
            redirectUrl: response.data?.data?.redirectUrl,
          }),
        )
        setSubmitLoading(false)
        navigate("/PaymentGateway/paymentRequest")
      } else {
        setSubmitLoading(false)
        navigate("/PaymentGateway/Failed")
      }
    } catch (err) {
      setSubmitLoading(false)
      navigate("/PaymentGateway/Failed")
    }
  }

  return (
    <div
      style={{
        height: windowDim.height,
        backgroundColor: theme.backgroundColor,
      }}
    >
      <Header
        text='Bill Payment'
        onBack={async () => {
          window.history.go(-1)
        }}
      />
      <div className='mainContainer' style={{ color: theme.appTextColor }}>
        <div
          className='optionsWidget optionsWidgetVerifyUpi'
          style={{ backgroundColor: theme.widgetBackgroundColor }}
        >
          <div className='optionsContainer'>
            <div className='optionsHeading'>Amount Payable</div>
            <div className='optionsAmount'>
              {formatAmount(paymentGateway.amountPayable)}
            </div>
          </div>
          <div className='borderLine'></div>
          <div className='optionsContainer'>
            <div className='optionsUpiVerifyHeading2'>UPI ID</div>

            <div className='inputContainerVerifyUpi '>
              <input
                value={inputId}
                onChange={e => setInputId(e.target.value)}
                className='otherAmountVerifyUpi'
              />

              {!isVerified ? (
                <span
                  onClick={() => handleVerify()}
                  className='currencyInputVerifyUpi'
                >
                  Verify
                </span>
              ) : (
                <span className='currencyInputVerifyUpi verifyUpiVerified'>
                  <img src={successTick}></img>
                  Verified
                </span>
              )}
            </div>
          </div>

          <div className='verifyUpiTypesContainer'>
            {options &&
              showOptions &&
              options.map(type => {
                return (
                  <div
                    onClick={() => setInputId(inputId + type)}
                    className='verifyUpiTypes'
                  >
                    {type}
                  </div>
                )
              })}
          </div>

          {isVerified && (
            <>
              <div className='verifyOtpAccNameHeading'>Account Holder Name</div>

              <div className='verifyOtpAccName'>{accHolderName}</div>
            </>
          )}
        </div>
      </div>
      <div className='paymentGatewayBtnContainer'>
        <ColorButton
          text='Proceed'
          isDisable={!isVerified}
          onPress={() => handleProceed()}
          isLoading={submitLoading}
        />
      </div>
    </div>
  )
}

export default PaymentUpiVerify
