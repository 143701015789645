import React from "react"
import styles from "../../styles"
import { View, Text, Image, ChevronLeftIcon } from "native-base"
import { Link } from "react-router-dom"
import cardControl from "../../../../assets/images/cardControl.png"
import resetPin from "../../../../assets/images/resetPin.png"
import manageBalance from "../../../../assets/images/manageBalance.png"
import blockReplace from "../../../../assets/images/blockReplace.png"
import EMI from "../../../../assets/images/EMI.png"
import { useWindowDimensions } from "react-native"
import CustomLoader from "../../../core/loader"
import { ProgramTypes } from "../../../../utils/constants"

const getOpacity = (props)=>{
  return (!props.isAccountActive || !props.card || props.card.isHotlisted
  ? "0.6"
  : "1"
  )
}
const naviagteTo= (props) =>{
  if(!props.isAccountActive || !props.card)
    return ""
  else if(!props.card.isHotlisted)
    return "/ManageCard/BlockCard"
  else
    return "/ManageCard/NewCardRequest"
}
const Home_v1 = props => {
  const windowDimensions = useWindowDimensions()
  const isResetPinDisabled =
    !props.isAccountActive ||
    !props.card ||
    props.card.isHotlisted ||
    props.card.isLocked ||
    (props.user.programType === ProgramTypes.PREPAID
      ? props.card?.cardType === "Virtual"
        ? (props.card?.isVirtualCardActivated)
        : !(props.card?.isPhysicalCardActivated)
      : !props.card?.isPhysicalCardActivated)

  return (
    <View {...styles.mainView} height={windowDimensions.height}>
      <View {...styles.header}>
        <Link
          style={{
            textDecoration: "None",
          }}
          to='/'
        >
          <ChevronLeftIcon size='24px' />
        </Link>
        <Text {...styles.manageCard} fontFamily={props.theme.fontFamily}>
          Manage Card
        </Text>
      </View>
      <Link
        style={{
          textDecoration: "None",
        }}
        to={
          !props.isAccountActive || !props.card || props.card.isHotlisted
            ? "#"
            : "/ManageCard/CardSettings"
        }
      >
        <View
          {...styles.cardControls}
          {...styles.commonStyles}
          opacity={
            getOpacity(props)
          }
        >
          <View
            p='10px'
            backgroundColor={props.theme.color1}
            borderRadius='32px'
          >
            <Image
              source={{ uri: cardControl }}
              alt='Card Control Icon'
              width='20px'
              height='20px'
            />
          </View>
          <Text {...styles.fontText} fontFamily={props.theme.fontFamily}>
            Card Controls & Limits
          </Text>
        </View>
      </Link>
      {props.user.programType !== props.ProgramTypes.PREPAID ? (
        props.isAccountActive &&
        props.user.enableManageEMI && (
          <Link
            style={{
              textDecoration: "None",
            }}
            to={"/ManageCard/ListEMI"}
          >
            <View {...styles.cardControls} {...styles.commonStyles}>
              <View
                p='10px'
                backgroundColor={props.theme.color1}
                borderRadius='32px'
              >
                <Image
                  source={{ uri: EMI }}
                  alt='Card Control Icon'
                  width='20px'
                  height='20px'
                />
              </View>
              <Text {...styles.fontText} fontFamily={props.theme.fontFamily}>
                My Active EMIs
              </Text>
            </View>
          </Link>
        )
      ) : (
        <View
          onClick={() => {
            window.location.href = props.session.repaymentUrl
          }}
        >
          <View {...styles.cardControls} {...styles.commonStyles}>
            <View
              p='10px'
              backgroundColor={props.theme.color1}
              borderRadius='32px'
            >
              <Image
                source={{ uri: manageBalance }}
                alt='Card Control Icon'
                width='20px'
                height='20px'
              />
            </View>
            <Text {...styles.fontText} fontFamily={props.theme.fontFamily}>
              Manage Balance
            </Text>
          </View>
        </View>
      )}
      <View
        {...styles.resetPin}
        {...styles.commonStyles}
        opacity={isResetPinDisabled ? "0.6" : "1"}
        onClick={() =>
          isResetPinDisabled ? props.alertToast() : props.handleResetPinClick()
        }
      >
        <View
          px={props.isResetPinLoading ? "0px" : "11.9px"}
          py={props.isResetPinLoading ? "0px" : "10.475px"}
          backgroundColor={
            props.isResetPinLoading ? "#ffffff" : props.theme.color1
          }
          borderRadius='32px'
        >
          {props.isResetPinLoading ? (
            <View {...styles.loader}>
              <CustomLoader color={props.theme.color1} width={40} height={40} />
            </View>
          ) : (
            <Image
              source={{ uri: resetPin }}
              alt='Reset Pin Icon'
              width='16.19px'
              height='19.05px'
            />
          )}
        </View>
        <Text {...styles.fontText} fontFamily={props.theme.fontFamily}>
          Reset Pin
        </Text>
      </View>
      <Link
        style={{
          textDecoration: "None",
          opacity: !props.isAccountActive || !props.card ? "0.6" : "1",
        }}
        to={
          naviagteTo(props)
        }
      >
        <View {...styles.cardControls} {...styles.commonStyles}>
          <View
            p='10px'
            backgroundColor={props.theme.color1}
            borderRadius='32px'
          >
            <Image
              source={{ uri: blockReplace }}
              alt='Block/Replace Card Icon'
              width='20px'
              height='20px'
            />
          </View>
          <Text {...styles.fontText} fontFamily={props.theme.fontFamily}>
            Block/Replace Card
          </Text>
        </View>
      </Link>
    </View>
  )
}

export default Home_v1
