import React from "react"
import { useSelector } from "react-redux"
import { Icon } from "native-base"

const CustomerCare_v2 = () => {
    const theme = useSelector(state => state.theme)
    return (
        <Icon viewBox='0 0 32 32' size='32px'>

            <svg version="1.0" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 300.000000 319.000000" preserveAspectRatio="xMidYMid meet" xmlnsXlink="http://www.w3.org/1999/xlink" zoomAndPan="magnify" contentScriptType="text/ecmascript" contentStyleType="text/css">

                <g  className="manageCard-customerCard_v2-icon-svg-path" transform="translate(0.000000,319.000000) scale(0.100000,-0.100000)" fill={theme.appTextColor} stroke={theme.appTextColor} strokeWidth={"50px"} >
                    <path d="M1175 3080 c-403 -45 -710 -232 -897 -545 -150 -250 -212 -635 -152 -935 34 -168 90 -302 197 -470 81 -127 133 -240 164 -358 25 -91 27 -114 31 -379 l4 -283 60 0 60 0 -4 288 c-5 269 -6 293 -31 384 -14 54 -41 136 -60 183 -33 78 -53 116 -164 296 -145 234 -192 522 -137 839 74 425 345 724 748 825 50 12 112 26 139 29 l47 7 0 -458 -1 -458 -60 -35 c-109 -62 -202 -185 -228 -301 -13 -56 -13 -162 0 -218 17 -75 59 -150 120 -215 155 -166 396 -200 591 -83 l56 34 151 -105 151 -105 -6 -46 c-10 -74 31 -152 98 -187 43 -22 129 -18 169 9 42 28 77 79 85 124 13 71 -36 162 -103 189 -35 15 -106 17 -138 5 -16 -7 -48 11 -150 81 -71 49 -141 97 -154 107 l-23 18 24 39 c60 96 84 236 58 345 -30 128 -129 259 -243 319 l-47 25 0 458 0 458 48 -6 c272 -38 450 -120 607 -279 156 -159 243 -343 290 -620 l16 -99 145 -248 c97 -165 144 -256 142 -271 -2 -18 -31 -37 -143 -95 l-140 -71 -6 -224 c-6 -261 -17 -301 -99 -391 -97 -107 -188 -134 -392 -118 -72 6 -140 11 -150 11 -17 0 -18 -15 -18 -220 l0 -220 60 0 60 0 0 154 0 154 133 -1 c117 -1 139 2 192 22 131 52 220 133 280 255 43 89 55 162 55 351 l0 150 114 57 c130 65 165 100 174 173 6 59 1 69 -156 340 -132 226 -135 232 -153 330 -63 343 -200 591 -419 757 -81 62 -261 151 -360 178 -174 48 -456 70 -635 50z m235 -557 l0 -448 -55 0 -55 0 0 448 0 447 55 0 55 0 0 -447z m95 -602 c73 -34 137 -97 173 -171 24 -48 27 -67 27 -150 0 -84 -3 -102 -28 -152 -38 -77 -96 -134 -175 -171 -58 -27 -78 -32 -147 -32 -69 0 -89 5 -147 32 -81 38 -141 97 -177 172 -22 48 -26 69 -26 151 0 84 3 102 28 152 88 179 293 253 472 169z m667 -938 c22 -20 23 -51 1 -75 -32 -36 -103 -12 -103 35 0 49 64 75 102 40z" />
                <path d="M1284 1826 c-139 -44 -204 -202 -138 -334 29 -59 70 -94 133 -117 152 -53 311 62 311 225 0 106 -66 196 -168 226 -55 17 -84 17 -138 0z m119 -120 c43 -18 67 -56 67 -106 0 -92 -89 -147 -170 -105 -42 22 -60 53 -60 105 0 53 20 87 64 106 40 17 59 17 99 0z" />
                </g>
            </svg>
        </Icon>
    )
}
export default CustomerCare_v2