import { Image, ScrollView, Text, View } from "native-base"
import React, { useEffect } from "react"
import { useWindowDimensions } from "react-native"
import { useSelector } from "react-redux"
import ErrorImage from "../../assets/images/ServerError.svg"
import { EventName, PwaVersions } from "../../utils/enums"
import {
  captureEvents,
  goToRedirectUrl,
  isIosDevice,
  postMessageToIos,
} from "../../utils/functions"
import { ColorButton, WhiteButton } from "../core/buttons"
import styles from "./styles"

import { DisplayConfig } from "../../utils/constants"
import { Header } from "../core"
import { useNavigate } from "react-router-dom"

const ErrorScreen = ({
  showButtons = true,
  updateQrPageErrorScreen,
  message = "Something went wrong, our team is working on it.",
  retry = false,
}) => {
  const windowDimensions = useWindowDimensions()

  const [session, theme, config] = useSelector(state => [
    state.session,
    state.theme,
    state.config,
  ])
  const navigate = useNavigate();

  useEffect(() =>{
    captureEvents({eventName:EventName.ERROR_SCREEN, metadata:{}})
  },[])

  useEffect(() => {
    
    if(  config?.version === PwaVersions.V2_1){
      navigate("/errorOccured");
    }
  }, [])
  
  const backHome = () => {
    if (isIosDevice()) {
      postMessageToIos(JSON.stringify({ cmd: "SESSION_EXPIRED" }))
    } else {
      window.location.href = session.redirectUrl
    }
  }

  const handleRetryClick = () => {
    if (updateQrPageErrorScreen) {
      updateQrPageErrorScreen()
    }
  }
  const getVersionedDisplay = () => {
    return (
      config?.version === PwaVersions.V2 || config?.version === PwaVersions.V2_1
    )
  }
  return (
    <View
      {...styles.mainView}
      minH={getVersionedDisplay() ? windowDimensions.height : null}
      bgColor={getVersionedDisplay() ? theme.backgroundColor : null}
    >
      <ScrollView width='100%'>
        <Header
          text='&nbsp;'
          onBack={async () => {
            goToRedirectUrl()
          }}
        />

        <View
          _web={{ maxW: DisplayConfig.MAX_WIDTH }}
          {...styles.innerView}
          h={windowDimensions.height}
        >
          <View {...styles.section1} mt='30%'>
            <View
              {...styles.imageContainer}
              bgColor={
                getVersionedDisplay() ? theme.widgetBackgroundColor : null
              }
            >
              {config?.version === PwaVersions.V2_1 ? (
                <svg
                  width='96'
                  height='96'
                  viewBox='0 0 96 96'
                  fill='none'
                  xmlns='http://www.w3.org/2000/svg'
                >
                  <path
                    d='M94.0796 21.5998H1.92051C0.858994 21.5998 0.000803563 20.7408 0.000803563 19.6801L0 1.91971C0 0.85819 0.859013 0 1.91971 0H94.0787C95.1403 0 95.9984 0.859013 95.9984 1.91971V19.6792C95.9992 20.7408 95.141 21.5998 94.0796 21.5998V21.5998ZM3.8398 17.7595H92.1582V3.83941H3.8398V17.7595Z'
                    fill={theme.color1}
                  />
                  <path
                    d='M94.0797 95.9971H1.92068C0.859167 95.9971 0.000976562 95.1381 0.000976562 94.0774V19.6775C0.000976562 18.616 0.85999 17.7578 1.92068 17.7578H94.0797C95.1412 17.7578 95.9994 18.6168 95.9994 19.6775L96.0002 94.0774C96.0002 95.1389 95.1412 95.9971 94.0797 95.9971ZM3.84203 92.1577H92.1604V21.5985H3.84203V92.1577Z'
                    fill={theme.color1}
                  />
                  <path
                    d='M83.8985 12.7203H27.739C26.6775 12.7203 25.8193 11.8613 25.8193 10.8006C25.8193 9.73905 26.6783 8.88086 27.739 8.88086H83.8985C84.96 8.88086 85.8182 9.73987 85.8182 10.8006C85.8182 11.8613 84.96 12.7203 83.8985 12.7203Z'
                    fill={theme.color1}
                  />
                  <path
                    d='M12.0887 12.7089C11.5897 12.7089 11.089 12.5161 10.7443 12.1521C10.5531 11.9793 10.4181 11.768 10.3224 11.5374C10.2268 11.3067 10.1689 11.0576 10.1689 10.8077C10.1689 10.2902 10.3811 9.7896 10.7443 9.44487C11.4546 8.73452 12.7411 8.73452 13.4515 9.44487C13.8171 9.7896 14.0268 10.2902 14.0268 10.8077C14.0268 11.0568 13.969 11.3067 13.8733 11.5374C13.7777 11.768 13.6427 11.9801 13.4515 12.1521C13.0859 12.5161 12.6061 12.7089 12.0886 12.7089H12.0887Z'
                    fill={theme.color1}
                  />
                  <path
                    d='M19.7704 12.7087C19.2714 12.7087 18.7707 12.5159 18.426 12.1518C18.2348 11.9791 18.0998 11.7677 18.0041 11.5371C17.9085 11.3065 17.8691 11.0574 17.8691 10.8075C17.8691 10.5391 17.9085 10.29 18.0041 10.0594C18.0998 9.82874 18.2348 9.61661 18.426 9.44463C18.5032 9.34901 18.5988 9.27187 18.7145 9.19554C18.8101 9.13768 18.9242 9.07902 19.0407 9.02277C19.1548 8.9834 19.2898 8.94563 19.4047 8.90625C19.6563 8.86849 19.9053 8.86849 20.1544 8.90625C20.2686 8.94563 20.4035 8.9834 20.5185 9.02277C20.635 9.07902 20.7491 9.13688 20.8447 9.19554C20.9419 9.27268 21.0568 9.34902 21.1332 9.44463C21.4988 9.78937 21.7085 10.29 21.7085 10.8075C21.7085 11.3065 21.4988 11.7862 21.1332 12.1518C20.7684 12.5159 20.2878 12.7087 19.7703 12.7087H19.7704Z'
                    fill={theme.color1}
                  />
                  <path
                    d='M71.776 81.4848H24.2277C22.9099 81.4848 21.7302 80.8002 21.0779 79.6567C20.4238 78.5108 20.435 77.1495 21.1044 76.0158L44.8785 35.7085C45.5367 34.5908 46.705 33.9238 48.0021 33.9238C49.2998 33.9238 50.4673 34.5916 51.1256 35.7085L74.8998 76.0178C75.5691 77.1501 75.5788 78.5113 74.9263 79.6557C74.2714 80.8007 73.0933 81.4854 71.7756 81.4854L71.776 81.4848ZM71.592 77.9692H71.6129H71.592ZM24.6012 77.6445H71.4007L48.001 37.9709L24.6012 77.6445Z'
                    fill={theme.color1}
                  />
                  <path
                    d='M46.1152 69.0395C46.1152 67.8896 46.914 67.0579 47.998 67.0579C49.1479 67.0579 49.8839 67.8904 49.8839 69.0395C49.8839 70.1581 49.1479 71.0187 47.998 71.0187C46.8794 71.0187 46.1152 70.1581 46.1152 69.0395ZM46.9132 64.4696L46.4656 49.1377H49.5319L49.0843 64.4696H46.9132Z'
                    fill={theme.color1}
                  />
                </svg>
              ) : (
                <Image {...styles.image} source={{ uri: ErrorImage }} />
              )}
            </View>
            <View {...styles.text1Container1}>
              <View {...styles.text1Container2}>
                <Text
                  {...styles.text1}
                  fontFamily={theme.fontFamily}
                  color={getVersionedDisplay() ? theme.appTextColor : null}
                >
                  Oops!
                </Text>
              </View>
              <View {...styles.text2Container}>
                <Text
                  {...styles.text2}
                  fontFamily={theme.fontFamily}
                  color={getVersionedDisplay() ? theme.appTextColor : null}
                >
                  {message}
                </Text>
              </View>
            </View>
          </View>
          <View
            _web={{ maxW: DisplayConfig.MAX_WIDTH }}
            {...styles.button}
            mt='20%'
            position={"fixed"}
            bottom={"24px"}
          >
            {showButtons &&
              (retry ? (
                <>
                  <ColorButton text='Retry' onPress={handleRetryClick} />
                  {config?.version !== PwaVersions.V2 &&
                  config?.version !== PwaVersions.V2_1 ? (
                    <WhiteButton text='Go Back' onPress={backHome} />
                  ) : null}
                </>
              ) : config?.version !== PwaVersions.V2 &&
                config?.version !== PwaVersions.V2_1 ? (
                <ColorButton text='Go Back' onPress={backHome} />
              ) : null)}
          </View>
        </View>
      </ScrollView>
    </View>
  )
}

export default ErrorScreen
