import { ChevronRightIcon } from "native-base"
import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import EMI from "../../../../../assets/images/EmiLogo.svg"
import moment from "moment"
import { setSelectedEmi } from "../../../../../store/actions/Session"
import { useNavigate } from "react-router-dom"
import { captureEvents, formatAmount, truncateString } from "../../../../../utils/functions"
import EmptyTransactionEmi from "../../../../svg/emptyTransactionEmi"
import { EventName, PwaVersions } from "../../../../../utils/enums"

const TransactionEmi = props => {
  const [theme, config] = useSelector(state => [state.theme, state.config])
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const getVersionedDisplay = () => {
    return (
      config?.version === PwaVersions.V2 || config?.version === PwaVersions.V2_1
    )
  }
  const [paidCounts, setPaidCounts] = useState([])
  useEffect(() => {
    const paid = props.data.map(item => {
      let temp = 0
      for (let schedule of item.schedules) {
        if (schedule.interest === 0 && schedule.principal === 0) {
          temp = temp + 1
        }
      }
      return temp
    })
    setPaidCounts(paid)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div className='emi-transactionEmi'>
      {props.data.length > 0 ? (
        props.data.map((item, index) => {
          return (
            <div
              className='emi-transactionEmi-widget'
              style={{
                backgroundColor: getVersionedDisplay()
                  ? `${theme.appTextColor}20`
                  : null,
              }}
            >
              <div
                className='emi-transactionEmi-widget-header'
                style={{
                  backgroundColor: getVersionedDisplay()
                    ? `${theme.appTextColor}10`
                    : null,
                }}
              >
                <div className='emi-transactionEmi-widget-header-img-container'>
                  {item.merchantLogo ? (
                    <img
                      className='emi-transactionEmi-widget-header-img'
                      src={`data:image/jpg;base64,${item.merchantLogo}`}
                    />
                  ) : (
                    <img
                      className='emi-transactionEmi-widget-header-img'
                      src={EMI}
                      source={{ uri: EMI }}
                      alt='Card Control Icon'
                    />
                  )}
                </div>
                <div className='emi-transactionEmi-widget-header-content'>
                  <div
                    className='emi-transactionEmi-widget-header-content-description'
                    style={{
                      color: getVersionedDisplay() ? theme.appTextColor : null,
                    }}
                  >
                    {item.txnDescription
                      ? truncateString(item.txnDescription, 40)
                      : ""}
                  </div>
                  <div
                    className='emi-transactionEmi-widget-header-content-date'
                    style={{
                      color: getVersionedDisplay() ? theme.appTextColor : null,
                    }}
                  >
                    {`${moment(item.createdOn).format("MMM 'YY")} EMIs`}
                  </div>
                </div>
                <div className='emi-transactionEmi-widget-header-content-amount-container'>
                  <div
                    className='emi-transactionEmi-widget-header-content-amount'
                    style={{
                      color: getVersionedDisplay() ? theme.appTextColor : null,
                    }}
                  >
                    {formatAmount(item.principal)}
                  </div>
                  <div
                    className='emi-transactionEmi-widget-header-content-paidCount'
                    style={{
                      color: getVersionedDisplay() ? theme.appTextColor : null,
                      borderColor: getVersionedDisplay()
                        ? theme.appTextColor
                        : "#000",
                    }}
                  >
                    {`${paidCounts[index]}/${item.tenureInMonths} Paid`}
                  </div>
                </div>
              </div>
              <div>
                {item.schedules.map((schedule, index) => (
                  <div
                    className='emi-transactionEmi-widget-container'
                    style={{
                      backgroundColor: getVersionedDisplay()
                        ? theme.widgetBackgroundColor
                        : null,
                    }}
                  >
                    <div
                      className='emi-transactionEmi-widget-emiRow'
                      style={{
                        paddingBottom:
                          item.schedules.length !== index + 1 ? "16px" : "12px",
                        borderBottomWidth:
                          item.schedules.length !== index + 1 ? "1px" : "0px",
                      }}
                    >
                      <div>
                        <div
                          className='emi-transactionEmi-widget-emiRow-text'
                          style={{
                            color: getVersionedDisplay()
                              ? theme.appTextColor
                              : null,
                          }}
                        >
                          {`EMI ${index + 1}/${item.schedules.length}`}
                        </div>
                        <div className='emi-transactionEmi-widget-emiRow-date'>
                          {`Due in ${moment(schedule.paymentDueDate).format(
                            "Do MMM 'YY",
                          )}`}
                        </div>
                      </div>
                      <div
                        className='emi-transactionEmi-widget-emiRow-text'
                        style={{
                          color: getVersionedDisplay()
                            ? theme.appTextColor
                            : null,
                        }}
                      >
                        {formatAmount(item.monthlyEmi)}
                      </div>
                    </div>
                  </div>
                ))}
              </div>
              <div className='emi-transactionEmi-widget-footer'>
                <div
                  className='emi-transactionEmi-widget-footer-container'
                  onClick={() => {
                    captureEvents({eventName:EventName.EMI_DETAILS_PRECLOSE_CTA, metadata:{selectedEmi:item }})
                    dispatch(setSelectedEmi({ currentSelectedEmi: item }))
                    navigate("/ManageCard/ForecloseEmi")
                  }}
                >
                  <div
                    className='emi-transactionEmi-widget-footer-text'
                    style={{ color: theme.color1 }}
                  >
                    Preclose
                  </div>
                  <ChevronRightIcon
                    mb='-2px'
                    ml='4px'
                    size='20px'
                    color={theme.color1}
                  />
                </div>
              </div>
            </div>
          )
        })
      ) : (
        <div className='emi-transactionEmi-emptyTransactionEmi'>
          <div>
            <EmptyTransactionEmi />
          </div>
          <div
            className='emi-transactionEmi-emptyTransactionEmi-text-1'
            style={{ color: getVersionedDisplay() ? theme.appTextColor : null }}
          >
            No Transaction EMIs
          </div>
          <div
            className='emi-transactionEmi-emptyTransactionEmi-text-2'
            style={{ color: getVersionedDisplay() ? theme.appTextColor : null }}
          >
            You can convert your transactions to EMI once the transaction has
            happened
          </div>
        </div>
      )}
    </div>
  )
}

export default TransactionEmi
