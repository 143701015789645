import React from "react"
import { useSelector } from "react-redux"
import { Icon } from "native-base"

const Copy = ({color}) => {
  const theme = useSelector(state => state.theme)
  return (
    <Icon viewBox='0 0 24 24' size='28px'>
      <svg
        width='24'
        height='24'
        viewBox='0 0 24 24'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <path
          d='M5.6 4C4.716 4 4 4.716 4 5.6V16.8H5.6V5.6H16.8V4H5.6ZM8.8 7.2C7.916 7.2 7.2 7.916 7.2 8.8V18.4C7.2 19.284 7.916 20 8.8 20H18.4C19.284 20 20 19.284 20 18.4V8.8C20 7.916 19.284 7.2 18.4 7.2H8.8ZM8.8 8.8H18.4V18.4H8.8V8.8Z'
          fill={ color || theme.color1}
        />
      </svg>
    </Icon>
  )
}

export default Copy
