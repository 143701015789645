import { Input, Text, View, useToast } from "native-base"
import React, { useEffect, useRef, useState } from "react"
import { useWindowDimensions } from "react-native-web"
import { useSelector } from "react-redux"
import { useLocation, useNavigate } from "react-router-dom"
import { DisplayConfig, ProgramTypes } from "../../../utils/constants"
import {
  AU_BlockCodes,
  EventName,
  Federal_BlockCodes,
  PwaVersions,
} from "../../../utils/enums"
import { Header } from "../../core"
import { ColorButton, WhiteButton } from "../../core/buttons"
import { showToast1 } from "../../core/toast"
import styles from "../styles"
import { captureEvents } from "../../../utils/functions"

const ResetPin_v2 = props => {
  const [theme, user, session, config] = useSelector(state => [
    state.theme,
    state.user,
    state.session,
    state.config,
  ])
  const windowDim = useWindowDimensions()
  const confirmPin0 = useRef(null)
  const confirmPin1 = useRef(null)
  const confirmPin2 = useRef(null)
  const confirmPin3 = useRef(null)
  const navigate = useNavigate()
  const nativeToast = useToast()
  const toast = message => {
    showToast1({ nativeToast, theme, message })
  }

  const [mainContainerHeight, setMainContainerHeight] = useState(0)
  const mainContainerRef = useRef(null)
  const [isFilled, setIsFilled] = useState(false)
  const location = useLocation();
  const fromActivation = location?.state?.from === "Activation";

  useEffect(() => {
    if (mainContainerRef.current) {
      setMainContainerHeight(mainContainerRef.current.clientHeight)
    }
  }, [mainContainerRef.current])

  useEffect(() =>{
    captureEvents({eventName:EventName.RESET_PIN, metadata:{}})
  },[])

  useEffect(() => {
    if (
      session.disabledFunctionsByBlockCode?.includes(
        Federal_BlockCodes.CC_PIN_DISABLE,
      ) ||
      session?.disabledFunctionsByBlockCode?.includes(
        AU_BlockCodes.RESET_PIN_DISABLE,
      ) ||
      session.disabledFunctionsByBlockCode.includes(
        AU_BlockCodes.CARD_DASHBOARD_DISABLE,
      )
    ) {
      toast("Access has been blocked for \nReset PIN page")
      navigate("/")
    }
  })

  const pin0 = useRef(null)
  const pin1 = useRef(null)
  const pin2 = useRef(null)
  const pin3 = useRef(null)

  useEffect(() => {
    setIsFilled(
      [
        pin0?.current?.value,
        pin1?.current?.value,
        pin2?.current?.value,
        pin3?.current?.value,
        confirmPin0?.current?.value,
        confirmPin1?.current?.value,
        confirmPin2?.current?.value,
        confirmPin3?.current?.value,
      ].every(val => val !== ""),
    )
  }, [
    pin0?.current?.value,
    pin1?.current?.value,
    pin2?.current?.value,
    pin3?.current?.value,
    confirmPin0?.current?.value,
    confirmPin1?.current?.value,
    confirmPin2?.current?.value,
    confirmPin3?.current?.value,
  ])

  const getPinMsg = () => {
    if (user.programType === ProgramTypes.CREDIT_CARD)
      return config?.version === PwaVersions.V2_1
        ? "Update Card PIN"
        :  fromActivation ? "Card PIN Setup" : "Setup a new Credit Card PIN"
    else if (user.programType === ProgramTypes.PREPAID)
      return fromActivation ? "Card PIN Setup" : "Setup a new Prepaid Card PIN"
    else return fromActivation ? "Card PIN Setup" : "Setup a new Card PIN"
  }

  return (
    <div
      style={{
        minHeight: windowDim.height,
        backgroundColor: theme.backgroundColor,
      }}
    >
      <Header
        marginBottom='0px'
        text={ (fromActivation &&  config?.version !== PwaVersions.V2_1 ) ? "Activate Card" : "Reset Card PIN"}
        onBack={async () => {
          window.history.go(-1)
        }}
      />
      <div>
        <div className='resetPin' ref={mainContainerRef}>
          <div
            className='resetPin-widget'
            style={{ backgroundColor: theme.widgetBackgroundColor }}
          >
            {config?.version !== PwaVersions.V2_1 && (
              <div>
                <div
                  className='resetPin-widget-heading'
                  style={{ color: theme.appTextColor }}
                >
                  {getPinMsg()}
                </div>        
                <div
                  className='resetPin-widget-sub-heading'
                  style={{ color: theme.appTextColor }}
                >
                  {  fromActivation ? "" : "Reset your card PIN"}
                </div>
              </div>
            )}
            <div>
              <div
                className='resetPin-widget-subHeading'
                style={{ color: theme.appTextColor }}
              >
                {config?.version === PwaVersions.V2_1
                  ? "Enter New PIN"
                  : fromActivation ? "Enter Card PIN" :"Set Pin"}
              </div>
              <div className='resetPin-pin-container'>
                <Input
                  {...styles.textInput}
                  bgColor={props.error ? "#FDF6F6" : "#fff"}
                  ref={pin0}
                  value={props.pin[0]}
                  onChangeText={text => {
                    if ((text < "0" || text > "9") && text !== "") return false
                    props.setPin([
                      text,
                      props.pin[1],
                      props.pin[2],
                      props.pin[3],
                    ])
                    if (text !== "") {
                      pin1.current.focus()
                    }
                  }}
                />
                <Input
                  {...styles.textInput}
                  bgColor={props.error ? "#FDF6F6" : "#fff"}
                  ref={pin1}
                  value={props.pin[1]}
                  onKeyPress={({ nativeEvent }) => {
                    if (
                      nativeEvent.key === "Backspace" &&
                      props.pin[1] === ""
                    ) {
                      pin0.current.focus()
                      props.setPin(["", "", props.pin[2], props.pin[3]])
                    }
                  }}
                  onChangeText={text => {
                    if ((text < "0" || text > "9") && text !== "") return false
                    props.setPin([
                      props.pin[0],
                      text,
                      props.pin[2],
                      props.pin[3],
                    ])
                    if (text !== "") {
                      pin2.current.focus()
                    }
                  }}
                />
                <Input
                  {...styles.textInput}
                  bgColor={props.error ? "#FDF6F6" : "#fff"}
                  ref={pin2}
                  value={props.pin[2]}
                  onKeyPress={({ nativeEvent }) => {
                    if (
                      nativeEvent.key === "Backspace" &&
                      props.pin[2] === ""
                    ) {
                      pin1.current.focus()
                      props.setPin([props.pin[0], "", "", props.pin[3]])
                    }
                  }}
                  onChangeText={text => {
                    if ((text < "0" || text > "9") && text !== "") return false
                    props.setPin([
                      props.pin[0],
                      props.pin[1],
                      text,
                      props.pin[3],
                    ])
                    if (text !== "") {
                      pin3.current.focus()
                    }
                  }}
                />
                <Input
                  {...styles.textInput}
                  bgColor={props.error ? "#FDF6F6" : "#fff"}
                  ref={pin3}
                  value={props.pin[3]}
                  onKeyPress={({ nativeEvent }) => {
                    if (
                      nativeEvent.key === "Backspace" &&
                      props.pin[3] === ""
                    ) {
                      pin2.current.focus()
                      props.setPin([props.pin[0], props.pin[1], "", ""])
                    }
                  }}
                  onChangeText={text => {
                    if ((text < "0" || text > "9") && text !== "") return false
                    props.setPin([
                      props.pin[0],
                      props.pin[1],
                      props.pin[2],
                      text,
                    ])
                    if (text !== "") {
                      pin3.current.blur()
                    }
                  }}
                />
              </div>
            </div>
            <div className='resetPin-pin-box'>
              <div
                className='resetPin-widget-subHeading'
                style={{ color: theme.appTextColor }}
              >
                {config?.version === PwaVersions.V2_1
                  ? "Re-Enter New PIN"
                  : fromActivation ? "Confirm Card PIN" : "Confirm Pin"}
              </div>
              <div className='resetPin-pin-container'>
                <Input
                  {...styles.textInput}
                  bgColor={props.error ? "#FDF6F6" : "#fff"}
                  ref={confirmPin0}
                  value={props.confirmPin[0]}
                  onChangeText={text => {
                    if ((text < "0" || text > "9") && text !== "") return false
                    props.setConfirmPin([
                      text,
                      props.confirmPin[1],
                      props.confirmPin[2],
                      props.confirmPin[3],
                    ])
                    if (text !== "") {
                      confirmPin1.current.focus()
                    }
                  }}
                />
                <Input
                  {...styles.textInput}
                  bgColor={props.error ? "#FDF6F6" : "#fff"}
                  ref={confirmPin1}
                  value={props.confirmPin[1]}
                  onKeyPress={({ nativeEvent }) => {
                    if (
                      nativeEvent.key === "Backspace" &&
                      props.confirmPin[1] === ""
                    ) {
                      confirmPin0.current.focus()
                      props.setConfirmPin([
                        "",
                        "",
                        props.confirmPin[2],
                        props.confirmPin[3],
                      ])
                    }
                  }}
                  onChangeText={text => {
                    if ((text < "0" || text > "9") && text !== "") return false
                    props.setConfirmPin([
                      props.confirmPin[0],
                      text,
                      props.confirmPin[2],
                      props.confirmPin[3],
                    ])
                    if (text !== "") {
                      confirmPin2.current.focus()
                    }
                  }}
                />
                <Input
                  {...styles.textInput}
                  bgColor={props.error ? "#FDF6F6" : "#fff"}
                  ref={confirmPin2}
                  value={props.confirmPin[2]}
                  onKeyPress={({ nativeEvent }) => {
                    if (
                      nativeEvent.key === "Backspace" &&
                      props.confirmPin[2] === ""
                    ) {
                      confirmPin1.current.focus()
                      props.setConfirmPin([
                        props.confirmPin[0],
                        "",
                        "",
                        props.confirmPin[3],
                      ])
                    }
                  }}
                  onChangeText={text => {
                    if ((text < "0" || text > "9") && text !== "") return false
                    props.setConfirmPin([
                      props.confirmPin[0],
                      props.confirmPin[1],
                      text,
                      props.confirmPin[3],
                    ])
                    if (text !== "") {
                      confirmPin3.current.focus()
                    }
                  }}
                />
                <Input
                  {...styles.textInput}
                  bgColor={props.error ? "#FDF6F6" : "#fff"}
                  ref={confirmPin3}
                  value={props.confirmPin[3]}
                  onKeyPress={({ nativeEvent }) => {
                    if (
                      nativeEvent.key === "Backspace" &&
                      props.confirmPin[3] === ""
                    ) {
                      confirmPin2.current.focus()
                      props.setConfirmPin([
                        props.confirmPin[0],
                        props.confirmPin[1],
                        "",
                        "",
                      ])
                    }
                  }}
                  onChangeText={text => {
                    if ((text < "0" || text > "9") && text !== "") return false
                    props.setConfirmPin([
                      props.confirmPin[0],
                      props.confirmPin[1],
                      props.confirmPin[2],
                      text,
                    ])
                    if (text !== "") {
                      confirmPin3.current.blur()
                    }
                  }}
                />
              </div>
            </div>
          </div>
        </div>
        <div className='resetPin-cta-container'>
          <div
            className='resetPin-cta'
            style={{ maxWidth: DisplayConfig.MAX_WIDTH }}
          >
            <ColorButton
              text='Submit'
              isLoading={props.submitLoading}
              onPress={props.handleSubmit}
              isDisable={!isFilled}
            />
            {!(
              config?.version == PwaVersions.V2 ||
              config?.version == PwaVersions.V2_1
            ) ? (
              <WhiteButton text='Cancel' onPress={props.Cancel} />
            ) : null}
          </div>
        </div>
      </div>
    </div>
  )
}

export default ResetPin_v2
