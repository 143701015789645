import React from "react"
import { View, Text, Image } from "native-base"
import { useSelector } from "react-redux"

import styles from "./styles"
import "./styles.css"
import EmptyStatementImage from "../../assets/images/emptyStatement.svg"

const EmptyStatement = () => {
  const theme = useSelector(state => state.theme)
  return (
    <>
      <View
        mt='30%'
        alignItems='center'
        justifyContent='center'
        alignSelf='center'
      >
        <View alignItems='center' justifyContent='center'>
          <View
            bgColor={"#F8F8F8"}
            px='29.19px'
            py='20.6px'
            borderRadius='100px'
            mt='40px'
          >
            <Image
              source={{ uri: EmptyStatementImage }}
              {...styles.emptyImage}
            />
          </View>
          <View mt='32px' alignItems='center' justifyContent='center'>
            <Text
              textAlign='center'
              fontFamily={theme.fontFamily}
              {...styles.emptyStateText}
            >
              No statements found.
            </Text>
            <Text
              textAlign='center'
              mt='24px'
              fontFamily={theme.fontFamily}
              {...styles.emptyStateText}
            >
              Start using your card and keep a track of your statements here.
            </Text>
          </View>
        </View>
      </View>
    </>
  )
}

export default EmptyStatement
