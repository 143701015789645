import React, { useState } from "react"
import { View, Text, Image, ScrollView } from "native-base"
import { useWindowDimensions } from "react-native"
import { useSelector } from "react-redux"
import { ColorButton } from "../../core/buttons"
import SuccessIcon from "../../../assets/images/Success1.svg"
import styles from "../../messages/styles"
import { getHeaderText, showCancelButton } from "../../../utils/auth"
import { captureEvents, goToRedirectUrl } from "../../../utils/functions"
import { AuthChallenges, HEADER_HEIGHT_IN_PX } from "../../../utils/constants"
import { Header } from "../../core"
import { EventName, PwaVersions } from "../../../utils/enums"

const SetMpinSuccess = ({ onSubmit }) => {
  const windowDimensions = useWindowDimensions()

  const [theme, config, screen, session] = useSelector(state => [
    state.theme,
    state.config,
    state.screen,
    state.session,
  ])

  const [submitLoading, setSubmitLoading] = useState(false)
  const successImageUrl = screen?.mpin?.setMpin?.successImageUrl

  const showHeader =
    config?.version === PwaVersions.V2 || config?.version === PwaVersions.V2_1
  const headerText = getHeaderText(AuthChallenges.MPIN)

  const handleSubmitClick = async () => {
    captureEvents({
      eventName: EventName.MPIN_SUCCESS_CTA,
      metadata: {  },
    })
    setSubmitLoading(true)
    await onSubmit()
    setSubmitLoading(false)
  }

  return (
    <div
      className='mpinSuccess'
      style={{
        minHeight:
          config?.version === PwaVersions.V2 ||
          config?.version === PwaVersions.V2_1
            ? windowDimensions.height
            : null,
        backgroundColor:
          config?.version === PwaVersions.V2 ||
          config?.version === PwaVersions.V2_1
            ? theme.backgroundColor
            : null,
      }}
    >
      <ScrollView w='100%'>
        {showHeader ? (
          <Header
            text={
              config?.version === PwaVersions.V2_1 ? "Setup mPIN" : headerText
            }
            onBack={async () => {
              showCancelButton() ? window.history.go(-1) : goToRedirectUrl()
            }}
          />
        ) : (
          <div className='mpinSuccess-noHeader'></div>
        )}
        <div
          style={{
            backgroundColor:
              config?.version === PwaVersions.V2 ||
              config?.version === PwaVersions.V2_1
                ? theme.backgroundColor
                : null,
          }}
        >
          <div className='mpinSuccess-content-container'>
            {successImageUrl ? (
              <img className='mpinSuccess-content-img' src={successImageUrl} />
            ) : (
              <div
                className='mpinSuccess-content-icon-container'
                style={{ backgroundColor: theme.widgetBackgroundColor }}
              >
                <img className='mpinSuccess-content-img' src={SuccessIcon} />
              </div>
            )}
            <div className='mpinSuccess-content-text'>
              <div
                className='mpinSuccess-content-text-1'
                style={{
                  fontFamily: theme.fontFamily,
                  color: theme.appTextColor,
                }}
              >
                {config?.version === PwaVersions.V2
                  ? "MPIN updated successfully!"
                  : "mPIN updated successfully!"}
              </div>
              <div
                className='mpinSuccess-content-text-2'
                style={{
                  fontFamily: theme.fontFamily,
                  color: theme.appTextColor,
                }}
              >
                {config?.version === PwaVersions.V2
                  ? "Use this MPIN to securely access your credit card"
                  : "Use this mPIN to securely access your credit card"}
              </div>
            </div>
          </div>
          <div className='mpinSuccess-cta-container'>
            <div className='mpinSuccess-cta'>
              <ColorButton
                text='Proceed'
                isLoading={submitLoading}
                onPress={handleSubmitClick}
              />
            </div>
          </div>
        </div>
      </ScrollView>
    </div>
  )
}

export default SetMpinSuccess
