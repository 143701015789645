import { ScrollView, Text, View } from "native-base"
import React, { useEffect, useState } from "react"
import { useWindowDimensions } from "react-native"
import { useSelector } from "react-redux"
import { useLocation, useNavigate } from "react-router-dom"
import {
  AU_BlockCodes,
  EventName,
  Federal_BlockCodes,
  Issuers,
  PwaVersions,
} from "../../utils/enums"
import {
  captureEvents,
  goToRedirectUrl,
  isIosDevice,
  postMessageToIos,
} from "../../utils/functions"
import { Header } from "../core"
import { ColorButton } from "../core/buttons"
import AttentionIcon from "../svg/attention"
import styles from "./styles"
import greyCircle from "../../assets/images/greyCircle.svg"

const BlockedMutipleAttempts = () => {
  const windowDimensions = useWindowDimensions()
  const navigate = useNavigate()

  const [session, theme, config, screen, user] = useSelector(state => [
    state.session,
    state.theme,
    state.config,
    state.screen,
    state.user,
  ])
  const [pageTitle, setPageTitle] = useState("")

  const location = useLocation()
  const headerTitle = location?.state?.headerTitle
  const timeLeft = location?.state?.time

  useEffect(() =>{
    let reason = location?.state?.from === "Customer" ? "PAN and DOB" : location?.state?.from  ;
    captureEvents({eventName:EventName.BLOCKED_MULTPLE_ATTEMPTS, reason: reason, metadata:{}})
  },[])

  useEffect(() => {
    let title = "Access Blocked!"

    if (!title) {
      navigate("/")
    }
    setPageTitle(title)
  }, [])

  const description = screen.accountClosed
  const isMobileNumPresent = () => {
    if (description?.moblieNo) return "Or"
    else return null
  }
  const backHome = () => {
    captureEvents({eventName:EventName.ACCESS_BLOCK_CTA, metadata:{}})
    if (isIosDevice()) {
      postMessageToIos(JSON.stringify({ cmd: "SESSION_EXPIRED" }))
    } else {
      window.location.href = session.redirectUrl
    }
  }

  const getVersionedDisplay = () => {
    return (
      config?.version === PwaVersions.V2 || config?.version === PwaVersions.V2_1
    )
  }

  //width will be minimum of (412, windowWidth)
  let windowWidth = 412
  if (windowDimensions.width < windowWidth) {
    windowWidth = windowDimensions.width
  }

  return (
    <View {...styles.mainView} minH={windowDimensions.height}>
      <ScrollView>
        <Header
          marginBottom='0px'
          text={headerTitle || "Alert"}
          onBack={async () => {
            goToRedirectUrl()
          }}
        />
        <View
          {...styles.innerView}
          bgColor={getVersionedDisplay() ? theme.backgroundColor : null}
          minW={windowWidth}
        >
          <View {...styles.section1}>
            <View mt='30px'>
              <img
                style={{
                  position: "absolute",
                  zIndex: -1,
                  top: "-25px",
                  height: "150px",
                  left: "-25px",
                  width: "150px",
                }}
                src={greyCircle}
              />
              <AttentionIcon color='#CA5454' />
              {/* <Image {...styles.image} source={{ uri: ErrorImage }} /> */}
            </View>
            <View {...styles.text1Container1}>
              <View {...styles.text1Container2}>
                <Text
                  {...styles.text1}
                  fontFamily={theme.fontFamily}
                  color={getVersionedDisplay() ? theme.appTextColor : null}
                >
                  {pageTitle}
                </Text>
              </View>
              <View>
                <Text
                  {...styles.textSubTitle}
                  fontFamily={theme.fontFamily}
                  color={getVersionedDisplay() ? theme.appTextColor : null}
                >
                  Your access has been temporarily restricted due to multiple
                  invalid attempts. Please retry after{" "}
                  {timeLeft || "Sometime"}.
                </Text>
              </View>
            </View>
          </View>

          <View {...styles.button} position='fixed' bottom={"20px"} mt='25%'>
            <ColorButton text='Go To Homepage' onPress={backHome} />
          </View>
        </View>
      </ScrollView>
    </View>
  )
}

export default BlockedMutipleAttempts
