import React, { useState, useRef, useEffect } from "react"
import { View, Animated } from "react-native"
import style from "../styles"
import StatementData from "../StatementData//index"
import { theme } from "native-base"

const StatementAccordionBody = ({ statement, isOpened, statementIndex }) => {
  const animatedController = useRef(new Animated.Value(0)).current
  const [bodySectionHeight, setBodySectionHeight] = useState(0)

  const bodyHeight = animatedController.interpolate({
    inputRange: [0, 1],
    outputRange: [0, bodySectionHeight],
  })
  useEffect(() => {
    if (isOpened !== undefined ? isOpened : statementIndex !== 0) {
      Animated.timing(animatedController, {
        duration: 500,
        toValue: 0,
        useNativeDriver: false,
      }).start()
    } else {
      Animated.timing(animatedController, {
        duration: 500,
        toValue: 1,
        useNativeDriver: false,
      }).start()
    }
  }, [isOpened, animatedController, statementIndex])

  return (
    <Animated.View
      style={{
        height: bodyHeight,
        ...style.dataContainer,
        backgroundColor: theme.widgerBackgroundColor,
      }}
    >
      <View
        onLayout={event => {
          setBodySectionHeight(event.nativeEvent.layout.height)
        }}
      >
        <StatementData statement={statement} />
      </View>
    </Animated.View>
  )
}

export default StatementAccordionBody
