import moment from "moment"
import { ChevronLeftIcon, Radio, Text, View, useToast } from "native-base"
import React, { useEffect, useState } from "react"
import { useWindowDimensions } from "react-native"
import { useDispatch, useSelector } from "react-redux"
import { Link, useLocation, useNavigate } from "react-router-dom"
import ApiIds from "../../../../auth/ApiIds"
import Loader from "../../../core/loader"
import AccountService from "../../../../services/AccountService"
import {
  clearCommonState,
  setCommonState,
} from "../../../../store/actions/Common"
import { handleApiAuth } from "../../../../utils/auth"
import {
  DisplayConfig,
  TRANSACTION_LOGO_HEIGHT,
  TRANSACTION_LOGO_WIDTH,
} from "../../../../utils/constants"
import {
  captureEvents,
  consoleError,
  formatAmount,
  getTransactionDate,
} from "../../../../utils/functions"
import { Header } from "../../../core"
import { ColorButton } from "../../../core/buttons"
import { showToast1 } from "../../../core/toast"
import TransactionLogo from "../../../core/transactionLogo"
import ErrorScreen from "../../../messages/errorScreen"
import styles from "./styles"

import { EventName, PwaVersions } from "../../../../utils/enums"
import ConvertEmiLoader from "../../../core/SkeletonLoader/convertEmiLoader"

const EMI_CONVERTED_ERROR_STRING = /is previously converted to EMI/

function EMIPlans_v1() {
  const windowDim = useWindowDimensions()
  const location = useLocation()
  const navigate = useNavigate()
  const nativeToast = useToast()
  const dispatch = useDispatch()

  const [theme, common, user, config, screen] = useSelector(state => [
    state.theme,
    state.common,
    state.user,
    state.config,
    state.screen,
  ])

  const { isEmiPlansSet, txnState, emiPlansData } = common
  const [value, setValue] = useState()
  const [emiPlans, setEmiPlans] = useState([])
  const [error /*, setError */] = useState(false)
  const [loading, setLoading] = useState(true)
  const [submitLoading, setSubmitLoading] = useState(false)

  useEffect(() =>{
    let data = {
    }
    captureEvents({eventName:EventName.CONVERT_TO_EMI, metadata:data})
  },[])


  useEffect(() => {
    ;(async () => {
      if (!isEmiPlansSet || location?.state?.txnId != txnState?.txnId ) {
        dispatch(clearCommonState())
        dispatch(setCommonState({ txnState: location.state }))

        await handleApiAuth({
          apiId: ApiIds.EMI_CONFIG,
          onAuthSuccess: onEmiConfigAuthSuccess(location.state.amount),
          onAuthFailure: onAuthFailure,
          onAuthCancel: onAuthCancel,
          otpReason: "to get emi plans",
          mpinReason: "to get emi plans",
          toast,
          navigate,
        })
      } else {
        let emi_plans = emiPlansData.emiPlans
        emi_plans.sort(sortByTenure)
        setEmiPlans(emi_plans)
        setValue(emiPlansData.emiPlans[0]["tenureInMonths"])
      }
      setLoading(false)
    })()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEmiPlansSet])

  function sortByTenure(a, b) {
    if (a.tenureInMonths < b.tenureInMonths) {
      return 1
    }
    if (a.tenureInMonths > b.tenureInMonths) {
      return -1
    }
    return 0
  }

  const onEmiConfigAuthSuccess = amount => async apiToken => {
    try {
      const response = await AccountService.getEMIConfig(
        apiToken,
        user.account.id,
        amount,
        location.state.txnId,
      )
      const result = response.data

      if (result?.success) {
        dispatch(
          setCommonState({
            isEmiPlansSet: true,
            emiPlansData: result.data,
          }),
        )

        navigate("/ConvertToEMI", { replace: true })
      } else {
        consoleError(result?.errors)
        if (EMI_CONVERTED_ERROR_STRING.test(result?.errors)) {
          toast(
            "Sorry!the transaction has already \nbeen converted. Please contact your \nbank for more details.",
          )
        } else {
          toast("Sorry an error occurred")
        }
        navigate("/", { replace: true })
      }
    } catch (error) {
      consoleError(error)
      toast("Sorry an error occurred")
      navigate("/", { replace: true })
    }
  }

  const onAuthFailure = async (error, message) => {
    error && consoleError(error)
    message && toast(message)
    navigate("/", { replace: true })
  }

  const onAuthCancel = async message => {
    message && toast(message)
    navigate("/", { replace: true })
  }

  const toast = message => {
    showToast1({ nativeToast, theme, message })
  }

  const onCreateEmiAuthSuccess = async apiToken => {
    try {
      const data = {
        tenureInMonths: value,
        txnRefId: txnState?.txnId,
      }
      const response = await AccountService.createEMI(
        apiToken,
        user.account.id,
        data,
      )
      let metadata = {
        tenureInMonths: value,
      } 
      captureEvents({eventName:EventName.CONVERT_TO_EMI_REQUESTED, metadata:metadata})
      const result = response?.data
      if (result?.success) {
        captureEvents({eventName:EventName.CONVERT_TO_EMI_SUCCESS, metadata:{}})
        if (txnState?.txnType === "AUTHORIZE") {
          toast("EMI Conversion requested")
        } else {
          toast("Successfully converted to EMI")
        }
        navigate("/", { replace: true })
      } else {
        captureEvents({eventName:EventName.CONVERT_TO_EMI_ERROR, metadata:{}})
        consoleError(result.errors)
        if (EMI_CONVERTED_ERROR_STRING.test(result?.errors)) {
          toast(
            "Sorry!the transaction has already \nbeen converted. Please contact your \nbank for more details.",
          )
        } else {
          captureEvents({eventName:EventName.CONVERT_TO_EMI_ERROR, metadata:{}})
          toast("Sorry an error occurred while \nconverting to EMI")
        }
        navigate("/", { replace: true })
      }
    } catch (error) {
      captureEvents({eventName:EventName.CONVERT_TO_EMI_ERROR, metadata:{}})
      consoleError(error)
      toast("Sorry an error occurred while \nconverting to EMI")
      navigate("/", { replace: true })
    }
  }

  const handleConvertToEMI = async () => {
    setSubmitLoading(true)

    await handleApiAuth({
      apiId: ApiIds.CREATE_EMI,
      onAuthSuccess: onCreateEmiAuthSuccess,
      onAuthFailure: onAuthFailure,
      onAuthCancel: onAuthCancel,
      otpReason: "to create emi",
      mpinReason: "to create emi",
      toast,
      navigate,
    })

    setSubmitLoading(false)
  }

  const getVersionedDisplay = () => {
    return (
      config?.version === PwaVersions.V2 || config?.version === PwaVersions.V2_1
    )
  }

  return (
    <div
      style={
        getVersionedDisplay
          ? {
              minHeight: windowDim.height,
              backgroundColor: theme.backgroundColor,
              maxWidth: DisplayConfig.MAX_WIDTH,
            }
          : {}
      }
      className='emiPlans-v1'
    >
      {error ? (
        <ErrorScreen />
      ) : loading ? (
        config.version === PwaVersions.V1 ? (
          <div
            className='emiPlans-error-v1'
            alignItems='center'
            justifyContent='center'
            style={{
              minHeight: windowDim.height,
              backgroundColor: `${
                config?.version === PwaVersions.V2 ||
                config?.version === PwaVersions.V2_1
                  ? theme.backgroundColor
                  : "white"
              }`,
            }}
          >
            <Loader color={theme.color1} width={120} height={120} />
          </div>
        ) : (
          <div>
            <ConvertEmiLoader />
          </div>
        )
      ) : (
        <div className='emiPlans-main-div-v1'>
          <div
            className='emiPlans-header-div-v1'
            style={{ maxWidth: DisplayConfig.MAX_WIDTH }}
          >
            {getVersionedDisplay() ? (
              <Header
                text='Convert to EMI'
                onBack={async () => {
                  window.history.go(-1)
                }}
              />
            ) : (
              <div
                className='emiPlans-header-v1'
                style={{
                  backgroundColor: getVersionedDisplay()
                    ? theme.headerBackgroundColor
                    : null,
                }}
              >
                <div className='emiPlans-header-leftIcon-v1'>
                  <Link
                    style={{
                      textDecoration: "None",
                    }}
                    to={txnState?.prevPath}
                  >
                    <ChevronLeftIcon
                      size='24px'
                      color={
                        getVersionedDisplay() ? theme.titleColor : "#303030"
                      }
                      width='fit-content'
                    />
                  </Link>
                </div>
                <div className='emiPlans-header-text-div-v1'>
                  <div
                    className='emiPlans-header-text-v1'
                    style={{
                      color: getVersionedDisplay()
                        ? theme.titleColor
                        : "#303030",
                      fontFamily: theme.fontFamily,
                    }}
                  >
                    Convert to EMI
                  </div>
                </div>
              </div>
            )}

            <div
              className='emiPlans-cardInterface-v1'
              {...styles.cardInterface}
            >
              {txnState?.logo ? (
                <img
                  {...styles.image}
                  src={`data:image/jpg;base64,${txnState?.logo}`}
                  alt='logo'
                  width={TRANSACTION_LOGO_WIDTH + "px"}
                  height={TRANSACTION_LOGO_HEIGHT + "px"}
                />
              ) : (
                <div className='emiPlans-tax-transactionLogo-v1'>
                  <TransactionLogo transactionType={txnState?.txnType} />
                </div>
              )}
            </div>
            <div
              className='emiPlans-transaction-title-div-v1'
              alignItems={"center"}
              mt='4px'
            >
              <div
                className='emiPlans-transaction-title-text-v1'
                style={{
                  color: getVersionedDisplay() ? theme.appTextColor : null,
                  fontFamily: theme.fontFamily,
                }}
              >
                {txnState?.description}
              </div>
            </div>
            <div className='emiPlans-tax-date-text-div-v1'>
              <div
                className='emiPlans-tax-date-text-v1'
                style={{
                  color: getVersionedDisplay() ? theme.appTextColor : null,
                  fontFamily: theme.fontFamily,
                }}
              >
                {getTransactionDate(
                  txnState?.transactionDate,
                  txnState?.postingDate,
                )}
              </div>
            </div>
            <div className="emiPlans-tax-amount-text-div-v1" alignItems={"center"} mt='8px'>
              <div
              className="emiPlans-tax-amount-text-div-v1"
                {...styles.displayAmount}
                style = {{color: getVersionedDisplay() ? theme.appTextColor : null, fontFamily:theme.fontFamily }}
              >
                {formatAmount(txnState?.amount)}
              </div>
            </div>
          </div>
          <div  className="emiPlans-choose-plan-div" >
            <div className="emiPlans-choose-plan-div-1" >
              <div className="emiPlans-choose-plan-text-div" >
                <div
                  className="emiPlans-choose-plan-text"
                  style={{color:getVersionedDisplay() ? theme.appTextColor : null}}
                >
                  Choose Plan
                </div>
              </div>
              {emiPlans &&
                emiPlans.map((emiPlan, index) => (
                  <div

                  className="emiPlans-map-div"
                    key={index}
             
               
                    style={{
                      margin: "5px",
                      marginLeft:"0px",
                      background: getVersionedDisplay()
                        ? theme.widgetBackgroundColor
                        : "#F8F8F8",
                      borderBottom: "1px solid #ECECEC",
                    }}
                  >
                    <div>
                      <div
                      className="emiPlans-monthlyPlanTextBox"
                        style={{backgroundColor: getVersionedDisplay()
                          ? `${theme.backgroundColor}60`
                          : "#FFE5A6"}}
                      >
                        <div

                          className="emiPlans-monthlyPlanText"
                    
                          style={{color:getVersionedDisplay() ? theme.appTextColor : null}}
                        >
                          {" "}
                          {`${emiPlan.tenureInMonths} months`}
                        </div>
                      </div>
                      <div className="emiPlans-amountPerMonth-div"  >
                        <div
                          className="emiPlans-amountPerMonth-text" 
                          style={{color:getVersionedDisplay() ? theme.appTextColor : null}}
                        >
                          {`₹${emiPlan.emi}`}
                          <span style={{ fontSize: "14px" }}>/month</span>
                        </div>
                      </div>
                      <div
                        className="emiPlans-monthlyPlanIntrestBox-div"
                        {...styles.monthlyPlanIntrestBox}
                     
                      >
                        <div
                        className="emiPlans-monthlyPlanIntrestBox-div-text"
                          {...styles.monthlyPlanIntrest}
                          color={
                            getVersionedDisplay() ? theme.appTextColor : null
                          }
                        >
                          Interest:{" "}
                          {`${formatAmount(
                            emiPlan.schedules.reduce((total, schedule) => {
                              return schedule.interest + total
                            }, 0),
                          )}`}
                        </div>
                        <div
                        className="emiPlans-monthlyPlanIntrest-text-1"
                        style={{color:getVersionedDisplay() ? theme.appTextColor : null}}
                        
                        >
                          .
                        </div>
                        <div
                        className="emiPlans-monthlyPlanIntrest-text-2"
                          style={{color:getVersionedDisplay() ? theme.appTextColor : null}}
                        >
                          Processing Fees:{" "}
                          {`${formatAmount(emiPlan.processingFees)}`}
                        </div>
                      </div>
                      <div className="emiPlans-monthlyPlanDuration-div" >
                        <div
                          className="emiPlans-monthlyPlanDuration-text"
                          style={{color:getVersionedDisplay() ? theme.appTextColor : null}}
                        >
                          Duration:{" "}
                          {`${moment(
                            emiPlan.schedules[0].paymentDueDate,
                          ).format("MMM 'YY")}`}{" "}
                          -{" "}
                          {moment(
                            emiPlan.schedules[emiPlan.schedules.length - 1]
                              .paymentDueDate,
                          ).format("MMM 'YY")}
                        </div>
                      </div>
                    </div>
                    <div>
                      <Radio.Group
                        value={value}
                        onChange={item => {
                          setValue(item)
                        }}
                        color={theme.color1}
                        name='myRadioGroup'
                        accessibilityLabel='pick'
                      >
                        <Radio
                          value={emiPlan.tenureInMonths}
                          colorScheme={theme.color1}
                          my={1}
                        ></Radio>
                      </Radio.Group>
                    </div>
                  </div>
                ))}
            </div>
            
            <div className="emiPlans-tnc-box" >
              <div
              className="emiPlans-tnc-div-v1" 
                style={{maxWidth:DisplayConfig.MAX_WIDTH}}
              >
                {getVersionedDisplay() && screen?.emi?.isTncEnabled && (
                  <div
                  className="emiPlans-tnc-text-div-v1" 
                  >
                    <div
                     className="emiPlans-tnc-text" 
                      style={{color:getVersionedDisplay() ? theme.appTextColor : null, fontFamily:theme.fontFamily}}
                    >
                      By continuing you agree to the
                    </div>
                    <div

                     className="emiPlans-tnc-link-div"
                      onClick={() =>
                        (window.location.href =
                          screen?.emi?.isTncEnabled ||
                          "https://hyperface.co.in")
                      }
                    >
                      <div
                       className="emiPlans-tnc-link-text"
                       style={{color:getVersionedDisplay() ? theme.appTextColor : null, fontFamily:theme.fontFamily}}
                      >
                        Terms & Conditions{" "}
                      </div>
                    </div>
                  </div>
                )}
                <ColorButton
                  style={{ width: "100%", borderRadius: "6px" }}
                  text='Convert to EMI'
                  isLoading={submitLoading}
                  onPress={() => handleConvertToEMI()}
                />
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  )
}

export default EMIPlans_v1
