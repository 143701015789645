const styles = {
  mainContainer: {
    width: "100%",
    background: "#fff",
  },
  toast: {
    fontWeight: "600",
    fontSize: "16px",
    lineHeight: "19px",
    letterSpacing: "0.01em",
  },

  body: { justifyContent: "center", alignItems: "center", my: "24px" },
  messageContainer: {
    display: "flex",
    flexDirection: "row",
    backgroundColor: "#F8F8F8",
    padding: 3,
    w: "90%",
    mb: "20px",
  },
  messageText: {
    fontSize: 12,
    color: "#7B7B7B",
  },
  infoSvgContainer: {
    width: "30px",
    mt: "3px",
  },

  header: {
    fontSize: "18px",
    fontWeight: "500",
    color: "#303030",
    fontFamily: "Inter",
  },

  reasonsHeader: {
    fontSize: "20px",
    fontWeight: "600",
    color: "#303030",
    fontFamily: "Inter",
  },

  reasonsSubHeader: {
    mt: "12px",
    fontSize: "16px",
    fontWeight: "500",
    color: "#7B7B7B",
    fontFamily: "Inter",
  },
  reasonsText: {
    ml: "6px",
    fontSize: "14px",
    fontWeight: "600",
    fontFamily: "Inter",
  },

  addressText: {
    fontFamily: "Inter",
    fontWeight: "500",
    fontSize: "16px",
    lineHeight: "20px",
    alignItems: "center",
    color: "#425466",
    width: "90%",
  },

  addressContainer: {
    my: "32px",
    p: "16px",
    borderRadius: "8px",
  },

  reasonsOptionContainer: {
    borderWidth: "1",
    py: "6px",
    pl: "8px",
    pr: "28px",
    borderRadius: "32px",
    mb: "12px",
  },

  reasonsInput: {
    w: "100%",
    borderWidth: "0px",
    size: "lg",
    borderBottomWidth: "2",
  },

  heroSection: {
    p: "24px",
    bgColor: "#F7F9FF",
    mx: "16px",
    borderRadius: "8px",
  },

  heroTextSection: { alignItems: "center", justifyContent: "center" },

  textPara: { mt: "24px", width: "100%" },

  text: {
    textAlign: "center",
    fontFamily: "Inter",
    fontSize: "14px",
    fontWeight: "500",
  },
  cardBlockedText: {
    textAlign: "center",
    fontFamily: "Inter",
    fontSize: "16px",
    fontWeight: "500",
  },
  CTAsection: {
    alignItems: "center",
    justifyContent: "center",
    position: "fixed",
    width: "100%",
    bottom: "16px",
  },

  cardBlockedContentBox: {
    p: "32px",
    bgColor: "#F7F9FF",
    mx: "16px",
    borderRadius: "8px",
  },

  cardBlockedTextsection: { mt: "36px", width: "90%" },
  tncCheckBox: {
    borderColor: "grey",
    _checked: { borderColor: "grey" },
    mt: "3px",
    ml: "0px",
    w: "8%",
  },
}

export default styles
