import { Issuers } from "../../utils/enums"
import {
  SET_USER,
  SET_CUSTOMER,
  SET_ACCOUNT,
  SET_CARDS,
  SET_SUMMARY,
  SET_EMI_DETAILS,
  SET_REWARD, 
  SET_SPEND_GROUP,
  SET_SPEND_CATEGORY,
  SET_CARD_REPLACEMENT_FEE,
  SET_REWARD_SUMMARY,
  SET_ACTIVATION_CODE,
} from "../ActionTypes"
import store from "../Store"

export const setUser = data => dispatch => {
  const session = store.getState().session
  if(session?.issuer === Issuers.AU_BANK){
    if(data?.account?.status){
      data.account.status = "ACTIVE"
    }
  }
  dispatch({
    type: SET_USER,
    payload: data,
  })
}

export const setCustomer = data => dispatch => {
  dispatch({
    type: SET_CUSTOMER,
    payload: data,
  })
}

export const setAccount = data => dispatch => {
  const session = store.getState().session
  if(session?.issuer === Issuers.AU_BANK){
    if(data?.account?.status){
      data.account.status = "ACTIVE"
    }
  }
  dispatch({
    type: SET_ACCOUNT,
    payload: data,
  })
}

export const setCards = data => dispatch => {
  dispatch({
    type: SET_CARDS,
    payload: data,
  })
}

export const setEmiDetails = data => dispatch => {
  dispatch({
    type: SET_EMI_DETAILS,
    payload: data,
  })
}

export const setSummary = data => dispatch => {
  dispatch({
    type: SET_SUMMARY,
    payload: data,
  })
}
export const setRewardPoints = data => dispatch => {
  dispatch({
    type: SET_REWARD,
    payload: data,
  })
}

export const setAggregateRewardsSummary = data => dispatch => {
  dispatch({
    type: SET_REWARD_SUMMARY,
    payload: data,
  })
}
export const setSpendGroup = data => dispatch => {
  dispatch({
    type: SET_SPEND_GROUP,
    payload: data,
  })
}

export const setSpendCategory = data => dispatch => {
  dispatch({
    type: SET_SPEND_CATEGORY,
    payload: data,
  })
}

export const setReplacementFee = data => dispatch => {
  dispatch({
    type: SET_CARD_REPLACEMENT_FEE,
    payload: data,
  })
}

export const setactivationCodeFromURL = data => dispatch => {
  dispatch({
    type: SET_ACTIVATION_CODE,
    payload: data,
  })
}