import { View, Text, ChevronRightIcon } from "native-base"
import React from "react"
import { useDispatch, useSelector } from "react-redux"
import { useNavigate } from "react-router-dom"
import moment from "moment"
import { setSelectedEmi } from "../../../../../store/actions/Session"
import { captureEvents, formatAmount } from "../../../../../utils/functions"
import EmptyBillEmi from "../../../../svg/emptyBillEmi"
import { EventName, PwaVersions } from "../../../../../utils/enums"

const getColor = (getVersionedDisplay, theme) => {
  return getVersionedDisplay() ? theme.appTextColor : null
}
const BillEmi = props => {
  const [theme, config] = useSelector(state => [state.theme, state.config])
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const getVersionedDisplay = () => {
    return (
      config?.version === PwaVersions.V2 || config?.version === PwaVersions.V2_1
    )
  }

  return (
    <div className='emi-billedEmi'>
      {props.data.length > 0 ? (
        props.data.map(item => (
          <div
            className='emi-billedEmi-widget'
            style={{
              backgroundColor: getVersionedDisplay()
                ? `${theme.appTextColor}20`
                : null,
            }}
          >
            <div
              className='emi-billedEmi-widget-header'
              style={{
                backgroundColor: getVersionedDisplay()
                  ? `${theme.appTextColor}10`
                  : null,
              }}
            >
              <div
                className='emi-billedEmi-widget-header-content-date'
                style={{ color: getColor(getVersionedDisplay, theme) }}
              >
                {`${moment(item.createdOn).format("MMM 'YY")} EMIs`}
              </div>
              <div
                className='emi-billedEmi-widget-header-content-paidCount'
                style={{
                  color: getColor(getVersionedDisplay, theme),
                  borderColor: getColor(getVersionedDisplay, theme),
                }}
                borderColor={getColor(getVersionedDisplay, theme)}
              >
                {`${item.currentCycle - 1}/${item.tenureInMonths} Paid`}
              </div>
            </div>
            <div>
              {item.schedules.map((schedule, index) => (
                <div
                  className='emi-billedEmi-widget-container'
                  style={{
                    backgroundColor: getVersionedDisplay()
                      ? theme.widgetBackgroundColor
                      : null,
                  }}
                >
                  <div
                    className='emi-billedEmi-widget-emiRow'
                    style={{
                      paddingBottom:
                        item.schedules.length !== index + 1 ? "16px" : "12px",
                      borderBottomWidth:
                        item.schedules.length !== index + 1 ? "1px" : "0px",
                    }}
                  >
                    <div>
                      <div
                        className='emi-billedEmi-widget-emiRow-text'
                        style={{ color: getColor(getVersionedDisplay, theme) }}
                      >
                        {` EMI ${index + 1}/${item.schedules.length}`}
                      </div>
                      <div className='emi-billedEmi-widget-emiRow-date'>
                        {`Due in ${moment(schedule.paymentDueDate).format(
                          "Do MMM 'YY",
                        )}`}
                      </div>
                    </div>
                    <div
                      className='emi-billedEmi-widget-emiRow-text'
                      style={{ color: getColor(getVersionedDisplay, theme) }}
                    >
                      {formatAmount(item.monthlyEmi)}
                    </div>
                  </div>
                </div>
              ))}
            </div>
            <div className='emi-billedEmi-widget-footer'>
              <div
                className='emi-billedEmi-widget-footer-container'
                onClick={() => {
                  captureEvents({eventName:EventName.EMI_DETAILS_PRECLOSE_CTA, metadata:{selectedEmi:item }})
                  dispatch(setSelectedEmi({ currentSelectedEmi: item }))
                  navigate("/ManageCard/ForecloseEmi")
                }}
              >
                <div
                  className='emi-billedEmi-widget-footer-text'
                  style={{ color: theme.color1 }}
                >
                  Preclose
                </div>
                <ChevronRightIcon ml='4px' size='20px' color={theme.color1} />
              </div>
            </div>
          </div>
        ))
      ) : (
        <div className='emi-billedEmi-emptyBillEmi'>
          <div>
            <EmptyBillEmi />
          </div>
          <div
            className='emi-billedEmi-emptyBillEmi-text-1'
            style={{ color: getVersionedDisplay() ? theme.appTextColor : null }}
          >
            No Balance EMIs
          </div>
          <div
            className='emi-billedEmi-emptyBillEmi-text-2'
            style={{ color: getVersionedDisplay() ? theme.appTextColor : null }}
          >
            You can convert your upcoming bill amount to EMI once the statement
            is generated
          </div>
        </div>
      )}
    </div>
  )
}

export default BillEmi
