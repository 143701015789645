import React, { useState } from "react"
import { useSelector } from "react-redux"
import { View, Text, ChevronRightIcon } from "native-base"
import styles from "./styles"
import {
  TRANSACTION_LOGO_WIDTH,
  TRANSACTION_LOGO_HEIGHT,
  EmiStatus,
} from "../../../utils/constants"
import {
  truncateString,
  getTransactionDate,
  formatAmount,
  capitalizeFirsLetter,
  formatString,
} from "../../../utils/functions"
import TransactionLogo from "../../core/transactionLogo"
import { useNavigate } from "react-router-dom"

export const getEmiLabel = emiStatus =>{
  if(emiStatus === EmiStatus.NOT_CREATED){
    return null
  }
  else if(emiStatus === EmiStatus.PENDING){
    return "EMI Pending"
  }
  else if(emiStatus === EmiStatus.ACTIVE){
    return "EMI Converted"
  }
  else if(emiStatus === EmiStatus.CANCELLED){
    return "EMI Cancelled"
  }
  else if(emiStatus === EmiStatus.FORECLOSED){
    return "EMI Foreclosed"
  }
  else if(emiStatus === EmiStatus.CLOSED){
    return "EMI Closed"
  }
  else{
    return null
  }
}

export const getEmiLabelColor = emiStatus =>{
  if(emiStatus === EmiStatus.NOT_CREATED){
    return null
  }
  else if(emiStatus === EmiStatus.PENDING){
    return "#fcf55f"
  }
  else if(emiStatus === EmiStatus.ACTIVE){
    return "#d5f0aa"
  }
  else if(emiStatus === EmiStatus.CANCELLED){
    return "#ff7575"
  }
  else if(emiStatus === EmiStatus.FORECLOSED){
    return "#ff7575"
  }
  else if(emiStatus === EmiStatus.CLOSED){
    return "#ff7575"
  }
  else{
    return null
  }
}

const Transactions = props => {
  const navigate = useNavigate()
  const [isDescFull, setIsDescFull] = useState(false)
  const theme = useSelector(state => state.theme)

  const handleConvertToEmiClick = transaction => {
    navigate("/ConvertToEMI", {
      state: {
        txnId: transaction.id,
        amount: transaction.amount,
        description: formatString(capitalizeFirsLetter(transaction.description)),
        logo: transaction.identifiedMerchantLogo,
        transactionDate: transaction.transactionDate,
        postingDate: transaction.postingDate,
        txnType: transaction.txnType,
        prevPath: "/Transactions",
      },
    })
  }

  return (
    <>
      {/* Transactions Section */}
      <View {...styles.transactionSection}>
        <View mt='16px'>
          <View>
            <View {...styles.transactionContainer}>
              <View mb='16px' w='20%'>
                {props.transaction.identifiedMerchantLogo ? (
                  <img
                    {...styles.image}
                    src={`data:image/jpg;base64,${props.transaction.identifiedMerchantLogo}`}
                    alt='logo'
                    width={TRANSACTION_LOGO_WIDTH + "px"}
                    height={TRANSACTION_LOGO_HEIGHT + "px"}
                  />
                ) : (
                  <TransactionLogo
                    transactionType={props.transaction.txnType}
                  />
                )}
              </View>
              <View w='50%' mb='16px'>
                <View>
                  <Text
                    fontFamily={props.fontFamily}
                    {...styles.transactee}
                    onClick={() => setIsDescFull(!isDescFull)}
                  >
                    {isDescFull
                      ? formatString(capitalizeFirsLetter(props.transaction.description))
                      : formatString(truncateString(
                          capitalizeFirsLetter(
                            props.transaction.description,
                          ) || "",
                          25,
                        ))}
                  </Text>
                </View>
                <View>
                  {Boolean(props.transaction.transactionDate) && (
                    <>
                      <Text
                        fontFamily={props.fontFamily}
                        {...styles.transactionTime}
                      >
                        {getTransactionDate(
                          props.transaction.transactionDate,
                          props.transaction.postingDate,
                        )}
                      </Text>
                      {props.transaction.emiAllowed &&
                        props.transaction.emiStatus ===
                          EmiStatus.NOT_CREATED && (
                          <View {...styles.transactionConvertToEMI}>
                            <Text
                              {...styles.transactionConvertToEMIText}
                              color={theme.color1}
                              onClick={() =>
                                handleConvertToEmiClick(props.transaction)
                              }
                            >
                              Convert to EMI
                            </Text>
                            <ChevronRightIcon
                              color={theme.color1}
                              size='20px'
                              width='fit-content'
                            />
                          </View>
                        )}
                    </>
                  )}
                </View>
              </View>
              <View
                styles={{ display: "flex", flexDirection: "column" }}
                width='50%'
              >
                <View {...styles.transactionAmountContainer}>
                  <Text
                    fontFamily={props.fontFamily}
                    {...styles.transactionType}
                  >
                    {props.transaction.txnNature === "DEBIT" ? "-" : "+"}
                  </Text>
                  <Text
                    fontFamily={props.fontFamily}
                    {...styles.transactionAmount}
                  >
                    {formatAmount(props.transaction.amount)}
                  </Text>
                </View>
                {getEmiLabel(props.transaction.emiStatus) && (
                  <View
                    {...styles.transactionConvertedToEMI}
                    background={getEmiLabelColor(props.transaction.emiStatus)}
                  >
                    <Text {...styles.transactionConvertedToEMIText}>
                      {getEmiLabel(props.transaction.emiStatus)}
                    </Text>
                  </View>
                )}
              </View>
            </View>
          </View>
        </View>
      </View>
    </>
  )
}

export default Transactions
