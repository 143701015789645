import { Header } from "../../core"
import { useWindowDimensions } from "react-native-web"
import styles from "./styles.css"
import { useDispatch, useSelector } from "react-redux"
import { useEffect, useState } from "react"
import { Radio } from "../../../core/Radio"
import { ColorButton } from "../../core/buttons"
import { captureEvents, formatAmount } from "../../../utils/functions"
import paymentFailIcon from "../../../assets/images/redCross.svg"
import { useNavigate } from "react-router-dom"
import { EventName } from "../../../utils/enums"

const options = [
    "@ybi",
    "@paytm",
    "@apl",
    "@axl",
    "@aubank",
    "@ilb",
]

const PaymentExpired = () => {
    const windowDim = useWindowDimensions()
    const [inputId, setInputId] = useState("")
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const [theme, user, paymentGateway] =
        useSelector(state => [
            state.theme,
            state.user,
            state.paymentGateway
        ])

    useEffect(() => {
        captureEvents({ eventName: EventName.PAY_BILL_FAILURE_CTA, metadata: { error: "Session Expired" } })
    }, [])
        
    const handleProceed = async () => {
        captureEvents({eventName:EventName.PAY_BILL_FAILURE_CTA, metadata:{action:"retry payment"}})
        navigate("/paymentGateway/options")
    }

    const retryOtherMethods = async () => {
        captureEvents({eventName:EventName.PAY_BILL_FAILURE_CTA, metadata:{action:"other payment method"}})
        navigate("/paymentGateway/options")
    }
    

    return (
        <div
            style={{
                height: windowDim.height,
                backgroundColor: theme.backgroundColor,
            }}
        >
            <Header
                text='Bill Payment'
                onBack={async () => {
                    window.history.go(-1)
                }}
            />


            <div className='mainContainer' style={{ color: theme.appTextColor }}>
                <div style={{marginTop:"200px"}}  className="paymentSuccessContentContainer" >

                    <div className="paymentSuccessIcon" >
                        <img src={paymentFailIcon} />
                    </div>

                    <div className="paymentSuccessTitle" >
                    Session Expired
                    </div>

                    <div className="paymentSuccessDesc" >
                    Oops! Your session has timed out.
                    </div>
                    <div className="paymentSuccessDesc"  style={{marginTop:"1px"}} >
                     Please retry the payment
                    </div>

                </div>




            </div>
            <div className="paymentFailedBottomTextContainer" >
                <div className="paymentFailedBottomTextContainerText" >
                Can’t pay using UPI? Try  <span style={{cursor:"pointer"}} onClick={()=> retryOtherMethods() } className="paymentReqHighlight" > Other payment type </span>
                </div> 
            </div>
            <div className='paymentGatewayBtnContainer'>
                <ColorButton
                    text='Retry Now'
                    onPress={() => handleProceed()}
                />
            </div>
        </div>
    )
}

export default PaymentExpired
