import { Header } from "../../core"
import { useWindowDimensions } from "react-native-web"
import styles from "./styles.css"
import { useSelector } from "react-redux"
import { useEffect, useState } from "react"
import { Radio } from "../../../core/Radio"
import { ColorButton } from "../../core/buttons"
import { captureEvents, formatAmount } from "../../../utils/functions"
import ixigoImage from "../../../assets/images/ixigoImage.svg"
import auImage from "../../../assets/images/auImage.svg"
import upiImage from "../../../assets/images/upiImage.svg"
import AccountService from "../../../services/AccountService"
import { useNavigate } from "react-router-dom"
import { EventName } from "../../../utils/enums"



const GET_UPI_APP_NAME = {
  okaxis: "Google Pay",
  oksbi: "Google Pay",
  okhdfc: "Google Pay",
  okicici: "Google Pay",
  ybl: "Phone Pay",
  axl: "Phone Pay",
  ibl: "Phone Pay",
  paytm: "Paytm",
  upi: "BHIM",
  jio: "JIO Pay",
  freecharge: "Freecharge",
  amazonpay:"Amazon Pay",
  axisbank: "BHIM Axis Pay",
  aubank: "AU Bank"
}


const PaymentRequest = () => {
  const windowDim = useWindowDimensions()
  const [isOtherAmountSelected, setIsOtherAmountSelected] = useState(false)
  const [remainingMin, setRemainingMin] = useState(0)
  const [remainingSeconds, setRemainingSeconds] = useState(0)
  const [upiAppName, setUpiAccountName] = useState();
  
  const navigate = useNavigate()

  const [theme, user, paymentGateway] =
    useSelector(state => [
      state.theme,
      state.user,
      state.paymentGateway
    ])

  const upiFlow = paymentGateway?.pgconfig[0]?.flows[0]
  const timeFromApiInSec =  upiFlow?.sessionExpiryInSec || 300
  const INTERVAL_API_CALL = (upiFlow?.pollingInSec)*1000 || 3000
  let remainingTime = timeFromApiInSec  - 1
  const countdownTimer = () => {
    const timeInterval = setInterval(() => {
      const totalMinutes = Math.floor(remainingTime / 60)
      const totalSeconds = Math.floor(remainingTime % 60).toLocaleString(
        "en-US",
        {
          minimumIntegerDigits: 2,
          useGrouping: false,
        },
      )
      setRemainingMin(totalMinutes)
      setRemainingSeconds(totalSeconds)
      remainingTime--
      if(remainingTime < 0){
        remainingTime= 3000000
        navigate("/PaymentGateway/Expired");
      }
    }, 1000)
  }

  const options = [
    {
      text2: `Go to the  ${GET_UPI_APP_NAME[paymentGateway?.vpaId?.split("@")[1]]  } app where your UPI ID is linked with the bank account`,
    },
    {
      text2: "Approve the payment request received from AU Bank",
    },
    {
      text2: "Enter your UPI PIN to complete the payment and return to the ixigo app",
    },
  ]
  useEffect(() => {

    captureEvents({eventName:EventName.PAY_BILL_PAYMENT_REQ_PAGE, metadata:{amount:paymentGateway?.amountPayable, upiId: paymentGateway?.vpaId }})
    if(!paymentGateway?.vpaTxnRefId || !paymentGateway?.vpaId ){
      captureEvents({eventName:EventName.PAYMENT_FAILURE, metadata:{amount:paymentGateway?.amountPayable}})
      navigate('/PaymentGateway/Failed')
    }

    countdownTimer()
  }, [])


 
useEffect(() => {

  let data = {
    accountId : user?.account?.id,
    txnRefId : paymentGateway.txnRefId,
  }
  let interval;

    ;(async () => {

     interval = setInterval( async () => {
      const response =  await AccountService.getPGTransactionStatus(data);
      if(response.status == 200){
        console.log(response?.data?.data?.status)
        if(response?.data?.data?.status === "SUCCESS"){
          captureEvents({eventName:EventName.PAYMENT_SUCCESS, metadata:{amount:paymentGateway?.amountPayable}})
          navigate('/PaymentGateway/Success')
          return clearInterval(interval)
        }
        else if(response?.data?.data?.status != "PENDING"){
          captureEvents({eventName:EventName.PAYMENT_FAILURE, metadata:{amount:paymentGateway?.amountPayable}})
          clearInterval(interval)
          navigate('/PaymentGateway/Failed')
        }
      }
    }, INTERVAL_API_CALL);
  
    return () => clearInterval(interval); 
  })()

  return () => clearInterval(interval); 
}, [])

 
  const getCurrentWidth = () =>{

    let totalTime = timeFromApiInSec
    let timeRemains = Number( Number(remainingMin*60) + Number(remainingSeconds));
    let currentWidth = (timeRemains/totalTime)*100;

    return currentWidth;
  }

  return (
    <div
      style={{
        height: windowDim.height,
        backgroundColor: theme.backgroundColor,
      }}
    >
      <Header
        text='Bill Payment'
        onBack={async () => {
          window.history.go(-1)
        }}
      />
      <div className='mainContainer' style={{ color: theme.appTextColor }}>
        <div className='paymentReqTopContainer'>
          <div className='paymentReqText'>A payment request of</div>

          <div className='paymentReqAmount'>{formatAmount(paymentGateway?.amountPayable)}</div>

          <div className='paymentReqSent'>
            has been sent to{" "}
            <span className='paymentReqHighlight'> {paymentGateway.vpaId} </span>
          </div>

          <div className='paymentReqOpenAppText'>
            Open your <span className='paymentReqAppName'>{  GET_UPI_APP_NAME[paymentGateway?.vpaId?.split("@")[1]]  } app </span> to
            approve the payment before the timer runs out.
          </div>

          {/* Change width of span according to time left, width Totol should be 100%  */}
          <div className='paymentReqTimeLine'>
            <span
              style={{ width: (getCurrentWidth()+"%") }}
              className='paymentReqTimeLineColored'
            ></span>

            <span
              style={{ width: ( (100-getCurrentWidth())+"%") }}
              className='paymentReqTimeLineGreyed'
            ></span>
          </div>

          <div className='paymentReqRetryPayment'>
            {" "}
            Payment request expires in{" "}
            <span className='paymentReqHighlight'>
              {" "}
              {remainingMin && remainingMin} :{" "}
              {remainingSeconds && remainingSeconds}{" "}
            </span>
          </div>
        </div>

        <div className='optionsContainer  paymentReqStepsContainer'>
          <div className='paymentReqStepsHeading'>Steps :</div>
          {options.map((item, index) => (
            <>
              <div className='optionsContent'>
                <div className='paymentReqStepsNumContainer'>
                  <div className='paymentReqStepsNum'>{index + 1}</div>
                </div>
                <div>
                  <div className='optionsContentText paymentReqStepsText '>
                    {item.text2}
                  </div>
                </div>
              </div>
            </>
          ))}
        </div>
      </div>

      <div className="paymentReqBottomImageContainer">
        <img src={auImage} ></img>
        <img src={upiImage} ></img>
        <img src={ixigoImage} ></img>
      </div>
    </div>
  )
}

export default PaymentRequest
