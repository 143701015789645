import React, { useEffect } from "react"
import { ScrollView } from "native-base"
import { useWindowDimensions } from "react-native"
import { useSelector } from "react-redux"
import { ColorButton, WhiteButton } from "../../core/buttons"
import ErrorImage from "../../../assets/images/ServerError.svg"
import { captureEvents, goToRedirectUrl } from "../../../utils/functions"
import styles from "../../messages/styles"
import { getHeaderText, showCancelButton } from "../../../utils/auth"
import { AuthChallenges, HEADER_HEIGHT_IN_PX } from "../../../utils/constants"
import { Header } from "../../core"
import { EventName, PwaVersions } from "../../../utils/enums"
import Error from "../../svg/errorImage"

const MpinBlocked = ({ onSubmit }) => {
  const windowDimensions = useWindowDimensions()

  const [theme, config] = useSelector(state => [state.theme, state.config])

  const showHeader =
    config?.version === PwaVersions.V2 || config?.version === PwaVersions.V2_1
  const headerText = getHeaderText(AuthChallenges.MPIN)

  useEffect(() =>{
    captureEvents({eventName:EventName.MPIN_BLOCKED, metadata:{}})
  },[])

  return (
    <div
      className='mpinBlocked'
      style={{
        minHeight:
          config?.version === PwaVersions.V2 ||
          config?.version === PwaVersions.V2_1
            ? windowDimensions.height
            : null,
        backgroundColor:
          config?.version === PwaVersions.V2 ||
          config?.version === PwaVersions.V2_1
            ? theme.backgroundColor
            : null,
      }}
    >
      <ScrollView w='100%'>
        {showHeader ? (
          <Header
            text={config?.version === PwaVersions.V2_1 ? "Alert" : headerText}
            onBack={async () => {
              showCancelButton() ? window.history.go(-1) : goToRedirectUrl()
            }}
          />
        ) : (
          <div className='header-disabled'></div>
        )}
        <div
          style={{
            backgroundColor:
              config?.version === PwaVersions.V2 ||
              config?.version === PwaVersions.V2_1
                ? theme.backgroundColor
                : null,
          }}
        >
          <div className='mpinBlocked-content'>
            <div
              className='mpinBlocked-img-container'
              style={{ backgroundColor: "#f8f8f8" }}
            >
              {config?.version === PwaVersions.V2_1 ? (
                <Error />
              ) : (
                <img className='mpinBlocked-img' src={ErrorImage} />
              )}
            </div>
            <div className='mpinBlocked-text'>
              <div className='mpinBlocked-text-1' fontFamily={theme.fontFamily}>
                Access Blocked!
              </div>
              <div className='mpinBlocked-text-2'>
              {config?.version === PwaVersions.V2_1 ?
                "Your access has been restricted due to multiple incorrect attempts. Reset your mPIN to continue."
                :
                "Your access has been restricted due to multiple incorrect attempts. Reset your MPIN to continue."
              }
              </div>
            </div>
          </div>
          <div className='mpinBlocked-cta-container'>
            <div className='mpinBlocked-cta'>
              {/* <View {...styles.messageContainer}>
              <View {...styles.infoSvgContainer}>
                <Info iconProps={{ viewBox: "0 0 30 30" }} />
              </View>
            </View> */}
              <ColorButton
                text={config?.version === PwaVersions.V2_1 ? 'Reset mPIN': "Reset MPIN"}
                onPress={() => onSubmit()}
                mb='10px'
              />
              {!(
                config?.version == PwaVersions.V2 ||
                config?.version == PwaVersions.V2_1
              ) ? (
                <WhiteButton text='Go Back' onPress={() => goToRedirectUrl()} />
              ) : null}
            </div>
          </div>
        </div>
      </ScrollView>
    </div>
  )
}

export default MpinBlocked
