import moment from "moment"
import { ChevronDownIcon, ChevronUpIcon, Image, Text, View } from "native-base"
import React, { useState } from "react"
import rewardsTransactionLogo from "../../../assets/images/rewardsTransactionLogo.svg"
import {
  captureEvents,
  formatAmount,
  formatString,
  truncateString,
} from "../../../utils/functions"
import { EventName, PwaVersions } from "../../../utils/enums"
import { useSelector } from "react-redux"

const Transactions = props => {
  const [isOpen, setIsOpen] = useState(false)
  const [showDesc, setShowDesc] = useState(false)
  const [config] = useSelector(state => [state.config])
  return (
    <View py='12px' borderBottomWidth={"1px"} borderColor='#ECECEC'>
      <View
        flexDir={"row"}
        justifyContent='center'
        alignItems={"center"}
        w='100%'
      >
        {config.version !== PwaVersions.V2_1 && (
          <View
            style={
              props.fromRewards
                ? { marginLeft: "-10px", marginRight: "10px" }
                : {}
            }
            w={config.version !== PwaVersions.V2_1 ? "0%" : "15%"}
          >
            <Image
              source={{
                uri: props.transaction?.merchantLogo || rewardsTransactionLogo,
              }}
              width='48px'
              height='48px'
              alt='Alternate Text '
            />
          </View>
        )}
        <View w={config.version !== PwaVersions.V2_1 ? "100%" : "85%"}>
          <View
            onClick={() => setShowDesc(!showDesc)}
            display={"flex"}
            flexDir={"row"}
            alignItems={"start"}
            justifyContent={"space-between"}
          >
            <View w='65%'>
              <Text
                fontFamily={props.theme.fontFamily}
                fontWeight='600'
                fontSize='14px'
                lineHeight='18px'
                letterSpacing='0.02em'
                color={props.theme.appTextColor}
              >
                {showDesc
                  ? config.version === PwaVersions.V2_1
                    ? formatString(props.transaction.merchantName)
                    : formatString(props.transaction.description)
                  : truncateString(
                      config.version === PwaVersions.V2_1
                        ? formatString(props.transaction.merchantName)
                        : formatString(props.transaction.description),
                      16,
                    )}
              </Text>
            </View>
            <View>
              <Text
                fontFamily={props.theme.fontFamily}
                fontWeight='600'
                fontSize='14px'
                lineHeight='18px'
                letterSpacing='0.02em'
                color={props.theme.appTextColor}
                textAlign={"end"}
              >
                {props.transaction.status === "REVERSED" ? "- " : "+ "}
                {`${
                  Number(props.transaction.outcomeValue) ||
                  Number(props.transaction.value) ||
                  0
                } Points`}
              </Text>
            </View>
            {props.transaction.metadata?.txnAmount ? (
              <View
                w='3%'
                onClick={() => {
                  captureEvents({
                    eventName: EventName.REWARDS_HISTORY_CTA,
                    metadata: { transaction: props?.transaction },
                  })
                  setIsOpen(!isOpen)
                }}
              >
                {isOpen ? (
                  <ChevronUpIcon color={props.theme.color1} size='20px' />
                ) : (
                  <ChevronDownIcon color={props.theme.color1} size='20px' />
                )}
              </View>
            ) : (
              <></>
            )}
          </View>
          <View>
            <View>
              <Text
                fontFamily={props.theme.fontFamily}
                fontWeight='400'
                fontSize='12px'
                lineHeight='18px'
                letterSpacing='0.02em'
                color={`${props.theme.appTextColor}:alpha.70`}
              >
                {moment(
                  props.transaction.outcomePostingDate ||
                    props.transaction.postingDate,
                ).format("DD MMM YYYY")}
              </Text>
            </View>
            <View
              w={config.version !== PwaVersions.V2_1 ? "43%" : "35%"}
              display={"flex"}
              justifyContent={"flex-end"}
            >
              {props.transaction.status &&
                props.transaction.status !== "POSTED" && (
                  <View
                    p='4px'
                    borderRadius={"4px"}
                    backgroundColor={"#FEFF86"}
                  >
                    <Text
                      fontFamily={props.theme.fontFamily}
                      fontWeight='400'
                      fontSize='12px'
                      lineHeight='12px'
                      letterSpacing='0.02em'
                      color={props.theme.appTextColor}
                    >
                      Pending
                    </Text>
                  </View>
                )}
            </View>
          </View>
        </View>
      </View>
      {isOpen && (
        <View
          borderRadius={"8px"}
          background={props.theme.backgroundColor}
          borderWidth={"1px"}
          borderColor='#ECECEC'
          mt='16px'
          p='12px'
        >
          <View
            alignItems={"center"}
            justifyContent='space-between'
            flexDir={"row"}
            mt='12px'
          >
            <View>
              <Text
                fontFamily={props.theme.fontFamily}
                fontWeight='400'
                fontSize='12px'
                lineHeight='12px'
                letterSpacing='0.02em'
                color={props.theme.appTextColor}
              >
                Transaction Amount
              </Text>
            </View>
            <View>
              <Text
                fontFamily={props.theme.fontFamily}
                fontWeight='400'
                fontSize='12px'
                lineHeight='12px'
                letterSpacing='0.02em'
                color={props.theme.appTextColor}
              >
                {formatAmount(Number(props.transaction.metadata?.txnAmount))}
              </Text>
            </View>
          </View>

          <View
            alignItems={"center"}
            justifyContent='space-between'
            flexDir={"row"}
            mt='12px'
          >
            <View>
              <Text
                fontFamily={props.theme.fontFamily}
                fontWeight='400'
                fontSize='12px'
                lineHeight='12px'
                letterSpacing='0.02em'
                color={props.theme.appTextColor}
              >
                Transaction Date
              </Text>
            </View>
            <View>
              <Text
                fontFamily={props.theme.fontFamily}
                fontWeight='400'
                fontSize='12px'
                lineHeight='12px'
                letterSpacing='0.02em'
                color={props.theme.appTextColor}
              >
                {moment(props.transaction.metadata?.txnDate).format(
                  "DD MMM YYYY",
                )}
              </Text>
            </View>
          </View>

          {/* {
            <View
              alignItems={"center"}
              justifyContent='space-between'
              flexDir={"row"}
              mt='12px'
            >
              <View w='60%'>
                <Text
                  fontFamily={props.theme.fontFamily}
                  fontWeight='400'
                  fontSize='12px'
                  lineHeight='12px'
                  letterSpacing='0.02em'
                  color={props.theme.appTextColor}
                >
                  Benefit Applied
                </Text>
              </View>
              <View w='40%'>
                <Text
                  textAlign={"right"}
                  fontFamily={props.theme.fontFamily}
                  fontWeight='400'
                  fontSize='12px'
                  lineHeight='12px'
                  letterSpacing='0.02em'
                  color={props.theme.appTextColor}
                >
                  {formatString(
                    truncateString(
                      config.version === PwaVersions.V2_1
                        ? props.transaction.description
                        : props.transaction.merchantName,
                      14,
                    ),
                  )}
                </Text>
              </View>
            </View>
          } */}
        </View>
      )}
    </View>
  )
}

export default Transactions
