import React from "react"
import { Text } from "native-base"
import { View, Animated } from "react-native"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faChevronDown, faChevronUp } from "@fortawesome/free-solid-svg-icons"
import StatementAccordions from "../StatementAccordions/StatementAccordions"
import { useSelector } from "react-redux"
import style from "../styles"

const YearAccordion_v2 = ({
  yearIndex,
  year,
  statements,
  isOpened,
  toggle,
  bodyHeight,
  setBodySectionHeight,
}) => {
  const theme = useSelector(state => state.theme)
  return (
    <>
      <View
        style={{
          ...style.header,
          borderTopWidth: 1,
          borderBottomWidth: 1,
          borderRadius: 0,
          borderColor: yearIndex > 0 ? "#ECECEC" : "#FFFFFF",
        }}
      >
        <Text
          {...style.yearText}
          fontFamily={theme.fontFamily}
          fontSize='24px'
          lineHeight={"30px"}
          color={theme.appTextColor}
        >
          {year}
        </Text>
        <FontAwesomeIcon
          icon={isOpened ? faChevronUp : faChevronDown}
          size='24px'
          style={{ width: "10%", color: theme.appTextColor }}
          onClick={() => toggle()}
        />
      </View>
      <Animated.View
        style={{
          marginBottom: 15,
          overflow: "hidden",
          height: bodyHeight,
        }}
      >
        <View
          onLayout={event => {
            setBodySectionHeight(event.nativeEvent.layout.height)
          }}
        >
          <StatementAccordions yearIndex={yearIndex} statements={statements} />
        </View>
      </Animated.View>
    </>
  )
}

export default YearAccordion_v2
