const styles = {
  header: {
    paddingVertical: 8,
    borderRadius: 5,
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    borderWidth: 0,
    cursor: "pointer",
    marginBottom: 4,
  },
  emptyStateText: {
    width: "80%",
    fontSize: "16px",
    lineHeight: "20px",
    fontWeight: "600",
    color: "#303030",
    opacity: "0.64",
    textAlign: "center",
    letterSpacing: "0.02em",
  },
  toast: {
    fontWeight: "600",
    fontSize: "16px",
    lineHeight: "19px",
    letterSpacing: "0.01em",
  },
  loader: { w: "100%", alignItems: "center", justifyContent: "center" },
  yearText: { width: "85%", fontWeight: "700" },
  dataContainer: {
    backgroundColor: "#0D286C",
    marginTop: 0,
    px: "8px",
    overflow: "hidden",
  },
  mainView: {
    width: "100%",
    borderRadius: 10,
    borderWidth: 1,
    borderColor: "#f0f5fc",
  },
  periodText: {
    fontSize: "12px",
    fontFamily: "Inter",
    fontWeight: 700,
    pl: 3,
    width: "65%",
    borderWidth: 0,
  },
  periodAmount: {
    fontSize: "12px",
    fontFamily: "Inter",
    fontWeight: 400,
    textAlign: "right",
    width: "50%",
  },
  statementHeader: {
    borderWidth: 1,
    py: 2,
    width: "100%",
    backgroundColor: "#FFF",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    borderRadius: 10,
    cursor: "pointer",
    marginBottom: 1,
  },
  statementDataHeader: {
    mx: 5,
  },
  summaryHeader: {
    width: "100%",
    marginTop: 10,
    marginBottom: 10,
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
  },
  statementDetails: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    marginBottom: 10,
  },
  statementFontCommonStyles: {
    fontSize: "14px",
    fontFamily: "Inter",
    fontWeight: 400,
  },
  statementAmountFontCommonStyles: {
    fontSize: "12px",
    fontFamily: "Inter",
    fontWeight: 700,
  },
}

export default styles
