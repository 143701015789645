import moment from "moment"
import { Text, View, useToast } from "native-base"
import React, { useEffect, useState } from "react"
import { useWindowDimensions } from "react-native-web"
import { useSelector } from "react-redux"
import logo from "../../../assets/images/default-offers-logo.png"
import { DisplayConfig } from "../../../utils/constants"
import { Header } from "../../core"
import RewardIcon from "../../svg/rewards"
import OfferDetailAccordian_v2 from "./OfferDetailAccordian_v2"
import { EventName, PwaVersions } from "../../../utils/enums"
import Settings from "../../svg/settings"
import { showToast1 } from "../../core/toast"
import { captureEvents, copyToClipboard } from "../../../utils/functions"

const OfferDetails_v2 = () => {
  const windowDimensions = useWindowDimensions()
  const [show, setShow] = useState(false)
  const [theme, session, config, user] = useSelector(state => [
    state.theme,
    state.session,
    state.config,
    state.user,
  ])
  const nativeToast = useToast()
  const toast = message => {
    showToast1({ nativeToast, theme, message })
  }

  useEffect(() => {
    let data = {
      offer: session.currentOffer,
      sourcePage: session?.eventFlow,
    }
    captureEvents({ eventName: EventName.OFFER_DETAILS, metadata: data })
  }, [])
  const copy = () => {
    copyToClipboard(JSON.parse(session.currentOffer.offerDetails)?.voucherCode)
    toast("Code Copied")
  }
  console.log(
    session?.currentOffer?.benefitExpiry,
    moment(session?.currentOffer?.benefitExpiry).isAfter(moment()),
    moment(session?.currentOffer?.benefitExpiry) < moment().add(100, "years"),
    moment(session?.currentOffer?.benefitExpiry),
    moment().add(100, "years"),
  )
  const account = user.account
  const card = user.cards?.[0]
  const isAccountActive =
    account.status === "ACTIVE" || account.status === "DORMANT"
  const [isMilestoneDetailOpen, setIsMilestoneDetailOpen] = useState(false)
  return (
    <>
      <View alignItems='center' justifyContent='center'>
        <View _web={{ maxW: DisplayConfig.MAX_WIDTH }} w='100%'>
          <View
            _web={{ maxW: DisplayConfig.MAX_WIDTH }}
            w='100%'
            minH={windowDimensions.height}
            bgColor={theme.backgroundColor}
          >
            <Header
              text={"Offer Details"}
              onBack={async () => {
                window.history.go(-1)
              }}
              rightNavigateURL={isAccountActive && card ? "/ManageCard" : null}
              rightProp={<Settings />}
            />

            <View w='100%' alignItems={"center"} justifyContent='center'>
              <View
                w='90%'
                my='12px'
                bgColor={theme.widgetBackgroundColor}
                shadow={2}
                borderRadius={"12px"}
                borderWidth={"1px"}
                borderColor={"#ececec"}
              >
                <View
                  alignItems={"center"}
                  p='16px'
                  bgColor={
                    JSON.parse(session.currentOffer.offerDetails)
                      ?.usePrimaryColor
                      ? session.currentOffer.displayColor
                      : theme.widgetBackgroundColor
                  }
                  borderRadius={"12px"}
                >
                  {session.currentOffer.benefitType === "MILESTONE" ? (
                    <View>
                      <View
                        onClick={() =>
                          setIsMilestoneDetailOpen(!isMilestoneDetailOpen)
                        }
                      >
                        <RewardIcon
                          percent={
                            1 -
                            session.currentOffer.currentValue /
                              session.currentOffer.threshold
                          }
                          color={
                            session.currentOffer?.displayColor || "#B9E9FC"
                          }
                          size='160px'
                        />
                      </View>

                      {isMilestoneDetailOpen && (
                        <View
                          position={"absolute"}
                          borderRadius={"4px"}
                          p='4px'
                          borderWidth={"1px"}
                          backgroundColor={theme.widgetBackgroundColor}
                          top='20%'
                          right={"20%"}
                          borderColor='#ECECEC'
                          shadow={2}
                        >
                          <Text
                            fontWeight='600'
                            fontSize='12px'
                            fontFamily={theme.fontFamily}
                            color={theme.appTextColor}
                          >
                            {`${session.currentOffer.currentValue} out of ${session.currentOffer.threshold} completed`}
                          </Text>
                        </View>
                      )}
                    </View>
                  ) : (
                    <img
                      src={session.currentOffer.illustrationUrl || logo}
                      height='160px'
                      width={"160px"}
                      alt=''
                    />
                  )}
                </View>
                <View
                  w='100%'
                  alignItems={"center"}
                  justifyContent='center'
                  bgColor={theme.widgetBackgroundColor}
                >
                  <View w='85%' p='20px'>
                    <View>
                      <Text
                        fontWeight='700'
                        fontSize='18px'
                        fontFamily={theme.fontFamily}
                        color={theme.appTextColor}
                      >
                        {session?.currentOffer?.displayTitle ||
                          session?.currentOffer?.name}
                      </Text>
                    </View>
                    {moment(session?.currentOffer?.benefitExpiry).isAfter(
                      moment(),
                    ) && (
                      <View alignItems={"center"} flexDir='row' mt='8px'>
                        <Text
                          fontWeight='600'
                          fontSize='12px'
                          fontFamily={theme.fontFamily}
                          color={theme.appTextColor}
                        >
                          Expires on:
                        </Text>
                        <Text
                          fontWeight='400'
                          fontSize='12px'
                          fontFamily={theme.fontFamily}
                          color={theme.appTextColor}
                          ml='8px'
                        >
                          {moment(session?.currentOffer?.benefitExpiry).format(
                            "DD MMM YYYY",
                          )}
                        </Text>
                      </View>
                    )}
                  </View>
                </View>
                {JSON.parse(session.currentOffer.offerDetails)?.voucherCode && (
                  <div
                    style={{
                      width: "100%",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      marginBottom: "24px",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                        border: "1px dashed #D7DBE0",
                        padding: "8px 16px",
                        borderRadius: "8px",
                        marginTop: "12px",
                        width: "80%",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <div> Code: </div>
                        <div style={{ marginLeft: "8px" }}>
                          {!show
                            ? "*************"
                            : JSON.parse(session.currentOffer.offerDetails)
                                ?.voucherCode}
                        </div>
                      </div>
                      <div
                        onClick={() => {
                          if (!show) {
                            captureEvents({
                              eventName: EventName.OFFER_GENERATED,
                              metadata: { name: session?.currentOffer?.name },
                            })
                          } else {
                            captureEvents({
                              eventName: EventName.OFFER_DETAILS_COPY_CTA,
                              metadata: { name: session?.currentOffer?.name },
                            })
                          }
                          !show ? setShow(true) : copy()
                        }}
                        style={{ color: theme.color1, cursor: "pointer" }}
                        className='code_action_text'
                      >
                        {!show ? "Show" : "Copy"}
                      </div>
                    </div>
                  </div>
                )}
                {JSON.parse(session.currentOffer.offerDetails)?.content &&
                  JSON.parse(session.currentOffer.offerDetails)?.content.map(
                    (content, index) => (
                      <View
                        p='24px'
                        borderTopWidth={1}
                        borderColor={`${theme.appTextColor}:alpha.20`}
                      >
                        <OfferDetailAccordian_v2 data={content} index={index} />
                      </View>
                    ),
                  )}
              </View>
            </View>
          </View>
        </View>
      </View>
    </>
  )
}

export default OfferDetails_v2
