export const SET_FONT = "SET_FONT"
export const SET_DESIGN = "SET_DESIGN"
export const SET_SESSION = "SET_SESSION"
export const SET_REDIRECT_URL = "SET_REDIRECT_URL"
export const SET_ONBOARDING_URL = "SET_ONBOARDING_URL"
export const SET_REPAYMENT_DETAILS = "SET_REPAYMENT_DETAILS"
export const SET_SESSION_INACTIVE_EXPIRY = "SET_SESSION_INACTIVE_EXPIRY"
export const SET_SESSION_EXPIRY = "SET_SESSION_EXPIRY"
export const SET_USER = "SET_USER"
export const SET_SUMMARY = "SET_SUMMARY"
export const SET_CUSTOMER = "SET_CUSTOMER"
export const SET_ACCOUNT = "SET_ACCOUNT"
export const SET_CARDS = "SET_CARDS"
export const SET_OTP_STATE = "SET_OTP_STATE"
export const SET_SET_MPIN_STATE = "SET_SET_MPIN_STATE"
export const CLEAR_SET_MPIN_STATE = "CLEAR_SET_MPIN_STATE"
export const SET_SET_MPIN_API_TOKEN = "SET_SET_MPIN_API_TOKEN"
export const SET_EMI_DETAILS = "SET_EMI_DETAILS"
export const SET_SELECTED_EMI = "SET_SELECTED_EMI"
export const SET_AUTH_STATE = "SET_AUTH_STATE"
export const SET_VERIFIED_CHALLENGES = "SET_VERIFIED_CHALLENGES"
export const SET_DEVICE_ID = "SET_DEVICE_ID"
export const SET_CURRENT_FACTOR = "SET_CURRENT_FACTOR"
export const SET_AFA_DETAILS = "SET_AFA_DETAILS"
export const SET_COMMON_STATE = "SET_COMMON_STATE"
export const CLEAR_COMMON_STATE = "CLEAR_COMMON_STATE"
export const SET_SCREEN_STATE = "SET_SCREEN_STATE"
export const SET_CONFIG_STATE = "SET_CONFIG_STATE"
export const SAVE_STATE_BEFORE_SET_MPIN = "SAVE_STATE_BEFORE_SET_MPIN"
export const RESTORE_STATE_BEFORE_SET_MPIN = "RESTORE_STATE_BEFORE_SET_MPIN"
export const SET_IS_MPIN_SET = "SET_IS_MPIN_SET"
export const SET_FEATURE_FLAGS = "SET_FEATURE_FLAGS"
export const SET_CURRENT_OFFER = "SET_CURRENT_OFFER"
export const SET_DISABLED_FUNCTIONS_BLOCK_CODE =
  "SET_DISABLED_FUNCTIONS_BLOCK_CODE"
export const SET_MPIN_METADATA = "SET_MPIN_METADATA"
export const SET_FIRST_TIME_LOADED = "SET_FIRST_TIME_LOADED"
export const SET_ISSUER = "SET_ISSUER"
export const SET_REWARD = "SET_REWARD"
export const SET_SPEND_GROUP = "SET_SPEND_GROUP"
export const SET_SPEND_CATEGORY = "SET_SPEND_CATEGORY"
export const SET_CARD_REPLACEMENT_FEE = "SET_CARD_REPLACEMENT_FEE"
export const SET_REWARD_SUMMARY = "SET_REWARD_SUMMARY"
export const SET_EVENT_FLOW = "SET_EVENT_FLOW"
export const SET_PG_CONFIG = "SET_PG_CONFIG"
export const SET_PG_VPA = "SET_PG_VPA"
export const SET_PG_TRANSACTION = "SET_PG_TRANSACTION"
export const SET_PG_AMOUNT = "SET_PG_AMOUNT"
export const SET_CLIENT_PARAMS = "SET_CLIENT_PARAMS"
export const SET_ACTIVATION_CODE = "SET_ACTIVATION_CODE"
